import { useQuery } from "react-query";
import { GetDetailInvoiceResponse } from "../../constants/types";
import axios from "../axios";

const handleRequest = async (id: number) => {
    const { data } = await axios.get<GetDetailInvoiceResponse>(
        `/api/detailvoice/${id}`,
    );
    return data.data;
};

export default function useDetailInvoice(id: number) {
    return useQuery<GetDetailInvoiceResponse["data"]>(
        ["detailInvoice", id],
        () => handleRequest(id),
        { enabled: !!id },
    );
}
