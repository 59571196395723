import {
    Box,
    Grid,
    Card,
    CardContent,
    Typography,
    TextField,
    Stack,
    Link,
    IconButton,
    InputAdornment,
} from "@mui/material";
import React, { useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import axios from "../../services/axios";
import { ChangePasswordResponse, PasswordValues } from "../../constants/types";
import defaultAxios, { AxiosError } from "axios";
import { VisibilityOff, Visibility } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import Swal from "sweetalert2";
import { Link as RouterLink } from "react-router-dom";

const schema = yup
    .object({
        passwordLama: yup.string().required("Kolom wajib diisi"),
        passwordBaru: yup
            .string()
            .required("Kolom wajib diisi")
            .min(8, "Kata sandi minimal 8 karakter"),
        passwordBaruUlang: yup
            .string()
            .required("Kolom wajib diisi")
            .oneOf([yup.ref("passwordBaru")], "Kata sandi baru tidak sama"),
    })
    .required();

const CardChangePassword = () => {
    const [showPasswordLama, setShowPasswordLama] = useState(false);
    const [showPasswordBaru, setShowPasswordBaru] = useState(false);
    const [showPasswordBaruUlang, setShowPasswordBaruUlang] = useState(false);
    const [isButtonLoading, setIsButtonLoading] = useState(false);

    const initialValues = useMemo(
        () => ({
            passwordLama: "",
            passwordBaru: "",
            passwordBaruUlang: "",
        }),
        [],
    );

    const {
        handleSubmit,
        control,
        reset,
        setError,
        formState: { errors },
    } = useForm<PasswordValues>({
        resolver: yupResolver(schema),
        defaultValues: initialValues,
    });

    const onSubmit = async (values: PasswordValues) => {
        setIsButtonLoading(true);
        try {
            const { data } = await axios.post<ChangePasswordResponse>(
                `/api/user-gantipass`,
                {
                    passwordLama: values.passwordLama,
                    passwordBaru: values.passwordBaru,
                },
            );
            if (data.code === 200) {
                // assignWords("Kata sandi berhasil diubah");
                // openAlert();
                setIsButtonLoading(false);
                Swal.fire({
                    title: "Kata sandi berhasil diubah",
                    position: "top-end",
                    showConfirmButton: false,
                    icon: "success",
                    toast: true,
                    timer: 3000,
                    timerProgressBar: true,
                    showCloseButton: true,
                    customClass: {
                        container: "my-swal",
                    },
                });
            }
            reset(initialValues);
            // console.log(data.nama);
        } catch (error) {
            setIsButtonLoading(false);
            if (defaultAxios.isAxiosError(error)) {
                const serverError = error as AxiosError<
                    ChangePasswordResponse | undefined
                >;
                if (serverError && serverError?.response) {
                    const fieldError = serverError?.response?.data;
                    if (fieldError?.code === 400) {
                        const errorMessage = fieldError.message;
                        setError("passwordLama", {
                            type: "manual",
                            message: errorMessage,
                        });
                    }
                }
            }
            console.error(error);
        }
    };

    return (
        <Grid item xs={12} sm={12} md={6}>
            <Card variant="outlined" sx={{ height: "620px" }}>
                <CardContent
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                        height: "100%",
                    }}
                >
                    <Box>
                        <Typography variant="h6" fontWeight="bold">
                            Ubah Password
                        </Typography>
                        <Typography
                            variant="body2"
                            color="#545E6A"
                            sx={{
                                maxWidth: "500px",
                                marginBottom: 2,
                            }}
                        >
                            Lakukan perubahan kata sandi secara berkala untuk
                            menjaga keamanan akun Anda.
                        </Typography>
                        <Grid container alignItems="center">
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <Typography variant="body2" color="#808080">
                                    Kata Sandi Lama
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <Controller
                                    name="passwordLama"
                                    control={control}
                                    render={({ field }) => (
                                        <TextField
                                            id="passwordLama"
                                            fullWidth
                                            size="small"
                                            placeholder="Masukkan kata sandi lama"
                                            type={
                                                showPasswordLama
                                                    ? "text"
                                                    : "password"
                                            }
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            onClick={() => {
                                                                setShowPasswordLama(
                                                                    (prev) =>
                                                                        !prev,
                                                                );
                                                            }}
                                                            edge="end"
                                                        >
                                                            {showPasswordLama ? (
                                                                <VisibilityOff />
                                                            ) : (
                                                                <Visibility />
                                                            )}
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }}
                                            sx={{ maxWidth: "500px" }}
                                            error={Boolean(errors.passwordLama)}
                                            helperText={
                                                errors.passwordLama
                                                    ? errors.passwordLama
                                                          .message
                                                    : " "
                                            }
                                            {...field}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <Typography variant="body2" color="#808080">
                                    Kata Sandi Baru
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <Controller
                                    name="passwordBaru"
                                    control={control}
                                    render={({ field }) => (
                                        <TextField
                                            id="passwordBaru"
                                            fullWidth
                                            size="small"
                                            placeholder="Minimal 8 karakter"
                                            sx={{ maxWidth: "500px" }}
                                            type={
                                                showPasswordBaru
                                                    ? "text"
                                                    : "password"
                                            }
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            onClick={() => {
                                                                setShowPasswordBaru(
                                                                    (prev) =>
                                                                        !prev,
                                                                );
                                                            }}
                                                            edge="end"
                                                        >
                                                            {showPasswordBaru ? (
                                                                <VisibilityOff />
                                                            ) : (
                                                                <Visibility />
                                                            )}
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }}
                                            error={Boolean(errors.passwordBaru)}
                                            helperText={
                                                errors.passwordBaru
                                                    ? errors.passwordBaru
                                                          .message
                                                    : " "
                                            }
                                            {...field}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <Typography variant="body2" color="#808080">
                                    Ulangi Kata Sandi Baru
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <Controller
                                    name="passwordBaruUlang"
                                    control={control}
                                    render={({ field }) => (
                                        <TextField
                                            id="passwordBaruUlang"
                                            fullWidth
                                            size="small"
                                            placeholder="Minimal 8 karakter"
                                            type={
                                                showPasswordBaruUlang
                                                    ? "text"
                                                    : "password"
                                            }
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            onClick={() => {
                                                                setShowPasswordBaruUlang(
                                                                    (prev) =>
                                                                        !prev,
                                                                );
                                                            }}
                                                            edge="end"
                                                        >
                                                            {showPasswordBaruUlang ? (
                                                                <VisibilityOff />
                                                            ) : (
                                                                <Visibility />
                                                            )}
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }}
                                            sx={{ maxWidth: "500px" }}
                                            error={Boolean(
                                                errors.passwordBaruUlang,
                                            )}
                                            helperText={
                                                errors.passwordBaruUlang
                                                    ? errors.passwordBaruUlang
                                                          .message
                                                    : " "
                                            }
                                            {...field}
                                        />
                                    )}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                    <Stack
                        direction="row"
                        alignItems="center"
                        spacing={2}
                        marginTop={2}
                    >
                        <LoadingButton
                            loading={isButtonLoading}
                            loadingPosition={"center"}
                            variant="contained"
                            size="large"
                            onClick={handleSubmit(onSubmit)}
                            type="submit"
                            sx={{ width: "fit-content" }}
                        >
                            Simpan
                        </LoadingButton>
                        <Link
                            component={RouterLink}
                            to="/lupa-kata-sandi?loggedIn=true"
                            variant="body2"
                            underline="hover"
                            sx={{
                                cursor: "pointer",
                            }}
                        >
                            <Typography fontWeight="bold" variant="body2">
                                Lupa kata sandi?
                            </Typography>
                        </Link>
                    </Stack>
                </CardContent>
            </Card>
        </Grid>
    );
};

export default CardChangePassword;
