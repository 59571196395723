import {
    Dialog,
    DialogTitle,
    Stack,
    IconButton,
    Typography,
    DialogContent,
    TextField,
    Grid,
    useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import React, { useEffect, useMemo, useState } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import axios from "../../services/axios";
import { useEditKategoriModal } from "../../context/editKategoriModalContext";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
// import { useKategoriModal } from "../../context/kategoriModalContext";
import EditIcon from "@mui/icons-material/Edit";
import { ErrorFieldResponse } from "../../constants/types";
// import useUser from "../../services/queries/useUser";
import Swal from "sweetalert2";
import { LoadingButton } from "@mui/lab";
import defaultAxios, { AxiosError } from "axios";
import { useKategoriModal } from "../../context/kategoriModalContext";
import { useAuth } from "../../context/authContext";
import { useTransaksi } from "../../context/transaksiContext";

interface IModalEditSubKategoriProps {
    refetch: () => void;
}

interface IValues {
    nama: string;
}

const schema = yup
    .object({
        nama: yup.string().required("Kolom wajib diisi"),
    })
    .required();

const ModalEditSubKategori = ({ refetch }: IModalEditSubKategoriProps) => {
    const theme = useTheme();
    const isPhoneScreen = useMediaQuery(theme.breakpoints.between("xs", "sm"));
    const {
        isOpenModalEditSubKategori,
        closeModalEditSubKategori,
        namaSubKategori,
        idSubKategori,
        idKategori,
    } = useEditKategoriModal();
    const { openModalSubKategori } = useKategoriModal();
    const [isButtonLoading, setIsButtonLoading] = useState(false);
    const { ukmIdUser } = useAuth();
    const { setFetchingItems } = useTransaksi();

    const initialValues = useMemo(
        () => ({
            nama: namaSubKategori,
        }),
        [namaSubKategori],
    );

    const {
        handleSubmit,
        control,
        formState: { errors },
        reset,
        getValues,
        setError,
    } = useForm<IValues>({
        resolver: yupResolver(schema),
        defaultValues: initialValues,
    });

    const onSubmit = async (values: IValues) => {
        setIsButtonLoading(true);
        try {
            const { data } = await axios.post(`/api/subkategori-update`, {
                ukmId: ukmIdUser && ukmIdUser[0],
                kategoriId: idKategori,
                subId: idSubKategori,
                nama: values.nama,
            });
            if (data.code === 200) {
                // assignWords("Kategori berhasil diubah menjadi " + getValues("namaKategori"));
                // openAlert();
                Swal.fire({
                    title:
                        "Sub kategori berhasil diubah menjadi " +
                        getValues("nama"),
                    position: "top-end",
                    showConfirmButton: false,
                    icon: "success",
                    toast: true,
                    timer: 3000,
                    timerProgressBar: true,
                    showCloseButton: true,
                    customClass: {
                        container: "my-swal",
                    },
                });
            }
            setIsButtonLoading(false);
            refetch();
            setFetchingItems();
            closeModalEditSubKategori();
            openModalSubKategori();
        } catch (error) {
            if (defaultAxios.isAxiosError(error)) {
                const serverError = error as AxiosError<
                    ErrorFieldResponse | undefined
                >;
                if (serverError && serverError?.response) {
                    const fieldError = serverError?.response?.data;
                    if (fieldError?.errors) {
                        Object.keys(fieldError.errors).forEach((key) => {
                            const errorMessage = fieldError.errors[key];
                            setError(key as keyof typeof initialValues, {
                                type: "manual",
                                message: errorMessage[0],
                            });
                        });
                    }
                }
            }
            setIsButtonLoading(false);
            console.error(error);
            // console.log(data.nama);
        }
    };

    useEffect(() => {
        if (isOpenModalEditSubKategori) {
            if (namaSubKategori && idSubKategori && idKategori) {
                reset(initialValues);
                // console.log(initialValues);
            }
        }
    }, [
        idKategori,
        idSubKategori,
        initialValues,
        isOpenModalEditSubKategori,
        namaSubKategori,
        reset,
    ]);

    return (
        <Dialog
            maxWidth="xs"
            fullWidth={true}
            open={isOpenModalEditSubKategori}
            onClose={closeModalEditSubKategori}
            PaperProps={{
                sx: { borderRadius: 1 },
            }}
        >
            <DialogTitle>
                <Stack direction="row" spacing={1} alignItems="center">
                    <IconButton onClick={closeModalEditSubKategori}>
                        <ArrowBackIcon />
                    </IconButton>
                    <Typography variant="h6" fontWeight="bold">
                        Edit Sub Kategori
                    </Typography>
                </Stack>
            </DialogTitle>
            <DialogContent>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Grid container spacing={1}>
                        <Grid item xs={8} sm={9}>
                            <Controller
                                name="nama"
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        autoFocus
                                        margin="dense"
                                        id="nama"
                                        fullWidth
                                        size="small"
                                        variant="outlined"
                                        error={Boolean(errors.nama)}
                                        helperText={
                                            errors.nama
                                                ? errors.nama.message
                                                : " "
                                        }
                                        {...field}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={4} sm={3}>
                            <LoadingButton
                                loading={isButtonLoading}
                                loadingPosition="center"
                                variant="contained"
                                fullWidth
                                size="large"
                                type="submit"
                                onClick={handleSubmit(onSubmit)}
                                sx={{ top: 8, height: "40px" }}
                            >
                                {isPhoneScreen ? <EditIcon /> : "Simpan"}
                            </LoadingButton>
                        </Grid>
                    </Grid>
                </form>
            </DialogContent>
        </Dialog>
    );
};

export default ModalEditSubKategori;
