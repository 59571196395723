import React from "react";
import { Box, Button, Typography, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import ThemeDefaultComponent from "../ThemeDefaultComponent/ThemeDefaultComponent";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useHistory } from "react-router-dom";

interface IVerifikasiEmailProps {}

const VerifikasiEmail = (props: IVerifikasiEmailProps) => {
    const theme = useTheme();
    const isTabletScreen = useMediaQuery(theme.breakpoints.down("md"));
    const history = useHistory();

    return (
        <ThemeDefaultComponent>
            <Box
                sx={{
                    width: isTabletScreen ? "100%" : undefined,
                    height: isTabletScreen ? "100%" : undefined,
                    display: "flex",
                    flexDirection: "column",
                    gap: 6,
                    justifyContent: "center",
                    alignItems: "center",
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    backgroundColor: "#FFF",
                    borderRadius: 1,
                    padding: isTabletScreen ? 3 : 9,
                }}
            >
                <CheckCircleIcon
                    htmlColor="#45A779"
                    sx={{ width: "100px", height: "100px" }}
                />
                <Box display="flex" flexDirection="column" gap={1}>
                    <Typography
                        variant="h5"
                        fontWeight="bold"
                        textAlign="center"
                    >
                        Cek email anda untuk verifikasi
                    </Typography>
                    <Typography
                        variant="body1"
                        color="#808080"
                        textAlign="center"
                    >
                        Silahkan verifikasi akun anda dengan cara mengklik link
                        yang kami kirimkan ke email anda.
                    </Typography>
                </Box>
                <Button variant="contained" onClick={() => history.push("/")}>
                    Kembali ke Login
                </Button>
            </Box>
        </ThemeDefaultComponent>
    );
};

export default VerifikasiEmail;
