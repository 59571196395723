import React, { useEffect, useState } from "react";
import { useAuth } from "../../context/authContext";
import useMetodePembayaran from "../../services/queries/useMetodePembayaran";
import { IOptionsUKM } from "../../constants/types";
import { useTransaksi } from "../../context/transaksiContext";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Tooltip from "@mui/material/Tooltip";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Visibility from "@mui/icons-material/Visibility";
import useUkm from "../../services/queries/useUkm";
import Edit from "@mui/icons-material/Edit";
import Delete from "@mui/icons-material/Delete";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Add from "@mui/icons-material/Add";
import ImportExport from "@mui/icons-material/ImportExport";
import NoRowsImage from "../../components/NoRowsImage/NoRowsImage";
import LoadingTabel from "../../components/LoadingTabel/LoadingTabel";
import ModalDetailMetodePembayaran from "../../components/ModalDetailMetodePembayaran/ModalDetailMetodePembayaran";
import ModalMetodePembayaran from "../../components/ModalMetodePembayaran/ModalMetodePembayaran";
import ModalDeleteMetodePembayaran from "../../components/ModalDeleteMetodePembayaran/ModalDeleteMetodePembayaran";

interface IDataMetodePembayaran {
    metodeId?: number;
    namaMetode: string;
    noTujuan: string;
    namaPemegang: string;
    gambar: string | null;
    isCharge: boolean;
    ukm?: IOptionsUKM;
    charge: { mode: string | null; charge: number };
}

interface IDataDeleteMetodePembayaran {
    idMetodePembayaran: string;
    namaMetodePembayaran: string;
}

const MetodePembayaranPage = () => {
    const theme = useTheme();
    const isPhoneScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const { ukmIdUser } = useAuth();
    const [
        isOpenModalDetailMetodePembayaran,
        setIsOpenModalDetailMetodePembayaran,
    ] = useState(false);
    const [isOpenModalMetodePembayaran, setIsOpenModalMetodePembayaran] =
        useState(false);
    const [
        isOpenModalDeleteMetodePembayaran,
        setIsOpenModalDeleteMetodePembayaran,
    ] = useState(false);

    const initalParamsUkm = {
        page: 1,
        search: "",
        size: 500,
    };
    const { data: dataUkm } = useUkm(initalParamsUkm);
    const initialParamsMetodePembayaran = {
        ukmId: Number(ukmIdUser && ukmIdUser[0]),
    };
    const { data: metodePembayaran, refetch: refetchMetodePembayaran } =
        useMetodePembayaran(initialParamsMetodePembayaran.ukmId);
    const [dataMetodePembayaran, setDataMetodePembayaran] =
        useState<IDataMetodePembayaran>();
    const [dataDeleteMetodePembayaran, setDataDeleteMetodePembayaran] =
        useState<IDataDeleteMetodePembayaran>();
    const { isFetchingItems, cancelFetchingItems } = useTransaksi();

    const openModalDetailPembayaran = (
        dataMetodePembayaran: IDataMetodePembayaran,
    ) => {
        setDataMetodePembayaran(dataMetodePembayaran);
        setIsOpenModalDetailMetodePembayaran(true);
    };
    const closeModalDetailMetodePembayaran = () =>
        setIsOpenModalDetailMetodePembayaran(false);

    const openModalMetodePembayaran = (
        dataMetodePembayaran?: IDataMetodePembayaran,
    ) => {
        setDataMetodePembayaran(dataMetodePembayaran);
        setIsOpenModalMetodePembayaran(true);
    };
    const closeModalMetodePembayaran = () =>
        setIsOpenModalMetodePembayaran(false);

    const openModalDeleteMetodePembayaran = () =>
        setIsOpenModalDeleteMetodePembayaran(true);
    const closeModalDeleteMetodePembayaran = () =>
        setIsOpenModalDeleteMetodePembayaran(false);

    const columns: GridColDef[] = [
        {
            field: "namaMetode",
            headerName: "Nama Metode",
            flex: isPhoneScreen ? undefined : 1.5,
            minWidth: 270,
            renderCell: (params) => (
                <Tooltip title={params.value}>
                    <span className="table-cell-trucate">{params.value}</span>
                </Tooltip>
            ),
        },
        {
            field: "noTujuan",
            headerName: "No. Tujuan",
            flex: isPhoneScreen ? undefined : 1,
            minWidth: 160,
            renderCell: (params) => (
                <Tooltip title={params.value}>
                    <span className="table-cell-trucate">{params.value}</span>
                </Tooltip>
            ),
        },
        {
            field: "namaPemegang",
            headerName: "Nama Pemegang",
            flex: isPhoneScreen ? undefined : 1.5,
            minWidth: 270,
            renderCell: (params) => (
                <Tooltip title={params.value}>
                    <span className="table-cell-trucate">{params.value}</span>
                </Tooltip>
            ),
        },
        {
            field: "aksi",
            headerName: "Aksi",
            align: "center",
            minWidth: 160,
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params) => {
                return (
                    <Stack direction="row" spacing={3}>
                        <Button
                            variant="contained"
                            onClick={() => {
                                openModalDetailPembayaran({
                                    gambar: params.row.gambar,
                                    namaMetode: params.row.namaMetode,
                                    namaPemegang: params.row.namaPemegang,
                                    noTujuan: params.row.noTujuan,
                                    ukm: undefined,
                                    charge: params.row.charge,
                                    isCharge: params.row.charge.charge
                                        ? true
                                        : false,
                                });
                            }}
                            size="small"
                            color="buttongreen"
                            disableElevation
                            sx={{
                                minWidth: "unset",
                                padding: "8px",
                                width: "32px",
                                height: "32px",
                            }}
                        >
                            <Visibility
                                sx={{ width: "16px", height: "16px" }}
                            />
                        </Button>
                        <Button
                            variant="contained"
                            onClick={() =>
                                openModalMetodePembayaran({
                                    metodeId: params.row.id,
                                    ukm: {
                                        id: params.row.ukmId.id,
                                        nama: String(
                                            dataUkm?.content.find(
                                                (li) =>
                                                    li.id ===
                                                    params.row.ukmId.id,
                                            )?.namaToko,
                                        ),
                                    },
                                    namaMetode: params.row.namaMetode,
                                    namaPemegang: params.row.namaPemegang,
                                    noTujuan: params.row.noTujuan,
                                    gambar: null,
                                    charge: params.row.charge,
                                    isCharge: params.row.charge.charge
                                        ? true
                                        : false,
                                })
                            }
                            size="small"
                            color="buttonyellow"
                            disableElevation
                            sx={{
                                minWidth: "unset",
                                padding: "8px",
                                width: "32px",
                                height: "32px",
                            }}
                        >
                            <Edit sx={{ width: "16px", height: "16px" }} />
                        </Button>
                        <Button
                            variant="contained"
                            onClick={() => {
                                setDataDeleteMetodePembayaran({
                                    idMetodePembayaran: params.row.id,
                                    namaMetodePembayaran: params.row.namaMetode,
                                });
                                openModalDeleteMetodePembayaran();
                            }}
                            size="small"
                            color="buttonred"
                            disableElevation
                            sx={{
                                minWidth: "unset",
                                padding: "8px",
                                width: "32px",
                                height: "32px",
                            }}
                        >
                            <Delete sx={{ width: "16px", height: "16px" }} />
                        </Button>
                    </Stack>
                );
            },
        },
    ];

    useEffect(() => {
        if (isFetchingItems === true) {
            refetchMetodePembayaran();
            cancelFetchingItems();
        }
    }, [cancelFetchingItems, isFetchingItems, refetchMetodePembayaran]);

    return (
        <Box>
            <Box>
                <Stack spacing={4} marginY={2}>
                    <Typography variant="h6" fontWeight="bold">
                        Metode Pembayaran
                    </Typography>
                    <Stack spacing={2}>
                        <Stack direction="row" justifyContent="flex-end">
                            <Button
                                variant="contained"
                                startIcon={<Add />}
                                disableElevation
                                onClick={() => openModalMetodePembayaran()}
                            >
                                Tambah
                            </Button>
                        </Stack>
                        <Box
                            sx={{
                                height: 631,
                                width: "100%",
                                borderRadius: 1,
                                "& .MuiDataGrid-cell:focus-within": {
                                    outline: "none !important",
                                },
                                "& .MuiDataGrid-iconButtonContainer": {
                                    visibility: "visible",
                                    width: "0 !important",
                                },
                            }}
                        >
                            <DataGrid
                                pagination
                                rows={metodePembayaran ?? []}
                                columns={columns}
                                components={{
                                    ColumnUnsortedIcon: () => {
                                        return <ImportExport />;
                                    },
                                    NoRowsOverlay: NoRowsImage,
                                    LoadingOverlay: LoadingTabel,
                                }}
                                disableColumnMenu
                                disableSelectionOnClick
                                rowsPerPageOptions={[10]}
                                pageSize={10}
                            />
                        </Box>
                    </Stack>
                </Stack>
            </Box>
            <ModalDetailMetodePembayaran
                isOpenModalDetailMetodePembayaran={
                    isOpenModalDetailMetodePembayaran
                }
                closeModalDetailMetodePembayaran={
                    closeModalDetailMetodePembayaran
                }
                dataMetodePembayaran={dataMetodePembayaran}
            />
            <ModalMetodePembayaran
                isOpenModalMetodePembayaran={isOpenModalMetodePembayaran}
                closeModalMetodePembayaran={closeModalMetodePembayaran}
                dataMetodePembayaran={dataMetodePembayaran}
            />
            {dataDeleteMetodePembayaran && (
                <ModalDeleteMetodePembayaran
                    isOpenModalDeleteMetodePembayaran={
                        isOpenModalDeleteMetodePembayaran
                    }
                    closeModalDeleteMetodePembayaran={
                        closeModalDeleteMetodePembayaran
                    }
                    idMetodePembayaran={
                        dataDeleteMetodePembayaran.idMetodePembayaran
                    }
                    namaMetodePembayaran={
                        dataDeleteMetodePembayaran.namaMetodePembayaran
                    }
                />
            )}
        </Box>
    );
};

export default MetodePembayaranPage;
