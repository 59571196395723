import { useQuery } from "react-query";
import {
    ParamsGetLaporanTotal,
    GetLaporanTotalResponse,
} from "../../constants/types";
import axios from "../axios";
import qs from "query-string";

const handleRequest = async (params: ParamsGetLaporanTotal) => {
    const apiGetLaporanTotal = qs.stringifyUrl({
        url: "api/laporanindex",
        query: {
            ukmId: params.ukmId,
            tanggalAwal: params.tanggalAwal,
            tanggalAkhir: params.tanggalAkhir,
        },
    });
    const { data } = await axios.get<GetLaporanTotalResponse>(
        apiGetLaporanTotal,
    );
    return data.data;
};

export default function useLaporanTotal(params: ParamsGetLaporanTotal) {
    return useQuery<GetLaporanTotalResponse["data"]>(
        ["laporanTotal", params],
        () => handleRequest(params),
    );
}
