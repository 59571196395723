import React from "react";
import Box from "@mui/material/Box";
import TabelBarang from "../../components/TabelBarang/TabelBarang";
import ModalImport from "../../components/ModalImport/ModalImport";
import ModalKatalog from "../../components/ModalKatalog/ModalKatalog";
import ModalKategori from "../../components/ModalKategori/ModalKategori";
import ModalPrintBarcode from "../../components/ModalPrintBarcode/ModalPrintBarcode";
import ModalEditKategori from "../../components/ModalEditKategori/ModalEditKategori";
import ModalDeleteKategori from "../../components/ModalDeleteKategori/ModalDeleteKategori";
import { useAuth } from "../../context/authContext";
import { getLocalUkmId } from "../../utils/handleChangeUkmId";
import useUkm from "../../services/queries/useUkm";
import useUser from "../../services/queries/useUser";
import useKategori from "../../services/queries/useKategori";
import moment from "moment";
import "moment/locale/id";
import ModalSubKategori from "../../components/ModalKategori/ModalSubKategori";
import ModalDeleteSubKategori from "../../components/ModalDeleteKategori/ModalDeleteSubKategori";
import useSubKategori from "../../services/queries/useSubKategori";
import ModalEditSubKategori from "../../components/ModalEditKategori/ModalEditSubKategori";
import ModalUnggahKategori from "../../components/ModalUnggahKategori/ModalUnggahKategori";

moment.locales();
moment.locale("id");

interface IStokBarangProps {}

const StokBarang = (props: IStokBarangProps) => {
    const { ukmIdUser } = useAuth();
    const initialParamsKategori = {
        ukmId: Number(ukmIdUser && ukmIdUser[0]),
        size: 500,
        page: 1,
        search: "",
    };
    const { refetch: refetchKategori } = useKategori(initialParamsKategori);
    const { refetch: refetchSubKategori } = useSubKategori(
        initialParamsKategori,
    );

    const initialParamsUkm = {
        size: 500,
        page: 1,
        search: "",
    };
    const { data: ukm } = useUkm(initialParamsUkm);
    const { data: user } = useUser();

    const ukmId = React.useMemo(() => {
        if (user?.userId) {
            return getLocalUkmId(user?.userId);
        }
        return null;
    }, [user?.userId]);

    const ukmData = React.useMemo(() => {
        if (ukmId) {
            return ukm?.content.find((li) => li.id === ukmId);
        }
        return ukm?.content[0];
    }, [ukm?.content, ukmId]);

    const refetchBoth = () => {
        refetchKategori();
        refetchSubKategori();
    };

    // const isSudahJatuhTempo = moment(time?.datetime).isAfter(
    //     ukmData?.pembayaranBerikutnya,
    // );

    // if (user?.aksesStok === 0 || user?.aksesStok === null) {
    //     return <TokoTidakAktif label="Anda tidak memiliki akses halaman ini" />;
    // }

    // if (
    //     ukmData &&
    //     (ukmData?.isActive === 0 ||
    //         ukmData?.isActive === null ||
    //         ukmData?.lewatTempoPro === 1 ||
    //         isSudahJatuhTempo)
    // ) {
    //     return <TokoTidakAktif namaToko={ukmData.namaToko} />;
    // }

    return (
        <Box>
            <TabelBarang />
            <ModalKategori refetch={refetchKategori} />
            <ModalSubKategori />
            <ModalDeleteKategori refetch={refetchKategori} />
            <ModalDeleteSubKategori refetch={refetchSubKategori} />
            <ModalEditKategori refetch={refetchKategori} />
            <ModalEditSubKategori refetch={refetchSubKategori} />
            <ModalKatalog namaUkm={ukmData?.namaToko} />
            <ModalImport />
            <ModalPrintBarcode />
            <ModalUnggahKategori refetch={refetchBoth} ukmData={ukmData} />
        </Box>
    );
};

export default StokBarang;
