import React, { useEffect, useMemo, useState } from "react";
import {
    ContentGetStokOpnameResponse,
    ProsesOpnameValues,
} from "../../../constants/types";
import { useTransaksi } from "../../../context/transaksiContext";
import { FormProvider, useForm } from "react-hook-form";
import axios from "../../../services/axios";
import Swal from "sweetalert2";
import Box from "@mui/material/Box";
import LoadingButton from "@mui/lab/LoadingButton";
import OpnameTerpilih from "./OpnameTerpilih";
import Typography from "@mui/material/Typography";
import moment from "moment";

interface IDetailOpnameStepProps {
    opname: ContentGetStokOpnameResponse[];
    open: boolean;
    onClose: () => void;
    tanggal: string;
}

const DetailOpnameStep = ({
    opname,
    open,
    onClose,
    tanggal,
}: IDetailOpnameStepProps) => {
    const [isButtonLoading, setIsButtonLoading] = useState(false);
    const { setFetchingItems } = useTransaksi();

    const initialValues: ProsesOpnameValues = useMemo(
        () => ({
            details: opname.map((li) => ({
                opnameId: li.id,
                jumlahOpname: "",
            })) ?? [
                {
                    opnameId: 0,
                    jumlahOpname: 0,
                },
            ],
        }),
        [opname],
    );

    const methods = useForm<ProsesOpnameValues>({
        defaultValues: initialValues,
    });

    const onSubmit = async (values: ProsesOpnameValues) => {
        setIsButtonLoading(true);
        try {
            const body = {
                details: values.details.map((li) => ({
                    opnameId: li.opnameId,
                    jumlahOpname: String(li.jumlahOpname),
                })),
            };
            const { data } = await axios.post(`/api/opname-proses`, body);
            if (data.code === 200) {
                Swal.fire({
                    title: data.message ?? "Proses opname berhasil",
                    position: "top-end",
                    showConfirmButton: false,
                    icon: "success",
                    toast: true,
                    timer: 3000,
                    timerProgressBar: true,
                    showCloseButton: true,
                    customClass: {
                        container: "my-swal",
                    },
                });
                onClose();
                setFetchingItems();
            }
            setIsButtonLoading(false);
        } catch (error) {
            setIsButtonLoading(false);
            console.error(error);
        }
    };

    useEffect(() => {
        if (open) {
            methods.reset(initialValues);
        }
    }, [initialValues, methods, open]);

    return (
        <React.Fragment>
            <Typography component="div" display="block" fontWeight={700}>
                Tanggal:{" "}
                <Typography display="inline" fontWeight={400}>
                    {moment(tanggal).format("LL")}
                </Typography>
            </Typography>
            <Box
                mt={2}
                mb={5}
                display="grid"
                gridTemplateColumns="repeat(auto-fill, minmax(200px, 1fr))"
                gap={3}
            >
                {opname &&
                    opname?.map((li, idx) => (
                        <FormProvider key={idx.toString()} {...methods}>
                            <OpnameTerpilih
                                key={String(li.id)}
                                data={li}
                                idx={idx}
                            />
                        </FormProvider>
                    ))}
            </Box>
            <Box
                mt={4}
                display="flex"
                justifyContent="flex-end"
                columnGap="16px"
            >
                <LoadingButton
                    loading={isButtonLoading}
                    loadingPosition="center"
                    variant="contained"
                    onClick={methods.handleSubmit(onSubmit)}
                >
                    Simpan
                </LoadingButton>
            </Box>
        </React.Fragment>
    );
};

export default DetailOpnameStep;
