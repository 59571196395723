import { useQuery } from "react-query";
import {
    ParamsGetProdukTerjual,
    GetProdukTerjualResponse,
} from "../../constants/types";
import axios from "../axios";

const queryString = require("query-string");

const handleRequest = async (params: ParamsGetProdukTerjual) => {
    const apiGetProdukterjual = queryString.stringifyUrl({
        url: "api/stok-terjual",
        query: {
            ukmId: params.ukmId,
            search: params.search,
            size: params.size,
            page: params.page,
            tanggalAwal: params.tanggalAwal,
            tanggalAkhir: params.tanggalAkhir,
        },
    });
    const { data } = await axios.get<GetProdukTerjualResponse>(
        apiGetProdukterjual,
    );
    return data.data;
};

export default function useProdukTerjual(params: ParamsGetProdukTerjual) {
    return useQuery<GetProdukTerjualResponse["data"]>(
        ["produkTerjual", params],
        () => handleRequest(params),
    );
}
