import { useQuery } from "react-query";
import {
    ParamsGetStokOpname,
    GetStokOpnameResponse,
} from "../../constants/types";
import axios from "../axios";
import qs from "query-string";

const handleRequest = async (params: ParamsGetStokOpname) => {
    const apiUrl = qs.stringifyUrl({
        url: "api/opname",
        query: {
            ukmId: params.ukmId,
            size: params.size,
            page: params.page,
            tanggal: params.tanggal,
        },
    });
    const { data } = await axios.get<GetStokOpnameResponse>(apiUrl);
    return data.data;
};

export default function useFakturPembelian(params: ParamsGetStokOpname) {
    return useQuery<GetStokOpnameResponse["data"]>(
        ["stokOpname", params],
        () => handleRequest(params),
        {
            enabled: !!params?.ukmId,
        },
    );
}
