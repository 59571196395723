const toRupiahDesimal = (num: number) => {
    return (
        "Rp. " +
        num
            .toFixed(2)
            .replace(".", ",")
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")
    );
};

export default toRupiahDesimal;
