export const addDays = (numOfDays: number, date = new Date()) => {
    date.setDate(date.getDate() + numOfDays);

    return date;
};

export const addMonths = (numOfMonths: number, date = new Date()) => {
    date.setMonth(date.getMonth() + numOfMonths);

    return date;
};

export const addMonthsCustom = (numOfMonths: number, customDate: Date) => {
    customDate.setMonth(customDate.getMonth() + numOfMonths);

    return customDate;
};

export const addYears = (numOfYears: number, date = new Date()) => {
    date.setFullYear(date.getFullYear() + numOfYears);

    return date;
};
