import { useQuery } from "react-query";
import { UserResponse } from "../../constants/types";
import axios from "../axios";

const handleRequest = async () => {
    const { data } = await axios.get<UserResponse>("/api/user-detail");
    return data.data;
};

export default function useUser() {
    return useQuery<UserResponse["data"]>("user", handleRequest);
}
