import {
    Dialog,
    DialogTitle,
    Stack,
    IconButton,
    Typography,
    DialogContent,
    Box,
    Grid,
    TextField,
    useMediaQuery,
    Button,
    InputAdornment,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import React, { Fragment, useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import axios from "../../services/axios";
import { LoadingButton } from "@mui/lab";
import { useUkmModal } from "../../context/ukmModalContext";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { EditUkmResponse, ModalUkmField } from "../../constants/types";
import { SketchPicker } from "react-color";
import { defaultColor } from "../../constants/common";
import Swal from "sweetalert2";

interface IModalUkmProps {
    refetchUkm: () => void;
    refetchUser: () => void;
}

const schema = yup.object({
    namaToko: yup
        .string()
        .required("Kolom wajib diisi")
        .max(50, "Nama terlalu panjang"),
    noTelp: yup
        .string()
        .required("Kolom wajib diisi")
        .matches(/^8[1-9][0-9]/, "No. Telepon tidak valid, contoh: 831xxxx")
        .min(9, "No. Telepon terlalu pendek")
        .max(13, "No. Telepon terlalu panjang"),
    alamatToko: yup
        .string()
        .required("Kolom wajib diisi")
        .max(500, "Alamat terlalu panjang"),
    gambarToko: yup.mixed(),
});

const ModalUkm = ({ refetchUkm, refetchUser }: IModalUkmProps) => {
    const theme = useTheme();
    const isPhoneScreen = useMediaQuery(theme.breakpoints.between("xs", "sm"));
    const isTabletScreen = useMediaQuery(theme.breakpoints.up("md"));
    const { isOpenModalUkm, closeModalUkm, dataUkm } = useUkmModal();
    const [isButtonLoading, setIsButtonLoading] = useState(false);
    const [displayColorPicker, setDisplayColorPicker] = useState(false);

    const initialValues = useMemo(
        () => ({
            id: dataUkm?.id,
            namaToko: "",
            noTelp: "",
            alamatToko: "",
            warna: defaultColor,
            gambarToko: null,
        }),
        [dataUkm?.id],
    );

    const {
        handleSubmit,
        control,
        formState: { errors },
        setValue,
        reset,
        setError,
        clearErrors,
        watch,
    } = useForm<ModalUkmField>({
        resolver: yupResolver(schema),
        defaultValues: initialValues,
    });

    const watchAlamat = watch("alamatToko");

    const onSubmit = async (values: ModalUkmField) => {
        setIsButtonLoading(true);
        const formattedNoTelp = "62" + values.noTelp;
        if (dataUkm) {
            try {
                const formData = new FormData();
                formData.append("nama", values.namaToko);
                formData.append("alamat", values.alamatToko);
                formData.append("noTelp", formattedNoTelp);
                formData.append("warna", values.warna);
                if (values.gambarToko) {
                    formData.append(
                        "gambar",
                        values.gambarToko ? (values.gambarToko as any) : "",
                    );
                }
                formData.append("ukmId", String(values.id));

                const { data } = await axios.post<EditUkmResponse>(
                    `/api/ukm-update`,
                    formData,
                    {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                    },
                );
                if (data.code === 200) {
                    Swal.fire({
                        title: data.message ?? "Informasi ukm telah diubah",
                        position: "top-end",
                        showConfirmButton: false,
                        icon: "success",
                        toast: true,
                        timer: 3000,
                        timerProgressBar: true,
                        showCloseButton: true,
                        customClass: {
                            container: "my-swal",
                        },
                    });
                    window.location.reload();
                    refetchUkm();
                    refetchUser();
                    // closeModalUkm();
                }
                setIsButtonLoading(false);
            } catch (error) {
                setIsButtonLoading(false);
                console.error(error);
            }
        }
    };

    useEffect(() => {
        if (isOpenModalUkm) {
            if (dataUkm) {
                reset({
                    ...dataUkm,
                    alamatToko:
                        dataUkm.alamatToko === "-" || !!!dataUkm.alamatToko
                            ? ""
                            : dataUkm.alamatToko,
                });
            } else {
                reset(initialValues);
            }
        }
    }, [initialValues, isOpenModalUkm, dataUkm, reset]);

    return (
        <Dialog
            maxWidth="sm"
            fullScreen={isPhoneScreen}
            fullWidth={true}
            open={isOpenModalUkm}
            onClose={closeModalUkm}
            scroll="body"
            PaperProps={{
                sx: {
                    borderRadius: 1,
                },
            }}
        >
            <DialogTitle>
                <Stack direction="row" spacing={1} alignItems="center">
                    <IconButton onClick={closeModalUkm}>
                        <ArrowBackIcon />
                    </IconButton>
                    <Typography variant="h6" fontWeight="bold">
                        {dataUkm ? "Ubah UKM" : "Tambah UKM"}
                    </Typography>
                </Stack>
            </DialogTitle>
            <DialogContent>
                {/* <code>{JSON.stringify(values)}</code> */}
                <Fragment>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12}>
                            <Typography variant="body2" color="#808080">
                                Nama UKM
                            </Typography>
                            <Controller
                                name="namaToko"
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        // margin="dense"
                                        id="namaToko"
                                        autoFocus
                                        placeholder="Contoh: UKM Budi Jaya"
                                        size="small"
                                        fullWidth
                                        variant="outlined"
                                        error={Boolean(errors.namaToko)}
                                        helperText={
                                            errors.namaToko
                                                ? errors.namaToko.message
                                                : ""
                                        }
                                        {...field}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Typography variant="body2" color="#808080">
                                No. Telepon
                            </Typography>
                            <Controller
                                name="noTelp"
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        // margin="dense"
                                        id="noTelp"
                                        placeholder="831xxxxxx"
                                        size="small"
                                        type="number"
                                        onWheel={(e) =>
                                            (e.target as HTMLElement).blur()
                                        }
                                        fullWidth
                                        variant="outlined"
                                        error={Boolean(errors.noTelp)}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment
                                                    position="start"
                                                    sx={{
                                                        marginTop: "-2px",
                                                        color: "#000",
                                                    }}
                                                >
                                                    <Typography>+62</Typography>
                                                </InputAdornment>
                                            ),
                                        }}
                                        helperText={
                                            errors.noTelp
                                                ? errors.noTelp.message
                                                : ""
                                        }
                                        {...field}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Stack
                                direction="row"
                                alignItems="center"
                                spacing={1}
                            >
                                <Typography variant="body2" color="#808080">
                                    Alamat
                                </Typography>
                                {!!!watchAlamat && (
                                    <Box
                                        sx={{
                                            width: "8px",
                                            height: "8px",
                                            borderRadius: "50%",
                                            backgroundColor: "#fa4c46",
                                            color: "#fa4c46",
                                            boxShadow: `0 0 0 2px #fff`,
                                            "&::after": {
                                                position: "absolute",
                                                width: "8px",
                                                height: "8px",
                                                borderRadius: "50%",
                                                animation:
                                                    "ripple 1.2s infinite ease-in-out",
                                                border: "1px solid currentColor",
                                                content: '""',
                                            },

                                            "@keyframes ripple": {
                                                "0%": {
                                                    transform: "scale(.8)",
                                                    opacity: 1,
                                                },
                                                "100%": {
                                                    transform: "scale(2.4)",
                                                    opacity: 0,
                                                },
                                            },
                                        }}
                                    />
                                )}
                            </Stack>
                            <Controller
                                name="alamatToko"
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        // margin="dense"
                                        id="alamatToko"
                                        placeholder="Contoh: Jalan Maju Jaya no. 93 Semarang"
                                        fullWidth
                                        size="small"
                                        variant="outlined"
                                        multiline
                                        rows={4}
                                        error={Boolean(errors.alamatToko)}
                                        helperText={
                                            errors.alamatToko
                                                ? errors.alamatToko.message
                                                : ""
                                        }
                                        {...field}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Typography variant="body2" color="#808080">
                                Warna Utama
                            </Typography>
                            <Stack
                                direction="row"
                                alignItems="center"
                                spacing={2}
                                marginTop={1}
                                marginBottom={2}
                            >
                                <Controller
                                    name="warna"
                                    control={control}
                                    render={({ field }) => (
                                        <Box>
                                            <Box
                                                onClick={() =>
                                                    setDisplayColorPicker(
                                                        (prev) => !prev,
                                                    )
                                                }
                                                sx={{
                                                    width: "54px",
                                                    height: "54px",
                                                    borderRadius: "10px",
                                                    background: `${field.value}`,
                                                    cursor: "pointer",
                                                }}
                                            />
                                            {displayColorPicker ? (
                                                <Box
                                                    sx={{
                                                        position: "absolute",
                                                        bottom: "170px",
                                                        left: "80px",
                                                    }}
                                                >
                                                    <Box
                                                        sx={{
                                                            position: "fixed",
                                                            top: "0px",
                                                            right: "0px",
                                                            bottom: "0px",
                                                            left: "0px",
                                                        }}
                                                        onClick={() =>
                                                            setDisplayColorPicker(
                                                                false,
                                                            )
                                                        }
                                                    />
                                                    <SketchPicker
                                                        color={field.value}
                                                        onChange={(color) =>
                                                            field.onChange(
                                                                color.hex,
                                                            )
                                                        }
                                                    />
                                                </Box>
                                            ) : null}
                                        </Box>
                                    )}
                                />
                                <Typography
                                    variant="subtitle2"
                                    fontWeight={400}
                                    sx={{ maxWidth: "130px" }}
                                >
                                    Klik persegi untuk pilih warna
                                </Typography>
                            </Stack>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Typography variant="body2" color="#808080">
                                Logo (opsional)
                            </Typography>
                            <Box>
                                <Controller
                                    name="gambarToko"
                                    control={control}
                                    render={({ field }) => (
                                        <TextField
                                            id="gambarToko"
                                            fullWidth
                                            type="file"
                                            variant="outlined"
                                            inputProps={{
                                                accept: "image/*",
                                            }}
                                            error={Boolean(errors.gambarToko)}
                                            helperText={
                                                errors.gambarToko
                                                    ? errors.gambarToko.message
                                                    : ""
                                            }
                                            {...field}
                                            onChange={(event: any) => {
                                                if (
                                                    event.target.files[0].size <
                                                    2097152
                                                ) {
                                                    console.log(
                                                        event.target.files[0],
                                                    );
                                                    setValue(
                                                        "gambarToko",
                                                        event.target.files[0],
                                                    );
                                                    clearErrors("gambarToko");
                                                } else {
                                                    event.target.value = "";
                                                    setError("gambarToko", {
                                                        type: "manual",
                                                        message:
                                                            "Ukuran gambar harus kurang dari 2MB",
                                                    });
                                                }
                                            }}
                                            value={undefined}
                                        />
                                    )}
                                />
                            </Box>
                            <Stack>
                                <Typography variant="caption" color="#6D6D6D">
                                    *Resolusi optimal: 300 x 300
                                </Typography>
                                <Typography
                                    variant="caption"
                                    color="#6D6D6D"
                                    marginBottom={2}
                                >
                                    *Ukuran maksimal: 2MB
                                </Typography>
                            </Stack>
                        </Grid>
                    </Grid>
                    <Stack
                        direction="row"
                        spacing={isTabletScreen ? 0 : 2}
                        alignItems="center"
                    >
                        <Button
                            variant="outlined"
                            onClick={() => closeModalUkm()}
                            fullWidth={isTabletScreen ? false : true}
                            sx={{
                                display: { xs: "block", sm: "none" },
                            }}
                        >
                            <Typography>Kembali</Typography>
                        </Button>
                        <LoadingButton
                            loading={isButtonLoading}
                            loadingPosition="center"
                            variant="contained"
                            onClick={handleSubmit(onSubmit)}
                            type="submit"
                            fullWidth={isTabletScreen ? false : true}
                        >
                            Simpan
                        </LoadingButton>
                    </Stack>
                </Fragment>
            </DialogContent>
        </Dialog>
    );
};

export default ModalUkm;
