import React, { Fragment, useEffect, useMemo, useState } from "react";
import {
    Box,
    Button,
    Checkbox,
    // Chip,
    Dialog,
    DialogTitle,
    DialogContent,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormHelperText,
    Grid,
    IconButton,
    InputAdornment,
    Stack,
    Step,
    StepContent,
    StepLabel,
    Stepper,
    Switch,
    TextField,
    ToggleButton,
    ToggleButtonGroup,
    Typography,
    useMediaQuery,
    // Autocomplete,
    Link,
    InputLabel,
    Select,
    MenuItem,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import NumberFormat from "react-number-format";
import axios from "../../services/axios";
import useUkm from "../../services/queries/useUkm";
// import useUser from "../../services/queries/useUser";
import useKategori from "../../services/queries/useKategori";
import useIncrementSKU from "../../services/queries/useIncrementSKU";
import {
    AddItemBody,
    AddItemResponse,
    SelectedProductBundling,
    ParamsGetKategori,
    ParamsGetIncrementSKU,
    // IOptions,
    ParamsGetSubKategori,
} from "../../constants/types";
// import LogoUlo from "../LogoUlo/LogoUlo";
import PilihItemStep from "./PilihItemStep/PilihItemStep";
import DetailItemStep from "./DetailItemStep/DetailItemStep";
import { LoadingButton } from "@mui/lab";
import Swal from "sweetalert2";
import defaultAxios, { AxiosError } from "axios";
import { useAuth } from "../../context/authContext";
import { useTransaksi } from "../../context/transaksiContext";
import { useStokModal } from "../../context/stokModalContext";
// import { getLocalUkmId } from "../../utils/handleChangeUkmId";
// import useLayanan from "../../services/queries/useLayanan";
// import toRupiah from "../../utils/toRupiah";
import { useMutasiStokModal } from "../../context/mutasiStokModalContext";
import useSubKategori from "../../services/queries/useSubKategori";
import formatHarga from "../../utils/formatHarga";
import Delete from "@mui/icons-material/Delete";
import Add from "@mui/icons-material/Add";

// interface CustomProps {
//   onChange: (event: { target: { name: string; value: string } }) => void;
//   name: string;
// }

// interface IModalStokProps {
//     refetchItem: () => void;
//     refetchKategori: () => void;
// }

// interface IValuesStok {
//     id: number | undefined;
//     nama: string;
//     harga: string;
//     jumlah_stok?: number | null;
//     kategori?: string | number | null;
//     sku: string;
//     kategoriBaru?: string | null;
//     deskripsi?: string;
//     gambar?: File;
// }

enum SectionTambah {
    Satuan = "SATUAN",
    Bundle = "BUNDLE",
    Free = "FREE",
}

const schema = yup.object({
    id: yup.string(),
    namaBarang: yup
        .string()
        .required("Kolom wajib diisi")
        .max(50, "Nama barang terlalu panjang"),
    // harga: yup.string().required("Kolom wajib diisi"),
    multipleHarga: yup.array().of(
        yup.object().shape({
            harga: yup.string().required("Kolom wajib diisi"),
            keterangan: yup.string().nullable(),
        }),
    ),
    sku: yup
        .string()
        .required("Kolom wajib diisi")
        .max(50, "SKU terlalu panjang"),
    deskripsi: yup.string().nullable().max(100, "Deskripsi terlalu panjang"),
    gambar: yup.mixed(),
    kategoriId: yup.string().required("Kolom wajib diisi"),
    isSubBaru: yup.boolean(),
    subId: yup.string().when("isSubBaru", {
        is: false,
        then: yup.string().required("Kolom wajib diisi"),
    }),
    subBaru: yup.string().when("isSubBaru", {
        is: true,
        then: yup
            .string()
            .required("Kolom wajib diisi")
            .max(50, "Kategori baru terlalu panjang"),
    }),
    isActiveStok: yup.boolean(),
    jumlahStok: yup
        .string()
        .nullable()
        .when("isActiveStok", {
            is: true,
            then: yup.string().required("Kolom wajib diisi"),
        }),
    isHPP: yup.boolean(),
    hpp: yup
        .string()
        .nullable()
        .when("isHPP", {
            is: true,
            then: yup.string().required("Kolom wajib diisi"),
        }),
    isFavorit: yup.boolean().required("Kolom wajib diisi"),
});

const ModalStok = () => {
    // const moment = require("moment");
    // const orderId = useMemo(() => moment().format("YYMMDDHHmmss"), [moment]);
    const theme = useTheme();
    const isPhoneScreen = useMediaQuery(theme.breakpoints.between("xs", "sm"));
    const isDesktopScreen = useMediaQuery(theme.breakpoints.up("md"));
    const { isOpenModal, closeModal, dataStok } = useStokModal();
    const { openModalMutasiStok } = useMutasiStokModal();
    const { ukmIdUser } = useAuth();
    const initialParams = {
        ukmId: ukmIdUser && ukmIdUser[0],
        size: 500,
        page: 1,
        search: "",
        kategoriId: undefined,
    };
    const [paramsKategori] = useState<ParamsGetKategori>(initialParams);
    const { data: dataKategori } = useKategori(paramsKategori);
    const [paramsSubKategori, setParamsSubKategori] =
        useState<ParamsGetSubKategori>(initialParams);
    const { data: subKategori } = useSubKategori(paramsSubKategori);
    const initialParamsIncrementSKU = {
        ukmId: ukmIdUser && ukmIdUser[0],
    };
    const [paramsIncrementSKU] = useState<ParamsGetIncrementSKU>(
        initialParamsIncrementSKU,
    );
    const { data: incrementSKU, refetch: refetchSKU } =
        useIncrementSKU(paramsIncrementSKU);
    const [section, setSection] = useState<SectionTambah>(SectionTambah.Satuan);
    const [activeStep, setActiveStep] = useState(0);
    const [products, setProducts] = useState<SelectedProductBundling[]>([]);
    const [isButtonLoading, setIsButtonLoading] = useState(false);
    const { setFetchingItems } = useTransaksi();
    const initialParamsUkm = {
        size: 500,
        page: 1,
        search: "",
    };
    const { data: ukm } = useUkm(initialParamsUkm);
    // const { data: user } = useUser();
    // const { data: dataLayanan, isLoading: isLoadingLayanan } = useLayanan();
    // const [selectedLayanan, setSelectedLayanan] = useState<IOptions | null>(
    //     null,
    // );

    // const filteredLayanan = dataLayanan?.filter((li) => li.isHide !== 1);

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        // if (activeStep === 1) {
        //     setProducts([]);
        // }
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const addProducts = (item: SelectedProductBundling[]) => {
        setProducts(item);
    };

    const resetBundlingSteps = () => {
        setActiveStep(0);
        setProducts([]);
    };

    // const ukmId = React.useMemo(() => {
    //     if (user?.userId) {
    //         return getLocalUkmId(user?.userId);
    //     }
    //     return null;
    // }, [user?.userId]);

    // const ukmData = React.useMemo(() => {
    //     if (ukmId) {
    //         return ukm?.content.find((li) => li.id === ukmId);
    //     }
    //     return ukm?.content[0];
    // }, [ukm?.content, ukmId]);

    const initialValues = useMemo(
        () => ({
            ukmId: ukmIdUser && ukmIdUser[0],
            namaBarang: "",
            harga: undefined,
            sku: "",
            deskripsi: "",
            gambar: null,
            isSubBaru: false,
            subId: "",
            subBaru: "",
            isActiveStok: true,
            jumlahStok: 1,
            isFavorit: false,
            multipleHarga: [{ harga: undefined, keterangan: null }],
        }),
        [ukmIdUser],
    );

    const steps = [
        {
            label: "Pilih produk",
            description: `Anda dapat memilih produk lebih dari satu untuk dijadikan satu paket penjualan.`,
        },
        {
            label: "Atur deskripsi dan harga paket",
            description: "Berikan nama dan atur harga paket penjualan Anda.",
        },
    ];

    const {
        handleSubmit,
        control,
        formState: { errors },
        reset,
        // resetField,
        setValue,
        // getValues,
        setError,
        clearErrors,
        watch,
    } = useForm<AddItemBody>({
        resolver: yupResolver(schema),
        defaultValues: initialValues,
    });
    const { fields, append, remove } = useFieldArray({
        control,
        name: `multipleHarga`,
    });

    useEffect(() => console.log("errors", errors), [errors]);

    const addHarga = () => {
        const newHarga = {
            harga: "",
            keterangan: null,
        };
        append(newHarga);
    };

    const kategoriId = watch("kategoriId");
    const watchSKU = watch("sku");
    const isHPP = watch("isHPP");

    useEffect(() => console.log("kategoriId", kategoriId), [kategoriId]);

    const generateSKU = () =>
        watch("namaBarang") === ""
            ? ""
            : watch("namaBarang")
                  .toUpperCase()
                  .replace(" ", "")
                  .substring(0, 4) +
              "-" +
              // String(Math.floor(Math.random() * 10000) + 1);
              incrementSKU?.urutanke;

    const isSubBaru = watch("isSubBaru");
    const isActiveStok = watch("isActiveStok");

    const onSubmit = async (values: AddItemBody) => {
        console.log(values);
        // const formattedHarga = Number(String(values.harga).replace(/\./g, ""));
        const formattedHargaBeli = String(values.hpp).replace(/\./g, "");
        setIsButtonLoading(true);
        if (dataStok) {
            try {
                const formData = new FormData();
                formData.append("ukmId", String(values.ukmId));
                formData.append(
                    "subId",
                    values.isSubBaru === true ? "" : String(values.subId),
                );
                formData.append(
                    "subBaru",
                    values.isSubBaru === true ? String(values.subBaru) : "",
                );
                formData.append(
                    "kategoriId",
                    values.isSubBaru === true ? String(values.kategoriId) : "",
                );
                formData.append("namaBarang", values.namaBarang);
                formData.append("sku", values.sku);
                // formData.append("harga", String(formattedHarga));
                formData.append(
                    "isActiveStok",
                    values.isActiveStok === true
                        ? "1"
                        : values.isActiveStok === false
                        ? "0"
                        : "0",
                );
                formData.append(
                    "favorite",
                    values.isFavorit === true
                        ? "1"
                        : values.isFavorit === false
                        ? "0"
                        : "0",
                );
                formData.append(
                    "jumlahStok",
                    values.isActiveStok === true
                        ? String(values.jumlahStok)
                        : values.isActiveStok === false
                        ? ""
                        : "",
                );
                if (values.gambar) {
                    formData.append(
                        "gambar",
                        values.gambar ? (values.gambar as any) : "",
                    );
                }
                formData.append(
                    "deskripsiBarang",
                    values.deskripsi === null ? "" : String(values.deskripsi),
                );
                for (let i = 0; i < values.multipleHarga.length; i += 1) {
                    formData.append(
                        `harga[${i}][harga]`,
                        formatHarga(String(values.multipleHarga[i].harga)),
                    );
                    formData.append(
                        `harga[${i}][keterangan]`,
                        values.multipleHarga[i].keterangan
                            ? String(values.multipleHarga[i].keterangan)
                            : "",
                    );
                }
                formData.append("stokId", String(values.id));
                const { data } = await axios.post<AddItemResponse>(
                    `/api/stok-update`,
                    formData,
                    {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                    },
                );
                if (data.code === 200) {
                    Swal.fire({
                        title: `Barang telah diubah`,
                        position: "top-end",
                        showConfirmButton: false,
                        icon: "success",
                        toast: true,
                        timer: 3000,
                        timerProgressBar: true,
                        showCloseButton: true,
                        customClass: {
                            container: "my-swal",
                        },
                    });
                    closeModal();
                }
                setFetchingItems();
                setIsButtonLoading(false);
            } catch (error) {
                if (defaultAxios.isAxiosError(error)) {
                    const serverError = error as AxiosError<any>;
                    if (serverError && serverError?.response) {
                        const fieldError = serverError?.response?.data;
                        if (fieldError?.errors) {
                            Object.keys(fieldError.errors).forEach((key) => {
                                const errorMessage = fieldError.errors[key];
                                setError(key as keyof typeof initialValues, {
                                    type: "manual",
                                    message: errorMessage[0],
                                });
                            });
                        } else {
                            Swal.fire({
                                title: `${serverError.response.data.message}`,
                                position: "top-end",
                                showConfirmButton: false,
                                icon: "error",
                                toast: true,
                                timer: 3000,
                                timerProgressBar: true,
                                showCloseButton: true,
                                customClass: {
                                    container: "my-swal",
                                },
                            });
                        }
                    }
                }
                console.error(error);
                setIsButtonLoading(false);
            }
        } else {
            try {
                const formData = new FormData();
                formData.append("ukmId", String(values.ukmId));
                formData.append(
                    "subId",
                    values.isSubBaru === true ? "" : String(values.subId),
                );
                formData.append(
                    "subBaru",
                    values.isSubBaru === true ? String(values.subBaru) : "",
                );
                formData.append(
                    "kategoriId",
                    values.isSubBaru === true ? String(values.kategoriId) : "",
                );
                formData.append("namaBarang", values.namaBarang);
                formData.append("sku", values.sku);
                // formData.append("harga", String(formattedHarga));
                formData.append(
                    "isActiveStok",
                    values.isActiveStok === true
                        ? "1"
                        : values.isActiveStok === false
                        ? "0"
                        : "0",
                );
                formData.append(
                    "favorite",
                    values.isFavorit === true
                        ? "1"
                        : values.isFavorit === false
                        ? "0"
                        : "0",
                );
                formData.append(
                    "jumlahStok",
                    values.isActiveStok === true
                        ? String(values.jumlahStok)
                        : values.isActiveStok === false
                        ? ""
                        : "",
                );
                if (values.gambar) {
                    formData.append(
                        "gambar",
                        values.gambar ? (values.gambar as any) : "",
                    );
                }
                formData.append(
                    "deskripsiBarang",
                    values.deskripsi === null ? "" : String(values.deskripsi),
                );
                formData.append(
                    "hargaBeli",
                    values.isHPP === true
                        ? formattedHargaBeli
                        : values.isHPP === false
                        ? "0"
                        : "0",
                );
                for (let i = 0; i < values.multipleHarga.length; i += 1) {
                    formData.append(
                        `harga[${i}][harga]`,
                        formatHarga(String(values.multipleHarga[i].harga)),
                    );
                    formData.append(
                        `harga[${i}][keterangan]`,
                        values.multipleHarga[i].keterangan
                            ? String(values.multipleHarga[i].keterangan)
                            : "",
                    );
                }
                const { data } = await axios.post<AddItemResponse>(
                    `/api/stok`,
                    formData,
                    {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                    },
                );
                if (data.code === 200) {
                    Swal.fire({
                        title: values.namaBarang + " telah ditambahkan",
                        position: "top-end",
                        showConfirmButton: false,
                        icon: "success",
                        toast: true,
                        timer: 3000,
                        timerProgressBar: true,
                        showCloseButton: true,
                        customClass: {
                            container: "my-swal",
                        },
                    });
                    closeModal();
                }
                setFetchingItems();
                setIsButtonLoading(false);
                console.log(data);
            } catch (error) {
                if (defaultAxios.isAxiosError(error)) {
                    const serverError = error as AxiosError<any>;
                    if (serverError && serverError?.response) {
                        const fieldError = serverError?.response?.data;
                        if (fieldError?.errors) {
                            Object.keys(fieldError.errors).forEach((key) => {
                                const errorMessage = fieldError.errors[key];
                                setError(key as keyof typeof initialValues, {
                                    type: "manual",
                                    message: errorMessage[0],
                                });
                            });
                        } else {
                            Swal.fire({
                                title: `${serverError.response.data.message}`,
                                position: "top-end",
                                showConfirmButton: false,
                                icon: "error",
                                toast: true,
                                timer: 3000,
                                timerProgressBar: true,
                                showCloseButton: true,
                                customClass: {
                                    container: "my-swal",
                                },
                            });
                        }
                    }
                }
                console.error(error);
                setIsButtonLoading(false);
            }
        }
        // console.log(formattedHarga);
    };
    //  untuk debugging value tiap field
    // const values = getValues();

    useEffect(() => {
        if (isOpenModal) {
            if (dataStok) {
                if (dataStok.isBundling > 0) {
                    addProducts(
                        dataStok.detailBundling.map((li) => ({
                            id: li.id,
                            nama: li.namaBarang,
                            harga: li.harga,
                            stok: li.stok,
                        })),
                    );
                    setActiveStep(1);
                    setSection(SectionTambah.Bundle);
                } else {
                    reset({
                        deskripsi: dataStok.deskripsi,
                        gambar: null,
                        harga: dataStok.harga,
                        id: dataStok.id,
                        isActiveStok:
                            dataStok.isActiveStok === 1 ? true : false,
                        isFavorit: dataStok.isFavorit === 1 ? true : false,
                        isSubBaru: false,
                        jumlahStok: dataStok.stok,
                        kategoriId: dataStok.kategoriId,
                        namaBarang: dataStok.namaBarang,
                        sku: dataStok.sku,
                        subBaru: "",
                        subId: dataStok.subId,
                        ukmId: Number(dataStok.ukm.id),
                        multipleHarga: dataStok.hargaMultiple,
                    });
                    setSection(SectionTambah.Satuan);
                }
                console.log(dataStok);
            } else {
                reset(initialValues);
            }
        }
    }, [isOpenModal, dataStok, reset, initialValues]);

    useEffect(() => {
        if (isOpenModal) {
            refetchSKU();
        }
    }, [isOpenModal, refetchSKU]);

    useEffect(() => {
        if (!isOpenModal) {
            resetBundlingSteps();
        }
    }, [isOpenModal]);

    // console.log(generateSKU());

    return (
        <Dialog
            maxWidth="md"
            fullScreen={isPhoneScreen}
            fullWidth={true}
            open={isOpenModal}
            onClose={closeModal}
            scroll="body"
            PaperProps={{
                sx: {
                    borderRadius: 1,
                },
            }}
        >
            <DialogTitle>
                <Stack direction="row" spacing={1} alignItems="center">
                    <IconButton onClick={closeModal}>
                        <ArrowBackIcon />
                    </IconButton>
                    <Typography variant="h6" fontWeight="bold">
                        {dataStok ? "Ubah Barang" : "Tambah Barang"}
                    </Typography>
                </Stack>
            </DialogTitle>
            <DialogContent dividers>
                {/* <code>{JSON.stringify(values)}</code> */}
                {!dataStok && (
                    <Box mb="26px">
                        <ToggleButtonGroup
                            color="primary"
                            value={section}
                            exclusive
                            onChange={(_, value) => {
                                if (value !== null) {
                                    setSection(value);
                                    resetBundlingSteps();
                                }
                            }}
                            fullWidth
                        >
                            <ToggleButton value={SectionTambah.Satuan}>
                                Satuan
                            </ToggleButton>
                            <ToggleButton
                                value={SectionTambah.Bundle}
                                disabled={!!dataStok}
                            >
                                Bundling / Paket
                            </ToggleButton>
                            {/* {ukmData?.isPro === 1 ? (
                                <ToggleButton
                                    value={SectionTambah.Bundle}
                                    // disabled={!!dataStok}
                                >
                                    Bundling / Paket
                                </ToggleButton>
                            ) : (
                                <ToggleButton
                                    value={SectionTambah.Free}
                                    // disabled={!!dataStok}
                                    // disabled={ukmData?.isPro !== 1}
                                    sx={{ display: "flex", gap: 1 }}
                                >
                                    <Typography fontSize="14px">
                                        Bundling / Paket
                                    </Typography>
                                    <Chip
                                        label="PRO"
                                        size="small"
                                        onClick={() =>
                                            setSection(SectionTambah.Free)
                                        }
                                    />
                                </ToggleButton>
                            )} */}
                        </ToggleButtonGroup>
                    </Box>
                )}

                {/* {dataStok?.itemSatuan && (
                    <React.Fragment>
                        <Typography variant="h6" fontWeight="bold">
                            List barang dalam paket
                        </Typography>
                        <Box
                            display="grid"
                            gridTemplateColumns="repeat(auto-fill, minmax(200px, 1fr))"
                            gap={3}
                            marginBottom={3}
                        >
                            {dataStok.itemSatuan.map((item) => (
                                <Card variant="outlined" sx={{ padding: 1 }}>
                                    <Box
                                        display="grid"
                                        alignItems="center"
                                        gridTemplateColumns="1fr auto"
                                        columnGap={2}
                                    >
                                        <Box>
                                            <Typography fontWeight="bold">
                                                {item.namaBarang}
                                            </Typography>
                                            <Typography>
                                                {toRupiah(item.harga ?? 0)}
                                            </Typography>
                                        </Box>
                                        <Typography variant="h5">0</Typography>
                                    </Box>
                                </Card>
                            ))}
                        </Box>
                    </React.Fragment>
                )} */}

                {/* {section === SectionTambah.Free && (
                    <Box
                        display="flex"
                        flexDirection="column"
                        justifyContent="center"
                        alignItems="center"
                        gap={2}
                        sx={{
                            width: "100%",
                            height: isPhoneScreen
                                ? "calc(100vh - 200px)"
                                : "400px",
                        }}
                    >
                        <LogoUlo width="100px" />
                        <Typography textAlign="center">
                            Mohon maaf, fitur ini hanya untuk pengguna versi
                            PRO. Pilih layanan terlebih dahulu:
                        </Typography>
                        <Stack
                            width={isPhoneScreen ? "100%" : "60%"}
                            marginTop={2}
                            spacing={2}
                        >
                            <Autocomplete
                                disablePortal
                                loading={isLoadingLayanan}
                                options={filteredLayanan ?? []}
                                getOptionLabel={(option) => option.nama}
                                isOptionEqualToValue={(option, value) =>
                                    option.id === value.id
                                }
                                size="small"
                                value={selectedLayanan}
                                onChange={(
                                    _event,
                                    selected: IOptions | null,
                                ) => {
                                    setSelectedLayanan(selected);
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Pilih layanan"
                                    />
                                )}
                            />
                            <Stack
                                direction={isPhoneScreen ? "column" : "row"}
                                display="flex"
                                justifyContent="space-between"
                                alignItems={isPhoneScreen ? "normal" : "center"}
                            >
                                <Box>
                                    <Typography color="#343941" fontSize="18px">
                                        {
                                            dataLayanan?.find(
                                                (item) =>
                                                    item.id ===
                                                    selectedLayanan?.id,
                                            )?.nama
                                        }
                                    </Typography>
                                    <Typography variant="body2" color="#6D6D6D">
                                        {
                                            dataLayanan?.find(
                                                (item) =>
                                                    item.id ===
                                                    selectedLayanan?.id,
                                            )?.deskripsiLayanan
                                        }
                                    </Typography>
                                </Box>
                                <Typography
                                    fontWeight="bold"
                                    color="#343941"
                                    fontSize="18px"
                                    mt={isPhoneScreen ? 2 : 0}
                                >
                                    {toRupiah(
                                        dataLayanan?.find(
                                            (item) =>
                                                item.id === selectedLayanan?.id,
                                        )?.harga ?? 0,
                                    )}
                                </Typography>
                            </Stack>
                        </Stack>
                        <Button
                            variant="contained"
                            target="_blank"
                            href={
                                process.env.NODE_ENV === "production"
                                    ? `https://juragan.ukmlokal.com/payment/U-${orderId}${user?.userId}/${selectedLayanan?.id}/${ukmId}`
                                    : `http://localhost:3000/payment/U-${orderId}${user?.userId}/${selectedLayanan?.id}/${ukmId}`
                            }
                            rel="noreferrer"
                        >
                            Upgrade ke Pro
                        </Button>
                    </Box>
                )} */}

                {section === SectionTambah.Satuan && (
                    <Fragment>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={8}>
                                <Controller
                                    name="namaBarang"
                                    control={control}
                                    render={({ field }) => (
                                        <TextField
                                            // margin="dense"
                                            id="namaBarang"
                                            label="Nama Barang"
                                            fullWidth
                                            variant="outlined"
                                            error={Boolean(errors.namaBarang)}
                                            helperText={
                                                errors.namaBarang
                                                    ? errors.namaBarang.message
                                                    : ""
                                            }
                                            {...field}
                                            onBlur={() => {
                                                if (dataStok) {
                                                    field.onBlur();
                                                } else {
                                                    if (watchSKU.length === 0) {
                                                        setValue(
                                                            "sku",
                                                            generateSKU(),
                                                        );
                                                        clearErrors("sku");
                                                    }
                                                    field.onBlur();
                                                }
                                            }}
                                        />
                                    )}
                                    rules={{ required: "Nama barang required" }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Controller
                                    name="sku"
                                    control={control}
                                    render={({ field }) => (
                                        <TextField
                                            // margin="dense"
                                            id="sku"
                                            label="SKU"
                                            fullWidth
                                            variant="outlined"
                                            error={Boolean(errors.sku)}
                                            helperText={
                                                errors.sku
                                                    ? errors.sku.message
                                                    : ""
                                            }
                                            {...field}
                                        />
                                    )}
                                    rules={{ required: "SKU required" }}
                                    defaultValue={generateSKU()}
                                />
                            </Grid>
                            <Grid item xs={12} marginTop={2}>
                                <Typography variant="body1">
                                    Multiple Harga
                                </Typography>
                                <Typography variant="body2" color="#545E6A">
                                    Silahkan atur harga multiple anda.
                                </Typography>
                            </Grid>
                            {fields.map((field, idx) => (
                                <React.Fragment key={field.id}>
                                    <Grid item xs={5} sm={4}>
                                        <Controller
                                            name={`multipleHarga.${idx}.harga`}
                                            control={control}
                                            render={({ field, fieldState }) => (
                                                <NumberFormat
                                                    customInput={TextField}
                                                    label="Harga"
                                                    // margin="dense"
                                                    thousandSeparator="."
                                                    decimalSeparator=","
                                                    fullWidth
                                                    allowNegative={false}
                                                    variant="outlined"
                                                    onValueChange={(values) => {
                                                        const { value } =
                                                            values;
                                                        const valueAkhir =
                                                            value.replace(
                                                                ".",
                                                                "",
                                                            );
                                                        console.log(valueAkhir);
                                                        setValue(
                                                            `multipleHarga.${idx}.harga`,
                                                            Number(valueAkhir),
                                                        );
                                                    }}
                                                    error={!!fieldState?.error}
                                                    helperText={
                                                        fieldState?.error &&
                                                        fieldState?.error
                                                            .message
                                                    }
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <Typography>
                                                                    Rp.
                                                                </Typography>
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                    {...field}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={5} sm={7}>
                                        <Controller
                                            name={`multipleHarga.${idx}.keterangan`}
                                            control={control}
                                            render={({ field, fieldState }) => (
                                                <TextField
                                                    // margin="dense"
                                                    id={`multipleHarga.${idx}.keterangan`}
                                                    label="Keterangan"
                                                    fullWidth
                                                    variant="outlined"
                                                    error={!!fieldState?.error}
                                                    helperText={
                                                        fieldState?.error &&
                                                        fieldState?.error
                                                            .message
                                                    }
                                                    {...field}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    {idx === 0 ? null : (
                                        <Grid item xs={1}>
                                            <Button
                                                onClick={() => remove(idx)}
                                                size="large"
                                                color="buttonred"
                                                sx={{
                                                    height: "54px",
                                                }}
                                            >
                                                <Delete />
                                            </Button>
                                        </Grid>
                                    )}
                                </React.Fragment>
                            ))}
                            <Grid item xs={11} justifyContent="center">
                                <Button
                                    variant="outlined"
                                    onClick={() => addHarga()}
                                    startIcon={<Add />}
                                    // size="small"
                                    color="buttongreen"
                                    fullWidth
                                >
                                    Tambah Harga
                                </Button>
                            </Grid>
                            <Grid item xs={12} marginTop={2}>
                                <Typography variant="body1">
                                    Deskripsi
                                </Typography>
                                <Typography variant="body2" color="#545E6A">
                                    Silahkan beri deskripsi untuk barang anda
                                    (opsional).
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <Controller
                                    name="deskripsi"
                                    control={control}
                                    render={({ field }) => (
                                        <TextField
                                            // margin="dense"
                                            id="deskripsi"
                                            label="Deskripsi (opsional)"
                                            fullWidth
                                            variant="outlined"
                                            multiline
                                            rows={4}
                                            error={Boolean(errors.deskripsi)}
                                            helperText={
                                                errors.deskripsi
                                                    ? errors.deskripsi.message
                                                    : ""
                                            }
                                            {...field}
                                        />
                                    )}
                                />
                            </Grid>
                        </Grid>
                        <Box marginTop={2} marginBottom={1}>
                            <Typography variant="body1">Gambar</Typography>
                            <Typography variant="body2" color="#545E6A">
                                Silahkan upload gambar barang (opsional).
                            </Typography>
                        </Box>
                        <Stack>
                            <Controller
                                name="gambar"
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        // margin="dense"
                                        id="gambar"
                                        fullWidth
                                        type="file"
                                        variant="outlined"
                                        // InputProps={{
                                        //     endAdornment: (
                                        //         <InputAdornment position="end">
                                        //             <IconButton
                                        //                 onClick={(event: any) => {
                                        //                     event.target.value = "";
                                        //                 }}
                                        //             >
                                        //                 <CloseIcon />
                                        //             </IconButton>
                                        //         </InputAdornment>
                                        //     ),
                                        // }}
                                        inputProps={{
                                            accept: "image/*",
                                        }}
                                        error={Boolean(errors.gambar)}
                                        helperText={
                                            errors.gambar
                                                ? errors.gambar.message
                                                : ""
                                        }
                                        {...field}
                                        // value={field.value || ""}
                                        onChange={(event: any) => {
                                            if (
                                                event.target.files[0].size <
                                                2097152
                                            ) {
                                                console.log(
                                                    event.target.files[0],
                                                );
                                                setValue(
                                                    "gambar",
                                                    event.target.files[0],
                                                );
                                                clearErrors("gambar");
                                            } else {
                                                event.target.value = "";
                                                setError("gambar", {
                                                    type: "manual",
                                                    message:
                                                        "Ukuran gambar harus kurang dari 2MB",
                                                });
                                            }
                                        }}
                                        value={undefined}
                                    />
                                )}
                            />
                            <Typography variant="caption" color="#6D6D6D">
                                *Resolusi optimal: 300 x 300
                            </Typography>
                            <Typography variant="caption" color="#6D6D6D">
                                *Ukuran maksimal: 2MB
                            </Typography>
                        </Stack>
                        <Grid container alignItems="center" marginTop={2}>
                            <Grid item xs={5} md={5}>
                                <Typography variant="body1">
                                    Kategori
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Controller
                                    name="isSubBaru"
                                    control={control}
                                    render={({ field }) => (
                                        <FormGroup>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox {...field} />
                                                }
                                                label="Tambah sub kategori baru"
                                                componentsProps={{
                                                    typography: {
                                                        variant: "body2",
                                                    },
                                                }}
                                            />
                                        </FormGroup>
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="body2" color="#545E6A">
                                    Cek jika ingin menambah sub kategori baru.
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} marginTop={1}>
                            {isSubBaru ? (
                                <>
                                    <Grid item xs={12} md={4}>
                                        <FormControl
                                            fullWidth
                                            disabled={isSubBaru}
                                        >
                                            <Controller
                                                control={control}
                                                name="kategoriId"
                                                render={({
                                                    field: { onChange, value },
                                                }) => (
                                                    <FormControl fullWidth>
                                                        <InputLabel>
                                                            Pilih Kategori
                                                        </InputLabel>
                                                        <Select
                                                            label="Pilih Kategori"
                                                            margin="dense"
                                                            onChange={(
                                                                event,
                                                            ) => {
                                                                onChange(event);
                                                                if (event) {
                                                                    setParamsSubKategori(
                                                                        (
                                                                            prev,
                                                                        ) => ({
                                                                            ...prev,
                                                                            kategoriId:
                                                                                undefined,
                                                                        }),
                                                                    );
                                                                } else {
                                                                    return;
                                                                }
                                                                setValue(
                                                                    "subId",
                                                                    undefined,
                                                                );
                                                            }}
                                                            value={value}
                                                            error={Boolean(
                                                                errors.kategoriId,
                                                            )}
                                                        >
                                                            {dataKategori &&
                                                            dataKategori.totalElements >
                                                                0 ? (
                                                                dataKategori.content.map(
                                                                    (k) => (
                                                                        <MenuItem
                                                                            key={
                                                                                k.id
                                                                            }
                                                                            value={
                                                                                k.id
                                                                            }
                                                                        >
                                                                            {
                                                                                k.nama
                                                                            }
                                                                        </MenuItem>
                                                                    ),
                                                                )
                                                            ) : (
                                                                <MenuItem value="">
                                                                    Tidak ada
                                                                    kategori
                                                                </MenuItem>
                                                            )}
                                                        </Select>
                                                    </FormControl>
                                                )}
                                            />
                                            <FormHelperText
                                                error={Boolean(
                                                    errors.kategoriId,
                                                )}
                                            >
                                                {errors.kategoriId
                                                    ? errors.kategoriId.message
                                                    : " "}
                                            </FormHelperText>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <Controller
                                            name="subBaru"
                                            control={control}
                                            render={({ field }) => (
                                                <TextField
                                                    // margin="dense"
                                                    id="subBaru"
                                                    label="Sub Kategori Baru"
                                                    fullWidth
                                                    variant="outlined"
                                                    error={Boolean(
                                                        errors.subBaru,
                                                    )}
                                                    helperText={
                                                        errors.subBaru
                                                            ? errors.subBaru
                                                                  .message
                                                            : " "
                                                    }
                                                    {...field}
                                                />
                                            )}
                                            rules={{
                                                required:
                                                    "Sub kategori baru required",
                                            }}
                                        />
                                    </Grid>
                                </>
                            ) : (
                                <>
                                    <Grid item xs={12} md={4}>
                                        <FormControl
                                            fullWidth
                                            disabled={isSubBaru}
                                        >
                                            <Controller
                                                control={control}
                                                name="kategoriId"
                                                render={({
                                                    field: { onChange, value },
                                                }) => (
                                                    <FormControl fullWidth>
                                                        <InputLabel>
                                                            Pilih Kategori
                                                        </InputLabel>
                                                        <Select
                                                            label="Pilih Kategori"
                                                            margin="dense"
                                                            onChange={(
                                                                event,
                                                            ) => {
                                                                onChange(event);
                                                                if (event) {
                                                                    setParamsSubKategori(
                                                                        (
                                                                            prev,
                                                                        ) => ({
                                                                            ...prev,
                                                                            kategoriId:
                                                                                event
                                                                                    .target
                                                                                    .value,
                                                                        }),
                                                                    );
                                                                } else {
                                                                    return;
                                                                }
                                                                setValue(
                                                                    "subId",
                                                                    undefined,
                                                                );
                                                            }}
                                                            value={value}
                                                            error={Boolean(
                                                                errors.kategoriId,
                                                            )}
                                                        >
                                                            {dataKategori &&
                                                            dataKategori.totalElements >
                                                                0 ? (
                                                                dataKategori.content.map(
                                                                    (k) => (
                                                                        <MenuItem
                                                                            key={
                                                                                k.id
                                                                            }
                                                                            value={
                                                                                k.id
                                                                            }
                                                                        >
                                                                            {
                                                                                k.nama
                                                                            }
                                                                        </MenuItem>
                                                                    ),
                                                                )
                                                            ) : (
                                                                <MenuItem value="">
                                                                    Tidak ada
                                                                    kategori
                                                                </MenuItem>
                                                            )}
                                                        </Select>
                                                    </FormControl>
                                                )}
                                            />
                                            <FormHelperText
                                                error={Boolean(
                                                    errors.kategoriId,
                                                )}
                                            >
                                                {errors.kategoriId
                                                    ? errors.kategoriId.message
                                                    : " "}
                                            </FormHelperText>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <FormControl
                                            fullWidth
                                            disabled={isSubBaru}
                                        >
                                            <Controller
                                                control={control}
                                                name="subId"
                                                render={({
                                                    field: { onChange, value },
                                                }) => (
                                                    <FormControl fullWidth>
                                                        <InputLabel>
                                                            Pilih Sub Kategori
                                                        </InputLabel>
                                                        <Select
                                                            label="Pilih Sub Kategori"
                                                            margin="dense"
                                                            onChange={onChange}
                                                            value={value}
                                                            error={Boolean(
                                                                errors.subId,
                                                            )}
                                                        >
                                                            {subKategori &&
                                                            subKategori.totalElements >
                                                                0 ? (
                                                                subKategori.content.map(
                                                                    (k) => (
                                                                        <MenuItem
                                                                            key={
                                                                                k.id
                                                                            }
                                                                            value={
                                                                                k.id
                                                                            }
                                                                        >
                                                                            {
                                                                                k.nama
                                                                            }
                                                                        </MenuItem>
                                                                    ),
                                                                )
                                                            ) : (
                                                                <MenuItem value="">
                                                                    {ukm !==
                                                                        null &&
                                                                    ukm !==
                                                                        undefined
                                                                        ? "Tidak ada sub kategori"
                                                                        : "Pilih UKM terlebih dulu"}
                                                                </MenuItem>
                                                            )}
                                                        </Select>
                                                    </FormControl>
                                                )}
                                            />
                                            <FormHelperText
                                                error={Boolean(errors.subId)}
                                            >
                                                {errors.subId
                                                    ? errors.subId.message
                                                    : " "}
                                            </FormHelperText>
                                        </FormControl>
                                    </Grid>
                                </>
                            )}
                        </Grid>
                        <Grid
                            display={!!dataStok ? "none" : undefined}
                            container
                            alignItems="center"
                        >
                            <Grid item xs={5} md={5}>
                                <Typography variant="body1">
                                    Harga Pokok Penjualan (HPP)
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Controller
                                    name="isHPP"
                                    control={control}
                                    render={({ field }) => (
                                        <FormGroup>
                                            <FormControlLabel
                                                control={
                                                    <Switch
                                                        checked={field.value}
                                                        onChange={
                                                            field.onChange
                                                        }
                                                        sx={{
                                                            "& .MuiSwitch-thumb":
                                                                {
                                                                    color: "#eaeaea",
                                                                },
                                                        }}
                                                    />
                                                }
                                                label={
                                                    field.value
                                                        ? "Aktif"
                                                        : "Tidak Aktif"
                                                }
                                                componentsProps={{
                                                    typography: {
                                                        variant: "body2",
                                                    },
                                                }}
                                            />
                                        </FormGroup>
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="body2" color="#545E6A">
                                    Nonaktifkan jika tidak membutuhkan harga
                                    pokok penjualan (HPP).
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid
                            display={!!dataStok ? "none" : undefined}
                            container
                            marginY={1}
                        >
                            <Grid item xs={12} md={6}>
                                {isHPP ? (
                                    <Controller
                                        name="hpp"
                                        control={control}
                                        render={({ field }) => (
                                            <NumberFormat
                                                customInput={TextField}
                                                label="Harga Pokok Penjualan"
                                                // margin="dense"
                                                thousandSeparator="."
                                                decimalSeparator=","
                                                fullWidth
                                                variant="outlined"
                                                onValueChange={(values) => {
                                                    const { value } = values;
                                                    const valueAkhir =
                                                        value.replace(".", "");
                                                    console.log(valueAkhir);
                                                    setValue(
                                                        "hpp",
                                                        Number(valueAkhir),
                                                    );
                                                }}
                                                error={Boolean(errors.hpp)}
                                                helperText={
                                                    errors.hpp
                                                        ? errors.hpp.message
                                                        : " "
                                                }
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <Typography>
                                                                Rp.
                                                            </Typography>
                                                        </InputAdornment>
                                                    ),
                                                }}
                                                {...field}
                                            />
                                        )}
                                    />
                                ) : (
                                    ""
                                )}
                            </Grid>
                        </Grid>
                        <Grid container alignItems="center">
                            <Grid item xs={5} md={5}>
                                <Typography variant="body1">Stok</Typography>
                            </Grid>
                            <Grid item>
                                <Controller
                                    name="isActiveStok"
                                    control={control}
                                    render={({ field }) => (
                                        <FormGroup>
                                            <FormControlLabel
                                                control={
                                                    <Switch
                                                        disabled={!!dataStok}
                                                        checked={field.value}
                                                        onChange={
                                                            field.onChange
                                                        }
                                                        sx={{
                                                            "& .MuiSwitch-thumb":
                                                                {
                                                                    color: "#eaeaea",
                                                                },
                                                        }}
                                                    />
                                                }
                                                label={
                                                    field.value
                                                        ? "Aktif"
                                                        : "Tidak Aktif"
                                                }
                                                componentsProps={{
                                                    typography: {
                                                        variant: "body2",
                                                    },
                                                }}
                                            />
                                        </FormGroup>
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="body2" color="#545E6A">
                                    Nonaktifkan jika tidak membutuhkan stok.
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            marginTop={1}
                            marginBottom={2}
                            columnSpacing={2}
                            alignItems="center"
                        >
                            <Grid item xs={12} sm={6}>
                                {isActiveStok ? (
                                    <Controller
                                        name="jumlahStok"
                                        control={control}
                                        render={({ field }) => (
                                            <TextField
                                                disabled={!!dataStok}
                                                margin="dense"
                                                id="jumlahStok"
                                                label="Jumlah Stok"
                                                type="number"
                                                fullWidth
                                                variant="outlined"
                                                error={Boolean(
                                                    errors.jumlahStok,
                                                )}
                                                helperText={
                                                    errors.jumlahStok
                                                        ? errors.jumlahStok
                                                              .message
                                                        : ""
                                                }
                                                {...field}
                                            />
                                        )}
                                        rules={{
                                            required: "Jumlah stok required",
                                        }}
                                    />
                                ) : (
                                    ""
                                )}
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                md={6}
                                display={!!dataStok ? undefined : "none"}
                            >
                                <Typography
                                    component="div"
                                    variant="body2"
                                    color="#545E6A"
                                >
                                    Anda dapat melakukan perubahan jumlah stok
                                    melalui fitur Mutasi Stok.{" "}
                                    <Link
                                        onClick={(event) => {
                                            event.stopPropagation();
                                            closeModal();
                                            openModalMutasiStok();
                                        }}
                                        underline="hover"
                                        sx={{ cursor: "pointer" }}
                                    >
                                        Ke Mutasi Stok
                                    </Link>
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container alignItems="center" mb={3}>
                            <Grid item xs={5} md={5}>
                                <Typography variant="body1">Favorit</Typography>
                            </Grid>
                            <Grid item>
                                <Controller
                                    name="isFavorit"
                                    control={control}
                                    render={({ field }) => (
                                        <FormGroup>
                                            <FormControlLabel
                                                control={
                                                    <Switch
                                                        checked={field.value}
                                                        onChange={
                                                            field.onChange
                                                        }
                                                        sx={{
                                                            "& .MuiSwitch-thumb":
                                                                {
                                                                    color: "#eaeaea",
                                                                },
                                                        }}
                                                    />
                                                }
                                                label={
                                                    field.value
                                                        ? "Favorit"
                                                        : "Tidak Favorit"
                                                }
                                                componentsProps={{
                                                    typography: {
                                                        variant: "body2",
                                                    },
                                                }}
                                            />
                                        </FormGroup>
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="body2" color="#545E6A">
                                    Jika mengaktifkan favorit akan membuat
                                    tampilan barang berada di paling awal.
                                </Typography>
                            </Grid>
                        </Grid>
                        <Stack
                            direction="row"
                            spacing={isDesktopScreen ? 0 : 2}
                            alignItems="center"
                        >
                            <Button
                                variant="outlined"
                                onClick={() => closeModal()}
                                fullWidth={isDesktopScreen ? false : true}
                                sx={{ display: { xs: "block", md: "none" } }}
                            >
                                <Typography>Kembali</Typography>
                            </Button>
                            <LoadingButton
                                loading={isButtonLoading}
                                loadingPosition="center"
                                variant="contained"
                                onClick={handleSubmit(onSubmit)}
                                type="submit"
                                fullWidth={isDesktopScreen ? false : true}
                            >
                                Simpan
                            </LoadingButton>
                        </Stack>
                    </Fragment>
                )}

                {section === SectionTambah.Bundle && (
                    <Fragment>
                        <Grid>
                            <Stepper
                                activeStep={activeStep}
                                orientation="vertical"
                            >
                                {steps.map((step, index) => (
                                    <Step key={String(index + 1)}>
                                        <StepLabel>{step.label}</StepLabel>
                                        <StepContent>
                                            <Typography color="GrayText">
                                                {step.description}
                                            </Typography>
                                            <Box my={4}>
                                                {index === 0 && (
                                                    <Fragment>
                                                        <PilihItemStep
                                                            addProducts={
                                                                addProducts
                                                            }
                                                        />
                                                        <Box
                                                            mt={4}
                                                            display="grid"
                                                            gridTemplateColumns="1fr 1fr 1fr"
                                                            justifyContent="flex-end"
                                                            columnGap="16px"
                                                        >
                                                            <div />
                                                            <Button
                                                                disabled={
                                                                    index === 0
                                                                }
                                                                onClick={
                                                                    handleBack
                                                                }
                                                                variant="outlined"
                                                            >
                                                                Kembali
                                                            </Button>
                                                            <Button
                                                                variant="contained"
                                                                disabled={
                                                                    activeStep ===
                                                                        0 &&
                                                                    products.length ===
                                                                        0
                                                                }
                                                                onClick={
                                                                    handleNext
                                                                }
                                                            >
                                                                Lanjutkan
                                                            </Button>
                                                        </Box>
                                                    </Fragment>
                                                )}
                                                {index === 1 && (
                                                    <DetailItemStep
                                                        products={products}
                                                        onBack={handleBack}
                                                        dataStokBundle={{
                                                            id: dataStok?.id,
                                                            item: dataStok?.detailBundling.map(
                                                                (li) => ({
                                                                    itemId: li.id,
                                                                    jumlahItem:
                                                                        li.jumlahYangDiambil,
                                                                }),
                                                            ) ?? [
                                                                {
                                                                    itemId: 1,
                                                                    jumlahItem: 1,
                                                                },
                                                            ],
                                                            namaBundling:
                                                                dataStok?.namaBarang ??
                                                                "",
                                                            deskripsiBundling:
                                                                dataStok?.deskripsi ??
                                                                "",
                                                            subId:
                                                                dataStok?.subId ??
                                                                "",
                                                            kategoriId:
                                                                dataStok?.kategoriId ??
                                                                "",
                                                            sku:
                                                                dataStok?.sku ??
                                                                "",
                                                            hargaBundling:
                                                                String(
                                                                    dataStok?.harga,
                                                                ) ?? "",
                                                            isActiveStok:
                                                                !!dataStok?.isActiveStok,
                                                            jumlahStok:
                                                                dataStok?.stok ??
                                                                1,
                                                            favorite:
                                                                !!dataStok?.isFavorit,
                                                        }}
                                                    />
                                                )}
                                            </Box>
                                        </StepContent>
                                    </Step>
                                ))}
                            </Stepper>
                        </Grid>
                    </Fragment>
                )}
            </DialogContent>
        </Dialog>
    );
};

export default ModalStok;
