import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import moment from "moment";
import "moment/locale/id";
import React, { useEffect, useState } from "react";
import usePreOrder from "../../services/queries/usePreOrder";
import {
    ContentGetPreOrderResponse,
    DataPO,
    DetailFaktur,
    ParamsGetPreOrder,
} from "../../constants/types";
import MenuItem from "@mui/material/MenuItem";
import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import id from "date-fns/locale/id";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import ArrowDropDown from "@mui/icons-material/ArrowDropDown";
import Button from "@mui/material/Button";
import Add from "@mui/icons-material/Add";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Search from "@mui/icons-material/Search";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import ImportExport from "@mui/icons-material/ImportExport";
import NoRowsImage from "../../components/NoRowsImage/NoRowsImage";
import LoadingTabel from "../../components/LoadingTabel/LoadingTabel";
import { useAuth } from "../../context/authContext";
import toRibuanDesimal from "../../utils/toRibuanDesimal";
import Tooltip from "@mui/material/Tooltip";
import Visibility from "@mui/icons-material/Visibility";
import ModalPreOder from "../../components/ModalPreOder/ModalPreOder";
import { useTransaksi } from "../../context/transaksiContext";
import ModalDetailPreOrder from "../../components/ModalDetailPreOrder/ModalDetailPreOrder";
import CheckIcon from "@mui/icons-material/Check";
import ModalAccPreOrder from "../../components/ModalAccPreOrder/ModalAccPreOrder";

moment.locales();
moment.locale("id");

registerLocale("id", id);
setDefaultLocale("id");

type DateFilter = [Date | null, Date | null];

const PreOrderPage = () => {
    const theme = useTheme();
    const isPhoneScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const isLaptopScreen = useMediaQuery(theme.breakpoints.up("md"));
    const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
    const { ukmIdUser } = useAuth();
    const { isFetchingItems, cancelFetchingItems } = useTransaksi();
    let now = moment().format("YYYY/MM/DD");
    const today = new Date();
    const [dateRange, setDateRange] = useState<DateFilter>([
        new Date(),
        new Date(),
    ]);
    const [startDate, endDate] = dateRange;
    const [search, setSearch] = useState("");
    const initialParamsPreOrder = {
        ukmId: ukmIdUser && ukmIdUser[0],
        search: "",
        size: 10,
        page: 1,
        tanggalAwal: now,
        tanggalAkhir: now,
    };
    const [paramsPreOrder, setParamsPreOrder] = useState<ParamsGetPreOrder>(
        initialParamsPreOrder,
    );
    const {
        data: preOrder,
        refetch: refetchPreOrder,
        isLoading: isLoadingPreOrder,
    } = usePreOrder(paramsPreOrder);
    const [isOpenModalPreOrder, setIsOpenModalPreOrder] = useState(false);
    const [isOpenModalDetailPreOrder, setIsOpenModalDetailPreOrder] =
        useState(false);
    const [isOpenModalAccPreOrder, setIsOpenModalAccPreOrder] = useState(false);
    const [detailFaktur, setDetailFaktur] = useState<DetailFaktur[]>([]);
    const initialDataFaktur = {
        id: 0,
        faktur: "",
        ukm: {
            id: null,
            nama: null,
        },
        suplier: {
            id: null,
            nama: null,
        },
        tanggal: null,
        totalPembelian: 0,
        isVerif: 0,
    };
    const [dataFaktur, setDataFaktur] = useState<DataPO>(initialDataFaktur);

    const openModalPreOrder = () => {
        setIsOpenModalPreOrder(true);
    };

    const closeModalPreOrder = () => {
        setIsOpenModalPreOrder(false);
    };

    const openModalDetailPreOrder = (
        data: DetailFaktur[],
        dataFaktur: DataPO,
    ) => {
        setDetailFaktur(data);
        setDataFaktur(dataFaktur);
        setIsOpenModalDetailPreOrder(true);
    };

    const closeModalDetailPreOrder = () => {
        setDetailFaktur([]);
        setIsOpenModalDetailPreOrder(false);
    };

    const openModalAccPreOrder = (dataFaktur: DataPO) => {
        setDataFaktur(dataFaktur);
        setIsOpenModalAccPreOrder(true);
    };

    const closeModalAccPreOrder = () => {
        setIsOpenModalAccPreOrder(false);
    };

    const handleChangeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(event.target.value);
    };

    const handleSearch = () => {
        setParamsPreOrder((prev) => ({
            ...prev,
            search: search,
        }));
    };

    const handleOnPagination = (page: number) => {
        setParamsPreOrder((prev) => ({
            ...prev,
            page: page + 1,
        }));
    };

    const handleOnSizeChange = (size: number) => {
        setParamsPreOrder((prev) => ({ ...prev, size }));
    };

    const handleChangePeriode = (event: SelectChangeEvent) => {
        setDateRange([new Date(event.target.value), new Date()]);
        setParamsPreOrder((prev) => ({
            ...prev,
            tanggalAwal: String(event.target.value),
            tanggalAkhir: now,
            page: 1,
        }));
        refetchPreOrder();
    };

    const handleChangeJangkaWaktu = (
        tanggalAwal: string,
        tanggalAkhir: string,
    ) => {
        setParamsPreOrder((prev) => ({
            ...prev,
            tanggalAwal: tanggalAwal,
            tanggalAkhir: tanggalAkhir,
            page: 1,
        }));
        refetchPreOrder();
    };

    const columns: GridColDef[] = [
        {
            field: "tanggal",
            headerName: "Tanggal",
            flex: isLaptopScreen ? 0.4 : undefined,
            minWidth: 160,
            renderCell: (params) => {
                return (
                    <Tooltip
                        title={
                            params.value
                                ? moment(params.value).format("LL")
                                : "-"
                        }
                    >
                        <span className="table-cell-trucate">
                            {params.value
                                ? moment(params.value).format("LL")
                                : "-"}
                        </span>
                    </Tooltip>
                );
            },
        },
        {
            field: "faktur",
            headerName: "No. Faktur",
            flex: isLaptopScreen ? 0.8 : undefined,
            minWidth: 160,
            renderCell: (params) => (
                <Tooltip title={params.value}>
                    <span className="table-cell-trucate">{params.value}</span>
                </Tooltip>
            ),
        },
        {
            field: "suplier",
            headerName: "Supplier",
            flex: isLaptopScreen ? 0.8 : undefined,
            minWidth: 200,
            renderCell: (params) => {
                return (
                    <Tooltip title={params.value.id ? params.value.nama : "-"}>
                        <span className="table-cell-trucate">
                            {params.value.id ? params.value.nama : "-"}
                        </span>
                    </Tooltip>
                );
            },
        },
        {
            field: "isVerif",
            headerName: "Diverifikasi?",
            flex: isLaptopScreen ? 0.3 : undefined,
            minWidth: 160,
            renderCell: (params) => {
                return (
                    <Tooltip title={params.value === 1 ? "Sudah" : "Belum"}>
                        <Typography
                            variant="body2"
                            color={params.value === 1 ? "#61C15F" : "#FF7373"}
                        >
                            {params.value === 1 ? "Sudah" : "Belum"}
                        </Typography>
                    </Tooltip>
                );
            },
        },
        {
            field: "isLunas",
            headerName: "Disetujui?",
            flex: isLaptopScreen ? 0.3 : undefined,
            minWidth: 160,
            renderCell: (params) => {
                return (
                    <Tooltip title={params.value === 1 ? "Sudah" : "Belum"}>
                        <Typography
                            variant="body2"
                            color={params.value === 1 ? "#61C15F" : "#FF7373"}
                        >
                            {params.value === 1 ? "Sudah" : "Belum"}
                        </Typography>
                    </Tooltip>
                );
            },
        },
        {
            field: "totalPembelian",
            headerName: "Total Pembelian",
            align: "right",
            flex: isLaptopScreen ? 0.3 : undefined,
            minWidth: 160,
            renderCell: (params) => {
                return (
                    <Tooltip title={toRibuanDesimal(params.value)}>
                        <Typography
                            variant="subtitle2"
                            fontWeight={400}
                            textAlign="right"
                        >
                            {toRibuanDesimal(params.value)}
                        </Typography>
                    </Tooltip>
                );
            },
        },
        {
            field: "aksi",
            headerName: "Aksi",
            headerAlign: "center",
            minWidth: 160,
            sortable: false,
            disableColumnMenu: true,
            align: "center",
            renderCell: (params) => {
                const row: ContentGetPreOrderResponse = params.row;

                return (
                    <Stack direction="row" spacing={3}>
                        <Tooltip title="Lihat detail">
                            <Button
                                variant="contained"
                                onClick={() => {
                                    openModalDetailPreOrder(row.detailFaktur, {
                                        id: row.id,
                                        faktur: row.faktur ?? "",
                                        ukm: row.ukm,
                                        suplier: row.suplier,
                                        tanggal: row.tanggal,
                                        isVerif: row.isVerif,
                                        totalPembelian: Number(
                                            row.totalPembelian,
                                        ),
                                    });
                                }}
                                size="small"
                                color="buttongreen"
                                disableElevation
                                sx={{
                                    minWidth: "unset",
                                    padding: "8px",
                                    width: "32px",
                                    height: "32px",
                                }}
                            >
                                <Visibility
                                    sx={{ width: "16px", height: "16px" }}
                                />
                            </Button>
                        </Tooltip>
                        <Tooltip
                            title={
                                params.row.isVerif !== 1
                                    ? "Pre order perlu diverifikasi oleh juragan"
                                    : "Setujui pre order"
                            }
                        >
                            <span>
                                <Button
                                    disabled={
                                        params.row.isLunas === 1 ||
                                        params.row.isVerif !== 1
                                    }
                                    variant="contained"
                                    onClick={() => {
                                        openModalAccPreOrder({
                                            id: row.id,
                                            faktur: row.faktur ?? "",
                                            ukm: row.ukm,
                                            suplier: row.suplier,
                                            tanggal: row.tanggal,
                                            isVerif: row.isVerif,
                                            totalPembelian: Number(
                                                row.totalPembelian,
                                            ),
                                        });
                                    }}
                                    size="small"
                                    color="buttonblue"
                                    disableElevation
                                    sx={{
                                        minWidth: "unset",
                                        padding: "8px",
                                        width: "32px",
                                        height: "32px",
                                    }}
                                >
                                    <CheckIcon
                                        sx={{ width: "16px", height: "16px" }}
                                    />
                                </Button>
                            </span>
                        </Tooltip>
                    </Stack>
                );
            },
        },
    ];

    useEffect(() => {
        if (isFetchingItems === true) {
            refetchPreOrder();
            cancelFetchingItems();
        }
    }, [cancelFetchingItems, isFetchingItems, refetchPreOrder]);

    return (
        <Box>
            <Box>
                <Stack spacing={2} marginY={2}>
                    <Typography variant="h6" fontWeight="bold">
                        Pre Order
                    </Typography>
                    <Grid container alignItems="center" gap={3}>
                        <Grid item xs={12} md={12} xl={6}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} md={6}>
                                    <Select
                                        value={
                                            paramsPreOrder.tanggalAwal ===
                                                now ||
                                            paramsPreOrder.tanggalAwal ===
                                                moment()
                                                    .subtract(7, "days")
                                                    .format("YYYY-MM-DD") ||
                                            paramsPreOrder.tanggalAwal ===
                                                moment()
                                                    .subtract(1, "month")
                                                    .format("YYYY-MM-DD") ||
                                            paramsPreOrder.tanggalAwal ===
                                                moment()
                                                    .subtract(3, "months")
                                                    .format("YYYY-MM-DD") ||
                                            paramsPreOrder.tanggalAwal ===
                                                moment()
                                                    .subtract(1, "years")
                                                    .format("YYYY-MM-DD")
                                                ? paramsPreOrder.tanggalAwal
                                                : "Custom"
                                        }
                                        size="small"
                                        fullWidth
                                        onChange={handleChangePeriode}
                                    >
                                        <MenuItem value={now}>
                                            Hari ini
                                        </MenuItem>
                                        <MenuItem
                                            value={moment()
                                                .subtract(7, "days")
                                                .format("YYYY-MM-DD")}
                                        >
                                            1 Minggu Terakhir
                                        </MenuItem>
                                        <MenuItem
                                            value={moment()
                                                .subtract(1, "month")
                                                .format("YYYY-MM-DD")}
                                        >
                                            1 Bulan Terakhir
                                        </MenuItem>
                                        <MenuItem
                                            value={moment()
                                                .subtract(3, "months")
                                                .format("YYYY-MM-DD")}
                                        >
                                            3 Bulan Terakhir
                                        </MenuItem>
                                        <MenuItem
                                            value={moment()
                                                .subtract(1, "years")
                                                .format("YYYY-MM-DD")}
                                        >
                                            1 Tahun Terakhir
                                        </MenuItem>
                                        <MenuItem
                                            value={"Custom"}
                                            sx={{ display: "none" }}
                                        >
                                            Custom
                                        </MenuItem>
                                    </Select>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <DatePicker
                                        className="range-filter"
                                        dateFormat="dd/MM/yyyy"
                                        disabledKeyboardNavigation
                                        onFocus={(e) => e.target.blur()}
                                        selectsRange={true}
                                        startDate={startDate}
                                        endDate={endDate}
                                        onChange={(update) => {
                                            setDateRange(update);
                                            if (update[1]) {
                                                handleChangeJangkaWaktu(
                                                    moment(update[0]).format(
                                                        "YYYY/MM/DD",
                                                    ),
                                                    moment(update[1]).format(
                                                        "YYYY/MM/DD",
                                                    ),
                                                );
                                            }
                                        }}
                                        maxDate={today}
                                        monthsShown={isPhoneScreen ? 1 : 2}
                                        customInput={
                                            <Stack
                                                display="flex"
                                                justifyContent="space-between"
                                                direction="row"
                                                width="100%"
                                                bgcolor="#FFFF"
                                                height="40px"
                                                sx={{
                                                    border: "1px solid #cbcbcb",
                                                    borderRadius: "4px",
                                                    alignItems: "center",
                                                    padding: 2,
                                                    cursor: "pointer",
                                                }}
                                            >
                                                <Typography>
                                                    {moment(
                                                        paramsPreOrder.tanggalAwal,
                                                    ).format("DD/MM/YYYY")}{" "}
                                                    -{" "}
                                                    {paramsPreOrder.tanggalAkhir &&
                                                        moment(
                                                            paramsPreOrder.tanggalAkhir,
                                                        ).format("DD/MM/YYYY")}
                                                </Typography>
                                                <ArrowDropDown
                                                    sx={{
                                                        marginRight: "-9px",
                                                    }}
                                                />
                                            </Stack>
                                        }
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs md={0} xl></Grid>
                        <Grid item xs="auto">
                            <Button
                                variant="contained"
                                startIcon={<Add />}
                                disableElevation
                                fullWidth
                                onClick={openModalPreOrder}
                            >
                                Tambah
                            </Button>
                        </Grid>
                    </Grid>
                    <Grid container alignItems="center" gap={2}>
                        <Grid item xs="auto">
                            <TextField
                                placeholder="Cari pre order"
                                size="small"
                                fullWidth
                                value={search}
                                onChange={handleChangeSearch}
                                onKeyPress={(e) => {
                                    const element =
                                        e.target as HTMLInputElement;
                                    if (e.key === "Enter") {
                                        setParamsPreOrder((prev) => ({
                                            ...prev,
                                            search: element.value,
                                        }));
                                    }
                                }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton onClick={handleSearch}>
                                                <Search />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>
                        <Grid item xs></Grid>
                        <Grid item xs={4} md={2}></Grid>
                    </Grid>
                    <Box
                        marginTop={2}
                        sx={{
                            height: 631,
                            width: "100%",
                            borderRadius: 1,
                            "& .headerColumn": {
                                backgroundColor: "#E4EEE8",
                            },
                            "& .MuiDataGrid-columnHeaderTitle": {
                                fontWeight: "bold",
                            },
                            "& .MuiDataGrid-columnSeparator": {
                                visibility: "hidden",
                            },
                            "& .MuiDataGrid-cell:focus-within": {
                                outline: "none !important",
                            },
                            "& .MuiDataGrid-iconButtonContainer": {
                                visibility: "visible",
                                width: "0 !important",
                            },
                        }}
                    >
                        <DataGrid
                            pagination
                            rows={preOrder?.content ?? []}
                            columns={columns}
                            components={{
                                ColumnUnsortedIcon: () => {
                                    return <ImportExport />;
                                },
                                NoRowsOverlay: NoRowsImage,
                                LoadingOverlay: LoadingTabel,
                            }}
                            rowsPerPageOptions={[10]}
                            disableColumnMenu
                            disableSelectionOnClick
                            paginationMode="server"
                            onPageSizeChange={handleOnSizeChange}
                            onPageChange={handleOnPagination}
                            rowCount={preOrder?.totalElements ?? 0}
                            page={paramsPreOrder.page - 1}
                            pageSize={paramsPreOrder.size}
                            loading={isLoadingPreOrder}
                        />
                    </Box>
                </Stack>
            </Box>
            <ModalPreOder
                open={isOpenModalPreOrder}
                onClose={closeModalPreOrder}
            />
            <ModalDetailPreOrder
                isOpenModalDetailPreOrder={isOpenModalDetailPreOrder}
                closeModalDetailPreOrder={closeModalDetailPreOrder}
                detailFaktur={detailFaktur}
                fullScreen={fullScreen}
                dataFaktur={dataFaktur}
            />
            <ModalAccPreOrder
                open={isOpenModalAccPreOrder}
                onClose={closeModalAccPreOrder}
                namaPO={dataFaktur.faktur}
                idPO={dataFaktur.id}
            />
        </Box>
    );
};

export default PreOrderPage;
