import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import axios from "../../services/axios";
import Swal from "sweetalert2";
import LoadingButton from "@mui/lab/LoadingButton";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import defaultAxios, { AxiosError } from "axios";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import ArrowBack from "@mui/icons-material/ArrowBack";
import DialogContent from "@mui/material/DialogContent";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import NumberFormat from "react-number-format";
import Box from "@mui/material/Box";
import InputAdornment from "@mui/material/InputAdornment";
import Button from "@mui/material/Button";
import FormGroup from "@mui/material/FormGroup";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useTransaksi } from "../../context/transaksiContext";
import { useAuth } from "../../context/authContext";

interface IAddMetodePembayaranForm {
    metodeId?: number;
    namaMetode?: string;
    noTujuan: string;
    namaPemegang: string;
    gambar: File | string | null;
    ukmId?: string | number;
    isCharge: boolean;
    charge: { mode: string | null; charge: number };
}

interface IModalMetodePembayaranProps {
    isOpenModalMetodePembayaran: boolean;
    closeModalMetodePembayaran: () => void;
    dataMetodePembayaran?: IAddMetodePembayaranForm;
}

const schema = yup.object({
    namaMetode: yup
        .string()
        .required("Kolom wajib diisi")
        .max(50, "Nama metode terlalu panjang"),
    noTujuan: yup
        .string()
        .required("Kolom wajib diisi")
        .max(24, "No. tujuan terlalu panjang"),
    namaPemegang: yup
        .string()
        .required("Kolom wajib diisi")
        .max(50, "Nama pemegang terlalu panjang"),
    isCharge: yup.boolean(),
    charge: yup
        .object()
        .shape({
            mode: yup
                .string()
                .nullable()
                .when("isCharge", {
                    is: true,
                    then: yup.string().required("Kolom wajib diisi"),
                }),
            charge: yup
                .string()
                .nullable()
                .when("isCharge", {
                    is: true,
                    then: yup.string().required("Kolom wajib diisi"),
                }),
        })
        .required("Kolom wajib diisi"),
    gambar: yup.mixed(),
});

const ModalMetodePembayaran = ({
    isOpenModalMetodePembayaran,
    closeModalMetodePembayaran,
    dataMetodePembayaran,
}: IModalMetodePembayaranProps) => {
    const theme = useTheme();
    const isPhoneScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const [isButtonLoading, setIsButtonLoading] = useState(false);
    const { setFetchingItems } = useTransaksi();
    const { ukmIdUser } = useAuth();

    const initialValues = React.useMemo(
        () => ({
            ukmId: ukmIdUser && ukmIdUser[0],
            namaMetode: "",
            noTujuan: "",
            namaPemegang: "",
            gambar: null,
            charge: { mode: "nominal", charge: 0 },
            isCharge: false,
        }),
        [ukmIdUser],
    );

    const {
        handleSubmit,
        control,
        formState: { errors },
        setError,
        setValue,
        clearErrors,
        reset,
        watch,
    } = useForm<IAddMetodePembayaranForm>({
        resolver: yupResolver(schema),
        defaultValues: initialValues,
    });

    const chargeMode = watch("charge.mode");
    const isCharge = watch("isCharge");
    const watchUkmId = watch("ukmId");

    useEffect(() => console.log("watchUkmId", watchUkmId), [watchUkmId]);

    const handleErrorResponse = (error: any) => {
        if (defaultAxios.isAxiosError(error)) {
            const serverError = error as AxiosError<any>;
            if (serverError && serverError?.response) {
                const fieldError = serverError?.response?.data;
                if (fieldError?.errors) {
                    Object.keys(fieldError.errors).forEach((key) => {
                        const errorMessage = fieldError.errors[key];
                        setError(key as keyof typeof initialValues, {
                            type: "manual",
                            message: errorMessage[0],
                        });
                    });
                } else {
                    Swal.fire({
                        title: `${fieldError.message}`,
                        position: "top-end",
                        showConfirmButton: false,
                        icon: "error",
                        toast: true,
                        timer: 3000,
                        timerProgressBar: true,
                        showCloseButton: true,
                        customClass: {
                            container: "my-swal",
                        },
                    });
                }
            }
        }
    };

    const onSubmit = async (values: IAddMetodePembayaranForm) => {
        console.log(values);
        const formattedCharge = String(values.charge.charge).replace(/\./g, "");
        setIsButtonLoading(true);
        if (dataMetodePembayaran) {
            try {
                const formData = new FormData();
                formData.append("ukmId", String(values.ukmId));
                formData.append("namaMetode", String(values.namaMetode));
                formData.append("noTujuan", values.noTujuan);
                formData.append("namaPemegang", values.namaPemegang);
                formData.append(
                    "charge",
                    values.isCharge ? formattedCharge : "0",
                );
                formData.append(
                    "modeCharge",
                    values.isCharge
                        ? values.charge.mode === "persen"
                            ? "persen"
                            : values.charge.mode === "nominal"
                            ? "nominal"
                            : "nominal"
                        : "nominal",
                );
                console.log(values.gambar);
                if (values.gambar) {
                    formData.append(
                        "gambar",
                        values.gambar ? (values.gambar as any) : "",
                    );
                }
                formData.append("_method", "put");
                const { data } = await axios.post(
                    `/api/metode-bayar/${dataMetodePembayaran.metodeId}`,
                    formData,
                    {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                    },
                );
                if (data.code === 200) {
                    Swal.fire({
                        title: "Metode telah diubah",
                        position: "top-end",
                        showConfirmButton: false,
                        icon: "success",
                        toast: true,
                        timer: 3000,
                        timerProgressBar: true,
                        showCloseButton: true,
                        customClass: {
                            container: "my-swal",
                        },
                    });
                    setFetchingItems();
                    closeModalMetodePembayaran();
                }
                setIsButtonLoading(false);
                console.log(data);
            } catch (error) {
                setIsButtonLoading(false);
                handleErrorResponse(error);
            }
        } else {
            try {
                const formData = new FormData();
                formData.append("ukmId", String(values.ukmId));
                formData.append("namaMetode", String(values.namaMetode));
                formData.append("noTujuan", values.noTujuan);
                formData.append("namaPemegang", values.namaPemegang);
                formData.append(
                    "charge",
                    values.isCharge ? formattedCharge : "0",
                );
                formData.append(
                    "modeCharge",
                    values.isCharge
                        ? values.charge.mode === "persen"
                            ? "persen"
                            : values.charge.mode === "nominal"
                            ? "nominal"
                            : "nominal"
                        : "nominal",
                );
                console.log(values.gambar);
                if (values.gambar) {
                    formData.append(
                        "gambar",
                        values.gambar ? (values.gambar as any) : "",
                    );
                }
                const { data } = await axios.post(
                    `/api/metode-bayar`,
                    formData,
                    {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                    },
                );
                if (data.code === 200) {
                    Swal.fire({
                        title:
                            "Metode " +
                            values.namaMetode +
                            " telah ditambahkan",
                        position: "top-end",
                        showConfirmButton: false,
                        icon: "success",
                        toast: true,
                        timer: 3000,
                        timerProgressBar: true,
                        showCloseButton: true,
                        customClass: {
                            container: "my-swal",
                        },
                    });
                    setFetchingItems();
                    closeModalMetodePembayaran();
                }
                setIsButtonLoading(false);
                console.log(data);
            } catch (error) {
                setIsButtonLoading(false);
                handleErrorResponse(error);
            }
        }
    };

    useEffect(() => {
        if (isOpenModalMetodePembayaran) {
            if (dataMetodePembayaran) {
                reset({
                    ...dataMetodePembayaran,
                    isCharge:
                        dataMetodePembayaran.charge.charge > 0 ? true : false,
                    charge: {
                        mode: dataMetodePembayaran.charge.mode,
                        charge: Number(dataMetodePembayaran.charge.charge),
                    },
                    ukmId: ukmIdUser && ukmIdUser[0],
                });
            } else {
                reset(initialValues);
            }
        }
    }, [
        reset,
        initialValues,
        isOpenModalMetodePembayaran,
        dataMetodePembayaran,
        ukmIdUser,
    ]);

    return (
        <Dialog
            maxWidth="md"
            fullScreen={isPhoneScreen}
            fullWidth={true}
            open={isOpenModalMetodePembayaran}
            onClose={closeModalMetodePembayaran}
            scroll="body"
            PaperProps={{
                sx: {
                    borderRadius: 1,
                },
            }}
        >
            <DialogTitle>
                <Stack direction="row" spacing={1} alignItems="center">
                    <IconButton onClick={closeModalMetodePembayaran}>
                        <ArrowBack />
                    </IconButton>
                    <Typography variant="h6" fontWeight="bold">
                        {!!!dataMetodePembayaran
                            ? "Tambah Metode Pembayaran"
                            : "Ubah Metode Pembayaran"}
                    </Typography>
                </Stack>
            </DialogTitle>
            <DialogContent
                dividers
                sx={{ padding: isPhoneScreen ? undefined : 6 }}
            >
                <Stack gap={6}>
                    <Grid container alignItems="center" spacing={2}>
                        <Grid item xs={12}>
                            <Controller
                                name="namaMetode"
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        // margin="dense"
                                        id="namaMetode"
                                        label="Nama Metode"
                                        fullWidth
                                        variant="outlined"
                                        error={Boolean(errors.namaMetode)}
                                        helperText={
                                            errors.namaMetode
                                                ? errors.namaMetode.message
                                                : ""
                                        }
                                        {...field}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Controller
                                name="noTujuan"
                                control={control}
                                render={({ field }) => (
                                    <NumberFormat
                                        customInput={TextField}
                                        label="No. Tujuan"
                                        // margin="dense"
                                        // thousandSeparator="."
                                        // decimalSeparator=","
                                        fullWidth
                                        variant="outlined"
                                        onValueChange={(values) => {
                                            const { value } = values;
                                            const valueAkhir = value.replace(
                                                ".",
                                                "",
                                            );
                                            console.log(valueAkhir);
                                            setValue("noTujuan", valueAkhir);
                                        }}
                                        error={Boolean(errors.noTujuan)}
                                        helperText={
                                            errors.noTujuan
                                                ? errors.noTujuan.message
                                                : ""
                                        }
                                        {...field}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Controller
                                name="namaPemegang"
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        // margin="dense"
                                        id="namaPemegang"
                                        label="Nama Pemegang"
                                        fullWidth
                                        variant="outlined"
                                        error={Boolean(errors.namaPemegang)}
                                        helperText={
                                            errors.namaPemegang
                                                ? errors.namaPemegang.message
                                                : ""
                                        }
                                        {...field}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Box marginTop={2} marginBottom={1}>
                                <Typography variant="body1">Charge</Typography>
                                <Typography variant="body2" color="#545E6A">
                                    Charge untuk metode pembayaran (opsional).
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item>
                            <Controller
                                name="isCharge"
                                control={control}
                                render={({ field }) => (
                                    <FormGroup>
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={field.value}
                                                    onChange={field.onChange}
                                                    sx={{
                                                        "& .MuiSwitch-thumb": {
                                                            color: "#eaeaea",
                                                        },
                                                    }}
                                                />
                                            }
                                            label={
                                                field.value
                                                    ? "Aktif"
                                                    : "Tidak Aktif"
                                            }
                                            componentsProps={{
                                                typography: {
                                                    variant: "body2",
                                                },
                                            }}
                                        />
                                    </FormGroup>
                                )}
                            />
                        </Grid>
                        {isCharge && (
                            <>
                                <Grid item xs={12}>
                                    <Stack
                                        display="flex"
                                        direction="row"
                                        spacing={1}
                                        sx={{
                                            justifyContent: "flex-start",
                                            marginRight: { xs: 0, sm: 2 },
                                        }}
                                    >
                                        <Button
                                            size="medium"
                                            variant={
                                                chargeMode === "nominal"
                                                    ? "contained"
                                                    : "outlined"
                                            }
                                            onClick={() => {
                                                setValue(
                                                    "charge.mode",
                                                    "nominal",
                                                );
                                                setValue("charge.charge", 0);
                                            }}
                                        >
                                            Nominal
                                        </Button>
                                        <Button
                                            size="medium"
                                            variant={
                                                chargeMode === "persen"
                                                    ? "contained"
                                                    : "outlined"
                                            }
                                            onClick={() => {
                                                setValue(
                                                    "charge.mode",
                                                    "persen",
                                                );
                                                setValue("charge.charge", 0);
                                            }}
                                        >
                                            persen
                                        </Button>
                                    </Stack>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Controller
                                        name="charge.charge"
                                        control={control}
                                        render={({ field }) => (
                                            <NumberFormat
                                                customInput={TextField}
                                                label="Charge"
                                                // margin="dense"
                                                thousandSeparator="."
                                                decimalSeparator=","
                                                fullWidth
                                                variant="outlined"
                                                onValueChange={(values) => {
                                                    const { value } = values;
                                                    const valueAkhir =
                                                        value.replace(".", "");
                                                    console.log(valueAkhir);
                                                    setValue(
                                                        "charge.charge",
                                                        Number(valueAkhir),
                                                    );
                                                }}
                                                error={Boolean(
                                                    errors.charge?.charge,
                                                )}
                                                helperText={
                                                    errors.charge?.charge
                                                        ? errors.charge?.charge
                                                              .message
                                                        : ""
                                                }
                                                InputProps={{
                                                    startAdornment:
                                                        chargeMode ===
                                                            "nominal" && (
                                                            <InputAdornment position="start">
                                                                <Typography>
                                                                    Rp.
                                                                </Typography>
                                                            </InputAdornment>
                                                        ),
                                                    endAdornment: chargeMode ===
                                                        "persen" && (
                                                        <InputAdornment position="end">
                                                            <Typography>
                                                                %
                                                            </Typography>
                                                        </InputAdornment>
                                                    ),
                                                }}
                                                inputProps={{
                                                    inputMode: "numeric",
                                                    style: {
                                                        textAlign:
                                                            chargeMode ===
                                                            "persen"
                                                                ? "right"
                                                                : undefined,
                                                    },
                                                }}
                                                {...field}
                                            />
                                        )}
                                    />
                                </Grid>
                            </>
                        )}
                        <Grid item xs={12}>
                            <Box marginTop={2} marginBottom={1}>
                                <Typography variant="body1">
                                    Gambar QR
                                </Typography>
                                <Typography variant="body2" color="#545E6A">
                                    Silahkan upload gambar QR untuk metode
                                    pembayaran (opsional).
                                </Typography>
                            </Box>
                            <Stack>
                                <Controller
                                    name="gambar"
                                    control={control}
                                    render={({ field }) => (
                                        <TextField
                                            id="gambar"
                                            fullWidth
                                            type="file"
                                            variant="outlined"
                                            inputProps={{
                                                accept: "image/*",
                                            }}
                                            error={Boolean(errors.gambar)}
                                            helperText={
                                                errors.gambar
                                                    ? errors.gambar.message
                                                    : ""
                                            }
                                            {...field}
                                            // value={field.value || ""}
                                            onChange={(event: any) => {
                                                if (
                                                    event.target.files[0].size <
                                                    2097152
                                                ) {
                                                    console.log(
                                                        event.target.files[0],
                                                    );
                                                    setValue(
                                                        "gambar",
                                                        event.target.files[0],
                                                    );
                                                    clearErrors("gambar");
                                                } else {
                                                    event.target.value = "";
                                                    setError("gambar", {
                                                        type: "manual",
                                                        message:
                                                            "Ukuran gambar harus kurang dari 2MB",
                                                    });
                                                }
                                            }}
                                            value={undefined}
                                        />
                                    )}
                                />
                                <Typography variant="caption" color="#6D6D6D">
                                    *Resolusi optimal: 300 x 300
                                </Typography>
                                <Typography variant="caption" color="#6D6D6D">
                                    *Ukuran maksimal: 2MB
                                </Typography>
                            </Stack>
                        </Grid>
                    </Grid>
                    <LoadingButton
                        loading={isButtonLoading}
                        loadingPosition="center"
                        variant="contained"
                        onClick={handleSubmit(onSubmit)}
                    >
                        Simpan
                    </LoadingButton>
                </Stack>
            </DialogContent>
        </Dialog>
    );
};

export default ModalMetodePembayaran;
