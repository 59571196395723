import { Box } from "@mui/material";
import React from "react";
import { Route, Redirect, RouteProps } from "react-router-dom";
import BuatTokoStep from "../../components/BuatTokoStep/BuatTokoStep";
import Spinner from "../../components/Spinner/Spinner";
import useUser from "../../services/queries/useUser";

interface IBuatTokoPageProps {}

const BuatTokoPage = ({ ...rest }: IBuatTokoPageProps & RouteProps) => {
    const { data: user, status: statusUser } = useUser();

    if (statusUser === "loading") {
        return (
            <Box position="relative" height="100vh">
                <Spinner />
            </Box>
        );
    }

    return (
        <Route
            {...rest}
            render={({ location }) =>
                user?.semuaUkmId.length === 0 ? (
                    <BuatTokoStep />
                ) : (
                    <Redirect
                        to={{
                            pathname:
                                // user?.aksesTransaksi === 0 &&
                                // user.aksesStok === 0
                                //     ? "/laporan"
                                //     : user?.aksesTransaksi === 0 &&
                                //       user.aksesLaporan === 0
                                //     ? "/stok"
                                //     : user?.aksesTransaksi === 0
                                //     ? "/stok"
                                //     : "/transaksi",
                                "/transaksi",
                            state: { from: location },
                        }}
                    />
                )
            }
        />
    );
};

export default BuatTokoPage;
