// import { useTheme } from "@mui/material/styles";
// import useMediaQuery from "@mui/material/useMediaQuery";
import React, { useEffect, useState } from "react";
import { useAuth } from "../../context/authContext";
import moment from "moment";
import "moment/locale/id";
import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import id from "date-fns/locale/id";
import { useTransaksi } from "../../context/transaksiContext";
import { ErrorFieldResponse, ParamsGetStokOpname } from "../../constants/types";
import useStokOpname from "../../services/queries/useStokOpname";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
// import Select from "@mui/material/Select";
import ArrowDropDown from "@mui/icons-material/ArrowDropDown";
// import Button from "@mui/material/Button";
import Add from "@mui/icons-material/Add";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import Tooltip from "@mui/material/Tooltip";
import LoadingButton from "@mui/lab/LoadingButton";
import ImportExport from "@mui/icons-material/ImportExport";
import LoadingTabel from "../../components/LoadingTabel/LoadingTabel";
import NoRowsImage from "../../components/NoRowsImage/NoRowsImage";
import axios from "../../services/axios";
import defaultAxios, { AxiosError } from "axios";
import Swal from "sweetalert2";
import ModalProsesOpname from "../../components/ModalProsesOpname/ModalProsesOpname";
import Chip from "@mui/material/Chip";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import DownloadIcon from "@mui/icons-material/Download";
import ModalUnduhStokOpname from "../../components/ModalUnduhStokOpname/ModalUnduhStokOpname";

moment.locales();
moment.locale("id");

registerLocale("id", id);
setDefaultLocale("id");

const StokOpnamePage = () => {
    const { ukmIdUser } = useAuth();
    const { isFetchingItems, cancelFetchingItems } = useTransaksi();
    let now = moment().format("YYYY/MM/DD");
    const today = new Date();
    const [selectedDate, setSelectedDate] = useState<Date | null>(new Date());
    const initialParamsStokOpname = {
        ukmId: ukmIdUser && ukmIdUser[0],
        size: 10,
        page: 1,
        tanggal: now,
    };
    const [paramsStokOpname, setParamsStokOpname] =
        useState<ParamsGetStokOpname>(initialParamsStokOpname);
    const {
        data: stokOpname,
        refetch: refetchStokOpname,
        isLoading: isLoadingStokOpname,
    } = useStokOpname(paramsStokOpname);
    // const [dataOpname, setDataOpname] =
    // useState<ContentGetStokOpnameResponse | null>(null);

    const [isButtonLoading, setIsButtonLoading] = useState(false);
    const [isOpenModalProsesOpname, setIsOpenModalProsesOpname] =
        useState(false);
    const [isOpenModalUnduhStokOpname, setIsOpenModalUnduhStokOpname] =
        useState(false);
    // const [isOpenModalProsesOpnameSatuan, setIsOpenModalProsesOpnameSatuan] =
    // useState(false);

    const openModalProsesOpname = () => {
        setIsOpenModalProsesOpname(true);
    };

    const closeModalProsesOpname = () => {
        setIsOpenModalProsesOpname(false);
    };

    const openModalUnduhStokOpname = () => {
        setIsOpenModalUnduhStokOpname(true);
    };

    const closeModalUnduhStokOpname = () => {
        setIsOpenModalUnduhStokOpname(false);
    };

    // const openModalProsesOpnameSatuan = (
    //     data: ContentGetStokOpnameResponse,
    // ) => {
    //     setDataOpname(data);
    //     setIsOpenModalProsesOpnameSatuan(true);
    // };

    // const closeModalProsesOpnameSatuan = () => {
    //     setIsOpenModalProsesOpnameSatuan(false);
    // };

    const handleInisialisasiOpname = async () => {
        setIsButtonLoading(true);
        try {
            const body = {
                tanggal: paramsStokOpname.tanggal,
                ukmId: ukmIdUser && ukmIdUser[0],
            };
            const { data } = await axios.post(`/api/opname-init`, body);
            if (data.code === 200) {
                Swal.fire({
                    title: data.message ?? "Stok opname berhasil dimulai",
                    position: "top-end",
                    showConfirmButton: false,
                    icon: "success",
                    toast: true,
                    timer: 3000,
                    timerProgressBar: true,
                    showCloseButton: true,
                    customClass: {
                        container: "my-swal",
                    },
                });
                refetchStokOpname();
            }
            setIsButtonLoading(false);
        } catch (error) {
            if (defaultAxios.isAxiosError(error)) {
                const serverError = error as AxiosError<
                    ErrorFieldResponse | undefined
                >;
                if (serverError && serverError?.response) {
                    const fieldError = serverError?.response?.data;
                    Swal.fire({
                        title: `${fieldError?.message ?? "Terjadi Kesalahan!"}`,
                        position: "top-end",
                        showConfirmButton: false,
                        icon: "error",
                        toast: true,
                        timer: 3000,
                        timerProgressBar: true,
                        showCloseButton: true,
                        customClass: {
                            container: "my-swal",
                        },
                    });
                }
            }
            console.error(error);
            setIsButtonLoading(false);
        }
    };

    const handleChangeJangkaWaktu = (tanggal: string) => {
        setParamsStokOpname((prev) => ({
            ...prev,
            tanggal: tanggal,
            page: 1,
        }));
        refetchStokOpname();
    };

    const handleOnPagination = (page: number) => {
        setParamsStokOpname((prev) => ({
            ...prev,
            page: page + 1,
        }));
    };

    const handleOnSizeChange = (size: number) => {
        setParamsStokOpname((prev) => ({ ...prev, size }));
    };

    const columns: GridColDef[] = [
        // {
        //     field: "tanggal",
        //     headerName: "Tanggal",
        //     minWidth: 160,
        //     renderCell: (params) => (
        //         <Tooltip
        //             title={
        //                 params.value ? moment(params.value).format("LL") : "-"
        //             }
        //         >
        //             <Typography variant="body2">
        //                 {params.value ? moment(params.value).format("LL") : "-"}
        //             </Typography>
        //         </Tooltip>
        //     ),
        // },
        {
            field: "stok",
            headerName: "Nama Stok",
            flex: 1,
            minWidth: 500,
            renderCell: (params) => (
                <Stack direction="row" spacing={2} alignItems="flex-start">
                    <Stack direction="column" spacing={0.3}>
                        <Tooltip title={params.value.nama}>
                            <Typography
                                component="div"
                                variant="subtitle2"
                                fontWeight="bold"
                            >
                                <Typography
                                    variant="subtitle2"
                                    display="inline"
                                >
                                    {params.value.nama}
                                </Typography>
                            </Typography>
                        </Tooltip>
                        <Tooltip title={params.value.sku}>
                            <Typography variant="caption">
                                {params.value.sku}
                            </Typography>
                        </Tooltip>
                    </Stack>
                    {params.row.isProses === 1 ? (
                        Number(params.row.jumlahAsli) ===
                        Number(params.row.jumlahOpname) ? (
                            <Chip
                                label="Jumlah sama"
                                onDelete={() => {}}
                                deleteIcon={<CheckIcon />}
                                variant="outlined"
                                color="buttongreen"
                                size="small"
                            />
                        ) : (
                            <Chip
                                label="Jumlah berbeda"
                                onDelete={() => {}}
                                deleteIcon={<CloseIcon />}
                                variant="outlined"
                                color="buttonred"
                                size="small"
                            />
                        )
                    ) : null}
                </Stack>
            ),
        },
        {
            field: "jumlahAsli",
            headerName: "Jumlah Asli",
            minWidth: 170,
            renderCell: (params) => (
                <Tooltip title={params.value}>
                    <span className="table-cell-trucate">{params.value}</span>
                </Tooltip>
            ),
        },
        {
            field: "jumlahOpname",
            headerName: "Jumlah Opname",
            minWidth: 170,
            renderCell: (params) => (
                <Tooltip title={params.value ?? "-"}>
                    <Typography variant="body2">
                        {params.value ?? "-"}
                    </Typography>
                </Tooltip>
            ),
        },
        // {
        //     field: "ukm",
        //     headerName: "UKM",
        //     flex: isLaptopScreen ? 0.6 : undefined,
        //     minWidth: 160,
        //     renderCell: (params) => {
        //         return (
        //             <Typography variant="body2">
        //                 {params.row.ukm.nama}
        //             </Typography>
        //         );
        //     },
        // },
        {
            field: "isProses",
            headerName: "Sudah diproses?",
            minWidth: 170,
            renderCell: (params) => {
                return (
                    <Tooltip title={params.value === 1 ? "Sudah" : "Belum"}>
                        <Typography
                            variant="body2"
                            color={params.value === 1 ? "#61C15F" : "#FF7373"}
                        >
                            {params.value === 1 ? "Sudah" : "Belum"}
                        </Typography>
                    </Tooltip>
                );
            },
        },
        // {
        //     field: "aksi",
        //     headerName: "Aksi",
        //     headerAlign: "center",
        //     minWidth: 150,
        //     sortable: false,
        //     disableColumnMenu: true,
        //     align: "center",
        //     renderCell: (params) => {
        //         const row: ContentGetStokOpnameResponse = params.row;
        //         return (
        //             <Stack direction="row" spacing={2}>
        //                 <Tooltip title="Lihat Detail">
        //                     <span>
        //                         <Button
        //                             variant="contained"
        //                             onClick={() => {
        //                                 openModalProsesOpnameSatuan(row);
        //                             }}
        //                             size="small"
        //                             color="buttongreen"
        //                             disableElevation
        //                             sx={{
        //                                 minWidth: "unset",
        //                                 padding: "8px",
        //                                 width: "32px",
        //                                 height: "32px",
        //                             }}
        //                         >
        //                             <Check
        //                                 sx={{ width: "16px", height: "16px" }}
        //                             />
        //                         </Button>
        //                     </span>
        //                 </Tooltip>
        //             </Stack>
        //         );
        //     },
        // },
    ];

    useEffect(() => {
        if (isFetchingItems === true) {
            refetchStokOpname();
            cancelFetchingItems();
        }
    }, [cancelFetchingItems, isFetchingItems, refetchStokOpname]);

    return (
        <Box>
            <Box>
                <Stack spacing={2} marginY={2}>
                    <Typography variant="h6" fontWeight="bold">
                        Stok Opname
                    </Typography>
                    <Grid
                        container
                        columnGap={3}
                        rowGap={2}
                        alignItems="center"
                    >
                        <Grid item xs={12} md={12} xl={7}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} md={4}>
                                    <DatePicker
                                        className="range-filter"
                                        dateFormat="dd/MM/yyyy"
                                        disabledKeyboardNavigation
                                        onFocus={(e) => e.target.blur()}
                                        selected={selectedDate}
                                        onChange={(update) => {
                                            setSelectedDate(update);
                                            handleChangeJangkaWaktu(
                                                moment(update).format(
                                                    "YYYY/MM/DD",
                                                ),
                                            );
                                        }}
                                        maxDate={today}
                                        monthsShown={1}
                                        customInput={
                                            <Stack
                                                display="flex"
                                                justifyContent="space-between"
                                                direction="row"
                                                // width="100%"
                                                bgcolor="#FFFF"
                                                height="40px"
                                                sx={{
                                                    border: "1px solid #cbcbcb",
                                                    borderRadius: "4px",
                                                    alignItems: "center",
                                                    padding: 2,
                                                    cursor: "pointer",
                                                }}
                                            >
                                                <Typography>
                                                    {selectedDate?.toLocaleString(
                                                        "id",
                                                        {
                                                            year: "numeric",
                                                            month: "long",
                                                            day: "numeric",
                                                        },
                                                    )}
                                                </Typography>
                                                <ArrowDropDown
                                                    sx={{
                                                        marginRight: "-9px",
                                                    }}
                                                />
                                            </Stack>
                                        }
                                    />
                                </Grid>
                                <Grid item xs={12} md={8}>
                                    <Stack direction="row" spacing={1}>
                                        <LoadingButton
                                            disabled={
                                                stokOpname &&
                                                stokOpname.totalElements > 0
                                            }
                                            loading={isButtonLoading}
                                            loadingPosition="start"
                                            variant="contained"
                                            startIcon={<Add />}
                                            disableElevation
                                            fullWidth
                                            onClick={handleInisialisasiOpname}
                                        >
                                            Mulai Opname
                                        </LoadingButton>
                                        <LoadingButton
                                            disabled={
                                                stokOpname &&
                                                stokOpname.content.length > 0
                                                    ? stokOpname.content.filter(
                                                          (li) =>
                                                              li.isProses === 1,
                                                      ).length ===
                                                      stokOpname.totalElements
                                                        ? true
                                                        : false
                                                    : true
                                            }
                                            loading={isLoadingStokOpname}
                                            loadingPosition="start"
                                            variant="contained"
                                            startIcon={<CheckIcon />}
                                            disableElevation
                                            fullWidth
                                            onClick={openModalProsesOpname}
                                        >
                                            Proses Opname
                                        </LoadingButton>
                                    </Stack>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs md={0} xl></Grid>
                        {/* HIDE DULU UNTUK SEMENTARA */}
                        <Grid item xs={12} md={4} lg={3} xl={2}>
                            <Button
                                variant="outlined"
                                onClick={openModalUnduhStokOpname}
                                startIcon={<DownloadIcon />}
                                disableElevation
                                fullWidth
                            >
                                Unduh Opname
                            </Button>
                        </Grid>
                    </Grid>
                    <Box
                        marginTop={2}
                        sx={{
                            height: 631,
                            width: "100%",
                            borderRadius: 1,
                            "& .headerColumn": {
                                backgroundColor: "#E4EEE8",
                            },
                            "& .MuiDataGrid-columnHeaderTitle": {
                                fontWeight: "bold",
                            },
                            "& .MuiDataGrid-columnSeparator": {
                                visibility: "hidden",
                            },
                            "& .MuiDataGrid-cell:focus-within": {
                                outline: "none !important",
                            },
                            "& .MuiDataGrid-iconButtonContainer": {
                                visibility: "visible",
                                width: "0 !important",
                            },
                        }}
                    >
                        <DataGrid
                            pagination
                            rows={stokOpname?.content ?? []}
                            columns={columns}
                            components={{
                                ColumnUnsortedIcon: () => {
                                    return <ImportExport />;
                                },
                                NoRowsOverlay: NoRowsImage,
                                LoadingOverlay: LoadingTabel,
                            }}
                            rowsPerPageOptions={[10]}
                            disableColumnMenu
                            disableSelectionOnClick
                            paginationMode="server"
                            onPageSizeChange={handleOnSizeChange}
                            onPageChange={handleOnPagination}
                            rowCount={stokOpname?.totalElements ?? 0}
                            page={paramsStokOpname.page - 1}
                            pageSize={paramsStokOpname.size}
                            loading={isLoadingStokOpname}
                        />
                    </Box>
                </Stack>
            </Box>
            {stokOpname && stokOpname.content && (
                <ModalProsesOpname
                    open={isOpenModalProsesOpname}
                    onClose={closeModalProsesOpname}
                    tanggal={paramsStokOpname.tanggal}
                    data={stokOpname.content}
                />
            )}
            <ModalUnduhStokOpname
                isOpenModalUnduhStokOpname={isOpenModalUnduhStokOpname}
                closeModalUnduhStokOpname={closeModalUnduhStokOpname}
            />
        </Box>
    );
};

export default StokOpnamePage;
