import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-scroll";
import Box from "@mui/material/Box";
import Fab from "@mui/material/Fab";
import Grid from "@mui/material/Grid";
import Badge from "@mui/material/Badge";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import ModalStok from "../../components/ModalStok/ModalStok";
import ListPesan from "../../components/ListPesan/ListPesan";
import ListBarang from "../../components/ListBarang/ListBarang";
import ModalDiskon from "../../components/ModalDiskon/ModalDiskon";
// import TokoTidakAktif from "../../components/TokoTidakAktif/TokoTidakAktif";
import ModalDetailBarang from "../../components/ModalDetailBarang/ModalDetailBarang";
// import { useAuth } from "../../context/authContext";
import useUkm from "../../services/queries/useUkm";
// import useItem from "../../services/queries/useItem";
import useUser from "../../services/queries/useUser";
// import useKategori from "../../services/queries/useKategori";
import scrollToHash from "../../utils/scrollToHash";
import { getLocalUkmId, setLocalUkmId } from "../../utils/handleChangeUkmId";
// import {
//     ParamsGetItem,
//     ParamsGetKategori,
//     ParamsGetSubKategori,
// } from "../../constants/types";
import { useTransaksi } from "../../context/transaksiContext";
import Spinner from "../../components/Spinner/Spinner";
// import useTime from "../../services/queries/useTime";
import moment from "moment";
import "moment/locale/id";
// import useSubKategori from "../../services/queries/useSubKategori";

moment.locales();
moment.locale("id");

interface ITransaksi {}

const Transaksi = (props: ITransaksi) => {
    // const { ukmIdUser } = useAuth();
    const { data: user, status: statusUser } = useUser();
    const theme = useTheme();
    const isPhoneScreen = useMediaQuery(theme.breakpoints.down("md"));
    const isTabletScreen = useMediaQuery(theme.breakpoints.between("md", "lg"));
    const isLaptopScreen = useMediaQuery(theme.breakpoints.up("lg"));
    // const initialParamsItem = {
    //     ukmId: undefined,
    //     size: 1500,
    //     page: 1,
    //     search: "",
    //     subKategori: "",
    // };

    // const [paramsItem, setParamsItem] =
    //     useState<ParamsGetItem>(initialParamsItem);
    // const { refetch: refetchItem } = useItem(paramsItem);
    // const initialParamsSubKategori = {
    //     ukmId: undefined,
    //     size: 500,
    //     page: 1,
    //     search: "",
    // };
    // const [paramsSubKategori, setParamsSubKategori] =
    //     useState<ParamsGetSubKategori>(initialParamsSubKategori);
    // const { refetch: refetchSubKategori } = useSubKategori(paramsSubKategori);
    const initialParamsUkm = {
        size: 500,
        page: 1,
        search: "",
    };
    const { data: ukm, status: statusUkm } = useUkm(initialParamsUkm);
    // const { data: time, status: statusTime } = useTime();

    const [isCardView, setIsCardView] = useState(false);
    const { cart } = useTransaksi();

    const handleCardView = useCallback(() => setIsCardView(true), []);
    const handleListView = useCallback(() => setIsCardView(false), []);

    const ukmId = React.useMemo(() => {
        if (user?.userId) {
            return getLocalUkmId(user?.userId);
        }
        return null;
    }, [user?.userId]);

    // const ukmData = React.useMemo(() => {
    //     if (ukmId) {
    //         return ukm?.content.find((li) => li.id === ukmId);
    //     }
    //     return ukm?.content[0];
    // }, [ukm?.content, ukmId]);

    // useEffect(() => {
    //     if (ukmIdUser) {
    //         setParamsItem((prev) => ({
    //             ...prev,
    //             ukmId: ukmIdUser && ukmIdUser[0],
    //         }));
    //         setParamsSubKategori((prev) => ({
    //             ...prev,
    //             ukmId: ukmIdUser && ukmIdUser[0],
    //         }));
    //     }
    // }, [ukmIdUser]);

    useEffect(() => {
        scrollToHash();
    }, []);

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const queryUkmId = urlParams.get("redirect");

    useEffect(() => {
        if (queryUkmId) {
            user?.userId && setLocalUkmId(user?.userId, Number(queryUkmId));
        }
    }, [queryUkmId, user?.userId]);

    useEffect(() => {
        if (queryUkmId && user && ukm && Number(queryUkmId) !== ukmId) {
            // window.location.reload();
            window.location.replace("/transaksi");
        } else if (queryUkmId && user && ukm && Number(queryUkmId) === ukmId) {
            window.location.replace("/transaksi");
        }
    }, [queryUkmId, ukm, ukmId, user]);

    // const isSudahJatuhTempo = moment(time?.datetime).isAfter(
    //     ukmData?.pembayaranBerikutnya,
    // );

    if (
        statusUser === "loading" ||
        statusUkm === "loading" ||
        // statusTime === "loading" ||
        (queryUkmId && Number(queryUkmId) !== ukmId)
    ) {
        return (
            <Box
                position="relative"
                height={isLaptopScreen ? "100vh" : "calc(100vh - 56px)"}
            >
                <Spinner />
            </Box>
        );
    }

    // if (user?.aksesTransaksi === 0 || user?.aksesTransaksi === null) {
    //     return <TokoTidakAktif label="Anda tidak memiliki akses halaman ini" />;
    // }

    // if (
    //     ukmData &&
    //     (ukmData?.isActive === 0 ||
    //         ukmData?.isActive === null ||
    //         ukmData?.lewatTempoPro === 1 ||
    //         isSudahJatuhTempo)
    // ) {
    //     return <TokoTidakAktif namaToko={ukmData.namaToko} />;
    // }

    return (
        <Box bgcolor="#ffffff">
            <Grid container>
                <Grid
                    item
                    xs={12}
                    sm={12}
                    md={isTabletScreen ? 5.5 : isCardView ? 7 : 6}
                    sx={{
                        overflowY: "scroll",
                    }}
                >
                    <ListBarang
                        isCardView={isCardView}
                        cardView={handleCardView}
                        listView={handleListView}
                    />
                </Grid>
                <Grid
                    id="barangPesanan"
                    item
                    xs={12}
                    sm={12}
                    md={isTabletScreen ? 6.5 : isCardView ? 5 : 6}
                    sx={{
                        borderLeft: isPhoneScreen
                            ? undefined
                            : "1px solid rgba(0, 0, 0, 0.12)",
                        borderRight: isPhoneScreen
                            ? undefined
                            : "1px solid rgba(0, 0, 0, 0.12)",
                    }}
                >
                    <ListPesan />
                </Grid>
            </Grid>
            <Link
                activeClass="active"
                to="barangPesanan"
                spy={true}
                smooth={true}
                duration={500}
            >
                <Box
                    sx={{ display: { xs: "block", md: "none" } }}
                    position="fixed"
                    bottom={70}
                    right={16}
                >
                    <Badge
                        badgeContent={cart.length}
                        color="secondary"
                        overlap="circular"
                    >
                        <Fab color="primary" aria-label="add">
                            <ShoppingCartIcon />
                        </Fab>
                    </Badge>
                </Box>
            </Link>
            <ModalStok />
            <ModalDetailBarang />
            <ModalDiskon />
        </Box>
    );
};

export default Transaksi;
