import React, { useEffect, useMemo, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import formatHarga from "../../../utils/formatHarga";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import NumberFormat from "react-number-format";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import InputAdornment from "@mui/material/InputAdornment";
import toRupiahDesimal from "../../../utils/toRupiahDesimal";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import ArrowDropDown from "@mui/icons-material/ArrowDropDown";
import id from "date-fns/locale/id";
import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import Autocomplete from "@mui/material/Autocomplete";
import useUkm from "../../../services/queries/useUkm";
import { ParamsGetItem } from "../../../constants/types";
import useItem from "../../../services/queries/useItem";

registerLocale("id", id);
setDefaultLocale("id");

interface IItemTerpilihProps {
    id: number;
    nama: string;
    sku: string;
    idx: number;
}

type IOptionsStokPusat = {
    id: string | number;
    nama: string;
    harga: string;
};

interface IValues {
    ukmId?: number;
    isKodeFaktur: boolean;
    kodeFaktur: string;
    tanggal?: Date | null;
    // supplier?: {
    //     id: number;
    //     nama: string;
    // };
    detail: {
        isTanggalExp: boolean;
        tanggalExp?: Date | null;
        stokPusat?: {
            id: number;
            nama: string;
            harga: string;
        };
        stokId: number;
        hargaBeli: string | number;
        jumlah: string | number;
        keterangan: string;
        totalHarga: string | number;
    }[];
}

const ItemTerpilih = ({ id, nama, sku, idx }: IItemTerpilihProps) => {
    const today = new Date();
    const { control, setValue, register, watch } = useFormContext<IValues>();
    const isTanggalExp = watch(`detail.${idx}.isTanggalExp`);
    const watchJumlah = watch(`detail.${idx}.jumlah`);
    const watchHargaBeli = watch(`detail.${idx}.hargaBeli`);
    const watchTotalHarga = watch(`detail.${idx}.totalHarga`);

    const initialParamsUkm = {
        size: 500,
        page: 1,
        search: "",
    };
    const { data: ukm } = useUkm(initialParamsUkm);
    const selectedUkm = ukm && ukm.content.find((li) => li.isPusat === 1);

    const initialParamsStok: ParamsGetItem = useMemo(() => {
        return {
            ukmId: selectedUkm?.id,
            search: "",
            subKategori: "",
            size: 10,
            page: 1,
        };
    }, [selectedUkm?.id]);

    const [paramsStok, setParamsStok] = useState(initialParamsStok);
    const { data: stok, isLoading: isLoadingStok } = useItem(paramsStok);
    const mappedStok = stok?.content
        .filter((st) => st.detailBundling.length === 0)
        .map((li) => ({
            id: li.id,
            nama: li.namaBarang,
            harga: li.harga,
        }));

    useEffect(() => {
        if (selectedUkm) {
            setParamsStok((prev) => ({
                ...prev,
                ukmId: selectedUkm.id,
            }));
        }
    }, [selectedUkm]);

    return (
        <Box alignItems="end">
            <Box marginTop={3}>
                <Typography fontWeight="bold">{`${
                    idx + 1
                }. ${nama} (${sku})`}</Typography>
                <input
                    type="hidden"
                    {...register(`detail.${idx}.stokId`)}
                    value={id}
                />
                <Grid
                    container
                    alignItems="flex-start"
                    columnSpacing={1}
                    marginTop={1}
                >
                    <Grid item xs={12} sm={6} md={3}>
                        <Typography fontWeight={500}>
                            Stok dari pusat:
                        </Typography>
                        <Controller
                            name={`detail.${idx}.stokPusat`}
                            control={control}
                            render={({ field, fieldState }) => (
                                <Autocomplete
                                    disablePortal
                                    disableClearable
                                    loading={isLoadingStok}
                                    options={mappedStok ?? []}
                                    getOptionLabel={(option) => option.nama}
                                    isOptionEqualToValue={(option, value) =>
                                        option.id === value.id
                                    }
                                    size="small"
                                    value={field.value}
                                    onInputChange={(e) => {
                                        if (e) {
                                            const element =
                                                e.target as HTMLInputElement;
                                            setParamsStok((prev) => ({
                                                ...prev,
                                                search: element.value,
                                            }));
                                        } else {
                                            return;
                                        }
                                    }}
                                    onChange={(
                                        _event,
                                        newOption: IOptionsStokPusat,
                                    ) => {
                                        field.onChange(newOption);
                                        setValue(
                                            `detail.${idx}.hargaBeli`,
                                            String(Number(newOption.harga)),
                                        );
                                        setValue(`detail.${idx}.jumlah`, 1);
                                        setValue(
                                            `detail.${idx}.totalHarga`,
                                            String(Number(newOption.harga)),
                                        );
                                    }}
                                    fullWidth
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            placeholder="Pilih stok dari pusat"
                                            error={!!fieldState?.error}
                                            helperText={
                                                fieldState?.error &&
                                                "Pilih satu stok"
                                            }
                                        />
                                    )}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <Typography fontWeight={500}>Jumlah Stok:</Typography>
                        <Controller
                            name={`detail.${idx}.jumlah`}
                            control={control}
                            render={({ field, fieldState }) => (
                                <NumberFormat
                                    customInput={TextField}
                                    variant="outlined"
                                    placeholder="Jumlah Stok"
                                    size="small"
                                    fullWidth
                                    id={`detail.${idx}.jumlahStok`}
                                    error={!!fieldState?.error}
                                    helperText={
                                        fieldState?.error
                                            ? fieldState?.error.message
                                            : " "
                                    }
                                    {...field}
                                    onChange={(
                                        event: React.ChangeEvent<
                                            | HTMLInputElement
                                            | HTMLTextAreaElement
                                        >,
                                    ) => {
                                        field.onChange(
                                            String(event.target.value),
                                        );
                                        const totalNominal =
                                            Number(
                                                formatHarga(
                                                    String(watchHargaBeli),
                                                ),
                                            ) *
                                            Number(
                                                formatHarga(
                                                    String(event.target.value),
                                                ),
                                            );
                                        setValue(
                                            `detail.${idx}.totalHarga`,
                                            totalNominal,
                                        );
                                    }}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <Typography fontWeight={500} marginLeft={3}>
                            Harga Beli (satuan):
                        </Typography>
                        <Stack direction="row" spacing={1} alignItems="center">
                            <Typography marginTop={-3}>@</Typography>
                            <Controller
                                name={`detail.${idx}.hargaBeli`}
                                control={control}
                                render={({ field, fieldState }) => (
                                    <NumberFormat
                                        customInput={TextField}
                                        placeholder="0"
                                        // margin="dense"
                                        thousandSeparator="."
                                        decimalSeparator=","
                                        size="small"
                                        fullWidth
                                        variant="outlined"
                                        error={!!fieldState?.error}
                                        helperText={
                                            fieldState?.error
                                                ? fieldState?.error.message
                                                : " "
                                        }
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <Typography>Rp.</Typography>
                                                </InputAdornment>
                                            ),
                                            readOnly: true,
                                        }}
                                        {...field}
                                        onChange={(
                                            event: React.ChangeEvent<
                                                | HTMLInputElement
                                                | HTMLTextAreaElement
                                            >,
                                        ) => {
                                            field.onChange(
                                                String(event.target.value),
                                            );
                                            const totalNominal =
                                                Number(
                                                    formatHarga(
                                                        String(watchJumlah),
                                                    ),
                                                ) *
                                                Number(
                                                    formatHarga(
                                                        String(
                                                            event.target.value,
                                                        ),
                                                    ),
                                                );
                                            setValue(
                                                `detail.${idx}.totalHarga`,
                                                totalNominal,
                                            );
                                        }}
                                    />
                                )}
                            />
                        </Stack>
                    </Grid>
                </Grid>
                <Grid
                    container
                    alignItems="flex-start"
                    columnSpacing={1}
                    marginTop={1}
                >
                    <Grid item xs={12} sm={6} md={3}>
                        <Typography fontWeight={500}>Total:</Typography>
                        <Typography component="div">
                            {toRupiahDesimal(Number(watchTotalHarga))}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container alignItems="center" spacing={1}>
                    <Grid item>
                        <Controller
                            name={`detail.${idx}.isTanggalExp`}
                            control={control}
                            render={({ field }) => (
                                <FormGroup>
                                    <FormControlLabel
                                        control={<Checkbox {...field} />}
                                        label="Centang jika ingin beri kadaluarsa"
                                        componentsProps={{
                                            typography: {
                                                variant: "body2",
                                            },
                                        }}
                                    />
                                </FormGroup>
                            )}
                        />
                    </Grid>
                    {isTanggalExp && (
                        <Grid item>
                            <Controller
                                name={`detail.${idx}.tanggalExp`}
                                control={control}
                                render={({ field }) => (
                                    <DatePicker
                                        className="range-filter"
                                        dateFormat="dd/MM/yyyy"
                                        popperPlacement="top-start"
                                        disabledKeyboardNavigation
                                        onFocus={(e) => e.target.blur()}
                                        startDate={field.value}
                                        onChange={(update) => {
                                            field.onChange(update);
                                        }}
                                        minDate={today}
                                        monthsShown={1}
                                        customInput={
                                            <Stack
                                                display="flex"
                                                justifyContent="space-between"
                                                direction="row"
                                                // width="100%"
                                                bgcolor="#FFFF"
                                                height="40px"
                                                sx={{
                                                    border: "1px solid #cbcbcb",
                                                    borderRadius: "4px",
                                                    alignItems: "center",
                                                    padding: 2,
                                                    cursor: "pointer",
                                                }}
                                            >
                                                <Typography>
                                                    {field.value?.toLocaleString(
                                                        "id",
                                                        {
                                                            year: "numeric",
                                                            month: "long",
                                                            day: "numeric",
                                                        },
                                                    )}
                                                </Typography>
                                                <ArrowDropDown
                                                    sx={{
                                                        marginRight: "-9px",
                                                    }}
                                                />
                                            </Stack>
                                        }
                                    />
                                )}
                            />
                        </Grid>
                    )}
                </Grid>
                <Controller
                    name={`detail.${idx}.keterangan`}
                    control={control}
                    render={({ field, fieldState }) => (
                        <TextField
                            variant="outlined"
                            placeholder="Keterangan"
                            size="small"
                            id="keterangan"
                            fullWidth
                            multiline
                            rows={3}
                            error={!!fieldState?.error}
                            helperText={
                                fieldState?.error && fieldState?.error.message
                            }
                            sx={{ marginTop: 1 }}
                            {...field}
                        />
                    )}
                />
            </Box>
        </Box>
    );
};

export default ItemTerpilih;
