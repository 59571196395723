import { ArrowBack } from "@mui/icons-material";
import LoadingButton from "@mui/lab/LoadingButton";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import defaultAxios, { AxiosError } from "axios";
import React, { useCallback, useState } from "react";
import Swal from "sweetalert2";
import axios from "../../services/axios";
import { useTransaksi } from "../../context/transaksiContext";

interface IModalDeleteMetodePembayaranProps {
    isOpenModalDeleteMetodePembayaran: boolean;
    closeModalDeleteMetodePembayaran: () => void;
    namaMetodePembayaran: string;
    idMetodePembayaran: string;
}

const ModalDeleteMetodePembayaran = ({
    isOpenModalDeleteMetodePembayaran,
    closeModalDeleteMetodePembayaran,
    namaMetodePembayaran,
    idMetodePembayaran,
}: IModalDeleteMetodePembayaranProps) => {
    const [isButtonLoading, setIsButtonLoading] = useState(false);
    const { setFetchingItems } = useTransaksi();

    const handleDelete = async (id: string | number | undefined) => {
        setIsButtonLoading(true);
        try {
            const { data } = await axios.delete(`/api/metode-bayar/${id}`);
            if (data.code === 200) {
                Swal.fire({
                    title: namaMetodePembayaran + " berhasil dihapus!",
                    position: "top-end",
                    showConfirmButton: false,
                    icon: "success",
                    toast: true,
                    timer: 3000,
                    timerProgressBar: true,
                    showCloseButton: true,
                    customClass: {
                        container: "my-swal",
                    },
                });
            }
            setFetchingItems();
            setIsButtonLoading(false);
            closeModalDeleteMetodePembayaran();
        } catch (error) {
            setIsButtonLoading(false);
            handleErrorResponse(error);
        }
    };

    const handleErrorResponse = useCallback(
        (error: any) => {
            if (defaultAxios.isAxiosError(error)) {
                const serverError = error as AxiosError<any>;
                if (serverError && serverError.response) {
                    Swal.fire({
                        title: "Terjadi Kesalahan!",
                        text: `${serverError.response.data.message}`,
                        icon: "error",
                        confirmButtonColor: "#45A779",
                        customClass: {
                            container: "my-swal",
                        },
                    });
                    closeModalDeleteMetodePembayaran();
                } else {
                    Swal.fire({
                        title: "Terjadi Kesalahan!",
                        text: `Telah Terjadi Kesalahan`,
                        icon: "error",
                        confirmButtonColor: "#45A779",
                        customClass: {
                            container: "my-swal",
                        },
                    });
                    closeModalDeleteMetodePembayaran();
                }
            }
        },
        [closeModalDeleteMetodePembayaran],
    );

    return (
        <Dialog
            maxWidth="xs"
            fullWidth={true}
            open={isOpenModalDeleteMetodePembayaran}
            onClose={closeModalDeleteMetodePembayaran}
            PaperProps={{
                sx: { borderRadius: 1 },
            }}
        >
            <DialogTitle>
                <Stack direction="row" spacing={1} alignItems="center">
                    <IconButton
                        onClick={() => {
                            closeModalDeleteMetodePembayaran();
                        }}
                    >
                        <ArrowBack />
                    </IconButton>
                    <Typography variant="h6" fontWeight="bold">
                        Hapus Kategori
                    </Typography>
                </Stack>
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {namaMetodePembayaran} akan dihapus secara permanen. Yakin
                    ingin menghapus?
                </DialogContentText>
            </DialogContent>
            <DialogActions sx={{ padding: 2 }}>
                <LoadingButton
                    loading={isButtonLoading}
                    loadingPosition="center"
                    variant="outlined"
                    onClick={() => handleDelete(idMetodePembayaran)}
                    color="error"
                >
                    Hapus
                </LoadingButton>
                <Button
                    variant="contained"
                    onClick={() => {
                        closeModalDeleteMetodePembayaran();
                    }}
                    color="error"
                >
                    Tidak
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ModalDeleteMetodePembayaran;
