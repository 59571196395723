import {
    Box,
    Button,
    Typography,
    FormControl,
    FormHelperText,
    OutlinedInput,
    useMediaQuery,
    Link,
    IconButton,
    InputAdornment,
    Checkbox,
    FormControlLabel,
    Grid,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useAuth } from "../../context/authContext";
// import { ReactComponent as LoginImage } from "../../assets/images/LoginImage.svg";
import LoginImage from "../../assets/images/IlustrasiMainMenu.png";
import LogoUlo from "../LogoUlo/LogoUlo";
import LoadingOverlay from "../LoadingOverlay/LoadingOverlay";
import { VisibilityOff, Visibility } from "@mui/icons-material";
import { Link as RouterLink } from "react-router-dom";
import ThemeDefaultComponent from "../ThemeDefaultComponent/ThemeDefaultComponent";
import Swal from "sweetalert2";
import Countdown from "react-countdown";

interface ILoginProps {}

interface ILoginInputs {
    email: string;
    password: string;
}

const schema = yup
    .object({
        email: yup
            .string()
            // .email("Format email salah")
            .required("Kolom wajib diisi"),
        password: yup.string().required("Kolom wajib diisi"),
    })
    .required();

const Login = (props: ILoginProps) => {
    const moment = require("moment");
    const theme = useTheme();
    const isPhoneScreen = useMediaQuery(theme.breakpoints.between("xs", "sm"));
    const isTabletScreen = useMediaQuery(theme.breakpoints.down("md"));
    const isLaptopScreen = useMediaQuery(theme.breakpoints.up("md"));
    const isDesktopScreen = useMediaQuery(theme.breakpoints.up("xl"));
    const { login, isLoading, loginCount, resetLoginCount } = useAuth();
    const [showPassword, setShowPassword] = useState(false);
    const [saveEmail, setSaveEmail] = useState(false);
    const [initEmail, setInitEmail] = useState<any>();

    const startTime = moment();
    const endTime = moment(localStorage.getItem("time")) ?? moment();
    const duration = moment.duration(endTime.diff(startTime));
    const initialSeconds = parseInt(duration.asSeconds()) % 60;
    const initialMinutes = parseInt(duration.asMinutes()) % 60;
    const timeoutId = useRef<any>(null);
    const [seconds, setSeconds] = useState<number>(
        localStorage.getItem("time") ? initialSeconds : 0,
    );
    const [minutes, setMinutes] = useState<number>(
        localStorage.getItem("time") ? initialMinutes : 0,
    );
    const thisYear = new Date().getFullYear();

    const localStorageTime = localStorage.getItem("time");

    const dateExpiredTime = moment(localStorage.getItem("time"));

    const dateNow = moment();
    const expiredTime = dateExpiredTime.diff(dateNow);

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const queryEmail = urlParams.get("email");

    // const queryUkmId = urlParams.get("redirect");

    const [startTimer, setStartTimer] = useState(false);
    // const { data: user } = useUser();

    const handleClickShowPassword = () => {
        setShowPassword((prev) => !prev);
    };

    const initialValues = useMemo(
        () => ({
            email: initEmail ?? "",
            password: "",
        }),
        [initEmail],
    );

    const {
        handleSubmit,
        control,
        watch,
        reset,
        formState: { errors },
    } = useForm<ILoginInputs>({
        resolver: yupResolver(schema),
        defaultValues: initialValues,
    });

    const watchEmail = watch("email");

    const handleSaveEmail = (checked: boolean) => {
        if (checked) {
            setSaveEmail(true);
        } else {
            setSaveEmail(false);
        }
    };

    const countTimer = useCallback(() => {
        if (seconds <= 0) {
            if (minutes <= 0) {
                localStorage.removeItem("time");
                setStartTimer(false);
            } else {
                setMinutes(minutes - 1);
                setSeconds(59);
            }
        } else {
            setSeconds(seconds - 1);
        }
    }, [seconds, minutes]);

    useEffect(() => {
        timeoutId.current = window.setTimeout(countTimer, 1000);
        // cleanup function
        return () => window.clearTimeout(timeoutId.current);
    }, [seconds, countTimer]);
    const add5Minutes = moment().add(5, "m");

    useEffect(() => {
        if (loginCount >= 5) {
            // setMinutes(2);
            setMinutes(5);
            setSeconds(0);
            setStartTimer(true);
            localStorage.setItem("time", add5Minutes);
            resetLoginCount();
        }
    }, [add5Minutes, loginCount, resetLoginCount]);

    useEffect(() => {
        if (localStorage.getItem("email")) {
            setSaveEmail(true);
        } else {
            setSaveEmail(false);
        }
    }, []);

    useEffect(() => {
        if (queryEmail) {
            reset({ email: queryEmail ?? "", password: "" });
        } else if (localStorage.getItem("email")) {
            reset({ email: localStorage.getItem("email") ?? "", password: "" });
        } else {
            setInitEmail("");
        }
    }, [queryEmail, reset]);

    const onSubmit = (data: ILoginInputs) => {
        if (loginCount >= 5) {
            Swal.fire({
                title: "Silahkan menunnggu!",
                text: `Anda harus menunggu sebelum bisa mencoba masuk kembali`,
                icon: "error",
                confirmButtonColor: "#45A779",
                customClass: {
                    container: "my-swal",
                },
            });
        } else {
            login(data);
        }
        if (saveEmail) {
            // localStorage.setItem("saveEmail", "true");
            localStorage.setItem("email", watchEmail);
        } else {
            // localStorage.setItem("saveEmail", "false");
            localStorage.removeItem("email");
        }
    };

    return (
        <ThemeDefaultComponent>
            <Box
                padding={3}
                display={isDesktopScreen ? undefined : "flex"}
                justifyContent={isDesktopScreen ? undefined : "center"}
                sx={{ backgroundColor: "#45A779" }}
            >
                <Box
                    sx={{
                        minHeight: "560px",
                        width: isTabletScreen ? undefined : "fit-content",
                        // width:
                        //     isPhoneScreen || isTabletScreen
                        //         ? undefined
                        //         : isLaptopScreen
                        //         ? "90%"
                        //         : "650px",
                        // height: isPhoneScreen
                        //     ? undefined
                        //     : isLaptopScreen
                        //     ? "90%"
                        //     : "550px",
                        position: isDesktopScreen ? "absolute" : undefined,
                        top: isDesktopScreen ? "50%" : undefined,
                        left: isDesktopScreen ? "50%" : undefined,
                        transform: isDesktopScreen
                            ? "translate(-50%, -50%)"
                            : undefined,
                        backgroundColor: "#FFF",
                        display:
                            isPhoneScreen || isTabletScreen ? "flex" : "grid",
                        justifyContent: "center",
                        gridTemplateColumns: isPhoneScreen
                            ? undefined
                            : "1fr 1fr",
                        borderRadius: 1,
                        paddingX: isTabletScreen ? 2 : undefined,
                        paddingY: 2,
                    }}
                >
                    {isTabletScreen ? (
                        ""
                    ) : (
                        <Box position="relative">
                            <Box
                                sx={{
                                    position: "absolute",
                                    top: "50%",
                                    left: "50%",
                                    transform: "translate(-50%, -50%)",
                                    height: "90%",
                                    width: "90%",
                                }}
                            >
                                <img
                                    alt="Ilustrasi Main"
                                    src={LoginImage}
                                    width="100%"
                                    height="100%"
                                    style={{ objectFit: "contain" }}
                                />
                            </Box>
                            <Box
                                position="absolute"
                                bottom={0}
                                left={24}
                                display="flex"
                                justifyContent="center"
                            >
                                <Typography
                                    variant="caption"
                                    component="div"
                                    textAlign="center"
                                >
                                    <Link
                                        variant="caption"
                                        target="_blank"
                                        href="https://nore.web.id/"
                                        underline="hover"
                                    >
                                        Nore Inovasi
                                    </Link>{" "}
                                    {`© ${thisYear}. All right reserved`}
                                </Typography>
                            </Box>
                        </Box>
                    )}
                    <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        justifyContent="center"
                        paddingY={3}
                        paddingX={isTabletScreen ? 2 : 4}
                        sx={{
                            backgroundColor: "#fff",
                            borderRadius: "inherit",
                        }}
                    >
                        <Link
                            href="https://ukmlokal.com/"
                            rel="noreferrer"
                            target="_blank"
                        >
                            <LogoUlo width="100px" />
                        </Link>
                        {isLaptopScreen ? (
                            <Typography
                                marginTop={2}
                                component="div"
                                textAlign="center"
                                variant="h6"
                                fontWeight="bold"
                            >
                                Tumbuh bersama UKM{" "}
                                <Typography
                                    variant="h6"
                                    fontWeight="bold"
                                    sx={{ color: "#20965F" }}
                                    display="inline"
                                >
                                    LOKAL
                                </Typography>
                            </Typography>
                        ) : (
                            <React.Fragment>
                                <Typography
                                    marginTop={2}
                                    textAlign="center"
                                    variant="h6"
                                    fontWeight="bold"
                                >
                                    Tumbuh bersama
                                </Typography>
                                <Typography
                                    component="div"
                                    textAlign="center"
                                    variant="h6"
                                    fontWeight="bold"
                                >
                                    UKM{" "}
                                    <Typography
                                        variant="h6"
                                        fontWeight="bold"
                                        sx={{ color: "#20965F" }}
                                        display="inline"
                                    >
                                        LOKAL
                                    </Typography>
                                </Typography>{" "}
                            </React.Fragment>
                        )}

                        {/* <Typography textAlign="center" variant="h4" fontWeight="bold">
                  Cuan bersama UKM&nbsp;
                </Typography>
                <Typography
                  textAlign="center"
                  variant="h4"
                  fontWeight="bold"
                  sx={{ color: "#20965F" }}
                >
                  LOKAL
                </Typography> */}
                        <form
                            style={{
                                width: "100%",
                                maxWidth: "500px",
                                minWidth: "200px",
                            }}
                            onSubmit={handleSubmit(onSubmit)}
                        >
                            <Typography
                                variant="h6"
                                fontWeight="bold"
                                color="#000"
                                fontSize="16px"
                                sx={{
                                    width: "fit-content",
                                    marginTop: 3,
                                    borderBottom: "1px solid #20965F",
                                    marginBottom: 2,
                                }}
                            >
                                Masuk
                            </Typography>
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <Typography variant="body2">
                                        Email atau Username
                                    </Typography>
                                    <Controller
                                        name="email"
                                        control={control}
                                        render={({ field }) => (
                                            <FormControl
                                                fullWidth
                                                variant="outlined"
                                                error={Boolean(errors.email)}
                                                sx={{
                                                    maxWidth: "500px",
                                                }}
                                            >
                                                <OutlinedInput
                                                    id="email"
                                                    // type="email"
                                                    autoComplete="email"
                                                    autoFocus={isLaptopScreen}
                                                    placeholder="Contoh: ulo@gmail.com"
                                                    size="small"
                                                    {...field}
                                                />
                                                <FormHelperText>
                                                    {errors.email
                                                        ? errors.email.message
                                                        : " "}
                                                </FormHelperText>
                                            </FormControl>
                                        )}
                                        rules={{ required: "Email required" }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="body2">
                                        Kata Sandi
                                    </Typography>
                                    <Controller
                                        name="password"
                                        control={control}
                                        render={({ field }) => (
                                            <FormControl
                                                fullWidth
                                                variant="outlined"
                                                error={Boolean(errors.password)}
                                                sx={{
                                                    maxWidth: "500px",
                                                }}
                                            >
                                                <OutlinedInput
                                                    id="password"
                                                    type={
                                                        showPassword
                                                            ? "text"
                                                            : "password"
                                                    }
                                                    autoComplete="password"
                                                    placeholder="Minimal 8 karakter"
                                                    endAdornment={
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                onClick={
                                                                    handleClickShowPassword
                                                                }
                                                                edge="end"
                                                            >
                                                                {showPassword ? (
                                                                    <VisibilityOff />
                                                                ) : (
                                                                    <Visibility />
                                                                )}
                                                            </IconButton>
                                                        </InputAdornment>
                                                    }
                                                    size="small"
                                                    {...field}
                                                />
                                                <FormHelperText>
                                                    {errors.password
                                                        ? errors.password
                                                              .message
                                                        : " "}
                                                </FormHelperText>
                                            </FormControl>
                                        )}
                                        rules={{
                                            required: "Kata sandi required",
                                        }}
                                    />
                                </Grid>
                            </Grid>
                            <Box display="flex" justifyContent="flex-end">
                                {/* <Link
                                    href={`https://api.whatsapp.com/send?phone=${noWaAdmin?.nomor}&text=Saya%20lupa%20kata%20sandi%20akun%20UKM%20LOKAL`}
                                    variant="body2"
                                    underline="hover"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    Lupa Kata Sandi?
                                </Link> */}
                                <Link
                                    component={RouterLink}
                                    to="/lupa-kata-sandi"
                                    variant="body2"
                                    underline="hover"
                                    sx={{
                                        cursor: "pointer",
                                    }}
                                >
                                    Lupa Kata Sandi?
                                </Link>
                            </Box>
                            <Box
                                width="100%"
                                display="flex"
                                justifyContent="flex-between"
                            >
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={saveEmail}
                                            onChange={(_, checked) => {
                                                handleSaveEmail(checked);
                                            }}
                                        />
                                    }
                                    label="Simpan Email ?"
                                    sx={{
                                        height: "fit-content",
                                    }}
                                />
                                <Button
                                    variant="outlined"
                                    onClick={() => {
                                        setMinutes(0);
                                        setSeconds(0);
                                        resetLoginCount();
                                    }}
                                    sx={{ display: "none" }}
                                >
                                    Reset timer
                                </Button>
                            </Box>
                            <Button
                                disabled={!!localStorageTime || startTimer}
                                type="submit"
                                fullWidth
                                size="large"
                                variant="contained"
                                sx={{ marginTop: 3, maxWidth: "500px" }}
                            >
                                {!!localStorageTime || startTimer ? (
                                    // <CountdownComponent
                                    //     expiredTime={expiredTime}
                                    //     stopTimer={stopTimer}
                                    // />
                                    <Countdown
                                        date={Date.now() + expiredTime}
                                        renderer={({
                                            completed,
                                            formatted,
                                        }) => {
                                            if (completed) {
                                                // Render a complete state
                                                localStorage.removeItem("time");
                                                setStartTimer(false);
                                                window.location.reload();
                                                return <span>Masuk</span>;
                                            } else {
                                                // Render a countdown
                                                return (
                                                    <span>
                                                        {formatted.minutes}:
                                                        {formatted.seconds}
                                                    </span>
                                                );
                                            }
                                        }}
                                    />
                                ) : (
                                    "Masuk"
                                )}
                            </Button>
                            {/* <Button
                                fullWidth
                                size="large"
                                variant="outlined"
                                href={
                                    process.env.NODE_ENV === "production"
                                        ? `https://juragan.ukmlokal.com`
                                        : `http://localhost:3000`
                                }
                                rel="noreferrer"
                                sx={{ marginTop: 2, maxWidth: "500px" }}
                            >
                                Ke Juragan
                            </Button> */}
                            {/* <Typography
                                variant="body2"
                                component="div"
                                textAlign="center"
                                sx={{ marginY: 2 }}
                            >
                                Belum punya akun?{" "}
                                <Link
                                    component={RouterLink}
                                    to="/registrasi"
                                    variant="body2"
                                    underline="hover"
                                    sx={{
                                        cursor: "pointer",
                                    }}
                                >
                                    Daftar
                                </Link>
                            </Typography> */}
                        </form>
                    </Box>
                </Box>
                <LoadingOverlay open={isLoading} />
            </Box>
        </ThemeDefaultComponent>
    );
};

export default Login;
