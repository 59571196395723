import { useQuery } from "react-query";
import { ParamsGetItem, GetItemResponse } from "../../constants/types";
import axios from "../axios";

const queryString = require("query-string");

const handleRequest = async (params: ParamsGetItem) => {
    const apiGetItem = queryString.stringifyUrl({
        url: "api/stok",
        query: {
            ukmId: params.ukmId,
            search: `${params.search}`,
            subKategori: params.subKategori,
            size: params.size,
            page: params.page,
        },
    });
    const { data } = await axios.get<GetItemResponse>(apiGetItem);
    return data.data;
};

export default function useItem(params: ParamsGetItem) {
    return useQuery<GetItemResponse["data"]>(
        ["item", params],
        () => handleRequest(params),
        {
            enabled: !!params?.ukmId,
        },
    );
}
