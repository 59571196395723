import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import {
    ContentGetStokOpnameResponse,
    ProsesOpnameValues,
} from "../../../constants/types";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";

interface IOpnameTerpilihProps {
    data: ContentGetStokOpnameResponse;
    idx: number;
}

const OpnameTerpilih = ({ data, idx }: IOpnameTerpilihProps) => {
    const { control, register } = useFormContext<ProsesOpnameValues>();

    return (
        <Box alignItems="end">
            <Box>
                <Typography fontWeight="bold">{`${idx + 1}. ${
                    data.stok.nama
                } (${data.stok.sku})`}</Typography>
                <Typography>Jumlah asli: {data.jumlahAsli}</Typography>
                <Box mt={1}>
                    <Typography>Jumlah opname:</Typography>
                    <Controller
                        name={`details.${idx}.jumlahOpname`}
                        control={control}
                        rules={{
                            required: "Jumlah opname harus diisi",
                        }}
                        render={({ field, fieldState }) => (
                            <TextField
                                type="number"
                                variant="outlined"
                                placeholder="0"
                                size="small"
                                error={!!fieldState?.error}
                                helperText={
                                    fieldState?.error &&
                                    fieldState?.error.message
                                }
                                {...field}
                            />
                        )}
                    />
                    <input
                        type="hidden"
                        {...register(`details.${idx}.opnameId`)}
                        value={data.id}
                    />
                </Box>
            </Box>
        </Box>
    );
};

export default OpnameTerpilih;
