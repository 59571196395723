import React from "react";
import {
    Page,
    Text,
    View,
    Document,
    StyleSheet,
    Font,
    Image,
} from "@react-pdf/renderer";
import Html from "react-pdf-html";
import PrintStrukA4Table from "./PrintStrukA4Table";

Font.register({
    family: "Open-Sans",
    src: "https://fonts.gstatic.com/s/opensans/v29/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsjZ0C4n.ttf",
});

Font.register({
    family: "Open-Sans-Bold",
    src: "https://fonts.gstatic.com/s/opensans/v34/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsg-1y4nY1M2xLER.ttf",
});

const styles = StyleSheet.create({
    page: {
        backgroundColor: "#FFF",
        paddingTop: 35,
        paddingBottom: 65,
        paddingHorizontal: 35,
    },
    title: {
        fontSize: 18,
        fontFamily: "Open-Sans-Bold",
    },
    infoToko: {
        fontSize: 11,
        fontFamily: "Open-Sans",
    },
    infoTokoBold: {
        fontSize: 11,
        fontFamily: "Open-Sans-Bold",
    },
    container: {
        flexDirection: "row",
        borderBottomColor: "#000",
        backgroundColor: "#fff",
        borderBottomWidth: 1,
        alignItems: "center",
        height: 24,
        textAlign: "center",
        fontSize: 11,
        fontFamily: "Open-Sans-Bold",
        flexGrow: 1,
    },
    no: {
        width: "10%",
        borderRightColor: "#000",
        borderRightWidth: 1,
    },
    namaBarang: {
        width: "50%",
        borderRightColor: "#000",
        borderRightWidth: 1,
    },
    qty: {
        width: "10%",
        borderRightColor: "#000",
        borderRightWidth: 1,
    },
    hargaSatuan: {
        width: "15%",
        borderRightColor: "#000",
        borderRightWidth: 1,
    },
    jumlah: {
        width: "15%",
    },
    pageNumber: {
        position: "absolute",
        fontSize: 12,
        bottom: 20,
        left: 0,
        right: 0,
        textAlign: "center",
        color: "grey",
        display: "flex",
        flexDirection: "column",
    },
    boldText: {
        fontWeight: 700,
        fontFamily: "Open-Sans-Bold",
    },
    divider: {
        marginVertical: 4,
        borderTop: "1px",
        color: "black",
        borderStyle: "dashed",
    },
    penutup: {
        textAlign: "center",
    },
    containerLogo: {
        marginLeft: 8,
    },
    logo: {
        width: 54,
        height: 54,
        display: "flex",
        flexDirection: "column",
        textAlign: "center",
        alignItems: "center",
        justifyContent: "center",
    },
    containerPenutup: {
        position: "absolute",
        fontSize: 12,
        bottom: 40,
        left: 0,
        right: 0,
        textAlign: "center",
        color: "grey",
        fontFamily: "Open-Sans",
    },
});

const stylesheet = {
    // clear margins for all <p> tags
    p: {
        margin: 0,
        textAlign: "center",
    },
};

interface ICart {
    id: number;
    namaBarang: string;
    sku?: string | undefined;
    qty: number;
    harga: number;
    jumlahStok?: number | undefined;
}

interface IPrintStrukA4Props {
    namaToko: string;
    gambarToko?: string;
    alamatToko: string;
    telpToko: string;
    total: number;
    kasir: string;
    sales: string | null;
    tanggalTransaksi: string;
    cart: ICart[] | undefined;
    diskon?: number;
    pajak?: number;
    tunai?: number;
    kembalian?: number;
    isPro: number;
    namaMetode?: string | null;
    belumLunas?: boolean;
    qr?: string | null;
    html?: any;
    keterangan?: string | null;
    namaPemegang: string | null;
    nomorTujuan: string | null;
}

const PrintStrukA4 = (props: IPrintStrukA4Props) => {
    return (
        <Document>
            <Page size="A4" orientation="landscape" style={styles.page}>
                <View
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "flex-start",
                    }}
                >
                    <View>
                        <Text style={styles.title}>
                            {props.namaToko.toUpperCase()}
                        </Text>
                        <View style={{ marginTop: 18 }}>
                            <Text style={styles.infoToko}>
                                {props.alamatToko}
                            </Text>
                            <Text style={styles.infoToko}>
                                Telp: {props.telpToko}
                            </Text>
                        </View>
                    </View>
                    {props.gambarToko ? (
                        <Image
                            style={{
                                height: 100,
                                width: 100,
                            }}
                            src={props.gambarToko}
                        />
                    ) : null}
                </View>
                <View
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: 24,
                        fontSize: 14,
                        fontFamily: "Open-Sans",
                    }}
                >
                    <View>
                        <Text>
                            ----------------------------------------------------------------------
                        </Text>
                    </View>
                    <View
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            paddingHorizontal: 8,
                            textAlign: "center",
                        }}
                    >
                        <Text>NOTA PENJUALAN</Text>
                    </View>
                    <View>
                        <Text>
                            ----------------------------------------------------------------------
                        </Text>
                    </View>
                </View>
                <View
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "flex-start",
                        marginTop: 24,
                        paddingRight: 32,
                    }}
                >
                    <View style={styles.infoToko}>
                        <Text style={{ fontFamily: "Open-Sans-Bold" }}>
                            Kasir:{" "}
                            <Text style={{ fontFamily: "Open-Sans" }}>
                                {props.kasir}
                            </Text>
                        </Text>
                        {props.sales ? (
                            <Text style={{ fontFamily: "Open-Sans-Bold" }}>
                                Sales:{" "}
                                <Text style={{ fontFamily: "Open-Sans" }}>
                                    {props.sales}
                                </Text>
                            </Text>
                        ) : null}
                        <Text style={{ fontFamily: "Open-Sans-Bold" }}>
                            Tanggal:{" "}
                            <Text style={{ fontFamily: "Open-Sans" }}>
                                {props.tanggalTransaksi}
                            </Text>
                        </Text>
                        {props.namaMetode ? (
                            <Text style={{ fontFamily: "Open-Sans-Bold" }}>
                                Metode:{" "}
                                <Text style={{ fontFamily: "Open-Sans" }}>
                                    {props.namaMetode}
                                </Text>
                            </Text>
                        ) : null}
                        {props.keterangan ? (
                            <Text style={{ fontFamily: "Open-Sans-Bold" }}>
                                Keterangan:{" "}
                                <Text style={{ fontFamily: "Open-Sans" }}>
                                    {props.keterangan}
                                </Text>
                            </Text>
                        ) : null}
                    </View>
                    {props.namaMetode &&
                    props.namaPemegang &&
                    props.nomorTujuan ? (
                        <View
                            style={{
                                display: "flex",
                                flexDirection: "row",
                            }}
                        >
                            <View style={styles.infoToko}>
                                <Text style={{ fontFamily: "Open-Sans-Bold" }}>
                                    Pembayaran dapat dibayar melalui:
                                </Text>
                                <Text>
                                    {props.namaMetode} {props.nomorTujuan}
                                </Text>
                                <Text>a/n {props.namaPemegang}</Text>
                            </View>
                            {props.qr ? (
                                <View style={styles.containerLogo}>
                                    <Image style={styles.logo} src={props.qr} />
                                </View>
                            ) : (
                                <View></View>
                            )}
                        </View>
                    ) : (
                        <View></View>
                    )}
                </View>
                <PrintStrukA4Table
                    cart={props.cart}
                    diskon={props.diskon}
                    pajak={props.pajak}
                    kembalian={props.kembalian}
                    tunai={props.tunai}
                    namaMetode={props.namaMetode}
                    total={props.total}
                />
                {props.belumLunas ? (
                    <View style={{ marginTop: 24 }}>
                        <View style={styles.divider}></View>
                        <Text style={styles.penutup}>BELUM LUNAS</Text>
                        <View style={styles.divider}></View>
                    </View>
                ) : (
                    <View></View>
                )}
                <View style={styles.containerPenutup}>
                    {props.html ? (
                        <Html
                            stylesheet={stylesheet}
                            style={{ fontSize: 12, color: "grey" }}
                        >
                            {`<div>${props.html}</div>`}
                        </Html>
                    ) : (
                        // <div
                        //     dangerouslySetInnerHTML={{ __html: props.html }}
                        // />
                        <>
                            <Text style={styles.penutup}>
                                Terimakasih telah berbelanja
                            </Text>
                            <Text style={styles.penutup}>
                                Sampai bertemu kembali
                            </Text>
                        </>
                    )}
                </View>
                <Text style={styles.pageNumber}>
                    <View style={{ marginTop: 10 }}>
                        <Text>
                            {props.isPro !== 1 && (
                                <Text style={styles.boldText}>Free</Text>
                            )}{" "}
                            POS System by UKM Lokal
                        </Text>
                    </View>
                </Text>
            </Page>
        </Document>
    );
};

export default PrintStrukA4;
