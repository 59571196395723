import React, { useCallback, useState } from "react";
import axios from "../../services/axios";
import Swal from "sweetalert2";
import defaultAxios, { AxiosError } from "axios";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import ArrowBack from "@mui/icons-material/ArrowBack";
import Typography from "@mui/material/Typography";
import LoadingButton from "@mui/lab/LoadingButton";
import Button from "@mui/material/Button";
import { useTransaksi } from "../../context/transaksiContext";

interface IModalAccPreOrderProps {
    open: boolean;
    onClose: () => void;
    namaPO: string;
    idPO: number;
}

const ModalAccPreOrder = ({
    open,
    onClose,
    namaPO,
    idPO,
}: IModalAccPreOrderProps) => {
    const [isButtonLoading, setIsButtonLoading] = useState(false);
    const { setFetchingItems } = useTransaksi();

    const handleAcc = async (poId: number) => {
        setIsButtonLoading(true);
        try {
            const formData = new FormData();
            formData.append("poId", String(poId));
            const { data } = await axios.post(`/api/po-acc`, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            });
            if (data.code === 200) {
                Swal.fire({
                    title: namaPO + " berhasil disetujui!",
                    position: "top-end",
                    showConfirmButton: false,
                    icon: "success",
                    toast: true,
                    timer: 3000,
                    timerProgressBar: true,
                    showCloseButton: true,
                    customClass: {
                        container: "my-swal",
                    },
                });
                setIsButtonLoading(false);
                setFetchingItems();
            }
            onClose();
        } catch (error) {
            setIsButtonLoading(false);
            handleErrorResponse(error);
        }
    };

    const handleErrorResponse = useCallback(
        (error: any) => {
            if (defaultAxios.isAxiosError(error)) {
                const serverError = error as AxiosError<any>;
                if (serverError && serverError.response) {
                    Swal.fire({
                        title: "Terjadi Kesalahan!",
                        text: `${serverError.response.data.message}`,
                        icon: "error",
                        confirmButtonColor: "#45A779",
                        customClass: {
                            container: "my-swal",
                        },
                    });
                    onClose();
                } else {
                    Swal.fire({
                        title: "Terjadi Kesalahan!",
                        text: `Telah Terjadi Kesalahan`,
                        icon: "error",
                        confirmButtonColor: "#45A779",
                        customClass: {
                            container: "my-swal",
                        },
                    });
                    onClose();
                }
            }
        },
        [onClose],
    );

    return (
        <Dialog
            maxWidth="xs"
            fullWidth={true}
            open={open}
            onClose={onClose}
            PaperProps={{
                sx: {
                    //   minHeight: "150px",
                    //   maxHeight: "150px",
                    borderRadius: 1,
                },
            }}
        >
            <DialogTitle>
                <Stack direction="row" spacing={1} alignItems="center">
                    <IconButton onClick={onClose}>
                        <ArrowBack />
                    </IconButton>
                    <Typography variant="h6" fontWeight="bold">
                        Setujui Pre Order
                    </Typography>
                </Stack>
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Yakin ingin menyetujui pre order {namaPO}?
                </DialogContentText>
            </DialogContent>
            <DialogActions sx={{ padding: 2 }}>
                <Button variant="outlined" onClick={onClose}>
                    Tidak
                </Button>
                <LoadingButton
                    loading={isButtonLoading}
                    loadingPosition="center"
                    variant="contained"
                    onClick={() => handleAcc(idPO)}
                >
                    Ya
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
};

export default ModalAccPreOrder;
