import { useQuery } from "react-query";
import { GetDetailInvoiceVoidResponse } from "../../constants/types";
import axios from "../axios";

const handleRequest = async (id: number | null) => {
    const { data } = await axios.get<GetDetailInvoiceVoidResponse>(
        `/api/detailrevert/${id}`,
    );
    return data.data;
};

export default function useDetailInvoiceVoid(id: number | null) {
    return useQuery<GetDetailInvoiceVoidResponse["data"]>(
        ["detailInvoiceVoid", id],
        () => handleRequest(id),
        { enabled: !!id },
    );
}
