/* eslint-disable react/jsx-no-comment-textnodes */
import ArrowBack from "@mui/icons-material/ArrowBack";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import React from "react";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Box from "@mui/material/Box";
import UloNoPicture from "../../assets/images/UloNoPicture.png";

interface IDataMetodePembayaran {
    namaMetode: string;
    noTujuan: string;
    namaPemegang: string;
    gambar: string | null;
}

interface IModalDetailMetodePembayaranProps {
    isOpenModalDetailMetodePembayaran: boolean;
    closeModalDetailMetodePembayaran: () => void;
    dataMetodePembayaran?: IDataMetodePembayaran;
}

const ModalDetailMetodePembayaran = ({
    isOpenModalDetailMetodePembayaran,
    closeModalDetailMetodePembayaran,
    dataMetodePembayaran,
}: IModalDetailMetodePembayaranProps) => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

    return (
        <Dialog
            maxWidth="md"
            fullScreen={fullScreen}
            fullWidth={true}
            open={isOpenModalDetailMetodePembayaran}
            onClose={closeModalDetailMetodePembayaran}
            PaperProps={{
                sx: {
                    borderRadius: 1,
                },
            }}
        >
            <DialogTitle>
                <Stack direction="row" spacing={1} alignItems="center">
                    <IconButton onClick={closeModalDetailMetodePembayaran}>
                        <ArrowBack />
                    </IconButton>
                    <Typography variant="h6" fontWeight="bold">
                        Metode - {dataMetodePembayaran?.namaMetode}
                    </Typography>
                </Stack>
            </DialogTitle>
            <DialogContent dividers>
                <Stack alignItems="center">
                    <Box
                        border="1px solid rgba(0,0,0,0.12)"
                        borderRadius={1}
                        marginY={2}
                    >
                        <img
                            src={
                                dataMetodePembayaran?.gambar
                                    ? dataMetodePembayaran?.gambar
                                    : UloNoPicture
                            }
                            alt="qrcode"
                            width={250}
                            height={250}
                            style={{ objectFit: "contain" }}
                        />
                    </Box>
                    <Typography>
                        a/n {dataMetodePembayaran?.namaPemegang}
                    </Typography>
                    <Typography fontWeight="bold">
                        {dataMetodePembayaran?.noTujuan}
                    </Typography>
                </Stack>
            </DialogContent>
        </Dialog>
    );
};

export default ModalDetailMetodePembayaran;
