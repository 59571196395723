import { useQuery } from "react-query";
import {
    ParamsGetSubKategori,
    GetSubKategoriResponse,
} from "../../constants/types";
import axios from "../axios";
import qs from "query-string";

const handleRequest = async (params: ParamsGetSubKategori) => {
    const apiUrl = qs.stringifyUrl({
        url: "api/subkategori",
        query: {
            ukmId: params.ukmId,
            search: `${params.search}`,
            size: params.size,
            page: params.page,
            kategoriId: params.kategoriId,
        },
    });
    const { data } = await axios.get<GetSubKategoriResponse>(apiUrl);
    return data.data;
};

export default function useSubKategori(params: ParamsGetSubKategori) {
    return useQuery<GetSubKategoriResponse["data"]>(
        ["sub-kategori", params],
        () => handleRequest(params),
        {
            enabled: !!params.ukmId,
        },
    );
}
