import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Pagination from "@mui/material/Pagination";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import BuildIcon from "@mui/icons-material/Build";
import PrintIcon from "@mui/icons-material/Print";
import DeleteIcon from "@mui/icons-material/Delete";
import SettingsIcon from "@mui/icons-material/Settings";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import MoveToInboxIcon from "@mui/icons-material/MoveToInbox";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import AssignmentReturnedIcon from "@mui/icons-material/AssignmentReturned";
import useUkm from "../../services/queries/useUkm";
import useItem from "../../services/queries/useItem";
import useUser from "../../services/queries/useUser";
import ModalStok from "../../components/ModalStok/ModalStok";
import ModalMutasiStok from "../../components/ModalMutasiStok/ModalMutasiStok";
import ModalDetailBarang from "../../components/ModalDetailBarang/ModalDetailBarang";
import avatarAlt from "../../utils/avatarAlt";
import { getLocalUkmId } from "../../utils/handleChangeUkmId";
import { useAuth } from "../../context/authContext";
import { useTransaksi } from "../../context/transaksiContext";
import { useStokModal } from "../../context/stokModalContext";
import { useImportModal } from "../../context/importModalContext";
import { useKatalogModal } from "../../context/katalogModalContext";
import { useKategoriModal } from "../../context/kategoriModalContext";
import { useHapusStokModal } from "../../context/hapusStokModalContext";
import { useMutasiStokModal } from "../../context/mutasiStokModalContext";
import { usePrintBarcodeModal } from "../../context/printBarcodeModal";
import {
    ContentGetItemResponse,
    // IOptions,
    ParamsGetItem,
    ParamsGetSubKategori,
} from "../../constants/types";
import { ReactComponent as BarangTidakDitemukan } from "../../assets/images/BarangTidakDitemukan.svg";
import Spinner from "../Spinner/Spinner";
import NoRowsImage from "../NoRowsImage/NoRowsImage";
import LoadingTabel from "../LoadingTabel/LoadingTabel";
import CardStokBarang from "../CardStokBarang/CardStokBarang";
import ModalDeleteStok from "../ModalDeleteStok/ModalDeleteStok";
// import Autocomplete from "@mui/material/Autocomplete";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import { Search, Star } from "@mui/icons-material";
import truncate from "../../utils/truncate";
import moment from "moment";
import "moment/locale/id";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { Badge, Skeleton, Tooltip } from "@mui/material";
import useSubKategori from "../../services/queries/useSubKategori";
import toRupiah from "../../utils/toRupiah";
import toRibuan from "../../utils/toRibuan";

moment.locales();
moment.locale("id");

interface ITabelBarangProps {}

const TabelBarang = (props: ITabelBarangProps) => {
    const theme = useTheme();
    const { ukmIdUser } = useAuth();
    const { openModalPrintBarcode } = usePrintBarcodeModal();
    const isPhoneScreen = useMediaQuery(theme.breakpoints.between("xs", "sm"));
    const isTabletScreen = useMediaQuery(theme.breakpoints.between("sm", "md"));
    const isLaptopScreen = useMediaQuery(theme.breakpoints.up("md"));
    // const isDesktopScreen = useMediaQuery(theme.breakpoints.up("xl"));

    const initialParamsItem = {
        ukmId: ukmIdUser && ukmIdUser[0],
        size: 10,
        page: 1,
        search: "",
        subKategori: "",
    };
    const [paramsItem, setParamsItem] =
        useState<ParamsGetItem>(initialParamsItem);
    const {
        data: dataItem,
        refetch: refetchItem,
        isLoading: isLoadingItem,
    } = useItem(paramsItem);

    const initialParamsSubKategori = {
        ukmId: Number(ukmIdUser && ukmIdUser[0]),
        size: 500,
        page: 1,
        search: "",
    };
    const [paramsSubKategori, setParamsSubKategori] =
        useState<ParamsGetSubKategori>(initialParamsSubKategori);
    const {
        status: statusSubKategori,
        data: dataSubKategori,
        refetch: refetchSubKategori,
    } = useSubKategori(paramsSubKategori);
    const initialParamsUkm = {
        size: 500,
        page: 1,
        search: "",
    };
    const { data: ukm } = useUkm(initialParamsUkm);
    const { data: user } = useUser();
    const [subKategori, setSubKategori] = useState("");
    // const [selectedKategori, setSelectedKategori] = useState<IOptions | null>(
    //     null,
    // );

    const ukmId = React.useMemo(() => {
        if (user?.userId) {
            return getLocalUkmId(user?.userId);
        }
        return null;
    }, [user?.userId]);

    const ukmData = React.useMemo(() => {
        if (ukmId) {
            return ukm?.content.find((li) => li.id === ukmId);
        }
        return ukm?.content[0];
    }, [ukm?.content, ukmId]);

    const { isFetchingItems, cancelFetchingItems } = useTransaksi();

    const { openModal } = useStokModal();
    const { openModalImport } = useImportModal();
    const { openModalKatalog } = useKatalogModal();
    const { openModalKategori, openModalSubKategori } = useKategoriModal();
    const { openModalHapusStok } = useHapusStokModal();
    const { openModalMutasiStok } = useMutasiStokModal();

    const [search, setSearch] = useState("");

    const columns: GridColDef[] = [
        // {
        //     field: "nomer",
        //     headerName: "No",
        //     headerAlign: "center",
        //     flex: isLaptopScreen ? 1 : undefined,
        //     align: "center",
        //     renderCell: (params) => {
        //         return params.value;
        //     },
        // },
        {
            field: "namaBarang",
            headerName: "Info Barang (Terjual)",
            headerAlign: "center",
            flex: isLaptopScreen ? 1.5 : undefined,
            minWidth: 235,
            valueGetter: (params) => params.value,
            renderCell: (params) => {
                return (
                    <Stack direction="row" alignItems="center" spacing={1}>
                        <Badge
                            overlap="circular"
                            anchorOrigin={{
                                vertical: "top",
                                horizontal: "right",
                            }}
                            badgeContent={
                                params.row.isFavorit > 0 ? (
                                    <Star
                                        sx={{
                                            position: "relative",
                                            border: 1,
                                            borderColor: "#e7e5e4",
                                            borderRadius: "50%",
                                            padding: 0.2,
                                            color: "#fbbf24",
                                            bgcolor: "#FFFFFF",
                                        }}
                                    />
                                ) : null
                            }
                        >
                            <Avatar
                                sx={{
                                    backgroundColor: "primary.main",
                                    width: 36,
                                    height: 39,
                                }}
                                variant="rounded"
                                alt={params.value}
                                src={params.row.gambar}
                            >
                                {avatarAlt(params.value)}
                            </Avatar>
                        </Badge>
                        <Stack direction="column" spacing={0.3}>
                            <Tooltip
                                title={`${params.value} ${
                                    params.row.terjual
                                        ? `(${params.row.terjual})`
                                        : "(0"
                                }`}
                            >
                                <Typography
                                    component="div"
                                    variant="subtitle2"
                                    fontWeight="bold"
                                >
                                    {`${truncate(params.value, 20)} `}
                                    <Typography
                                        variant="subtitle2"
                                        display="inline"
                                    >
                                        {`${
                                            params.row.terjual
                                                ? `(${params.row.terjual})`
                                                : "(0)"
                                        }`}
                                    </Typography>
                                </Typography>
                            </Tooltip>
                            <Tooltip
                                title={
                                    params.row.deskripsi === null
                                        ? " "
                                        : params.row.deskripsi
                                }
                            >
                                <Typography variant="caption">
                                    {params.row.deskripsi === null
                                        ? " "
                                        : truncate(params.row.deskripsi, 27)}
                                </Typography>
                            </Tooltip>
                        </Stack>
                    </Stack>
                );
            },
        },
        {
            field: "sku",
            headerName: "SKU",
            headerAlign: "center",
            align: "center",
            flex: isLaptopScreen ? 1 : undefined,
            minWidth: 150,
            renderCell: (params) => {
                return (
                    <Tooltip title={params.value}>
                        <span className="table-cell-trucate">
                            {params.value}
                        </span>
                    </Tooltip>
                );
            },
        },
        {
            field: "subKategori",
            headerName: "Sub Kategori",
            flex: isLaptopScreen ? 1 : undefined,
            minWidth: 160,
            valueGetter: (params) => params.row.subKategori.nama,
            renderCell: (params) => {
                return (
                    <Tooltip title={params.row.subKategori.nama}>
                        <span className="table-cell-trucate">
                            {params.row.subKategori.nama}
                        </span>
                    </Tooltip>
                );
            },
        },

        {
            field: "stok",
            headerName: "Stok",
            headerAlign: "center",
            align: "center",
            flex: isLaptopScreen ? 1 : undefined,
            minWidth: 100,
            renderCell: (params) => {
                return (
                    <Tooltip
                        title={
                            params.row.isBundling > 0
                                ? "Otomatis"
                                : params.value === null
                                ? "Nonaktif"
                                : params.value
                        }
                    >
                        <Typography
                            variant="subtitle2"
                            color={
                                params.value < 5 && params.value !== null
                                    ? "#ef4444"
                                    : "#76747C"
                            }
                            fontWeight={
                                params.value < 5 && params.value !== null
                                    ? 700
                                    : "normal"
                            }
                        >
                            {params.row.isBundling > 0
                                ? "Otomatis"
                                : params.value === null
                                ? "Nonaktif"
                                : params.value}
                        </Typography>
                    </Tooltip>
                );
            },
        },
        {
            field: "hargaBeliTerakhir",
            headerName: "Harga Beli",
            headerAlign: "center",
            align: "right",
            flex: isLaptopScreen ? 1 : undefined,
            minWidth: 150,
            renderCell: (params) => {
                return (
                    <Tooltip
                        title={
                            params.value ? toRupiah(Number(params.value)) : "-"
                        }
                    >
                        <span className="table-cell-trucate">
                            {params.value
                                ? toRupiah(Number(params.value))
                                : "-"}
                        </span>
                    </Tooltip>
                );
            },
        },
        {
            field: "hargaMultiple",
            headerName: "Harga Jual",
            headerAlign: "center",
            align: "right",
            flex: isLaptopScreen ? 1 : undefined,
            minWidth: 150,
            renderCell: (params) => {
                return (
                    <Tooltip
                        title={
                            params.value.length > 0
                                ? toRupiah(Number(params.value[0].harga))
                                : "-"
                        }
                    >
                        <span className="table-cell-trucate">
                            {params.value.length > 0
                                ? toRupiah(Number(params.value[0].harga))
                                : "-"}
                        </span>
                    </Tooltip>
                );
            },
        },
        {
            field: "cost",
            headerName: "Cost",
            headerAlign: "center",
            align: "right",
            flex: isLaptopScreen ? 1 : undefined,
            minWidth: 150,
            renderCell: (params) => {
                return (
                    <Tooltip
                        title={
                            params.value ? toRupiah(Number(params.value)) : "-"
                        }
                    >
                        <span className="table-cell-trucate">
                            {params.value
                                ? toRupiah(Number(params.value))
                                : "-"}
                        </span>
                    </Tooltip>
                );
            },
        },
        {
            field: "gross",
            headerName: "Gross",
            headerAlign: "center",
            align: "right",
            flex: isLaptopScreen ? 1 : undefined,
            minWidth: 150,
            renderCell: (params) => {
                return (
                    <Tooltip
                        title={
                            params.value ? toRupiah(Number(params.value)) : "-"
                        }
                    >
                        <span className="table-cell-trucate">
                            {params.value
                                ? toRupiah(Number(params.value))
                                : "-"}
                        </span>
                    </Tooltip>
                );
            },
        },
        {
            field: "profit",
            headerName: "Profit",
            headerAlign: "center",
            align: "right",
            flex: isLaptopScreen ? 1 : undefined,
            minWidth: 150,
            renderCell: (params) => {
                return (
                    <Tooltip
                        title={
                            params.value ? toRupiah(Number(params.value)) : "-"
                        }
                    >
                        <span className="table-cell-trucate">
                            {params.value
                                ? toRupiah(Number(params.value))
                                : "-"}
                        </span>
                    </Tooltip>
                );
            },
        },
        {
            field: "margin",
            headerName: "Margin (%)",
            headerAlign: "center",
            align: "right",
            flex: isLaptopScreen ? 1 : undefined,
            minWidth: 150,
            renderCell: (params) => {
                return (
                    <Tooltip title={params.value ?? "-"}>
                        <span className="table-cell-trucate">
                            {params.value ?? "-"}
                        </span>
                    </Tooltip>
                );
            },
        },
        // {
        //     field: "expAt",
        //     headerName: "Tanggal Kadaluarsa",
        //     // flex: isLaptopScreen ? 0.6 : undefined,
        //     align: "center",
        //     minWidth: 180,
        //     renderCell: (params) => {
        //         return (
        //             <Tooltip
        //                 title={
        //                     params.value
        //                         ? moment(params.value).format("LL")
        //                         : "-"
        //                 }
        //             >
        //                 <Typography variant="body2">
        //                     {params.value
        //                         ? moment(params.value).format("LL")
        //                         : "-"}
        //                 </Typography>
        //             </Tooltip>
        //         );
        //     },
        // },
        {
            field: "aksi",
            headerName: "Aksi",
            headerAlign: "center",
            align: "center",
            minWidth: 160,
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params) => {
                const row: ContentGetItemResponse = params.row;

                return (
                    // />
                    <Stack direction="row" spacing={2}>
                        <Button
                            variant="contained"
                            onClick={() =>
                                openModalPrintBarcode(
                                    params.row.sku,
                                    truncate(params.row.namaBarang, 24, true),
                                    `Rp${toRibuan(params.row.harga)}`,
                                )
                            }
                            size="small"
                            color="buttonblue"
                            disableElevation
                            sx={{
                                minWidth: "unset",
                                padding: "8px",
                                width: "32px",
                                height: "32px",
                            }}
                        >
                            <PrintIcon sx={{ width: "16px", height: "16px" }} />
                        </Button>
                        <Button
                            // disabled={user && !user.akses.stok.includes(25)}
                            variant="contained"
                            onClick={() =>
                                openModal({
                                    id: row.id,
                                    namaBarang: row.namaBarang,
                                    deskripsi: row.deskripsi ?? "",
                                    subId: row.subKategori.id,
                                    harga: Number(row.harga),
                                    sku: row.sku,
                                    stok: row.stok,
                                    isActiveStok: row.isActiveStok,
                                    itemSatuan: null,
                                    isBundling: row.isBundling,
                                    detailBundling:
                                        dataItem?.content.find(
                                            (li) => li.id === row.id,
                                        )?.detailBundling ?? [],
                                    isFavorit: row.isFavorit,
                                    ukm: row.ukm,
                                    kategoriId: row.subKategori.kategoriId,
                                    terjual: row.terjual,
                                    hargaMultiple: row.hargaMultiple.map(
                                        (li) => ({
                                            id: li.id,
                                            harga: Number(li.harga),
                                            keterangan: li.keterangan,
                                        }),
                                    ),
                                })
                            }
                            size="small"
                            color="buttonyellow"
                            sx={{
                                minWidth: "unset",
                                padding: "8px",
                                width: "32px",
                                height: "32px",
                            }}
                        >
                            <EditIcon sx={{ width: "16px", height: "16px" }} />
                        </Button>
                        <Button
                            // disabled={user && !user.akses.stok.includes(26)}
                            variant="contained"
                            onClick={() =>
                                openModalHapusStok(
                                    params.id,
                                    params.row.namaBarang,
                                )
                            }
                            size="small"
                            color="buttonred"
                            sx={{
                                minWidth: "unset",
                                padding: "8px",
                                width: "32px",
                                height: "32px",
                            }}
                        >
                            <DeleteIcon
                                sx={{ width: "16px", height: "16px" }}
                            />
                        </Button>
                    </Stack>
                );
            },
        },
    ];

    const handleOpenModalKategori = () => {
        openModalKategori();
    };

    const handleOnPagination = (page: number) => {
        setParamsItem((prev) => ({
            ...prev,
            page: page + 1,
        }));
    };

    const handleOnSizeChange = (size: number) => {
        setParamsItem((prev) => ({ ...prev, size }));
    };

    const handleSearch = () => {
        setParamsItem((prev) => ({
            ...prev,
            search: search,
        }));
    };

    useEffect(() => {
        const timeoutId = setTimeout(
            () =>
                setParamsItem((prev) => ({
                    ...prev,
                    search: search,
                })),
            1000,
        );
        return () => clearTimeout(timeoutId);
    }, [search]);

    useEffect(() => {
        refetchItem();
    }, [dataItem, refetchItem]);

    useEffect(() => {
        refetchSubKategori();
    }, [dataSubKategori, refetchSubKategori]);

    useEffect(() => {
        if (isFetchingItems === true) {
            refetchItem();
            refetchSubKategori();
            cancelFetchingItems();
        }
    }, [cancelFetchingItems, isFetchingItems, refetchItem, refetchSubKategori]);

    useEffect(() => {
        if (subKategori !== null) {
            // if (selectedKategori !== null) {
            setParamsItem((prev) => ({
                ...prev,
                subKategori: subKategori,
                // kategori: String(selectedKategori.id),
            }));
        } else {
            setParamsItem((prev) => ({
                ...prev,
                kategori: "",
            }));
        }
    }, [subKategori]);

    useEffect(() => {
        if (ukmIdUser) {
            setParamsItem((prev) => ({
                ...prev,
                ukmId: ukmIdUser && ukmIdUser[0],
            }));
            setParamsSubKategori((prev) => ({
                ...prev,
                ukmId: ukmIdUser && ukmIdUser[0],
            }));
        }
    }, [ukmIdUser]);

    // const isLoading = statusKategori === "loading" || statusItem === "loading";

    if (statusSubKategori === "loading") {
        return (
            <Box
                position="relative"
                height={isLaptopScreen ? "100vh" : "calc(100vh - 56px)"}
            >
                <Spinner />
            </Box>
        );
    }

    return (
        <React.Fragment>
            {isPhoneScreen ? (
                <Stack
                    display="flex"
                    justifyContent="space-between"
                    direction={{ xs: "column", sm: "row", md: "row" }}
                >
                    <Stack
                        my={2}
                        direction="row"
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                    >
                        <Typography fontSize="h6" fontWeight="bold">
                            Daftar Stok
                        </Typography>
                        <Box
                            display="flex"
                            alignItems="center"
                            gap={2}
                            flexDirection="row"
                        >
                            <Box width="max-content">
                                {isLoadingItem ? (
                                    <Skeleton variant="text" width={100} />
                                ) : (
                                    <Typography color="GrayText">
                                        {dataItem?.totalElements}
                                    </Typography>
                                )}
                            </Box>
                            <Button
                                variant="contained"
                                onClick={() => openModal()}
                                sx={{ objectFit: "contain" }}
                                startIcon={<AddIcon />}
                            >
                                Tambah
                            </Button>
                        </Box>
                    </Stack>
                    <Stack
                        display="flex"
                        justifyContent="space-between"
                        direction="row"
                        spacing={1}
                    >
                        <Button
                            variant="outlined"
                            onClick={() => openModalMutasiStok()}
                            // size="small"
                            sx={{
                                backgroundColor: "#ffffff",
                                objectFit: "contain",
                            }}
                        >
                            <MoveToInboxIcon />
                        </Button>
                        <Button
                            variant="outlined"
                            onClick={() => openModalKatalog()}
                            sx={{
                                backgroundColor: "#ffffff",
                                objectFit: "contain",
                            }}
                        >
                            <AssignmentReturnedIcon />
                        </Button>
                        <Button
                            variant="outlined"
                            onClick={() => openModalImport()}
                            sx={{
                                backgroundColor: "#ffffff",
                                objectFit: "contain",
                            }}
                        >
                            <FileUploadIcon />
                        </Button>
                        <Button
                            variant="outlined"
                            onClick={openModalSubKategori}
                            // size="small"
                            sx={{
                                backgroundColor: "#ffffff",
                                objectFit: "contain",
                            }}
                        >
                            <BuildIcon />
                        </Button>
                        <Button
                            variant="outlined"
                            onClick={handleOpenModalKategori}
                            // size="small"
                            sx={{
                                backgroundColor: "#ffffff",
                                objectFit: "contain",
                            }}
                        >
                            <SettingsIcon />
                        </Button>
                    </Stack>
                </Stack>
            ) : isTabletScreen ? (
                <Stack
                    display="flex"
                    justifyContent="space-between"
                    marginTop={2}
                    direction={{ xs: "column", sm: "row", md: "row" }}
                >
                    <Typography variant="h6" fontWeight="bold">
                        Daftar Stok
                    </Typography>
                    <Stack
                        display="flex"
                        justifyContent="space-around"
                        direction="row"
                        spacing={1}
                    >
                        <Button
                            // disabled={user && !user.akses.stok.includes(22)}
                            variant="outlined"
                            onClick={() => openModalMutasiStok()}
                            size="small"
                            sx={{ backgroundColor: "#ffffff" }}
                        >
                            <MoveToInboxIcon />
                        </Button>
                        <Button
                            variant="outlined"
                            onClick={() => openModalKatalog()}
                            size="small"
                            sx={{ backgroundColor: "#ffffff" }}
                        >
                            <AssignmentReturnedIcon />
                        </Button>
                        <Button
                            variant="outlined"
                            onClick={() => openModalImport()}
                            size="small"
                            sx={{ backgroundColor: "#ffffff" }}
                        >
                            <FileUploadIcon />
                        </Button>
                        <Button
                            // disabled={user && !user.akses.stok.includes(24)}
                            variant="outlined"
                            onClick={openModalSubKategori}
                            size="small"
                            sx={{ backgroundColor: "#ffffff" }}
                        >
                            <BuildIcon />
                        </Button>
                        <Button
                            // disabled={user && !user.akses.stok.includes(24)}
                            variant="outlined"
                            onClick={handleOpenModalKategori}
                            size="small"
                            sx={{ backgroundColor: "#ffffff" }}
                        >
                            <SettingsIcon />
                        </Button>
                    </Stack>
                </Stack>
            ) : (
                <Stack
                    display="flex"
                    justifyContent="space-between"
                    direction={{ xs: "column", sm: "row", md: "row" }}
                    marginTop={2}
                >
                    <Typography variant="h6" fontWeight="bold">
                        Daftar Stok
                    </Typography>
                    <Stack direction="row" spacing={{ xs: 0, sm: 1 }}>
                        <Button
                            // disabled={user && !user.akses.stok.includes(22)}
                            variant="outlined"
                            onClick={() => openModalMutasiStok()}
                            startIcon={<MoveToInboxIcon />}
                            size="medium"
                            sx={{ backgroundColor: "#ffffff" }}
                        >
                            Mutasi Stok
                        </Button>
                        <Button
                            variant="outlined"
                            onClick={() => openModalKatalog()}
                            startIcon={<AssignmentReturnedIcon />}
                            size="medium"
                            sx={{ backgroundColor: "#ffffff" }}
                        >
                            Buat Katalog
                        </Button>
                        <Button
                            variant="outlined"
                            onClick={() => openModalImport()}
                            startIcon={<FileUploadIcon />}
                            size="medium"
                            sx={{ backgroundColor: "#ffffff" }}
                        >
                            Unggah Stok
                        </Button>
                        <Button
                            // disabled={user && !user.akses.stok.includes(24)}
                            variant="outlined"
                            onClick={openModalSubKategori}
                            startIcon={<BuildIcon />}
                            size="medium"
                            sx={{ backgroundColor: "#ffffff" }}
                        >
                            Atur Sub Kategori
                        </Button>
                        <Button
                            // disabled={user && !user.akses.stok.includes(24)}
                            variant="outlined"
                            onClick={handleOpenModalKategori}
                            startIcon={<SettingsIcon />}
                            size="medium"
                            sx={{ backgroundColor: "#ffffff" }}
                        >
                            Atur Kategori
                        </Button>
                    </Stack>
                </Stack>
            )}
            <Box alignItems="center" marginY={2}>
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    spacing={2}
                    alignItems="center"
                >
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={4} md={3}>
                            <TextField
                                placeholder="Cari barang..."
                                size="small"
                                value={search}
                                onChange={(
                                    event: React.ChangeEvent<HTMLInputElement>,
                                ) => {
                                    setSearch(event.target.value);
                                }}
                                onKeyPress={(e) => {
                                    const element =
                                        e.target as HTMLInputElement;
                                    if (e.key === "Enter") {
                                        setParamsItem((prev) => ({
                                            ...prev,
                                            search: element.value,
                                            page: 1,
                                        }));
                                    }
                                }}
                                variant="outlined"
                                fullWidth
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton onClick={handleSearch}>
                                                <Search />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                    style: {
                                        backgroundColor: "white",
                                    },
                                }}
                            />
                        </Grid>
                        <Grid item xs={9} sm={4} md={4} lg={4} xl={2}>
                            <FormControl size="small" fullWidth>
                                <InputLabel id="demo-simple-select-helper-label">
                                    Sub Kategori
                                </InputLabel>
                                <Select
                                    labelId="demo-simple-select-helper-label"
                                    id="demo-simple-select-helper"
                                    label="Sub Kategori"
                                    defaultValue=""
                                    onChange={(e) =>
                                        setSubKategori(String(e.target.value))
                                    }
                                >
                                    <MenuItem value="">Semua</MenuItem>
                                    {dataSubKategori &&
                                        dataSubKategori?.content.map((k) => (
                                            <MenuItem key={k.id} value={k.id}>
                                                {k.nama}
                                            </MenuItem>
                                        ))}
                                </Select>
                            </FormControl>
                            {/* <Autocomplete
                                options={dataKategori?.content ?? []}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Kategori"
                                        variant="outlined"
                                    />
                                )}
                                getOptionLabel={(option) => option.nama}
                                size="small"
                                fullWidth
                                value={selectedKategori}
                                onChange={(
                                    _event,
                                    newKategori: IOptions | null,
                                ) => {
                                    setSelectedKategori(newKategori);
                                }}
                            /> */}
                        </Grid>
                        <Grid item xs={3} sm={1}></Grid>
                    </Grid>
                    <Box
                        display={{ xs: "none", sm: "flex" }}
                        alignItems="center"
                        gap={2}
                        flexDirection="row"
                    >
                        <Box width="max-content">
                            {isLoadingItem ? (
                                <Skeleton variant="text" width={100} />
                            ) : (
                                <Typography color="GrayText">
                                    {isLaptopScreen
                                        ? `Jumlah barang: ${dataItem?.totalElements}`
                                        : dataItem?.totalElements}
                                </Typography>
                            )}
                        </Box>
                        <Button
                            variant="contained"
                            onClick={() => openModal()}
                            startIcon={<AddIcon />}
                            size="medium"
                            // fullWidth
                            sx={{
                                lineHeight: 2,
                            }}
                        >
                            Tambah
                        </Button>
                    </Box>
                </Stack>
            </Box>
            {/* <ThemeProvider theme={theme}> */}
            {isPhoneScreen || isTabletScreen ? (
                dataItem && dataItem.totalElements > 0 ? (
                    <React.Fragment>
                        {dataItem.content.map((items) => (
                            <CardStokBarang
                                key={items.id}
                                id={items.id}
                                sku={items?.sku}
                                namaBarang={items.namaBarang}
                                jumlahStok={items.stok}
                                deskripsi={items.deskripsi ?? ""}
                                harga={Number(items.harga)}
                                url={items.gambar}
                                subKategori={items.subKategori.nama}
                                subKategoriId={items.subKategori}
                                isActiveStok={items.isActiveStok}
                                itemSatuan={items.itemSatuan}
                                isBundling={items.isBundling}
                                detailBundling={items.detailBundling}
                                isFavorit={items.isFavorit}
                                terjual={items.terjual}
                                ukm={items.ukm}
                                hargaMultiple={items.hargaMultiple.map(
                                    (li) => ({
                                        id: li.id,
                                        harga: Number(li.harga),
                                        keterangan: li.keterangan,
                                    }),
                                )}
                            />
                        ))}
                        <Box
                            display="flex"
                            justifyContent="center"
                            paddingY={2}
                        >
                            <Pagination
                                color="primary"
                                count={Math.ceil(
                                    Number(
                                        dataItem &&
                                            dataItem?.totalElements /
                                                initialParamsItem.size,
                                    ),
                                )}
                                page={paramsItem.page}
                                onChange={(event, value) => {
                                    setParamsItem((prev) => ({
                                        ...prev,
                                        page: value,
                                    }));
                                }}
                                siblingCount={0}
                                // sx={{ justifyContent: "center" }}
                            />
                        </Box>
                    </React.Fragment>
                ) : (
                    <Stack
                        direction="column"
                        justifyContent="center"
                        alignItems="center"
                        spacing={2}
                        paddingTop={2}
                    >
                        <BarangTidakDitemukan width="150px" height="150px" />
                        <Typography fontWeight="bold">
                            Barang tidak ditemukan
                        </Typography>
                    </Stack>
                )
            ) : (
                <Box
                    sx={{
                        // height: "80vh",
                        height: 820,
                        width: "100%",
                        marginBottom: 3,
                        backgroundColor: "#ffffff",
                        borderRadius: 1,
                        // "& .headerColumn": {
                        //     backgroundColor: "#E4EEE8",
                        //     // fontSize: "18px",
                        // },
                        "& .MuiDataGrid-columnHeaderTitle": {
                            fontWeight: "bold",
                        },
                        // "& .MuiDataGrid-columnSeparator": {
                        //     visibility: "hidden",
                        // },
                        // // "& .MuiDataGrid-columnHeaders": {
                        // //     borderRadius: 1,
                        // //     borderBottom: 0,
                        // // },
                        "& .MuiDataGrid-cell:focus-within": {
                            outline: "none !important",
                        },
                        "& .MuiDataGrid-iconButtonContainer": {
                            visibility: "visible",
                            width: "0 !important",
                        },
                    }}
                >
                    <DataGrid
                        pagination
                        components={{
                            ColumnUnsortedIcon: () => {
                                return <ImportExportIcon />;
                            },
                            NoRowsOverlay: NoRowsImage,
                            LoadingOverlay: LoadingTabel,
                            // Pagination: CustomPagination,
                        }}
                        rows={
                            dataItem?.content.map((item, index) => ({
                                ...item,
                                idx: index + 1,
                            })) ?? []
                        }
                        columns={columns}
                        disableColumnMenu
                        disableSelectionOnClick
                        paginationMode="server"
                        // rowsPerPageOptions={[10, 15, 20]}
                        rowsPerPageOptions={[10]}
                        onPageSizeChange={handleOnSizeChange}
                        onPageChange={handleOnPagination}
                        rowCount={dataItem?.totalElements ?? 0}
                        page={paramsItem.page - 1}
                        pageSize={paramsItem.size}
                        loading={isLoadingItem}
                        density="comfortable"
                    />
                </Box>
            )}
            <ModalStok />
            <ModalDeleteStok refetch={refetchItem} />
            <ModalDetailBarang />
            <ModalMutasiStok />
            {/* </ThemeProvider> */}
        </React.Fragment>
    );
};

export default TabelBarang;
