import React from "react";
import { ContentGetFakturPembelianResponse } from "../../constants/types";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import Tooltip from "@mui/material/Tooltip";
import Box from "@mui/material/Box";
import toRupiahDesimal from "../../utils/toRupiahDesimal";
import Typography from "@mui/material/Typography";
import toRibuan from "../../utils/toRibuan";
import moment from "moment";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import ArrowBack from "@mui/icons-material/ArrowBack";
import DialogContent from "@mui/material/DialogContent";
import AscDescIcon from "../AscDescIcon/AscDescIcon";
import LoadingTabel from "../LoadingTabel/LoadingTabel";
import NoRowsImage from "../NoRowsImage/NoRowsImage";

interface IModalDetailFakturPembelianProps {
    fullScreen: boolean;
    isOpenModalDetailFaktur: boolean;
    closeModalDetailFaktur: () => void;
    dataFaktur: ContentGetFakturPembelianResponse;
}

const ModalDetailFakturPembelian = ({
    fullScreen,
    isOpenModalDetailFaktur,
    closeModalDetailFaktur,
    dataFaktur,
}: IModalDetailFakturPembelianProps) => {
    const columns: GridColDef[] = [
        {
            field: "nama",
            headerName: "Nama Barang",
            flex: 1,
            minWidth: 160,
            renderCell: (params) => (
                <Tooltip title={params.value}>
                    <span className="table-cell-trucate">{params.value}</span>
                </Tooltip>
            ),
        },
        {
            field: "sku",
            headerName: "SKU",
            flex: 1,
            minWidth: 160,
            renderCell: (params) => (
                <Tooltip title={params.value}>
                    <span className="table-cell-trucate">{params.value}</span>
                </Tooltip>
            ),
        },
        {
            field: "harga",
            headerName: "Harga Jual",
            flex: 1,
            minWidth: 200,
            renderCell: (params) => {
                return (
                    <Box width="80%">
                        <Tooltip title={toRupiahDesimal(Number(params.value))}>
                            <Typography
                                variant="subtitle2"
                                fontWeight={400}
                                textAlign="right"
                            >
                                {toRupiahDesimal(Number(params.value))}
                            </Typography>
                        </Tooltip>
                    </Box>
                );
            },
        },
        {
            field: "hargaBeli",
            headerName: "Harga Beli",
            flex: 1,
            minWidth: 200,
            renderCell: (params) => {
                return (
                    <Box width="80%">
                        <Tooltip title={toRupiahDesimal(Number(params.value))}>
                            <Typography
                                variant="subtitle2"
                                fontWeight={400}
                                textAlign="right"
                            >
                                {toRupiahDesimal(Number(params.value))}
                            </Typography>
                        </Tooltip>
                    </Box>
                );
            },
        },
        {
            field: "jumlahFakturAwal",
            headerName: "Jumlah Beli",
            flex: 1,
            minWidth: 200,
            renderCell: (params) => {
                return (
                    <Tooltip title={toRibuan(params.value)}>
                        <Typography
                            variant="subtitle2"
                            fontWeight={400}
                            textAlign="right"
                        >
                            {toRibuan(params.value)}
                        </Typography>
                    </Tooltip>
                );
            },
        },
        {
            field: "expiredAt",
            headerName: "Tanggal Kadaluarsa",
            flex: 1,
            minWidth: 170,
            renderCell: (params) => {
                return (
                    <Tooltip
                        title={
                            params.row.expiredAt
                                ? moment(params.row.expiredAt).format("LL")
                                : "-"
                        }
                    >
                        <Typography variant="body2">
                            {params.row.expiredAt
                                ? moment(params.row.expiredAt).format("LL")
                                : "-"}
                        </Typography>
                    </Tooltip>
                );
            },
        },
    ];

    return (
        <Dialog
            maxWidth="lg"
            fullScreen={fullScreen}
            fullWidth={true}
            open={isOpenModalDetailFaktur}
            onClose={closeModalDetailFaktur}
            scroll="body"
            PaperProps={{
                sx: {
                    borderRadius: 1,
                },
            }}
        >
            <DialogTitle>
                <Stack direction="row" spacing={1} alignItems="center">
                    <IconButton onClick={closeModalDetailFaktur}>
                        <ArrowBack />
                    </IconButton>
                    <Typography variant="h6" fontWeight="bold">
                        Detail Faktur ({dataFaktur.faktur})
                    </Typography>
                </Stack>
            </DialogTitle>
            <DialogContent>
                <Stack spacing={3}>
                    <Stack spacing={1}>
                        <Stack direction="row" spacing={1}>
                            <Typography width="40%">Tanggal</Typography>
                            <Typography width="3%">:</Typography>
                            <Typography>
                                {dataFaktur.tanggal
                                    ? moment(dataFaktur.tanggal).format("LL")
                                    : "-"}
                            </Typography>
                        </Stack>
                        <Stack direction="row" spacing={1}>
                            <Typography width="40%">No. Faktur</Typography>
                            <Typography width="3%">:</Typography>
                            <Typography>{dataFaktur.faktur}</Typography>
                        </Stack>
                        <Stack direction="row" spacing={1}>
                            <Typography width="40%">Supplier</Typography>
                            <Typography width="3%">:</Typography>
                            <Typography>
                                {dataFaktur.suplier.nama ??
                                    dataFaktur.suplierNama ??
                                    "-"}
                            </Typography>
                        </Stack>
                        <Stack direction="row" spacing={1}>
                            <Typography width="40%">Jatuh Tempo</Typography>
                            <Typography width="3%">:</Typography>
                            <Typography>
                                {dataFaktur.jatuhTempo
                                    ? moment(dataFaktur.jatuhTempo).format("LL")
                                    : "-"}
                            </Typography>
                        </Stack>
                        <Stack direction="row" spacing={1}>
                            <Typography width="40%">Total</Typography>
                            <Typography width="3%">:</Typography>
                            <Typography>
                                {toRupiahDesimal(
                                    Number(dataFaktur.totalPembelian),
                                )}
                            </Typography>
                        </Stack>
                    </Stack>
                    <Stack spacing={1}>
                        <Typography fontWeight={500}>Detail Barang</Typography>
                        <Box
                            sx={{
                                height: 240,
                                width: "100%",
                                "& .MuiDataGrid-cell:focus-within": {
                                    outline: "none !important",
                                },
                                "& .MuiDataGrid-iconButtonContainer": {
                                    visibility: "visible",
                                    width: "0 !important",
                                },
                                "& .MuiDataGrid-cell": {
                                    maxHeight: "fit-content !important",
                                    overflow: "auto",
                                    whiteSpace: "initial !important",
                                    lineHeight: "16px !important",
                                    display: "flex !important",
                                    alignItems: "center",
                                    paddingTop: "10px !important",
                                    paddingBottom: "10px !important",
                                },
                                "& .MuiDataGrid-viewport,.MuiDataGrid-row,.MuiDataGrid-renderingZone":
                                    {
                                        maxHeight: "fit-content !important",
                                    },
                            }}
                        >
                            <DataGrid
                                components={{
                                    ColumnUnsortedIcon: AscDescIcon,
                                    NoRowsOverlay: NoRowsImage,
                                    LoadingOverlay: LoadingTabel,
                                }}
                                rows={dataFaktur.detailFaktur}
                                columns={columns}
                                disableColumnMenu
                                disableSelectionOnClick
                                rowsPerPageOptions={[10]}
                                pageSize={10}
                                density="compact"
                            />
                        </Box>
                    </Stack>
                </Stack>
            </DialogContent>
        </Dialog>
    );
};

export default ModalDetailFakturPembelian;
