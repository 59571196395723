import React from "react";
import { DataPO, DetailFaktur } from "../../constants/types";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import toRupiahDesimal from "../../utils/toRupiahDesimal";
import moment from "moment";
import toRibuan from "../../utils/toRibuan";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import ArrowBack from "@mui/icons-material/ArrowBack";
import DialogContent from "@mui/material/DialogContent";
import AscDescIcon from "../AscDescIcon/AscDescIcon";
import NoRowsImage from "../NoRowsImage/NoRowsImage";
import LoadingTabel from "../LoadingTabel/LoadingTabel";

interface IModalDetailPreOrderProps {
    fullScreen: boolean;
    isOpenModalDetailPreOrder: boolean;
    closeModalDetailPreOrder: () => void;
    detailFaktur: DetailFaktur[];
    dataFaktur: DataPO;
}

const ModalDetailPreOrder = ({
    fullScreen,
    isOpenModalDetailPreOrder,
    closeModalDetailPreOrder,
    detailFaktur,
    dataFaktur,
}: IModalDetailPreOrderProps) => {
    const columnsMasuk: GridColDef[] = [
        {
            field: "nama",
            headerName: "Nama Barang",
            flex: 1,
            minWidth: 160,
        },
        {
            field: "sku",
            headerName: "SKU",
            flex: 1,
            minWidth: 160,
        },
        {
            field: "harga",
            headerName: "Harga Jual",
            flex: 1,
            minWidth: 200,
            renderCell: (params) => {
                return (
                    <Box width="80%">
                        <Typography
                            variant="subtitle2"
                            fontWeight={400}
                            textAlign="right"
                        >
                            {toRupiahDesimal(Number(params.value))}
                        </Typography>
                    </Box>
                );
            },
        },
        {
            field: "hargaBeli",
            headerName: "Harga Beli",
            flex: 1,
            minWidth: 200,
            renderCell: (params) => {
                return (
                    <Box width="80%">
                        <Typography
                            variant="subtitle2"
                            fontWeight={400}
                            textAlign="right"
                        >
                            {toRupiahDesimal(Number(params.value))}
                        </Typography>
                    </Box>
                );
            },
        },
        {
            field: "jumlahStokFaktur",
            headerName: "Jumlah Beli",
            flex: 1,
            minWidth: 200,
            renderCell: (params) => {
                return (
                    <Typography
                        variant="subtitle2"
                        fontWeight={400}
                        textAlign="right"
                    >
                        {toRibuan(params.value)}
                    </Typography>
                );
            },
        },
        {
            field: "expiredAt",
            headerName: "Tanggal Kadaluarsa",
            flex: 1,
            minWidth: 170,
            renderCell: (params) => {
                return (
                    <Typography variant="body2">
                        {params.row.expiredAt
                            ? moment(params.row.expiredAt).format("LL")
                            : "-"}
                    </Typography>
                );
            },
        },
    ];

    return (
        <Dialog
            maxWidth="lg"
            fullScreen={fullScreen}
            fullWidth={true}
            open={isOpenModalDetailPreOrder}
            onClose={closeModalDetailPreOrder}
            scroll="body"
            PaperProps={{
                sx: {
                    borderRadius: 1,
                },
            }}
        >
            <DialogTitle>
                <Stack direction="row" spacing={1} alignItems="center">
                    <IconButton onClick={closeModalDetailPreOrder}>
                        <ArrowBack />
                    </IconButton>
                    <Typography variant="h6" fontWeight="bold">
                        Detail Pre Order {dataFaktur.faktur}
                    </Typography>
                </Stack>
            </DialogTitle>
            <DialogContent>
                <Stack spacing={3}>
                    <Stack spacing={1}>
                        <Stack direction="row" spacing={1}>
                            <Typography width="40%">Tanggal</Typography>
                            <Typography width="3%">:</Typography>
                            <Typography>
                                {dataFaktur.tanggal
                                    ? moment(dataFaktur.tanggal).format("LL")
                                    : "-"}
                            </Typography>
                        </Stack>
                        <Stack direction="row" spacing={1}>
                            <Typography width="40%">No. Faktur</Typography>
                            <Typography width="3%">:</Typography>
                            <Typography>{dataFaktur.faktur}</Typography>
                        </Stack>
                        <Stack direction="row" spacing={1}>
                            <Typography width="40%">Supplier</Typography>
                            <Typography width="3%">:</Typography>
                            <Typography>
                                {dataFaktur.suplier.id
                                    ? dataFaktur.suplier.nama
                                    : "-"}
                            </Typography>
                        </Stack>
                        <Stack direction="row" spacing={1}>
                            <Typography width="40%">
                                Sudah Terverifikasi?
                            </Typography>
                            <Typography width="3%">:</Typography>
                            <Typography
                                color={
                                    dataFaktur.isVerif === 1
                                        ? "#61C15F"
                                        : "#FF7373"
                                }
                            >
                                {dataFaktur.isVerif === 1 ? "Sudah" : "Belum"}
                            </Typography>
                        </Stack>
                        <Stack direction="row" spacing={1}>
                            <Typography width="40%">Total</Typography>
                            <Typography width="3%">:</Typography>
                            <Typography>
                                {toRupiahDesimal(dataFaktur.totalPembelian)}
                            </Typography>
                        </Stack>
                    </Stack>
                    <Stack spacing={1}>
                        <Box
                            sx={{
                                height: 240,
                                width: "100%",
                                "& .MuiDataGrid-cell:focus-within": {
                                    outline: "none !important",
                                },
                                "& .MuiDataGrid-iconButtonContainer": {
                                    visibility: "visible",
                                    width: "0 !important",
                                },
                                "& .MuiDataGrid-cell": {
                                    maxHeight: "fit-content !important",
                                    overflow: "auto",
                                    whiteSpace: "initial !important",
                                    lineHeight: "16px !important",
                                    display: "flex !important",
                                    alignItems: "center",
                                    paddingTop: "10px !important",
                                    paddingBottom: "10px !important",
                                },
                                "& .MuiDataGrid-viewport,.MuiDataGrid-row,.MuiDataGrid-renderingZone":
                                    {
                                        maxHeight: "fit-content !important",
                                    },
                            }}
                        >
                            <DataGrid
                                components={{
                                    ColumnUnsortedIcon: AscDescIcon,
                                    NoRowsOverlay: NoRowsImage,
                                    LoadingOverlay: LoadingTabel,
                                }}
                                rows={detailFaktur}
                                columns={columnsMasuk}
                                disableColumnMenu
                                disableSelectionOnClick
                                rowsPerPageOptions={[10]}
                                pageSize={10}
                                density="compact"
                            />
                        </Box>
                    </Stack>
                </Stack>
            </DialogContent>
        </Dialog>
    );
};

export default ModalDetailPreOrder;
