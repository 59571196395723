import { useQuery } from "react-query";
import {
    ParamsGetFakturPembelian,
    GetFakturPembelianResponse,
} from "../../constants/types";
import axios from "../axios";
import qs from "query-string";

const handleRequest = async (params: ParamsGetFakturPembelian) => {
    const apiGetFakturPembelian = qs.stringifyUrl({
        url: "api/faktur",
        query: {
            ukmId: params.ukmId,
            search: `${params.search}`,
            size: params.size,
            page: params.page,
            tanggalAwal: params.tanggalAwal,
            tanggalAkhir: params.tanggalAkhir,
        },
    });
    const { data } = await axios.get<GetFakturPembelianResponse>(
        apiGetFakturPembelian,
    );
    return data.data;
};

export default function useFakturPembelian(params: ParamsGetFakturPembelian) {
    return useQuery<GetFakturPembelianResponse["data"]>(
        ["fakturPembelian", params],
        () => handleRequest(params),
        {
            enabled: !!params?.ukmId,
        },
    );
}
