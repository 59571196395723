import {
    Dialog,
    DialogTitle,
    Stack,
    IconButton,
    Typography,
    DialogContent,
    TextField,
    Grid,
    useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import React, { useEffect, useMemo, useState } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import axios from "../../services/axios";
import { useEditKategoriModal } from "../../context/editKategoriModalContext";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
// import { useKategoriModal } from "../../context/kategoriModalContext";
import EditIcon from "@mui/icons-material/Edit";
import { EditKategoriBody, ErrorFieldResponse } from "../../constants/types";
// import useUser from "../../services/queries/useUser";
import Swal from "sweetalert2";
import { LoadingButton } from "@mui/lab";
import defaultAxios, { AxiosError } from "axios";
import { useKategoriModal } from "../../context/kategoriModalContext";
import { useAuth } from "../../context/authContext";

interface IModalEditKategoriProps {
    refetch: () => void;
}

// interface IValuesKategori {
//   id: number | string | undefined;
//   nama: string;
// }

const schema = yup
    .object({
        namaKategori: yup.string().required("Kolom wajib diisi"),
    })
    .required();

const ModalEditKategori = ({ refetch }: IModalEditKategoriProps) => {
    const theme = useTheme();
    const isPhoneScreen = useMediaQuery(theme.breakpoints.between("xs", "sm"));
    const {
        isOpenModalEditKategori,
        closeModalEditKategori,
        namaKategori,
        idKategori,
    } = useEditKategoriModal();
    const { openModalKategori } = useKategoriModal();
    const [isButtonLoading, setIsButtonLoading] = useState(false);
    const { ukmIdUser } = useAuth();
    // const { data: user } = useUser();

    const initialValues = useMemo(
        () => ({
            // id: idKategori,
            ukmId: ukmIdUser && ukmIdUser[0],
            kategoriId: idKategori,
            namaKategori: namaKategori,
        }),
        [idKategori, namaKategori, ukmIdUser],
    );

    const {
        handleSubmit,
        control,
        formState: { errors },
        reset,
        getValues,
        setError,
    } = useForm<EditKategoriBody>({
        resolver: yupResolver(schema),
        defaultValues: initialValues,
    });

    const onSubmit = async (values: EditKategoriBody) => {
        setIsButtonLoading(true);
        try {
            const { data } = await axios.post(`/api/kategori-update`, {
                ukmId: values.ukmId,
                kategoriId: values.kategoriId,
                nama: values.namaKategori,
            });
            if (data.code === 200) {
                // assignWords("Kategori berhasil diubah menjadi " + getValues("namaKategori"));
                // openAlert();
                Swal.fire({
                    title:
                        "Kategori berhasil diubah menjadi " +
                        getValues("namaKategori"),
                    position: "top-end",
                    showConfirmButton: false,
                    icon: "success",
                    toast: true,
                    timer: 3000,
                    timerProgressBar: true,
                    showCloseButton: true,
                    customClass: {
                        container: "my-swal",
                    },
                });
            }
            setIsButtonLoading(false);
            refetch();
            closeModalEditKategori();
            openModalKategori();
        } catch (error) {
            if (defaultAxios.isAxiosError(error)) {
                const serverError = error as AxiosError<
                    ErrorFieldResponse | undefined
                >;
                if (serverError && serverError?.response) {
                    const fieldError = serverError?.response?.data;
                    if (fieldError?.errors) {
                        Object.keys(fieldError.errors).forEach((key) => {
                            const errorMessage = fieldError.errors[key];
                            setError(key as keyof typeof initialValues, {
                                type: "manual",
                                message: errorMessage[0],
                            });
                        });
                    }
                }
            }
            setIsButtonLoading(false);
            console.error(error);
            // console.log(data.nama);
        }
    };

    useEffect(() => {
        if (isOpenModalEditKategori) {
            if (namaKategori && idKategori) {
                reset(initialValues);
                // console.log(initialValues);
            }
        }
    }, [
        idKategori,
        initialValues,
        isOpenModalEditKategori,
        namaKategori,
        reset,
    ]);

    return (
        <Dialog
            maxWidth="xs"
            fullWidth={true}
            open={isOpenModalEditKategori}
            onClose={closeModalEditKategori}
            PaperProps={{
                sx: { borderRadius: 1 },
            }}
        >
            <DialogTitle>
                <Stack direction="row" spacing={1} alignItems="center">
                    <IconButton
                        onClick={() => {
                            closeModalEditKategori();
                            openModalKategori();
                        }}
                    >
                        <ArrowBackIcon />
                    </IconButton>
                    <Typography variant="h6" fontWeight="bold">
                        Edit Kategori
                    </Typography>
                </Stack>
            </DialogTitle>
            <DialogContent>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Grid container spacing={1}>
                        <Grid item xs={8} sm={9}>
                            <Controller
                                name="namaKategori"
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        autoFocus
                                        margin="dense"
                                        id="nama"
                                        fullWidth
                                        size="small"
                                        variant="outlined"
                                        error={Boolean(errors.namaKategori)}
                                        helperText={
                                            errors.namaKategori
                                                ? errors.namaKategori.message
                                                : " "
                                        }
                                        {...field}
                                    />
                                )}
                                rules={{ required: "Nama barang required" }}
                            />
                        </Grid>
                        <Grid item xs={4} sm={3}>
                            <LoadingButton
                                loading={isButtonLoading}
                                loadingPosition="center"
                                variant="contained"
                                fullWidth
                                size="large"
                                type="submit"
                                onClick={handleSubmit(onSubmit)}
                                sx={{ top: 8, height: "40px" }}
                            >
                                {isPhoneScreen ? <EditIcon /> : "Simpan"}
                            </LoadingButton>
                        </Grid>
                    </Grid>
                </form>
            </DialogContent>
        </Dialog>
    );
};

export default ModalEditKategori;
