import {
    Button,
    Card,
    CardContent,
    Box,
    Avatar,
    Stack,
    Typography,
    Badge,
} from "@mui/material";
import avatarAlt from "../../utils/avatarAlt";
import { useUkmModal } from "../../context/ukmModalContext";
import moment from "moment";
import "moment/locale/id";
import { ContentUkm } from "../../constants/types";

moment.locales();
moment.locale("id");

interface ICardInfoTokoProps {
    data: ContentUkm;
}

const CardInfoToko = ({ data }: ICardInfoTokoProps) => {
    const { id, namaToko, gambarToko, alamatToko, noTelp, warna } = data;
    const { openModalUkm } = useUkmModal();
    // const { openModalHapusUkm } = useHapusUkmModal();
    // const { data: provinsiOptions } = useProvinsi();
    // const { data: kotaOptions } = useKota();
    // const { data: time } = useTime();

    // const isSudahJatuhTempo = moment(time?.datetime).isAfter(
    //     pembayaranBerikutnya,
    // );

    // useEffect(
    //     () => console.log("isSudahJatuhTempo", isSudahJatuhTempo),
    //     [isSudahJatuhTempo],
    // );

    // const selectedProvinsi = provinsiOptions?.content.find(
    //     (li) => li.nama === provinsi,
    // );
    // const selectedKota = kotaOptions?.content.find((li) => li.nama === kota);

    return (
        <Card variant="outlined">
            <CardContent>
                <Box display="flex" justifyContent="center">
                    <Avatar
                        alt={namaToko}
                        src={gambarToko === null ? "" : gambarToko}
                        sx={{
                            bgcolor: "primary.main",
                            width: 100,
                            height: 100,
                            marginY: 3,
                        }}
                    >
                        {avatarAlt(namaToko)}
                    </Avatar>
                </Box>
                <Stack direction="row" spacing={2} alignItems="center">
                    <Typography
                        variant="h6"
                        fontWeight="bold"
                        sx={{
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                        }}
                    >
                        {namaToko}
                    </Typography>
                    <Box
                        bgcolor={warna ?? "primary.main"}
                        borderRadius="50%"
                        sx={{
                            maxWidth: "22px",
                            minWidth: "22px",
                            maxHeight: "22px",
                            minHeight: "22px",
                        }}
                    />
                </Stack>
                <Typography
                    component="div"
                    variant="body2"
                    color="#808080"
                    sx={{ marginBottom: 2 }}
                >
                    Status:{" "}
                    <Typography
                        display="inline"
                        variant="body2"
                        color={"#45A779"}
                    >
                        {"Pro "}
                    </Typography>
                    <Typography
                        display="inline"
                        variant="body2"
                        color={"#45A779"}
                    >
                        {"(Aktif)"}
                    </Typography>
                </Typography>
                <Typography variant="body2" sx={{ marginBottom: 2 }}>
                    No. Telp: +{noTelp}
                </Typography>
                <Stack direction="row" spacing={2}>
                    {alamatToko === "-" || !!!alamatToko ? (
                        <Badge
                            anchorOrigin={{
                                vertical: "top",
                                horizontal: "right",
                            }}
                            variant="dot"
                            sx={{
                                width: "100%",
                                "& .MuiBadge-badge": {
                                    height: "10px",
                                    width: "10px",
                                    borderRadius: "50%",
                                    backgroundColor: "#fa4c46",
                                    color: "#fa4c46",
                                    boxShadow: `0 0 0 2px #fff`,
                                    "&::after": {
                                        position: "absolute",
                                        top: 0,
                                        left: 0,
                                        width: "100%",
                                        height: "100%",
                                        borderRadius: "50%",
                                        animation:
                                            "ripple 1.2s infinite ease-in-out",
                                        border: "1px solid currentColor",
                                        content: '""',
                                    },
                                },
                                "@keyframes ripple": {
                                    "0%": {
                                        transform: "scale(.8)",
                                        opacity: 1,
                                    },
                                    "100%": {
                                        transform: "scale(2.4)",
                                        opacity: 0,
                                    },
                                },
                            }}
                        >
                            <Button
                                variant="contained"
                                size="large"
                                // disabled={isActive === 0}
                                fullWidth
                                onClick={() =>
                                    openModalUkm({
                                        id: id,
                                        // gambarToko: gambarToko ?? null,
                                        namaToko: namaToko,
                                        warna: warna,
                                        alamatToko: alamatToko,
                                        noTelp: noTelp.slice(2),
                                    })
                                }
                            >
                                Ubah
                            </Button>
                        </Badge>
                    ) : (
                        <Button
                            variant="contained"
                            size="large"
                            // disabled={isActive === 0}
                            fullWidth
                            onClick={() =>
                                openModalUkm({
                                    id: id,
                                    // gambarToko: gambarToko ?? null,
                                    namaToko: namaToko,
                                    warna: warna,
                                    alamatToko: alamatToko,
                                    noTelp: noTelp.slice(2),
                                })
                            }
                        >
                            Ubah
                        </Button>
                    )}
                    {/* <Button
                        variant="contained"
                        size="large"
                        fullWidth
                        color="error"
                        onClick={() => openModalHapusUkm(id, namaToko)}
                    >
                        Hapus
                    </Button> */}
                </Stack>
                {(alamatToko === "-" || !!!alamatToko) && (
                    <Typography
                        variant="body2"
                        align="center"
                        color="red"
                        marginTop={1}
                    >
                        Segera lengkapi data UKM anda!
                    </Typography>
                )}
            </CardContent>
        </Card>
    );
};

export default CardInfoToko;
