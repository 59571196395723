import { useQuery } from "react-query";
import { NoWaAdminResponse } from "../../constants/types";
import axios from "../axios";

const queryString = require("query-string");

const handleRequest = async () => {
    // const { data } = await axios.get<NoWaAdminResponse>("/api/NoWaAdmin");
    const apiGetWaAdmin = queryString.stringifyUrl({
        url: "api/waadmin",
    });
    const { data } = await axios.get<NoWaAdminResponse>(apiGetWaAdmin);
    return data.wa;
};

export default function useNoWaAdmin() {
    return useQuery<NoWaAdminResponse["wa"]>(["noWaAdmin"], () =>
        handleRequest(),
    );
}
