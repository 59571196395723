import { useQuery } from "react-query";
import { ParamsGetPreOrder, GetPreOrderResponse } from "../../constants/types";
import axios from "../axios";

const queryString = require("query-string");

const handleRequest = async (params: ParamsGetPreOrder) => {
    const apiGetPreOrder = queryString.stringifyUrl({
        url: "api/po",
        query: {
            ukmId: params.ukmId,
            search: params.search,
            size: params.size,
            page: params.page,
            tanggalAwal: params.tanggalAwal,
            tanggalAkhir: params.tanggalAkhir,
        },
    });
    const { data } = await axios.get<GetPreOrderResponse>(apiGetPreOrder);
    return data.data;
};

export default function usePreOrder(params: ParamsGetPreOrder) {
    return useQuery<GetPreOrderResponse["data"]>(["preOrder", params], () =>
        handleRequest(params),
    );
}
