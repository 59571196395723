import React, {
    createContext,
    ReactNode,
    useCallback,
    useMemo,
    useState,
} from "react";

interface State {
    closeModalHapusKategori: () => void;
    idKategori: number | string | undefined;
    isOpenModalHapusKategori: boolean;
    namaKategori: string;
    openModalHapusKategori: (id: number | string, nama: string) => void;
    closeModalHapusSubKategori: () => void;
    idSubKategori: number | string | undefined;
    isOpenModalHapusSubKategori: boolean;
    namaSubKategori: string;
    openModalHapusSubKategori: (
        idSubKategori: number | string,
        idKategori: number | string,
        nama: string,
    ) => void;
}

interface IHapusKategoriModalContext {
    children: ReactNode;
}

const HapusKategoriModalContext = createContext<State | undefined>(undefined);

const HapusKategoriModalProvider = ({
    children,
}: IHapusKategoriModalContext) => {
    const [isOpenModalHapusKategori, setIsOpenModalHapusKategori] =
        useState(false);
    const [idKategori, setIdKategori] = useState<number | string | undefined>();
    const [namaKategori, setNamaKategori] = useState("");

    const [isOpenModalHapusSubKategori, setIsOpenModalHapusSubKategori] =
        useState(false);
    const [idSubKategori, setIdSubKategori] = useState<
        number | string | undefined
    >();
    const [namaSubKategori, setNamaSubKategori] = useState("");

    const openModalHapusKategori = useCallback(
        (id: number | string | undefined, nama: string) => {
            setIdKategori(id);
            setNamaKategori(nama);
            setIsOpenModalHapusKategori(true);
        },
        [],
    );

    const closeModalHapusKategori = useCallback(() => {
        setIsOpenModalHapusKategori(false);
    }, []);

    const openModalHapusSubKategori = useCallback(
        (
            idSubKategori: number | string | undefined,
            idKategori: number | string | undefined,
            nama: string,
        ) => {
            setIdSubKategori(idSubKategori);
            setIdKategori(idKategori);
            setNamaSubKategori(nama);
            setIsOpenModalHapusSubKategori(true);
        },
        [],
    );

    const closeModalHapusSubKategori = useCallback(() => {
        setIsOpenModalHapusSubKategori(false);
    }, []);

    const value = useMemo(
        () => ({
            closeModalHapusKategori,
            idKategori,
            isOpenModalHapusKategori,
            namaKategori,
            openModalHapusKategori,
            closeModalHapusSubKategori,
            idSubKategori,
            isOpenModalHapusSubKategori,
            namaSubKategori,
            openModalHapusSubKategori,
        }),
        [
            closeModalHapusKategori,
            closeModalHapusSubKategori,
            idKategori,
            idSubKategori,
            isOpenModalHapusKategori,
            isOpenModalHapusSubKategori,
            namaKategori,
            namaSubKategori,
            openModalHapusKategori,
            openModalHapusSubKategori,
        ],
    );
    return (
        <HapusKategoriModalContext.Provider value={value}>
            {children}
        </HapusKategoriModalContext.Provider>
    );
};

const useHapusKategoriModal = () => {
    const context = React.useContext(HapusKategoriModalContext);
    if (context === undefined) {
        throw new Error(
            "useHapusKategoriModal must be used within a HapusKategoriModalProvider",
        );
    }
    return context;
};

export {
    HapusKategoriModalContext,
    HapusKategoriModalProvider,
    useHapusKategoriModal,
};
