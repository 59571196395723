import React, { useEffect, useState } from "react";
import moment from "moment";
import "moment/locale/id";
import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import id from "date-fns/locale/id";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useAuth } from "../../context/authContext";
import { useTransaksi } from "../../context/transaksiContext";
import {
    ContentGetFakturPembelianResponse,
    ParamsGetFakturPembelian,
} from "../../constants/types";
import useFakturPembelian from "../../services/queries/useFakturPembelian";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import toRupiahDesimal from "../../utils/toRupiahDesimal";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Visibility from "@mui/icons-material/Visibility";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import ArrowDropDown from "@mui/icons-material/ArrowDropDown";
import Add from "@mui/icons-material/Add";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Search from "@mui/icons-material/Search";
import ImportExport from "@mui/icons-material/ImportExport";
import NoRowsImage from "../../components/NoRowsImage/NoRowsImage";
import LoadingTabel from "../../components/LoadingTabel/LoadingTabel";
import ModalFakturPembayaran from "../../components/ModalFakturPembayaran/ModalFakturPembayaran";
import ModalDetailFakturPembelian from "../../components/ModalDetailFakturPembelian/ModalDetailFakturPembelian";

moment.locales();
moment.locale("id");

registerLocale("id", id);
setDefaultLocale("id");

type DateFilter = [Date | null, Date | null];

const FakturPembelianPage = () => {
    const theme = useTheme();
    const isPhoneScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const isLaptopScreen = useMediaQuery(theme.breakpoints.up("md"));
    const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
    const { ukmIdUser } = useAuth();
    const { isFetchingItems, cancelFetchingItems } = useTransaksi();
    let now = moment().format("YYYY/MM/DD");
    const today = new Date();
    const [dateRange, setDateRange] = useState<DateFilter>([
        new Date(),
        new Date(),
    ]);
    const [startDate, endDate] = dateRange;
    const [search, setSearch] = useState("");
    const initialParamsFakturPembelian = {
        ukmId: ukmIdUser && ukmIdUser[0],
        search: "",
        size: 10,
        page: 1,
        tanggalAwal: now,
        tanggalAkhir: now,
    };
    const [paramsFakturPembelian, setParamsFakturPembelian] =
        useState<ParamsGetFakturPembelian>(initialParamsFakturPembelian);
    const {
        data: fakturPembelian,
        refetch: refetchFakturPembelian,
        isLoading: isLoadingFakturPembelian,
    } = useFakturPembelian(paramsFakturPembelian);
    const [dataFaktur, setDataFaktur] =
        useState<ContentGetFakturPembelianResponse | null>(null);
    const [isOpenModalFakturPembelian, setIsOpenModalFakturPembelian] =
        useState(false);
    const [isOpenModalDetailFaktur, setIsOpenModalDetailFaktur] =
        useState(false);

    const openModalFakturPembelian = () => {
        setIsOpenModalFakturPembelian(true);
    };

    const closeModalFakturPembelian = () => {
        setIsOpenModalFakturPembelian(false);
    };

    const openModalDetailFaktur = (data: ContentGetFakturPembelianResponse) => {
        setDataFaktur(data);
        setIsOpenModalDetailFaktur(true);
    };

    const closeModalDetailFaktur = () => {
        setIsOpenModalDetailFaktur(false);
    };

    const handleChangeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(event.target.value);
    };

    const handleSearch = () => {
        setParamsFakturPembelian((prev) => ({
            ...prev,
            search: search,
        }));
    };

    const handleChangePeriode = (event: SelectChangeEvent) => {
        setDateRange([new Date(event.target.value), new Date()]);
        setParamsFakturPembelian((prev) => ({
            ...prev,
            tanggalAwal: String(event.target.value),
            tanggalAkhir: now,
            page: 1,
        }));
        refetchFakturPembelian();
    };

    const handleChangeJangkaWaktu = (
        tanggalAwal: string,
        tanggalAkhir: string,
    ) => {
        setParamsFakturPembelian((prev) => ({
            ...prev,
            tanggalAwal: tanggalAwal,
            tanggalAkhir: tanggalAkhir,
            page: 1,
        }));
        refetchFakturPembelian();
    };

    const handleOnPagination = (page: number) => {
        setParamsFakturPembelian((prev) => ({
            ...prev,
            page: page + 1,
        }));
    };

    const handleOnSizeChange = (size: number) => {
        setParamsFakturPembelian((prev) => ({ ...prev, size }));
    };

    const columns: GridColDef[] = [
        {
            field: "tanggal",
            headerName: "Tanggal",
            flex: isLaptopScreen ? 0.4 : undefined,
            minWidth: 160,
            renderCell: (params) => (
                <Tooltip
                    title={
                        params.value ? moment(params.value).format("LL") : "-"
                    }
                >
                    <Typography variant="body2">
                        {params.value ? moment(params.value).format("LL") : "-"}
                    </Typography>
                </Tooltip>
            ),
        },
        {
            field: "faktur",
            headerName: "Kode Faktur",
            flex: isLaptopScreen ? 0.8 : undefined,
            minWidth: 160,
            renderCell: (params) => (
                <Tooltip title={params.value}>
                    <span className="table-cell-trucate">{params.value}</span>
                </Tooltip>
            ),
        },
        {
            field: "suplier",
            headerName: "Supplier",
            flex: isLaptopScreen ? 0.8 : undefined,
            minWidth: 160,
            renderCell: (params) => (
                <Tooltip title={params.value.nama ?? "-"}>
                    <span className="table-cell-trucate">
                        {params.value.nama ?? params.row.suplierNama ?? ""}
                    </span>
                </Tooltip>
            ),
        },
        {
            field: "jatuhTempo",
            headerName: "Jatuh Tempo",
            minWidth: 160,
            renderCell: (params) => (
                <Tooltip
                    title={
                        params.value ? moment(params.value).format("LL") : "-"
                    }
                >
                    <Typography variant="body2">
                        {params.value ? moment(params.value).format("LL") : "-"}
                    </Typography>
                </Tooltip>
            ),
        },
        // {
        //     field: "ukm",
        //     headerName: "UKM",
        //     flex: isLaptopScreen ? 0.6 : undefined,
        //     minWidth: 160,
        //     renderCell: (params) => {
        //         return (
        //             <Typography variant="body2">
        //                 {params.row.ukm.nama}
        //             </Typography>
        //         );
        //     },
        // },
        {
            field: "totalPembelian",
            headerName: "Total Pembelian",
            align: "right",
            flex: isLaptopScreen ? 0.3 : undefined,
            minWidth: 160,
            renderCell: (params) => {
                return (
                    <Tooltip title={toRupiahDesimal(Number(params.value))}>
                        <Typography variant="subtitle2" textAlign="right">
                            {toRupiahDesimal(Number(params.value))}
                        </Typography>
                    </Tooltip>
                );
            },
        },
        {
            field: "aksi",
            headerName: "Aksi",
            headerAlign: "center",
            minWidth: 170,
            sortable: false,
            disableColumnMenu: true,
            align: "center",
            renderCell: (params) => {
                const row: ContentGetFakturPembelianResponse = params.row;
                return (
                    <Stack direction="row" spacing={2}>
                        <Tooltip title="Lihat Detail">
                            <span>
                                <Button
                                    variant="contained"
                                    onClick={() => {
                                        openModalDetailFaktur(row);
                                    }}
                                    size="small"
                                    color="buttongreen"
                                    disableElevation
                                    sx={{
                                        minWidth: "unset",
                                        padding: "8px",
                                        width: "32px",
                                        height: "32px",
                                    }}
                                >
                                    <Visibility
                                        sx={{ width: "16px", height: "16px" }}
                                    />
                                </Button>
                            </span>
                        </Tooltip>
                    </Stack>
                );
            },
        },
    ];

    useEffect(() => {
        if (isFetchingItems === true) {
            refetchFakturPembelian();
            cancelFetchingItems();
        }
    }, [cancelFetchingItems, isFetchingItems, refetchFakturPembelian]);

    return (
        <Box>
            <Box>
                <Stack spacing={2} marginY={2}>
                    <Typography variant="h6" fontWeight="bold">
                        Faktur Pembelian
                    </Typography>
                    <Grid container alignItems="center" gap={3}>
                        <Grid item xs={12} md={12} xl={6}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} md={6}>
                                    <Select
                                        value={
                                            paramsFakturPembelian.tanggalAwal ===
                                                now ||
                                            paramsFakturPembelian.tanggalAwal ===
                                                moment()
                                                    .subtract(7, "days")
                                                    .format("YYYY-MM-DD") ||
                                            paramsFakturPembelian.tanggalAwal ===
                                                moment()
                                                    .subtract(1, "month")
                                                    .format("YYYY-MM-DD") ||
                                            paramsFakturPembelian.tanggalAwal ===
                                                moment()
                                                    .subtract(3, "months")
                                                    .format("YYYY-MM-DD") ||
                                            paramsFakturPembelian.tanggalAwal ===
                                                moment()
                                                    .subtract(1, "years")
                                                    .format("YYYY-MM-DD")
                                                ? paramsFakturPembelian.tanggalAwal
                                                : "Custom"
                                        }
                                        size="small"
                                        fullWidth
                                        onChange={handleChangePeriode}
                                    >
                                        <MenuItem value={now}>
                                            Hari ini
                                        </MenuItem>
                                        <MenuItem
                                            value={moment()
                                                .subtract(7, "days")
                                                .format("YYYY-MM-DD")}
                                        >
                                            1 Minggu Terakhir
                                        </MenuItem>
                                        <MenuItem
                                            value={moment()
                                                .subtract(1, "month")
                                                .format("YYYY-MM-DD")}
                                        >
                                            1 Bulan Terakhir
                                        </MenuItem>
                                        <MenuItem
                                            value={moment()
                                                .subtract(3, "months")
                                                .format("YYYY-MM-DD")}
                                        >
                                            3 Bulan Terakhir
                                        </MenuItem>
                                        <MenuItem
                                            value={moment()
                                                .subtract(1, "years")
                                                .format("YYYY-MM-DD")}
                                        >
                                            1 Tahun Terakhir
                                        </MenuItem>
                                        <MenuItem
                                            value={"Custom"}
                                            sx={{ display: "none" }}
                                        >
                                            Custom
                                        </MenuItem>
                                    </Select>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <DatePicker
                                        className="range-filter"
                                        dateFormat="dd/MM/yyyy"
                                        disabledKeyboardNavigation
                                        onFocus={(e) => e.target.blur()}
                                        selectsRange={true}
                                        startDate={startDate}
                                        endDate={endDate}
                                        onChange={(update) => {
                                            setDateRange(update);
                                            if (update[1]) {
                                                handleChangeJangkaWaktu(
                                                    moment(update[0]).format(
                                                        "YYYY/MM/DD",
                                                    ),
                                                    moment(update[1]).format(
                                                        "YYYY/MM/DD",
                                                    ),
                                                );
                                            }
                                        }}
                                        maxDate={today}
                                        monthsShown={isPhoneScreen ? 1 : 2}
                                        customInput={
                                            <Stack
                                                display="flex"
                                                justifyContent="space-between"
                                                direction="row"
                                                width="100%"
                                                bgcolor="#FFFF"
                                                height="40px"
                                                sx={{
                                                    border: "1px solid #cbcbcb",
                                                    borderRadius: "4px",
                                                    alignItems: "center",
                                                    padding: 2,
                                                    cursor: "pointer",
                                                }}
                                            >
                                                <Typography>
                                                    {moment(
                                                        paramsFakturPembelian.tanggalAwal,
                                                    ).format("DD/MM/YYYY")}{" "}
                                                    -{" "}
                                                    {paramsFakturPembelian.tanggalAkhir &&
                                                        moment(
                                                            paramsFakturPembelian.tanggalAkhir,
                                                        ).format("DD/MM/YYYY")}
                                                </Typography>
                                                <ArrowDropDown
                                                    sx={{
                                                        marginRight: "-9px",
                                                    }}
                                                />
                                            </Stack>
                                        }
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs md={0} xl></Grid>
                        <Grid item xs="auto">
                            <Button
                                variant="contained"
                                startIcon={<Add />}
                                disableElevation
                                fullWidth
                                onClick={openModalFakturPembelian}
                            >
                                Tambah
                            </Button>
                        </Grid>
                    </Grid>
                    <Grid container alignItems="center" gap={2}>
                        <Grid item xs="auto">
                            <TextField
                                placeholder="Cari faktur"
                                size="small"
                                fullWidth
                                value={search}
                                onChange={handleChangeSearch}
                                onKeyPress={(e) => {
                                    const element =
                                        e.target as HTMLInputElement;
                                    if (e.key === "Enter") {
                                        setParamsFakturPembelian((prev) => ({
                                            ...prev,
                                            search: element.value,
                                        }));
                                    }
                                }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton onClick={handleSearch}>
                                                <Search />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>
                        <Grid item xs></Grid>
                        <Grid item xs={4} md={2}></Grid>
                    </Grid>
                    <Box
                        marginTop={2}
                        sx={{
                            height: 631,
                            width: "100%",
                            borderRadius: 1,
                            "& .headerColumn": {
                                backgroundColor: "#E4EEE8",
                            },
                            "& .MuiDataGrid-columnHeaderTitle": {
                                fontWeight: "bold",
                            },
                            "& .MuiDataGrid-columnSeparator": {
                                visibility: "hidden",
                            },
                            "& .MuiDataGrid-cell:focus-within": {
                                outline: "none !important",
                            },
                            "& .MuiDataGrid-iconButtonContainer": {
                                visibility: "visible",
                                width: "0 !important",
                            },
                        }}
                    >
                        <DataGrid
                            pagination
                            rows={fakturPembelian?.content ?? []}
                            columns={columns}
                            components={{
                                ColumnUnsortedIcon: () => {
                                    return <ImportExport />;
                                },
                                NoRowsOverlay: NoRowsImage,
                                LoadingOverlay: LoadingTabel,
                            }}
                            rowsPerPageOptions={[10]}
                            disableColumnMenu
                            disableSelectionOnClick
                            paginationMode="server"
                            onPageSizeChange={handleOnSizeChange}
                            onPageChange={handleOnPagination}
                            rowCount={fakturPembelian?.totalElements ?? 0}
                            page={paramsFakturPembelian.page - 1}
                            pageSize={paramsFakturPembelian.size}
                            loading={isLoadingFakturPembelian}
                        />
                    </Box>
                </Stack>
            </Box>
            <ModalFakturPembayaran
                isOpenModalFakturPembelian={isOpenModalFakturPembelian}
                closeModalFakturPembelian={closeModalFakturPembelian}
            />
            {dataFaktur && (
                <ModalDetailFakturPembelian
                    isOpenModalDetailFaktur={isOpenModalDetailFaktur}
                    closeModalDetailFaktur={closeModalDetailFaktur}
                    dataFaktur={dataFaktur}
                    fullScreen={fullScreen}
                />
            )}
        </Box>
    );
};

export default FakturPembelianPage;
