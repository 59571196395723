import React, {
    createContext,
    ReactNode,
    useCallback,
    useMemo,
    useState,
} from "react";

interface State {
    closeModalEditKategori: () => void;
    idKategori: number | string | undefined;
    isOpenModalEditKategori: boolean;
    namaKategori: string;
    openModalEditKategori: (id: number | undefined, nama: string) => void;
    closeModalEditSubKategori: () => void;
    idSubKategori: number | string | undefined;
    isOpenModalEditSubKategori: boolean;
    namaSubKategori: string;
    openModalEditSubKategori: (
        idSubKategori: number | undefined,
        idKategori: number | undefined,
        nama: string,
    ) => void;
    isOpenModalUnggahKategori: boolean;
    openModalUnggahKategori: () => void;
    closeModalUnggahKategori: () => void;
    isKategori: boolean;
    handleIsKategoriTrue: () => void;
    handleIsKategoriFalse: () => void;
}

interface IEditKategoriModalContext {
    children: ReactNode;
}

const EditKategoriModalContext = createContext<State | undefined>(undefined);

const EditKategoriModalProvider = ({ children }: IEditKategoriModalContext) => {
    const [isOpenModalEditKategori, setIsOpenModalEditKategori] =
        useState(false);
    const [idKategori, setIdKategori] = useState<number>();
    const [namaKategori, setNamaKategori] = useState("");

    const [isOpenModalEditSubKategori, setIsOpenModalEditSubKategori] =
        useState(false);
    const [idSubKategori, setIdSubKategori] = useState<number>();
    const [namaSubKategori, setNamaSubKategori] = useState("");
    const [isOpenModalUnggahKategori, setIsOpenModalUnggahKategori] =
        useState(false);
    const [isKategori, setIsKategori] = useState(false);

    const openModalUnggahKategori = () => setIsOpenModalUnggahKategori(true);
    const closeModalUnggahKategori = () => setIsOpenModalUnggahKategori(false);

    const handleIsKategoriTrue = () => setIsKategori(true);
    const handleIsKategoriFalse = () => setIsKategori(false);

    const openModalEditKategori = useCallback(
        (id: number | undefined, nama: string) => {
            setIdKategori(id);
            setNamaKategori(nama);
            setIsOpenModalEditKategori(true);
            console.log(id + nama);
        },
        [],
    );

    const closeModalEditKategori = useCallback(() => {
        setIsOpenModalEditKategori(false);
        setNamaKategori("");
        setIdKategori(undefined);
    }, []);

    const openModalEditSubKategori = useCallback(
        (
            idSubKategori: number | undefined,
            idKategori: number | undefined,
            nama: string,
        ) => {
            setIdSubKategori(idSubKategori);
            setIdKategori(idKategori);
            setNamaSubKategori(nama);
            setIsOpenModalEditSubKategori(true);
        },
        [],
    );

    const closeModalEditSubKategori = useCallback(() => {
        setIsOpenModalEditSubKategori(false);
        setNamaSubKategori("");
        setIdSubKategori(undefined);
    }, []);

    const value = useMemo(
        () => ({
            closeModalEditKategori,
            idKategori,
            isOpenModalEditKategori,
            namaKategori,
            openModalEditKategori,
            closeModalEditSubKategori,
            idSubKategori,
            isOpenModalEditSubKategori,
            namaSubKategori,
            openModalEditSubKategori,
            isOpenModalUnggahKategori,
            openModalUnggahKategori,
            closeModalUnggahKategori,
            isKategori,
            handleIsKategoriFalse,
            handleIsKategoriTrue,
        }),
        [
            closeModalEditKategori,
            closeModalEditSubKategori,
            idKategori,
            idSubKategori,
            isKategori,
            isOpenModalEditKategori,
            isOpenModalEditSubKategori,
            isOpenModalUnggahKategori,
            namaKategori,
            namaSubKategori,
            openModalEditKategori,
            openModalEditSubKategori,
        ],
    );
    return (
        <EditKategoriModalContext.Provider value={value}>
            {children}
        </EditKategoriModalContext.Provider>
    );
};

const useEditKategoriModal = () => {
    const context = React.useContext(EditKategoriModalContext);
    if (context === undefined) {
        throw new Error(
            "useEditKategoriModal must be used within a EditKategoriModalProvider",
        );
    }
    return context;
};

export {
    EditKategoriModalContext,
    EditKategoriModalProvider,
    useEditKategoriModal,
};
