import React, { useState } from "react";
import { DateFilter } from "../../constants/types";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import ArrowBack from "@mui/icons-material/ArrowBack";
import DialogContent from "@mui/material/DialogContent";
import moment from "moment";
import "moment/locale/id";
import id from "date-fns/locale/id";
import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import ArrowDropDown from "@mui/icons-material/ArrowDropDown";
import DialogActions from "@mui/material/DialogActions";
import LoadingButton from "@mui/lab/LoadingButton";
import axios from "../../services/axios";
import qs from "query-string";
import Box from "@mui/material/Box";
import { useAuth } from "../../context/authContext";
import useUkm from "../../services/queries/useUkm";

registerLocale("id", id);
setDefaultLocale("id");
moment.locales();
moment.locale("id");

interface IModalUnduhStokOpnameProps {
    isOpenModalUnduhStokOpname: boolean;
    closeModalUnduhStokOpname: () => void;
}

const ModalUnduhStokOpname = ({
    isOpenModalUnduhStokOpname,
    closeModalUnduhStokOpname,
}: IModalUnduhStokOpnameProps) => {
    const { ukmIdUser } = useAuth();

    const initialParamsUkm = {
        size: 500,
        page: 1,
        search: "",
    };
    const { data: dataUkm } = useUkm(initialParamsUkm);

    const selectedUkm =
        ukmIdUser &&
        dataUkm &&
        dataUkm?.content.find((li) => li.id === ukmIdUser[0]);

    const theme = useTheme();
    const isPhoneScreen = useMediaQuery(theme.breakpoints.between("xs", "sm"));
    let now = moment().format("YYYY/MM/DD");
    const today = new Date();
    const [isButtonLoading, setIsButtonLoading] = useState(false);
    const [dateRange, setDateRange] = useState<DateFilter>([
        new Date(),
        new Date(),
    ]);
    const [startDate, endDate] = dateRange;
    const [tanggalAwal, setTanggalAwal] = useState(now);
    const [tanggalAkhir, setTanggalAkhir] = useState(now);
    const [isCalendarOpen, setIsCalendarOpen] = useState(false);

    const handleCalendarClose = () => setIsCalendarOpen(false);
    const handleCalendarOpen = () => setIsCalendarOpen(true);

    const handleChangeJangkaWaktu = (
        tanggalAwal: string,
        tanggalAkhir: string,
    ) => {
        setTanggalAwal(tanggalAwal);
        setTanggalAkhir(tanggalAkhir);
    };

    const handleUnduhOpname = async () => {
        setIsButtonLoading(true);
        const formattedTanggalAwal = moment(tanggalAwal).format("DD-MM-YYYY");
        const formattedTanggalAkhir = moment(tanggalAkhir).format("DD-MM-YYYY");
        try {
            const apiUrl = qs.stringifyUrl({
                url: "api/opname-excel",
                query: {
                    ukmId: selectedUkm?.id,
                    tanggalAwal: moment(tanggalAwal).format("YYYY-MM-DD"),
                    tanggalAkhir: moment(tanggalAkhir).format("YYYY-MM-DD"),
                },
            });
            const res = await axios.get(apiUrl, {
                responseType: "blob",
            });
            if (res.status === 200) {
                const file = new Blob([res.data], {
                    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;",
                });
                const fileURL = URL.createObjectURL(file);
                const link = document.createElement("a");
                link.href = fileURL;
                link.setAttribute(
                    "download",
                    `Laporan_StokOpname_${selectedUkm?.namaToko}_${formattedTanggalAwal}_${formattedTanggalAkhir}`,
                ); //or any other extension
                document.body.appendChild(link);
                link.click();
                setIsButtonLoading(false);
            }
        } catch (error) {
            console.error(error);
            setIsButtonLoading(false);
        }
    };

    return (
        <Dialog
            maxWidth="sm"
            fullScreen={isPhoneScreen}
            fullWidth={true}
            open={isOpenModalUnduhStokOpname}
            onClose={closeModalUnduhStokOpname}
            scroll="body"
            PaperProps={{
                sx: {
                    borderRadius: 1,
                },
            }}
        >
            <DialogTitle>
                <Stack direction="row" spacing={1} alignItems="center">
                    <IconButton onClick={closeModalUnduhStokOpname}>
                        <ArrowBack />
                    </IconButton>
                    <Typography variant="h6" fontWeight="bold">
                        Unduh Laporan Opname
                    </Typography>
                </Stack>
            </DialogTitle>
            <DialogContent sx={{ pb: isCalendarOpen ? 24 : undefined }}>
                <Typography mb={1.5}>
                    <b>UKM:</b> {selectedUkm?.namaToko}
                </Typography>
                <Typography fontWeight="bold">Pilih Tanggal</Typography>
                <Typography
                    variant="body2"
                    color="#545E6A"
                    sx={{ maxWidth: "360px", mb: 2 }}
                >
                    Pilih jangka waktu laporan yang ingin diunduh
                </Typography>
                <Box maxWidth={360}>
                    <DatePicker
                        className="range-filter"
                        dateFormat="dd/MM/yyyy"
                        disabledKeyboardNavigation
                        onFocus={(e) => e.target.blur()}
                        selectsRange={true}
                        startDate={startDate}
                        endDate={endDate}
                        onChange={(update) => {
                            setDateRange(update);
                            if (update[1]) {
                                handleChangeJangkaWaktu(
                                    moment(update[0]).format("YYYY/MM/DD"),
                                    moment(update[1]).format("YYYY/MM/DD"),
                                );
                            }
                        }}
                        maxDate={today}
                        monthsShown={isPhoneScreen ? 1 : 2}
                        onCalendarClose={handleCalendarClose}
                        onCalendarOpen={handleCalendarOpen}
                        customInput={
                            <Stack
                                display="flex"
                                justifyContent="space-between"
                                direction="row"
                                width="100%"
                                bgcolor="#FFFF"
                                height="40px"
                                sx={{
                                    border: "1px solid #cbcbcb",
                                    borderRadius: "4px",
                                    alignItems: "center",
                                    padding: 2,
                                    cursor: "pointer",
                                }}
                            >
                                <Typography>
                                    {moment(tanggalAwal).format("DD/MM/YYYY")} -{" "}
                                    {tanggalAkhir &&
                                        moment(tanggalAkhir).format(
                                            "DD/MM/YYYY",
                                        )}
                                </Typography>
                                <ArrowDropDown
                                    sx={{
                                        marginRight: "-9px",
                                    }}
                                />
                            </Stack>
                        }
                    />
                </Box>
            </DialogContent>
            <DialogActions sx={{ p: 3 }}>
                <LoadingButton
                    loading={isButtonLoading}
                    loadingPosition="center"
                    disableElevation
                    onClick={handleUnduhOpname}
                    variant="contained"
                >
                    Unduh
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
};

export default ModalUnduhStokOpname;
