export interface IStokBarang {
    id: number;
    namaBarang: string;
    infoBarang: {
        nama: string;
        deskripsi?: string;
        url: string;
    };
    kategori: string;
    harga: number;
    jumlahStok: number;
    SKU: string;
    url: string;
}

export interface IKategori {
    id: number;
    nama: string;
}

export interface IInvoice {
    id: string;
    nomorInvoice: string;
    saldo: number;
    namaToko: string;
    alamatToko: string;
    email: string;
    noTelp: string;
    tanggalTransaksi: string;
    barang: {
        id: string;
        namaBarang: string;
        qty: number;
        harga: number;
    }[];
}

export interface LoginBody {
    email: string;
    password: string;
}

export interface RegistrasiForm {
    nama: string;
    username: string;
    email: string;
    no_telp: string;
    password: string;
    passwordUlang: string;
    referral: string;
    namaToko: string;
    alamat: string;
    provinsi?: {
        id: number;
        nama: string;
    };
    kota?: {
        id: number;
        nama: string;
        provinsiId: number;
        provinsiNama: string;
    };
}

export interface AuthState {
    isAuthenticated: boolean;
    isLoading: boolean;
    isRegistered: boolean;
    checkToken: (token: string) => void;
    login: (values: LoginBody) => void;
    logout: () => void;
    ukmIdUser: number[] | undefined;
    register: (values: RegistrasiForm) => void;
    handleRegistered: () => void;
    handleAuthenticated: (value: boolean) => void;
    setRegistered: React.Dispatch<React.SetStateAction<boolean>>;
    handleSetToken: (token: any) => Promise<void>;
    loginCount: number;
    resetLoginCount: () => void;
}

export interface CheckToken {
    code: number;
    message: string;
    data: string;
}

export interface LogoutResponse {
    code: number;
    message: string;
}

export interface LoginResponse {
    code: number;
    message: string;
    data: {
        token: string;
    };
}

export interface RegistrasiResponse {
    code: number;
    message: string;
    data: {
        nama: string;
        username: string;
        email: string;
        alamat: string;
        no_telp: string;
        role: string;
        status: number;
        gambar: string | null;
        updated_at: string;
        created_at: string;
        id: number;
        token: string;
    };
}

export interface ErrorsFieldResponse {
    field: string;
    message: string;
}

export interface ErrorResponse {
    code: number;
    message: string;
    data: {
        errors: {
            field: string;
            message: string;
        };
    };
}

export type DefaultResponse = {
    code: number;
    message: string;
};

export type ItemContent = {
    id: number;
    nomer: number;
    nama: string;
    deskripsi: string | null;
    harga: number;
    sku: string;
    jumlah_stok: number | null;
    kategori: number;
    created_at: string | null;
    updated_at: string | null;
    deleted_at: string | null;
    kategoriwa: null | {
        id: number;
        nama: string;
        created_at: string | null;
        updated_at: string | null;
        deleted_at: string | null;
    };
};

export type ItemResponse = ItemContent[];

export type UserResponse = {
    code: number;
    data: {
        userId: number;
        namaLengkap: string;
        username: string;
        role: {
            id: number;
            nama: string;
        };
        email: string;
        gambar: string | null;
        noTelp: string | null;
        ukmId: number[];
        semuaUkmId: number[];
    };
};

export type ParamsGetItem = {
    ukmId: string | number | undefined;
    search: string;
    subKategori: string;
    kategori?: string;
    size: number;
    page: number;
};

export type GetItemResponse = {
    code: number;
    message: string;
    data: {
        ukmId: string | number | undefined;
        totalElements: number;
        page: number;
        lastPage: boolean;
        size: number;
        nextId: number;
        content: ContentGetItemResponse[];
        // totalItem: number;
    };
};

export type ItemSatuan = {
    id: number;
    namaBarang: string;
    deskripsiBarang: string;
    sku: string;
    harga: number;
};

export type DetailBundling = {
    id: number;
    bundlingId: number;
    namaBarang: string;
    deskripsiBarang: string;
    sku: string;
    harga: number;
    stok: number;
    jumlahYangDiambil: number;
    faktur: {
        id: number;
        kodeFaktur: string;
        hargaBeli: number;
        jumlah: number;
    }[];
    newFaktur?: IFaktur[];
};

export type IOptionsUKM = {
    id: string | number;
    nama: string;
    // lewatTempoPro: number;
    // pembayaranBerikutnya: string | null;
};

export type DetailFakturType = {
    id: number;
    stokId: number;
    hargaBeli: string;
    jumlah: number;
    totalKulak: string;
    terjual: number;
    totalPendapatan: string;
    expiredAt: string | null;
    keterangan: string | null;
    keluar: number;
};

export type FakturType = {
    id: number;
    isLunas: number;
    kodeFaktur: string;
    tanggal: string | null;
    jatuhTempo: string | null;
    totalKulak: string;
    utang_dibayar: string;
    total_pendapatan: string;
    detail: DetailFakturType[];
};

export type ContentGetItemResponse = {
    id: number;
    ukm: IOptionsUKM;
    nomer: number;
    namaBarang: string;
    deskripsi: string | null;
    sku: string;
    subKategori: {
        id: number;
        kategoriId: number;
        nama: string;
    };
    gambar: string;
    harga: string;
    hargaMultiple: { id: number; harga: string; keterangan: string }[];
    isActiveStok: number;
    isFavorit: number;
    terjual: number;
    stok: number;
    isBundling: number;
    itemSatuan?: ItemSatuan[] | null;
    detailBundling: DetailBundling[];
    faktur: FakturType[];
};

export type JenisItem = {
    itemId: number;
    namaItem: string;
    skuItem: string;
    jumlahItem: number;
    hargaSatuan: number;
    hargaTotal: number;
};

export type InputPembayaranBody = {
    ukmId: number;
    jenisItem: JenisItem[];
    userInput: string;
    totalHarga: number;
    diskon: number;
    totalPembayaran: number;
    uangYangDibayar: number;
    kembalian: number;
};

export type InputPembayaranResponse = {
    code: number;
    message: string;
    data: {
        nomorInvoice: string;
        jenisItem: JenisItem[];
        totalPembayaran: number;
        diskon: number;
        totalHarga: number;
        encrypt: string;
    };
};
export type ParamsGetKategori = {
    ukmId: number | undefined;
    search: string;
    size: number;
    page: number;
};

export type GetKategoriResponse = {
    code: number;
    message: string;
    data: {
        ukmId: number | undefined;
        totalElements: number;
        page: number;
        lastPage: number;
        content: ContentGetKategoriResponse[];
    };
};

export type ContentGetKategoriResponse = {
    id: number;
    nama: string;
};

export type AddItemBody = {
    id: number;
    ukmId?: number;
    namaBarang: string;
    deskripsi: string;
    sku: string;
    harga: number;
    subId: number | undefined | string;
    gambar: File | null;
    isSubBaru: boolean;
    kategoriId: number | undefined | string;
    subBaru: string;
    isActiveStok: boolean;
    jumlahStok: number | null;
    isFavorit: boolean;
    isHPP: boolean;
    hpp: number;
    multipleHarga: {
        harga: number | string;
        keterangan: string | null;
    }[];
};

export type AddItemResponse = {
    code: number;
    message: string;
    data: AddItemBody;
};

export type ParamsGetLaporanTotal = {
    ukmId: number | undefined;
    tanggalAwal: string;
    tanggalAkhir: string;
};

export type GetLaporanTotalResponse = {
    code: number;
    message: string;
    data: {
        totalPendapatan: number;
        totalTransaksi: number;
        jumlahStokTerjual: number;
    };
};

export type ParamsGetProdukTerjual = {
    search: string;
    size: number;
    page: number;
    ukmId: number | undefined;
    tanggalAwal: string;
    tanggalAkhir: string;
};

export type ContentGetProdukTerjual = {
    id: number;
    namaItem: string;
    skuItem: string;
    jumlahItem: string;
    totalHarga: string;
};

export type GetProdukTerjualResponse = {
    code: number;
    message: string;
    data: {
        ukmId: string;
        totalElements: number;
        page: number;
        lastPage: boolean;
        content: ContentGetProdukTerjual[];
    };
};

export type AddKategoriBody = {
    ukmId: number;
    namaKategori: string;
};

export type AddKategoriResponse = {
    code: number;
    message: string;
};

export type EditKategoriBody = {
    ukmId: number;
    kategoriId: string | number;
    namaKategori: string;
};

export type ModalStokField = {
    id: number;
    ukm: IOptionsUKM;
    namaBarang: string;
    deskripsi: string;
    sku: string;
    harga: number;
    subId: number;
    kategoriId: number;
    isActiveStok: number;
    isFavorit: number;
    terjual: number;
    stok: number;
    isBundling: number;
    itemSatuan: ItemSatuan[] | null;
    detailBundling: DetailBundling[] | [];
    hargaMultiple: { id: number; harga: number; keterangan: string }[];
};

export type ProductsBundling = {
    itemId: number;
    jumlahItem: number;
};

export type AddProductBundling = {
    ukmId: number;
    namaBundling: string;
    deskripsiBundling?: string;
    kategoriBundling: string;
    sku: string;
    gambar: File | null;
    item: ProductsBundling[];
    hargaBundling: number;
    isActiveStok: boolean;
    jumlahStok: number;
};

export type SelectedProductBundling = {
    id: number;
    nama: string;
    harga: number;
    stok: number;
};

export type BundlingValues = {
    id: number | undefined;
    item: ProductsBundling[];
    namaBundling: string;
    deskripsiBundling: string;
    sku: string;
    hargaBundling: string;
    isActiveStok: boolean;
    jumlahStok: number;
    isSubBaru: boolean;
    kategoriId: number | undefined | string;
    subBaru: string;
    subId: number | undefined | string;
    gambar: File | null;
    favorite: boolean;
};

export type AddBundlingResponse = {
    code: number;
    message: string;
    data: {
        ukmId: number;
        namaBundling: string;
        deskripsiBundling: string;
        sku: string;
        kategoriBundling: string;
        gambar: string | null;
        hargaBundling: number;
        isActiveStok: 0 | 1; // 0 = false, 1 = true
        jumlahStok: number;
    };
};

export type ParamsGetListInvoice = {
    ukmId: number | undefined;
    search: string;
    size: number;
    page: number;
    tanggalAwal: string;
    tanggalAkhir: string;
    sales: string;
    kasir: string;
    metodeId: string;
    voidInvoice: string;
};

export type ContentGetListInvoice = {
    id: number;
    tanggal: string;
    revertId: number | null;
    nomorInvoice: string;
    hargaPenjualan: number;
    hargaAsli: string;
    diskon: string;
    pajak: string;
    totalPembayaran: string;
    uangYangDibayar: string;
    kembalian: string;
    kasir: {
        id: number | null;
        nama: string | null;
    };
    sales: {
        id: number | null;
        nama: string | null;
    };
    metode: string | number | null;
    metodeArr: {
        namaMetode: string | null;
        charge: {
            mode: string | null;
            charge: number | null;
        };
        noTujuan: string | null;
        namaPemegang: string | null;
        gambar: string | null;
    };
    keterangan: string | null;
    faktur: {
        idFaktur: number;
        jumlah: number;
        totalBeli: string;
    }[];
};

export type GetListInvoiceResponse = {
    code: number;
    message: string;
    data: {
        ukmId: string[];
        totalElements: number;
        page: number;
        lastPage: boolean;
        content: ContentGetListInvoice[];
        totalHargaAsli: string;
    };
};

export type ParamsGrafikPenjualan = {
    ukmId: number | undefined;
    tanggalAwal: string;
    tanggalAkhir: string;
};

export type GetGrafikPenjualanResponse = {
    code: number;
    message: string;
    data: {
        labels: string[];
        data: {
            totalPendapatan: number[];
            totalTransaksi: number[];
            jumlahProdukTerjual: number[];
        };
    };
};

export type DaftarItemListInvoice = {
    nomer: number;
    idStok: number;
    namaStok: string;
    skuStok: string;
    jumlahStok: number;
    hargaSatuan: number;
    hargaTotal: number;
    faktur: {
        idFaktur: number;
        jumlah: number;
    }[];
};

export type GetDetailInvoiceResponse = {
    code: number;
    message: string;
    data: {
        tanggalTransaksi: string;
        nomorInvoice: string;
        daftarStok: DaftarItemListInvoice[];
        totalPembayaran: string;
        diskon: string;
        pajak: string;
        hargaAsli: string;
        uangYangDibayar: string;
        kembalian: string;
        kasir: string;
        sales: string | null;
        metode: string | null;
        metodeArr: {
            namaMetode: string | null;
            charge: {
                mode: string | null;
                charge: number | null;
            };
            noTujuan: string | null;
            namaPemegang: string | null;
            gambar: string | null;
        };
        keterangan: string | null;
        encrypt: string | null;
    };
};

export type GetDetailInvoiceVoidResponse = {
    code: number;
    message: string;
    data: {
        tanggalTransaksi: string;
        daftarStok: DaftarItemListInvoice[];
        totalPembayaran: string;
        diskon: string;
        pajak: string;
        hargaAsli: string;
        uangYangDibayar: string;
        kembalian: string;
        kasir: string;
        sales: string | null;
        metode: string | null;
        metodeArr: {
            namaMetode: string | null;
            charge: {
                mode: string | null;
                charge: number | null;
            };
            noTujuan: string | null;
            namaPemegang: string | null;
            gambar: string | null;
        };
        keterangan: string | null;
    };
};

export type IncrementSKUResponse = {
    code: number;
    message: string;
    data: {
        urutanke: string;
    };
};

export type ParamsGetIncrementSKU = {
    ukmId: number | undefined;
};

export type ErrorFieldResponse = {
    message: string;
    errors: {
        [key: string]: string[];
    };
};

export type ContentUkm = {
    id: number | string;
    namaToko: string;
    gambarToko: string | null;
    gambarTokoBW: string | null;
    alamatToko: string;
    noTelp: string;
    warna: string;
    pajak: string | null;
    customStruk: string | null;
    isPusat: number;
};

export type ParamsGetUkm = {
    search: string;
    size: number;
    page: number;
};

export type GetUkmResponse = {
    code: number;
    message: string;
    data: {
        totalElements: number;
        page: number;
        lastPage: number;
        content: ContentUkm[];
    };
};

export type ParamsGetUkmIsDueExpired = {
    search: string;
    size: number;
    page: number;
    isDueExpired: number;
};

export type UserValues = {
    namaLengkap: string;
    username: string;
    email: string;
    gambar: File | string | null;
    noTelp: string | null;
};

export type EditUserResponse = {
    code: number;
    message: string;
    data: UserValues;
};

export type PasswordValues = {
    passwordLama: string;
    passwordBaru: string;
    passwordBaruUlang: string;
};

export type ChangePasswordResponse = {
    code: number;
    message: string;
};

export type ModalUkmField = {
    id: number | string;
    namaToko: string;
    noTelp: string;
    alamatToko: string;
    warna: string;
    gambarToko?: File | null;
    // deskripsi: string | null;
    // besarUsaha: string;
    // jenisUsaha: string;
    // omset: string;
    // provinsi?: {
    //     id: number;
    //     nama: string;
    // };
    // kota?: {
    //     id: number;
    //     nama: string;
    //     provinsiId: number;
    //     provinsiNama: string;
    // };
};

export type DownloadKatalogBody = {
    ukmId: number | undefined;
    kategori: string;
    tipe: string;
};

export type EditUkmResponse = {
    code: number;
    message: string;
};

export type GetInfiniteItemResponse = {
    pages: GetItemResponse["data"][];
    pageParams: (number | undefined)[];
};

export type SelectedProductMutasi = {
    id: number;
    nama: string;
    sku: string;
    harga: number;
    stok: number;
    faktur: {
        id: number;
        kodeFaktur: string;
        hargaBeli: number;
        jumlah: number;
    }[];
};

export type ModalBundlingField = {
    id: number | undefined;
    item: ProductsBundling[];
    namaBundling: string;
    deskripsiBundling: string;
    subId: number | undefined | string;
    kategoriId: number | undefined | string;
    sku: string;
    hargaBundling: string;
    isActiveStok: boolean;
    jumlahStok: number;
    favorite: boolean;
};

export type DetailMutasi = {
    itemId: number;
    jumlahMasuk: number | null;
    jumlahKeluar: number | null;
    // hargaBeli: number;
    keterangan: string;
};

export type AddMutasiBody = {
    ukmId: number | undefined;
    detail: DetailMutasi[];
};

export type AddMutasiResponse = {
    code: number;
    message: string;
};

export type NestedDetail = {
    isMasuk: string;
    itemId: number;
    jumlahStok: string;
    // hargaBeli: number;
    keterangan: string;
    faktur?: {
        id: number;
        kodeFaktur: string;
        hargaBeli: number;
        jumlah: number;
    };
    stokRepack: string | null;
    itemRepack: IOptions | null;
    hargaBeliRepack: number | null;
};

export type MutasiValues = {
    ukmId: number | undefined;
    detail: {
        nestedDetail: NestedDetail[];
    }[];
};

export type ParamsGetMutasiStok = {
    ukmId: number | undefined;
    search: string;
    size: number;
    page: number;
    tanggalAwal: string;
    tanggalAkhir: string;
};

export type ContentGetMutasiStok = {
    nomor: number;
    ukm: {
        id: number;
        nama: string;
    };
    stokId: number;
    namaBarang: string;
    sku: string;
    stokAwal: number;
    hargaBarang: number;
    masuk: number;
    keluar: number;
    jual: number;
    stokAkhir: number;
    keteranganMasuk: {
        jumlah: number;
        keterangan: string | null;
        tanggal: string;
        user: string;
    }[];
    keteranganKeluar: {
        jumlah: number;
        keterangan: string | null;
        tanggal: string;
        user: string;
    }[];
};

export type GetMutasiStokResponse = {
    code: number;
    message: string;
    data: {
        ukmId: string[];
        totalElements: number;
        page: number;
        lastPage: boolean;
        content: ContentGetMutasiStok[];
    };
};

export type BuatTokoForm = {
    namaToko: string;
    noTelpToko: string;
    alamatToko: string;
    warna: string;
    deskripsiToko: string;
    jenisUsaha: string;
    besarUsaha: string;
    omzetPerTahun: string;
    logoToko: string | null;
};

export type BuatTokoResponse = {
    code: number;
    message: string;
    data: {
        nama: string;
        alamat: string;
        deskripsi: string | null;
        no_telp: string;
        warna: string;
        jenis_usaha: string;
        besar_usaha: string;
        omset: string;
        status: number;
        gambar: string | null;
        updated_at: string;
        created_at: string;
        id: number;
    };
};

export type NoWaAdminResponse = {
    code: number;
    wa: {
        nama: string;
        nomor: string;
    };
};

export type ParamsPostUsersByUkm = {
    ukmId: number | undefined;
    size: number;
    page: number;
    search: string;
};

export type UsersByUkmContent = {
    userId: number;
    namaLengkap: string;
    username: string;
    aksesTransaksi: number;
    aksesStok: number;
    aksesLaporan: number;
    email: string;
    gambar: string | null;
    noTelp: string;
    ukmId: string[];
};

export type PostUsersByUkmResponse = {
    code: number;
    message: string;
    data: {
        totalElements: number;
        page: number;
        lastPage: boolean;
        content: UsersByUkmContent[];
    };
};

export type IOptions = {
    id: string | number;
    nama: string;
};

export type LupaPasswordResponse = {
    code: number;
    message: string;
};

export type GetMetodePembayaranResponse = {
    code: number;
    message: string;
    data: {
        id: number;
        ukmId: { id: number | null; nama: string | null };
        namaMetode: string;
        charge: { mode: string | null; charge: string | null };
        noTujuan: string;
        namaPemegang: string;
        gambar: string | null;
    }[];
};

export type GetFindMetodePembayaranResponse = {
    code: number;
    message: string;
    data: {
        id: number;
        ukmId: number;
        namaMetode: string;
        noTujuan: string;
        namaPemegang: string;
        gambar: string | null;
        charge: { mode: string | null; charge: string | null };
    };
};

export type FindCustomStrukResponse = {
    code: number;
    message: string;
    data: {
        html: string | null;
    };
};

export type DataLayanan = {
    id: number;
    nama: string;
    deskripsiLayanan: string;
    harga: number;
    isHide: number;
};

export type GetLayananResponse = {
    code: number;
    message: string;
    data: DataLayanan[];
};

export type DaftarItemListStruk = {
    nomer: number;
    idItem: number;
    namaItem: string;
    skuItem: string;
    jumlahItem: number;
    hargaSatuan: number;
    hargaTotal: number;
};

export type GetDetailStrukResponse = {
    code: number;
    message: string;
    data: {
        tanggalTransaksi: string;
        nomorInvoice: string;
        daftarItem: DaftarItemListStruk[];
        totalPembayaran: number;
        diskon: number;
        pajak: number;
        totalHarga: number;
        uangYangDibayar: number;
        kembalian: number;
        kasir: string;
        sales: string;
        metode: string | null;
        namaUkm: string;
        alamatUkm: string;
        noTelp: string;
        gambarToko: string | null;
        keterangan: string;
    };
};

export type SelectedFakturMutasi = {
    id: number;
    kodeFaktur: string;
    hargaBeli: number;
    jumlahStok: number;
};

export type ParamsGetFakturPembelian = {
    ukmId?: number | string;
    search: string;
    size: number;
    page: number;
    tanggalAwal: string;
    tanggalAkhir: string;
};

export type ContentGetFakturPembelianResponse = {
    id: number;
    nomer: number;
    ukm: {
        id: number;
        nama: string;
    };
    faktur: string;
    tanggal: string;
    suplier: {
        id: number | null;
        nama: string | null;
    };
    suplierNama: string | null;
    jatuhTempo: string | null;
    isLunas: number;
    totalPembelian: string;
    detailFaktur: {
        id: number;
        idFaktur: number;
        nomor: number;
        nama: string | null;
        sku: string | null;
        harga: string | null;
        jumlah: string | number | null;
        jumlahStokFaktur: number;
        jumlahFakturAwal: number;
        expiredAt: string | null;
        hargaBeli: string;
        keterangan: string;
    }[];
};

export type GetFakturPembelianResponse = {
    code: number;
    message: string;
    data: {
        ukmId?: string[];
        totalElements: number;
        totalPembelian: number;
        page: number;
        lastPage: boolean;
        size: number;
        nextId: number;
        content: ContentGetFakturPembelianResponse[];
    };
};

export interface ICart {
    id: number;
    namaBarang: string;
    sku?: string | undefined;
    qty: number;
    harga: number;
    url: string;
    jumlahStok?: number | undefined;
    detailBundling: DetailBundling[];
    hpp: {
        id: number;
        kodeFaktur: string;
        hargaBeli: number;
        jumlah: number;
    }[];
    faktur: IFaktur[];
    multipleHarga: {
        id: number;
        harga: number;
        keterangan: string | null;
    }[];
}

export interface IFaktur {
    fakturId: number;
    kodeFaktur: string;
    jumlahItem: number;
}

export type IDetailFaktur = {
    detail: {
        fakturId: number;
        kodeFaktur: string;
        jumlahStok: string;
    }[];
};

export type GetProvinsiResponse = {
    code: number;
    message: string;
    data: {
        content: {
            id: number;
            nama: string;
        }[];
    };
};

export type GetKotaResponse = {
    code: number;
    message: string;
    data: {
        content: {
            id: number;
            nama: string;
            provinsiId: number;
            provinsiNama: string;
        }[];
    };
};

export type GetTimeResponse = {
    abbreviation: string;
    client_ip: string;
    datetime: string;
    day_of_week: number;
    day_of_year: number;
    dst: boolean;
    dst_from: string | null;
    dst_offset: number;
    dst_until: string | null;
    raw_offset: number;
    timezone: string;
    unixtime: number;
    utc_datetime: string;
    utc_offset: string;
    week_number: number;
};

export interface Keterangan {
    jumlah: number;
    keterangan: string;
    tanggal: string;
    invoice: string;
    user: string | null;
}

export enum TipeStruk {
    Mobile = "MOBILE",
    PC = "PC",
}

export type ParamsGetSubKategori = {
    ukmId?: number | string;
    search: string;
    size: number;
    page: number;
    kategoriId?: number | string;
};

export type ContentGetSubKategoriResponse = {
    id: number;
    nama: string;
    ukm: {
        id: number;
        nama: string;
    };
    kategori: {
        id: number;
        nama: string;
    }[];
    produkTerhubung: number;
};

export type GetSubKategoriResponse = {
    code: number;
    message: string;
    data: {
        ukmId: number | undefined;
        totalElements: number;
        page: number;
        lastPage: number;
        content: ContentGetSubKategoriResponse[];
    };
};

export type ParamsGetPreOrder = {
    ukmId?: number | string;
    search: string;
    tanggalAwal: string;
    tanggalAkhir: string;
    size: number;
    page: number;
};

export type DetailFaktur = {
    id: number;
    idFaktur: number;
    nomor: number;
    nama: string;
    sku: string;
    harga: string;
    jumlah: number;
    jumlahStokFaktur: number;
    jumlahFakturAwal: number;
    expiredAt: string;
    hargaBeli: string;
    keterangan: string;
};

export interface DataPO {
    id: number;
    faktur: string;
    ukm: {
        id: number | null;
        nama: string | null;
    };
    suplier: {
        id: number | null;
        nama: string | null;
    };
    tanggal: string | null;
    totalPembelian: number;
    isVerif: number;
}

export type ContentGetPreOrderResponse = {
    id: number;
    nomer: number;
    ukm: {
        id: number;
        nama: string;
    };
    faktur: string | null;
    tanggal: string;
    suplier: {
        id: number;
        nama: null;
    };
    jatuhTempo: string | null;
    isLunas: number;
    isVerif: number;
    verifOleh: null;
    totalPembelian: string;
    detailFaktur: DetailFaktur[];
};

export type GetPreOrderResponse = {
    code: number;
    message: string;
    data: {
        ukmId: number | undefined;
        totalElements: number;
        totalPembelian: number;
        page: number;
        lastPage: boolean;
        size: number;
        nextId: number;
        content: ContentGetPreOrderResponse[];
    };
};

export type ParamsGetSupplier = {
    size: number;
    page: number;
    search: string;
};

export type ContentGetSupplierResponse = {
    nomer: number;
    id: number;
    nama: string;
    alamat: string;
    noTelepon: string;
    keterangan: string;
    cp: string;
    email: string;
};

export type GetSupplierResponse = {
    code: number;
    message: string;
    data: {
        totalElements: number;
        page: number;
        lastPage: boolean;
        content: ContentGetSupplierResponse[];
    };
};

export type SelectedProductPreOrder = {
    id: number;
    nama: string;
    harga: number;
    stok: number;
    sku: string;
};

export type SelectedProductFaktur = {
    id: number;
    nama: string;
    sku: string;
    harga: number;
    stok: number;
    hargaBeli: number | null;
};

export type FakturPembelianValues = {
    ukmId?: number;
    isKodeFaktur: boolean;
    kodeFaktur: string;
    tanggal?: Date | null;
    jatuhTempo?: Date | null;
    supplier: string;
    detail: {
        isTanggalExp: boolean;
        tanggalExp?: Date | null;
        stokId: number;
        hargaBeli: string | number;
        jumlah: string | number;
        keterangan: string;
    }[];
};

export type ParamsGetStokOpname = {
    ukmId?: number | string;
    size: number;
    page: number;
    tanggal: string;
};

export type ContentGetStokOpnameResponse = {
    id: number;
    nomer: number;
    ukm: {
        id: 2;
        nama: string;
    };
    tanggal: string;
    stok: {
        id: number;
        nama: string;
        sku: string;
    };
    jumlahAsli: number;
    jumlahOpname: number | null;
    isProses: number;
    userInit: {
        id: number;
        nama: string;
    };
    userProses: {
        id: number;
        nama: string;
    };
};

export type GetStokOpnameResponse = {
    code: number;
    message: string;
    data: {
        ukmId: string[];
        totalElements: number;
        page: number;
        lastPage: boolean;
        size: number;
        nextId: number;
        content: ContentGetStokOpnameResponse[];
    };
};

export type SelectedOpname = {
    opnameId: number;
    namaStok: string;
    sku: string;
    jumlahAsli: number;
};

export type ProsesOpnameValues = {
    details: {
        opnameId: string | number;
        jumlahOpname: string | number;
    }[];
};

export type DateFilter = [Date | null, Date | null];

export type ParamsKesimpulan = {
    ukmId: string | number | undefined;
    tanggal: string;
};

export type GetKesimpulanResponse = {
    code: number;
    message: string;
    data: {
        totalTransaksi: number;
        totalItemTerjual: number;
        totalPajak: number;
        totalGross: number;
        totalDiskon: number;
        totalVoid: number;
        totalItemVoid: number;
        totalNet: number;
        items: {
            nama: string | null;
            sku: string | null;
            jumlah: string;
        }[];
        metodePembayarans: {
            nama: string | null;
            total: string;
        }[];
    };
};
