import React, { useEffect, useMemo, useState } from "react";
import Box from "@mui/material/Box";
import Radio from "@mui/material/Radio";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Checkbox from "@mui/material/Checkbox";
import FormLabel from "@mui/material/FormLabel";
import FormGroup from "@mui/material/FormGroup";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import RadioGroup from "@mui/material/RadioGroup";
import DialogTitle from "@mui/material/DialogTitle";
import FormControl from "@mui/material/FormControl";
import DialogContent from "@mui/material/DialogContent";
import useMediaQuery from "@mui/material/useMediaQuery";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useTheme } from "@mui/material/styles";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Spinner from "../Spinner/Spinner";
import { useAuth } from "../../context/authContext";
import { useKatalogModal } from "../../context/katalogModalContext";
import { ParamsGetSubKategori } from "../../constants/types";
import axios from "../../services/axios";
import { ReactComponent as TemplateList } from "../../assets/images/TemplateList.svg";
import { ReactComponent as TemplateMenu } from "../../assets/images/TemplateMenu.svg";
import "./ModalKatalog.css";
import useSubKategori from "../../services/queries/useSubKategori";
import { LoadingButton } from "@mui/lab";

interface IModalKatalogProps {
    namaUkm?: string;
}

const ModalKatalog = ({ namaUkm }: IModalKatalogProps) => {
    const theme = useTheme();
    const isPhoneScreen = useMediaQuery(theme.breakpoints.between("xs", "sm"));
    const isTabletScreen = useMediaQuery(theme.breakpoints.up("md"));
    const { isOpenModalKatalog, closeModalKatalog } = useKatalogModal();
    const [value, setValue] = useState("List");
    const { ukmIdUser } = useAuth();

    const initialParamsKategori: ParamsGetSubKategori = {
        ukmId: ukmIdUser && ukmIdUser[0],
        search: "",
        size: 500,
        page: 1,
    };

    const [paramsKategori, setParamsKategori] = useState(initialParamsKategori);
    const [selectedKategori, setSelectedKategori] = useState<number[]>([]);
    const [isButtonLoading, setIsButtonLoading] = useState(false);

    const { data: subKategori, status: statusSubKategori } =
        useSubKategori(paramsKategori);

    const handleRadio = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue((event.target as HTMLInputElement).value);
    };

    const handleSelect = (checked: boolean, selectedId: number) => {
        if (checked) {
            setSelectedKategori((prev) => [...prev, selectedId]);
        } else {
            setSelectedKategori((prev) =>
                prev.filter((li) => li !== selectedId),
            );
        }
    };

    const handleSelectAll = (checked: boolean, selectedIds: number[]) => {
        if (checked) {
            setSelectedKategori(selectedIds);
        } else {
            setSelectedKategori([]);
        }
    };

    const apiDownloadKatalog = async () => {
        setIsButtonLoading(true);
        if (value === "Excel") {
            try {
                const res = await axios.post(
                    `/api/katalog-excel`,
                    {
                        ukmId: ukmIdUser && ukmIdUser[0],
                        subId: selectedKategori.join(","),
                    },
                    {
                        responseType: "blob",
                    },
                );
                if (res.status === 200) {
                    const file = new Blob([res.data], {
                        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;",
                    });
                    const fileURL = URL.createObjectURL(file);
                    const link = document.createElement("a");
                    link.href = fileURL;
                    link.setAttribute("download", `Katalog ${namaUkm}`); //or any other extension
                    document.body.appendChild(link);
                    link.click();
                    setIsButtonLoading(false);
                }
            } catch (error) {
                console.error(error);
                setIsButtonLoading(false);
            }
            // window.open(fileURL);
        } else {
            try {
                const res = await axios.post(
                    `/api/katalog`,
                    {
                        ukmId: ukmIdUser && ukmIdUser[0],
                        subId: selectedKategori.join(","),
                        tipe: value,
                    },
                    {
                        responseType: "blob",
                    },
                );
                if (res.status === 200) {
                    const file = new Blob([res.data], {
                        type: "application/pdf",
                    });
                    const fileURL = URL.createObjectURL(file);
                    window.open(fileURL);
                    setIsButtonLoading(false);
                }
            } catch (error) {
                console.error(error);
                setIsButtonLoading(false);
            }
        }
    };

    const allKategoriId = useMemo(() => {
        if (subKategori) {
            return subKategori?.content.map((li) => li.id);
        } else {
            return [];
        }
    }, [subKategori]);

    useEffect(() => {
        if (ukmIdUser) {
            setParamsKategori((prev) => ({
                ...prev,
                ukmId: ukmIdUser && ukmIdUser[0],
            }));
        }
    }, [ukmIdUser]);

    useEffect(() => {
        setSelectedKategori(allKategoriId);
    }, [allKategoriId]);

    const isLoading = statusSubKategori === "loading";

    if (isLoading)
        return (
            <Stack
                display="grid"
                gridTemplateColumns="1fr"
                alignItems="center"
                height={400}
                position="relative"
            >
                <Spinner />
            </Stack>
        );

    return (
        <Dialog
            maxWidth="md"
            fullScreen={isPhoneScreen}
            fullWidth
            open={isOpenModalKatalog}
            onClose={closeModalKatalog}
            scroll="body"
            PaperProps={{
                sx: {
                    borderRadius: 1,
                },
            }}
        >
            <DialogTitle>
                <Stack direction="row" spacing={1} alignItems="center">
                    <IconButton onClick={closeModalKatalog}>
                        <ArrowBackIcon />
                    </IconButton>
                    <Typography variant="h6" fontWeight="bold">
                        Buat Katalog
                    </Typography>
                </Stack>
            </DialogTitle>
            <DialogContent>
                <Box marginTop={2} marginBottom={3}>
                    <FormControl sx={{ width: "100%" }}>
                        <FormLabel>Pilih Sub Kategori</FormLabel>
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={
                                            selectedKategori.length ===
                                            allKategoriId.length
                                        }
                                        onChange={(_, checked) => {
                                            handleSelectAll(
                                                checked,
                                                allKategoriId,
                                            );
                                        }}
                                        indeterminate={
                                            selectedKategori.length > 0 &&
                                            selectedKategori.length !==
                                                allKategoriId.length
                                        }
                                    />
                                }
                                label="Pilih Semua"
                            />
                            <Box className="katalog__checkbox">
                                {subKategori &&
                                    subKategori?.content.map((li, idx) => (
                                        <FormControlLabel
                                            key={String(li.id)}
                                            control={
                                                <Checkbox
                                                    checked={selectedKategori.includes(
                                                        li.id,
                                                    )}
                                                    onChange={(_, checked) => {
                                                        handleSelect(
                                                            checked,
                                                            li.id,
                                                        );
                                                    }}
                                                />
                                            }
                                            label={li.nama}
                                            sx={{
                                                height: "fit-content",
                                            }}
                                        />
                                    ))}
                            </Box>
                        </FormGroup>
                    </FormControl>
                </Box>
                <Box marginBottom={3}>
                    <FormControl>
                        <FormLabel>Format Katalog</FormLabel>
                        <RadioGroup
                            row
                            name="radio-button-format-katalog"
                            value={value}
                            onChange={handleRadio}
                        >
                            <FormControlLabel
                                value="List"
                                control={<Radio />}
                                label="List"
                            />
                            <FormControlLabel
                                value="Menu"
                                control={<Radio />}
                                label="Menu"
                            />
                            <FormControlLabel
                                value="Excel"
                                control={<Radio />}
                                label="Excel (.xls)"
                            />
                        </RadioGroup>
                    </FormControl>
                </Box>
                <Box marginBottom={3}>
                    {value === "List" || value === "Excel" ? (
                        <TemplateList width="40%" height="40%" />
                    ) : (
                        <TemplateMenu width="40%" height="40%" />
                    )}
                </Box>
                <Box display="flex" justifyContent="flex-end">
                    <Box sx={{ width: { xs: "100%", md: "unset" } }}>
                        <Stack direction="row" spacing={2} alignItems="center">
                            <Button
                                variant="outlined"
                                onClick={() => closeModalKatalog()}
                                fullWidth
                                sx={{ display: { xs: "block", md: "none" } }}
                            >
                                <Typography>Kembali</Typography>
                            </Button>
                            <LoadingButton
                                loading={isButtonLoading}
                                loadingPosition="center"
                                variant="contained"
                                fullWidth={isTabletScreen ? false : true}
                                onClick={apiDownloadKatalog}
                            >
                                Unduh
                            </LoadingButton>
                        </Stack>
                    </Box>
                </Box>
            </DialogContent>
        </Dialog>
    );
};

export default ModalKatalog;
