/* eslint-disable @typescript-eslint/no-unused-expressions */
import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
    Box,
    Button,
    Checkbox,
    FormControl,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    TextField,
    Tooltip,
    Typography,
} from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import {
    SelectedProductMutasi,
    ParamsGetItem,
    FakturType,
    ParamsGetSubKategori,
    ContentGetItemResponse,
} from "../../../constants/types";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import useItem from "../../../services/queries/useItem";
import useUser from "../../../services/queries/useUser";
import toRupiah from "../../../utils/toRupiah";
import Spinner from "../../Spinner/Spinner";
import SearchIcon from "@mui/icons-material/Search";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import { useAuth } from "../../../context/authContext";
import NoRowsImage from "../../NoRowsImage/NoRowsImage";
import LoadingTabel from "../../LoadingTabel/LoadingTabel";
import useSubKategori from "../../../services/queries/useSubKategori";

interface IPilihItemMutasiStepProps {
    addProducts: (item: SelectedProductMutasi[]) => void;
}

const PilihItemMutasiStep = ({ addProducts }: IPilihItemMutasiStepProps) => {
    const { data: user, status: statusUser } = useUser();
    const { ukmIdUser } = useAuth();

    const initialParamsStok: ParamsGetItem = {
        ukmId: ukmIdUser && ukmIdUser[0],
        search: "",
        subKategori: "",
        size: 10,
        page: 1,
    };

    const initialParamsSubKategori: ParamsGetSubKategori = {
        ukmId: ukmIdUser && ukmIdUser[0],
        search: "",
        size: 500,
        page: 1,
    };

    const [paramsStok, setParamsStok] = useState(initialParamsStok);
    const [paramsSubKategori, setParamsSubKategori] = useState(
        initialParamsSubKategori,
    );
    const [search, setSearch] = useState<string>("");
    const [selectedSubKategori, setSelectedSubKategori] = useState<string>("");

    const { data: stok, isLoading: isLoadingStok } = useItem(paramsStok);
    const { data: subkategori, status: statusSubKategori } =
        useSubKategori(paramsSubKategori);

    const [selectedProducts, setSelectedProducts] = useState<
        SelectedProductMutasi[]
    >([]);

    const stokAllProduct = useMemo(() => {
        if (stok && stok.content.length > 0) {
            return stok.content
                ?.filter((item) => item.stok !== null)
                .map((list) => ({
                    id: list.id,
                    nama: list.namaBarang,
                    sku: list.sku,
                    harga: Number(list.harga),
                    stok: list.stok,
                    faktur: list.faktur.map((li) => ({
                        id: li.id,
                        kodeFaktur: li.kodeFaktur,
                        hargaBeli: Number(
                            li.detail.find((detail) => detail.stokId === li.id)
                                ?.hargaBeli,
                        ),
                        jumlah: Number(
                            li.detail.find((detail) => detail.stokId === li.id)
                                ?.jumlah,
                        ),
                    })),
                }));
        }
        setSelectedProducts([]);
    }, [stok]);

    const isSelected = (id: number) => {
        const filteredProduct = selectedProducts.filter(
            (li) => li.id === id,
        ).length;
        if (filteredProduct > 0) {
            return true;
        } else {
            return false;
        }
    };

    const handleClickAllProduct = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            if (event.target.checked) {
                console.log(event.target.checked);
                setSelectedProducts(stokAllProduct ?? []);
                return;
            }
            return setSelectedProducts([]);
        },
        [stokAllProduct],
    );

    const handleSelectedProducts = (
        id: number,
        nama: string,
        sku: string,
        harga: number,
        stok: number,
        faktur: FakturType[],
    ) => {
        if (isSelected(id)) {
            const newList = selectedProducts.filter((li) => li.id !== id);
            setSelectedProducts(newList);
        } else {
            setSelectedProducts((prev) => [
                ...prev,
                {
                    id,
                    nama,
                    sku,
                    harga,
                    stok,
                    faktur: faktur.map((li) => ({
                        id: li.id,
                        kodeFaktur: li.kodeFaktur,
                        jumlah: Number(li.detail[0].jumlah),
                        hargaBeli: Number(li.detail[0].hargaBeli),
                    })),
                },
            ]);
        }
    };
    // };

    const columns: GridColDef[] = [
        {
            field: "namaBarang",
            headerName: "Nama Barang",
            width: 300,
            flex: 2,
            minWidth: 150,
            renderCell: (params) => (
                <Tooltip title={params.value}>
                    <span className="table-cell-trucate">{params.value}</span>
                </Tooltip>
            ),
        },
        {
            field: "subKategori",
            headerName: "Sub Kategori",
            headerAlign: "center",
            align: "center",
            // width: 150,
            flex: 1,
            minWidth: 125,
            renderCell: (params) => (
                <Tooltip title={params.value.nama}>
                    <span className="table-cell-trucate">
                        {params.value.nama}
                    </span>
                </Tooltip>
            ),
        },
        {
            field: "harga",
            headerName: "Harga",
            headerAlign: "center",
            align: "center",
            // type: "number",
            // width: 150,
            flex: 1,
            minWidth: 125,
            renderCell: (params) => (
                <Tooltip title={toRupiah(params.value ?? 0)}>
                    <span className="table-cell-trucate">
                        {toRupiah(params.value ?? 0)}
                    </span>
                </Tooltip>
            ),
        },
        {
            field: "sku",
            headerName: "SKU",
            headerAlign: "center",
            align: "center",
            // type: "number",
            // width: 150,
            flex: 1,
            minWidth: 100,
            renderCell: (params) => (
                <Tooltip title={params.value}>
                    <span className="table-cell-trucate">{params.value}</span>
                </Tooltip>
            ),
        },
        {
            field: "stok",
            headerName: "Stok",
            headerAlign: "center",
            align: "center",
            // type: "number",
            // width: 150,
            flex: 1,
            minWidth: 100,
            renderCell: (params) => (
                <Tooltip
                    title={params.value === null ? "\u221e" : params.value}
                >
                    <span className="table-cell-trucate">
                        {params.value === null ? "\u221e" : params.value}
                    </span>
                </Tooltip>
            ),
        },
        {
            field: "aksi",
            headerName: "Aksi",
            headerAlign: "center",
            align: "center",
            minWidth: 100,
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params) => {
                const row: ContentGetItemResponse = params.row;
                return (
                    <IconButton
                        disabled={row.stok === null}
                        onClick={() => {
                            handleSelectedProducts(
                                row.id,
                                row.namaBarang,
                                row.sku,
                                params.row.harga,
                                row.stok,
                                row.faktur,
                            );
                            // setSelectedProducts((prev) => [
                            //     ...prev,
                            //     {
                            //         id: params.row.id,
                            //         nama: params.row.namaBarang,
                            //         sku: params.row.sku,
                            //         harga: params.row.harga,
                            //         stok: params.row.stok,
                            //     },
                            // ]);
                        }}
                        sx={{
                            minWidth: "unset",
                            padding: "6px",
                            width: "fit-content",
                        }}
                        // startIcon={<EditIcon />}
                        size="small"
                        color="primary"
                    >
                        {isSelected(params.row.id) ? (
                            <CheckBoxIcon />
                        ) : (
                            <CheckBoxOutlineBlankIcon />
                        )}
                    </IconButton>
                );
            },
        },
    ];

    useEffect(() => {
        if (user) {
            setParamsStok((prev) => ({
                ...prev,
                ukmId: ukmIdUser && ukmIdUser[0],
            }));

            setParamsSubKategori((prev) => ({
                ...prev,
                ukmId: ukmIdUser && ukmIdUser[0],
            }));
        }
    }, [ukmIdUser, user]);

    const handleOnPagination = (page: number) => {
        setParamsStok((prev) => ({
            ...prev,
            page: page + 1,
        }));
    };

    const handleOnSizeChange = (size: number) => {
        setParamsStok((prev) => ({ ...prev, size }));
    };

    const handleSearchStok = () => {
        setParamsStok((prev) => ({
            ...prev,
            search,
            subKategori: selectedSubKategori,
        }));
    };

    // const handleSelectedProducts = (
    //     ids: GridSelectionModel,
    //     _: GridCallbackDetails,
    // ) => {
    //     const selectedRows: SelectedProductMutasi[] | undefined = stok?.content
    //         .filter((li) => ids.includes(li.id))
    //         .map((li) => ({
    //             id: li.id,
    //             nama: li.namaBarang,
    //             harga: li.harga,
    //             stok: li.stok,
    //             sku: li.sku,
    //         }));
    //     addProducts(selectedRows ?? []);
    // };

    useEffect(() => {
        addProducts(selectedProducts ?? []);
    }, [addProducts, selectedProducts]);

    const isLoading =
        statusUser === "loading" || statusSubKategori === "loading";

    if (isLoading) {
        return (
            <Stack
                display="grid"
                gridTemplateColumns="1fr"
                alignItems="center"
                height={400}
                position="relative"
            >
                <Spinner />
            </Stack>
        );
    }

    return (
        <Box className="pilih-item">
            <Box
                ml={-1.1}
                flexDirection="row"
                display="flex"
                alignItems="center"
            >
                <Checkbox
                    onChange={handleClickAllProduct}
                    checked={selectedProducts.length === stokAllProduct?.length}
                />
                <Typography>Pilih Semua</Typography>
            </Box>
            <Box className="pilih-item__header" mb={2}>
                <TextField
                    placeholder="Cari barang..."
                    size="small"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    onKeyPress={(e) => {
                        e.key === "Enter" ? handleSearchStok() : undefined;
                    }}
                    variant="outlined"
                    fullWidth
                    InputProps={{
                        style: {
                            backgroundColor: "white",
                        },
                    }}
                />
                <FormControl size="small" fullWidth>
                    <InputLabel>Pilih sub kategori</InputLabel>
                    <Select
                        label="Pilih sub kategori"
                        value={selectedSubKategori}
                        onChange={(e) => setSelectedSubKategori(e.target.value)}
                        sx={{ backgroundColor: "#ffffff" }}
                    >
                        <MenuItem value="">-- Pilih Sub Kategori --</MenuItem>
                        {subkategori &&
                            subkategori.totalElements > 0 &&
                            subkategori.content.map((li) => (
                                <MenuItem
                                    key={String(li.id)}
                                    value={String(li.id)}
                                >
                                    {li.nama}
                                </MenuItem>
                            ))}
                    </Select>
                </FormControl>
                <Button
                    variant="outlined"
                    size="large"
                    fullWidth
                    sx={{
                        backgroundColor: "#ffffff",
                        lineHeight: 2,
                    }}
                    onClick={handleSearchStok}
                >
                    <SearchIcon />
                </Button>
            </Box>
            <Box
                sx={{
                    height: 460,
                    width: "100%",
                    backgroundColor: "#ffffff",
                    borderRadius: 1,
                    "& .headerColumn": {
                        backgroundColor: "#E4EEE8",
                        // fontSize: "18px",
                    },
                    "& .MuiDataGrid-columnHeaderTitle": {
                        fontWeight: "bold",
                    },
                    "& .MuiDataGrid-columnSeparator": {
                        visibility: "hidden",
                    },
                    "& .MuiDataGrid-cell:focus-within": {
                        outline: "none !important",
                    },
                    "& .MuiDataGrid-iconButtonContainer": {
                        visibility: "visible",
                        width: "0 !important",
                    },
                }}
            >
                <DataGrid
                    rows={stok?.content ?? []}
                    // rows={stok?.content ?? []}
                    components={{
                        ColumnUnsortedIcon: () => {
                            return <ImportExportIcon />;
                        },
                        NoRowsOverlay: NoRowsImage,
                        LoadingOverlay: LoadingTabel,
                    }}
                    columns={columns}
                    // checkboxSelection
                    paginationMode="server"
                    // rowsPerPageOptions={[10, 15, 20]}
                    rowsPerPageOptions={[10]}
                    onPageSizeChange={handleOnSizeChange}
                    onPageChange={handleOnPagination}
                    rowCount={stok?.totalElements ?? 0}
                    page={paramsStok.page - 1}
                    pageSize={paramsStok.size}
                    disableColumnMenu
                    disableSelectionOnClick
                    density="compact"
                    loading={isLoadingStok}
                    // onSelectionModelChange={handleSelectedProducts}
                    // isRowSelectable={(params) => params.row.stok !== null}
                />
            </Box>
            <Box mt={2}>
                <Typography fontWeight="bold">Barang Terpilih:</Typography>
                <Typography>
                    {selectedProducts.map((li) => li.nama).join(", ")}
                </Typography>
            </Box>
        </Box>
    );
};

export default PilihItemMutasiStep;
