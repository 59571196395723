import React, { useEffect, useMemo, useState } from "react";
import id from "date-fns/locale/id";
import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import moment from "moment";
import {
    FakturPembelianValues,
    IOptions,
    ParamsGetSupplier,
    SelectedProductFaktur,
} from "../../../constants/types";
import * as yup from "yup";
import { useForm, FormProvider, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTransaksi } from "../../../context/transaksiContext";
import useSupplier from "../../../services/queries/useSupplier";
import { addDays } from "../../../utils/addDate";
import axios from "../../../services/axios";
import Swal from "sweetalert2";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import ArrowDropDown from "@mui/icons-material/ArrowDropDown";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import BarangTerpilih from "../BarangTerpilih/BarangTerpilih";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import { useAuth } from "../../../context/authContext";

registerLocale("id", id);
setDefaultLocale("id");

interface IDetailBarangStepProps {
    products: SelectedProductFaktur[];
    onBack: () => void;
    isOpenModalFakturPembelian: boolean;
    closeModalFakturPembelian: () => void;
}

const schema = yup.object().shape({
    isKodeFaktur: yup.boolean(),
    kodeFaktur: yup
        .string()
        .nullable()
        .when("isKodeFaktur", {
            is: true,
            then: yup.string().required("Kolom wajib diisi"),
        }),
    supplier: yup.string().required("Kolom wajib diisi"),
    detail: yup.array().of(
        yup.object().shape({
            stokId: yup.number().nullable(),
            hargaBeli: yup.string().required("Kolom wajib diisi"),
            jumlah: yup.string().required("Kolom wajib diisi"),
            keterangan: yup.string().nullable(),
        }),
    ),
});

const DetailBarangStep = ({
    products,
    onBack,
    isOpenModalFakturPembelian,
    closeModalFakturPembelian,
}: IDetailBarangStepProps) => {
    const { ukmIdUser } = useAuth();
    const today = new Date();
    const [isButtonLoading, setIsButtonLoading] = useState(false);
    const { setFetchingItems } = useTransaksi();
    const initialParamsSupplier = {
        size: 300,
        page: 1,
        search: "",
    };
    const [paramsSupplier, setParamsSupplier] = useState<ParamsGetSupplier>(
        initialParamsSupplier,
    );
    const { data: supplier, isLoading: isLoadingSupplier } =
        useSupplier(paramsSupplier);
    const mappedSupplier = supplier?.content.map((li) => ({
        id: li.id,
        nama: li.nama,
    }));

    const initialValues: FakturPembelianValues = useMemo(
        () => ({
            ukmId: undefined,
            isKodeFaktur: false,
            supplier: "",
            kodeFaktur: "",
            tanggal: new Date(),
            detail: products.map((li) => ({
                isTanggalExp: false,
                tanggalExp: addDays(7),
                stokId: li.id,
                hargaBeli: "",
                jumlah: "",
                keterangan: "",
            })) ?? [
                {
                    isTanggalExp: false,
                    tanggalExp: null,
                    stokId: 1,
                    hargaBeli: "",
                    jumlah: 1,
                    keterangan: "",
                },
            ],
        }),
        [products],
    );

    const methods = useForm<FakturPembelianValues>({
        resolver: yupResolver(schema),
        defaultValues: initialValues,
    });

    const isKodeFaktur = methods.watch("isKodeFaktur");
    // const tanggal = methods.watch("tanggal");

    const onSubmit = async (values: FakturPembelianValues) => {
        console.log(values);
        // alert(JSON.stringify(values));
        setIsButtonLoading(true);
        try {
            const body = {
                ukmId: ukmIdUser && ukmIdUser[0],
                suplier: values.supplier,
                tanggal: values.tanggal
                    ? moment(values.tanggal).format("DD/MM/YYYY")
                    : null,
                kodeFaktur: values.isKodeFaktur ? values.kodeFaktur : null,
                detail: values.detail.map((li) => ({
                    tanggalExp: li.isTanggalExp
                        ? moment(li.tanggalExp).format("DD/MM/YYYY")
                        : undefined,
                    stokId: li.stokId,
                    hargaBeli: String(li.hargaBeli).replace(/\./g, ""),
                    jumlah: Number(li.jumlah),
                    keterangan: li.keterangan,
                })),
            };
            console.log(body);
            const { data } = await axios.post(`/api/faktur`, body);
            if (data.code === 200) {
                Swal.fire({
                    title: "Faktur pembelian dibuat",
                    position: "top-end",
                    showConfirmButton: false,
                    icon: "success",
                    toast: true,
                    timer: 3000,
                    timerProgressBar: true,
                    showCloseButton: true,
                    customClass: {
                        container: "my-swal",
                    },
                });
                closeModalFakturPembelian();
            }
            setFetchingItems();
            setIsButtonLoading(false);
            console.log(data);
        } catch (error) {
            setIsButtonLoading(false);
            console.error(error);
        }
    };

    useEffect(() => {
        if (isOpenModalFakturPembelian) {
            methods.reset(initialValues);
        }
    }, [initialValues, isOpenModalFakturPembelian, methods]);

    return (
        <React.Fragment>
            <Grid container alignItems="center" spacing={1} marginBottom={1}>
                <Grid item xs={12} sm={6} md={5}>
                    <Typography fontWeight="bold" component="div">
                        Tanggal Beli:
                    </Typography>
                    <Controller
                        name="tanggal"
                        control={methods.control}
                        render={({ field }) => (
                            <DatePicker
                                className="range-filter"
                                dateFormat="dd/MM/yyyy"
                                disabledKeyboardNavigation
                                onFocus={(e) => e.target.blur()}
                                startDate={field.value}
                                onChange={(update) => {
                                    field.onChange(update);
                                }}
                                minDate={today}
                                monthsShown={1}
                                customInput={
                                    <Stack
                                        display="flex"
                                        justifyContent="space-between"
                                        direction="row"
                                        width="100%"
                                        bgcolor="#FFFF"
                                        height="40px"
                                        sx={{
                                            border: "1px solid #cbcbcb",
                                            borderRadius: "4px",
                                            alignItems: "center",
                                            padding: 2,
                                            cursor: "pointer",
                                        }}
                                    >
                                        <Typography>
                                            {field.value?.toLocaleString("id", {
                                                year: "numeric",
                                                month: "long",
                                                day: "numeric",
                                            })}
                                        </Typography>
                                        <ArrowDropDown
                                            sx={{
                                                marginRight: "-9px",
                                            }}
                                        />
                                    </Stack>
                                }
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={5}>
                    <Typography fontWeight="bold" component="div">
                        Supplier:
                    </Typography>
                    <Controller
                        name="supplier"
                        control={methods.control}
                        render={({ field, fieldState }) => (
                            <TextField
                                fullWidth
                                variant="outlined"
                                placeholder="Contoh: PT. Sedia Barang"
                                size="small"
                                error={!!fieldState?.error}
                                helperText={
                                    fieldState?.error &&
                                    fieldState?.error.message
                                }
                                {...field}
                            />
                        )}
                    />
                </Grid>
            </Grid>
            <Grid container alignItems="center">
                <Grid item xs={6} sm={3}>
                    <Typography>Beri kode faktur manual?</Typography>
                </Grid>
                <Grid item xs={6} sm={3}>
                    <Controller
                        name="isKodeFaktur"
                        control={methods.control}
                        render={({ field }) => (
                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={field.value}
                                            onChange={field.onChange}
                                            sx={{
                                                "& .MuiSwitch-thumb": {
                                                    color: "#eaeaea",
                                                },
                                            }}
                                        />
                                    }
                                    label={field.value ? "Ya" : "Tidak"}
                                    componentsProps={{
                                        typography: {
                                            variant: "body2",
                                        },
                                    }}
                                />
                            </FormGroup>
                        )}
                    />
                </Grid>
                {isKodeFaktur && (
                    <Grid item xs={12}>
                        <Controller
                            name="kodeFaktur"
                            control={methods.control}
                            render={({ field, fieldState }) => (
                                <TextField
                                    variant="outlined"
                                    placeholder="Kode Faktur"
                                    size="small"
                                    id="kodeFaktur"
                                    error={!!fieldState?.error}
                                    helperText={
                                        fieldState?.error &&
                                        fieldState?.error.message
                                    }
                                    {...field}
                                />
                            )}
                        />
                    </Grid>
                )}
            </Grid>
            <Stack mt={2} mb={5} direction="column" spacing={2}>
                {products &&
                    products?.map((li, idx) => (
                        <FormProvider key={idx.toString()} {...methods}>
                            <BarangTerpilih
                                key={String(li.id)}
                                id={li.id}
                                nama={li.nama}
                                sku={li.sku}
                                idx={idx}
                                hargaJual={li.harga}
                                hargaBeli={li.hargaBeli}
                            />
                        </FormProvider>
                    ))}
            </Stack>
            <Box
                mt={4}
                display="grid"
                gridTemplateColumns="1fr 1fr 1fr"
                justifyContent="flex-end"
                columnGap="16px"
            >
                <div />
                <Button onClick={onBack} variant="outlined">
                    Kembali
                </Button>
                <LoadingButton
                    loading={isButtonLoading}
                    loadingPosition="center"
                    variant="contained"
                    onClick={methods.handleSubmit(onSubmit)}
                >
                    Simpan
                </LoadingButton>
            </Box>
        </React.Fragment>
    );
};

export default DetailBarangStep;
