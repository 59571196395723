import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Divider from "@mui/material/Divider";
import ArrowBack from "@mui/icons-material/ArrowBack";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import DialogTitle from "@mui/material/DialogTitle";
import LoadingButton from "@mui/lab/LoadingButton";
import DialogContent from "@mui/material/DialogContent";
import { BlobProvider } from "@react-pdf/renderer";
import React, { useState } from "react";
import { GetDetailInvoiceVoidResponse, TipeStruk } from "../../constants/types";
import useUkm from "../../services/queries/useUkm";
import useUser from "../../services/queries/useUser";
import toRupiah from "../../utils/toRupiah";
import { getLocalUkmId } from "../../utils/handleChangeUkmId";
import PrintStrukPdf from "../PrintStruk/PrintStrukPdf";
// import useFindCustomStruk from "../../services/queries/useFindCustomStruk";
import PrintStrukA4 from "../PrintStrukA4/PrintStrukA4";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import ToggleButton from "@mui/material/ToggleButton";
import { Tooltip } from "@mui/material";
import truncate from "../../utils/truncate";

interface IModalDetailInvoiceVoidProps {
    fullScreen: boolean;
    isOpenModalDetailInvoiceVoid: boolean;
    statusDetailInvoiceVoid: "idle" | "error" | "loading" | "success";
    closeModalDetailInvoiceVoid: () => void;
    data: GetDetailInvoiceVoidResponse["data"];
}

const ModalDetailInvoiceVoid = ({
    fullScreen,
    isOpenModalDetailInvoiceVoid,
    statusDetailInvoiceVoid,
    closeModalDetailInvoiceVoid,
    data,
}: IModalDetailInvoiceVoidProps) => {
    const {
        tanggalTransaksi,
        daftarStok,
        diskon,
        pajak,
        totalPembayaran,
        uangYangDibayar,
        kembalian,
        kasir,
        sales,
        metode,
        keterangan,
        metodeArr,
    } = data;
    const { data: user } = useUser();
    const initialParamsUkm = {
        size: 500,
        page: 1,
        search: "",
    };
    const { data: ukm } = useUkm(initialParamsUkm);
    const ukmId = React.useMemo(() => {
        if (user?.userId) {
            return getLocalUkmId(user?.userId);
        }
        return null;
    }, [user?.userId]);

    const ukmData = React.useMemo(() => {
        if (ukmId) {
            return ukm?.content.find((li) => li.id === ukmId);
        }
        return ukm?.content[0];
    }, [ukm?.content, ukmId]);

    const newCart = daftarStok.map((items, index) => ({
        id: index,
        namaBarang: String(items.namaStok),
        qty: Number(items.jumlahStok),
        harga: Number(items.hargaSatuan),
    }));

    // const { data: findCustomStruk } = useFindCustomStruk(Number(ukmId));
    const [tipeStruk, setTipeStruk] = useState<string | null>(
        localStorage.getItem("tipeStrukLaporan") ?? TipeStruk.Mobile,
    );

    const handleTipeStruk = (
        _event: React.MouseEvent<HTMLElement>,
        newTipeStruk: TipeStruk | null,
    ) => {
        if (newTipeStruk !== null) {
            setTipeStruk(newTipeStruk);
            localStorage.setItem("tipeStrukLaporan", newTipeStruk);
        }
    };

    return (
        <Dialog
            maxWidth="sm"
            fullScreen={fullScreen}
            fullWidth={true}
            open={
                isOpenModalDetailInvoiceVoid &&
                statusDetailInvoiceVoid === "success"
            }
            onClose={() => {
                closeModalDetailInvoiceVoid();
            }}
            PaperProps={{
                sx: {
                    borderRadius: 1,
                },
            }}
        >
            <DialogTitle>
                <Stack direction="row" spacing={1} alignItems="center">
                    <IconButton
                        onClick={() => {
                            closeModalDetailInvoiceVoid();
                        }}
                    >
                        <ArrowBack />
                    </IconButton>
                    <Typography variant="h6" fontWeight="bold">
                        Detail Invoice (Void)
                    </Typography>
                </Stack>
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={6} md={4}>
                        <Typography variant="subtitle2" color="#A1A1AA">
                            Tanggal
                        </Typography>
                        <Typography variant="subtitle2">
                            {/* Hide waktu pada tanggal transaksi */}
                            {tanggalTransaksi}
                        </Typography>
                    </Grid>
                    <Grid item xs={6} md={3}>
                        <Typography variant="subtitle2" color="#A1A1AA">
                            Metode
                        </Typography>
                        <Typography variant="subtitle2">
                            {metode ?? "Tunai"}
                        </Typography>
                    </Grid>
                </Grid>
                <Box my={2}>
                    <Divider />
                </Box>
                <Stack spacing={1}>
                    {daftarStok.map((items, index) => (
                        <Stack direction="row" key={index}>
                            <Grid container>
                                <Grid item xs={6}>
                                    <Tooltip title={items.namaStok}>
                                        <Typography
                                            variant="subtitle1"
                                            fontWeight="bold"
                                        >
                                            {truncate(items.namaStok, 20)}
                                        </Typography>
                                    </Tooltip>
                                    <Typography
                                        variant="subtitle2"
                                        color="#A1A1AA"
                                    >
                                        {toRupiah(items.hargaSatuan)}
                                    </Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <Typography
                                        variant="subtitle1"
                                        fontWeight="bold"
                                    >
                                        {items.jumlahStok}x
                                    </Typography>
                                </Grid>
                                <Grid
                                    item
                                    xs={4}
                                    display="flex"
                                    justifyContent="flex-end"
                                >
                                    <Typography
                                        variant="subtitle1"
                                        fontWeight="bold"
                                        textAlign="end"
                                    >
                                        {toRupiah(
                                            items.hargaSatuan *
                                                items.jumlahStok,
                                        )}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Stack>
                    ))}
                </Stack>
                <Box my={2}>
                    <Divider />
                </Box>
                <Stack direction="row" spacing={2} alignItems="center" mb={2}>
                    <Typography>Pilih Tipe Struk</Typography>
                    <ToggleButtonGroup
                        value={tipeStruk}
                        exclusive
                        size="small"
                        onChange={handleTipeStruk}
                    >
                        <ToggleButton
                            value={TipeStruk.Mobile}
                            sx={{
                                display: "flex",
                                paddingX: 2,
                            }}
                        >
                            <b>A8</b>
                        </ToggleButton>
                        <ToggleButton
                            value={TipeStruk.PC}
                            sx={{
                                display: "flex",
                                paddingX: 2,
                            }}
                        >
                            <b>A4</b>
                        </ToggleButton>
                    </ToggleButtonGroup>
                </Stack>
                <Stack
                    width="100%"
                    direction="column"
                    alignItems="center"
                    spacing={1}
                >
                    <Button
                        variant="text"
                        onClick={() => {
                            closeModalDetailInvoiceVoid();
                        }}
                        fullWidth
                        sx={{ display: { xs: "block", md: "none" } }}
                    >
                        Kembali
                    </Button>
                    <Box width="100%">
                        {ukmData &&
                            (tipeStruk === TipeStruk.Mobile ? (
                                <BlobProvider
                                    document={
                                        <PrintStrukPdf
                                            namaToko={ukmData.namaToko}
                                            alamatToko={ukmData.alamatToko}
                                            telpToko={ukmData.noTelp}
                                            gambarToko={
                                                ukmData?.gambarTokoBW === null
                                                    ? undefined
                                                    : ukmData?.gambarTokoBW
                                            }
                                            tanggalTransaksi={tanggalTransaksi}
                                            kasir={kasir}
                                            sales={sales}
                                            total={Number(totalPembayaran)}
                                            cart={newCart}
                                            diskon={Number(diskon)}
                                            pajak={Number(pajak)}
                                            tunai={Number(uangYangDibayar)}
                                            kembalian={Number(kembalian)}
                                            isPro={1}
                                            namaMetode={metode ?? "Tunai"}
                                            html={
                                                ukmData.customStruk ?? undefined
                                            }
                                            keterangan={keterangan}
                                            noMetode={metodeArr.noTujuan}
                                        />
                                    }
                                >
                                    {({ blob, url, loading, error }) => {
                                        return (
                                            <LoadingButton
                                                loading={loading}
                                                loadingPosition="center"
                                                fullWidth
                                                variant="contained"
                                                href={url ?? ""}
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                Print Ulang Struk
                                            </LoadingButton>
                                        );
                                    }}
                                </BlobProvider>
                            ) : (
                                <BlobProvider
                                    document={
                                        <PrintStrukA4
                                            namaToko={ukmData.namaToko}
                                            alamatToko={ukmData.alamatToko}
                                            telpToko={ukmData.noTelp}
                                            gambarToko={
                                                ukmData?.gambarToko === null
                                                    ? undefined
                                                    : ukmData?.gambarToko
                                            }
                                            tanggalTransaksi={tanggalTransaksi}
                                            kasir={kasir}
                                            sales={sales}
                                            total={Number(totalPembayaran)}
                                            cart={newCart}
                                            diskon={Number(diskon)}
                                            pajak={Number(pajak)}
                                            tunai={Number(uangYangDibayar)}
                                            kembalian={Number(kembalian)}
                                            isPro={1}
                                            namaMetode={metode ?? "Tunai"}
                                            html={
                                                ukmData.customStruk ?? undefined
                                            }
                                            keterangan={keterangan}
                                            namaPemegang={
                                                metodeArr.namaPemegang
                                            }
                                            nomorTujuan={metodeArr.noTujuan}
                                            qr={metodeArr.gambar}
                                        />
                                    }
                                >
                                    {({ blob, url, loading, error }) => {
                                        return (
                                            <LoadingButton
                                                loading={loading}
                                                loadingPosition="center"
                                                fullWidth
                                                variant="contained"
                                                href={url ?? ""}
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                Print Ulang Struk
                                            </LoadingButton>
                                        );
                                    }}
                                </BlobProvider>
                            ))}
                    </Box>
                </Stack>
            </DialogContent>
        </Dialog>
    );
};

export default ModalDetailInvoiceVoid;
