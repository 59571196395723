import React, { useCallback, useEffect, useMemo, useState } from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { useTheme } from "@mui/material/styles";
import {
    Autocomplete,
    Box,
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    Stack,
    TextField,
    Tooltip,
    Typography,
    useMediaQuery,
} from "@mui/material";
import { useKategoriModal } from "../../context/kategoriModalContext";
import { useEditKategoriModal } from "../../context/editKategoriModalContext";
import { useHapusKategoriModal } from "../../context/hapusKategoriModalContext";
import {
    ErrorFieldResponse,
    IOptions,
    ParamsGetKategori,
    ParamsGetSubKategori,
} from "../../constants/types";
import { useAuth } from "../../context/authContext";
import useUser from "../../services/queries/useUser";
import useSubKategori from "../../services/queries/useSubKategori";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import UploadIcon from "@mui/icons-material/Upload";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import axios from "../../services/axios";
import Swal from "sweetalert2";
import defaultAxios, { AxiosError } from "axios";
import Spinner from "../Spinner/Spinner";
import { LoadingButton } from "@mui/lab";
import LoadingTabel from "../LoadingTabel/LoadingTabel";
import NoRowsImage from "../NoRowsImage/NoRowsImage";
import useKategori from "../../services/queries/useKategori";
import { useTransaksi } from "../../context/transaksiContext";

const schema = yup
    .object({
        kategori: yup
            .object()
            .shape({
                id: yup.string().required("Kolom wajib diisi"),
                nama: yup.string().required("Kolom wajib diisi"),
            })
            .required("Kolom wajib diisi"),
        nama: yup.string().required("Kolom wajib diisi"),
    })
    .required();

interface IValues {
    ukmId: number;
    kategori: IOptions;
    nama: string;
}

const ModalSubKategori = () => {
    const theme = useTheme();
    const isPhoneScreen = useMediaQuery(theme.breakpoints.between("xs", "sm"));
    const { isOpenModalSubKategori, closeModalSubKategori } =
        useKategoriModal();
    const {
        openModalEditSubKategori,
        openModalUnggahKategori,
        handleIsKategoriFalse,
    } = useEditKategoriModal();
    const { openModalHapusSubKategori } = useHapusKategoriModal();
    const { ukmIdUser } = useAuth();
    const { status: statusUser } = useUser();
    const { isFetchingItems, cancelFetchingItems } = useTransaksi();

    const initialParams = {
        ukmId: ukmIdUser && ukmIdUser[0],
        size: 8,
        page: 1,
        search: "",
    };
    const [paramsKategori, setParamsKategori] =
        useState<ParamsGetKategori>(initialParams);
    const { data: kategori, isLoading: isLoadingKategori } =
        useKategori(paramsKategori);

    const mappedKategori = kategori?.content.map((li) => ({
        id: li.id,
        nama: li.nama,
    }));
    const [paramsSubKategori, setParamsSubKategori] =
        useState<ParamsGetSubKategori>(initialParams);
    const {
        data: subKategori,
        refetch: refetchSubKategori,
        isLoading: isLoadingSubKategori,
    } = useSubKategori(paramsSubKategori);
    const [isSuccessSubmit, setIsSuccessSubmit] = useState(false);
    const [isButtonLoading, setIsButtonLoading] = useState(false);

    useEffect(() => {
        if (isFetchingItems === true) {
            refetchSubKategori();
            cancelFetchingItems();
        }
    }, [cancelFetchingItems, isFetchingItems, refetchSubKategori]);

    useEffect(() => {
        refetchSubKategori();
    }, [isOpenModalSubKategori, refetchSubKategori]);

    const columns: GridColDef[] = [
        {
            field: "nama",
            headerName: "Sub Kategori",
            flex: 1,
            minWidth: 170,
            renderCell: (params) => (
                <Tooltip title={params.value}>
                    <span className="table-cell-trucate">{params.value}</span>
                </Tooltip>
            ),
        },
        {
            field: "kategori",
            headerName: "Kategori",
            flex: 1,
            minWidth: 160,
            renderCell: (params) => {
                return (
                    <Tooltip title={params.row.kategori.nama}>
                        <span className="table-cell-trucate">
                            {params.row.kategori.nama}
                        </span>
                    </Tooltip>
                );
            },
        },
        {
            field: "produkTerhubung",
            headerName: "Produk terhubung",
            flex: 1,
            minWidth: 160,
            renderCell: (params) => {
                return (
                    <Tooltip title={`${params.value} produk terhubung` ?? ""}>
                        <Typography variant="body2">
                            {`${params.value} produk terhubung` ?? "-"}
                        </Typography>
                    </Tooltip>
                );
            },
        },
        {
            field: "aksi",
            headerName: "Aksi",
            align: "center",
            minWidth: 160,
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params) => {
                return (
                    <Stack direction="row" spacing={3}>
                        <Button
                            variant="contained"
                            onClick={() => {
                                openModalEditSubKategori(
                                    params.row.id,
                                    params.row.kategori.id,
                                    params.row.nama,
                                );
                            }}
                            size="small"
                            color="buttonyellow"
                            disableElevation
                            sx={{
                                minWidth: "unset",
                                padding: "8px",
                                width: "32px",
                                height: "32px",
                            }}
                        >
                            <EditIcon sx={{ width: "16px", height: "16px" }} />
                        </Button>
                        <Button
                            variant="contained"
                            onClick={() => {
                                openModalHapusSubKategori(
                                    params.row.id,
                                    params.row.kategori.id,
                                    params.row.nama,
                                );
                            }}
                            size="small"
                            color="buttonred"
                            disableElevation
                            sx={{
                                minWidth: "unset",
                                padding: "8px",
                                width: "32px",
                                height: "32px",
                            }}
                        >
                            <DeleteIcon
                                sx={{ width: "16px", height: "16px" }}
                            />
                        </Button>
                    </Stack>
                );
            },
        },
    ];

    const handleOnPagination = (page: number) => {
        setParamsSubKategori((prev) => ({
            ...prev,
            page: page + 1,
        }));
    };

    const handleOnSizeChange = (size: number) => {
        setParamsSubKategori((prev) => ({ ...prev, size }));
    };

    const initialValues = useMemo(
        () => ({
            ukmId: ukmIdUser && ukmIdUser[0],
            kategori: undefined,
            nama: "",
        }),
        [ukmIdUser],
    );

    const {
        handleSubmit,
        control,
        reset,
        setError,
        formState: { errors },
    } = useForm<IValues>({
        resolver: yupResolver(schema),
        defaultValues: initialValues,
    });

    const onSubmit = async (values: IValues) => {
        setIsButtonLoading(true);
        try {
            const { data } = await axios.post(`/api/subkategori`, {
                ukmId: values.ukmId,
                kategoriId: values.kategori.id,
                nama: values.nama,
            });
            if (data.code === 200) {
                Swal.fire({
                    title: "Sub kategori berhasil ditambahkan",
                    position: "top-end",
                    showConfirmButton: false,
                    icon: "success",
                    toast: true,
                    timer: 3000,
                    timerProgressBar: true,
                    showCloseButton: true,
                    customClass: {
                        container: "my-swal",
                    },
                });
                // closeModalKategori();
                refetchSubKategori();
            }
            setIsButtonLoading(false);
            setIsSuccessSubmit(true);
            reset(initialValues);

            // console.log(data.nama);
        } catch (err) {
            if (defaultAxios.isAxiosError(err)) {
                const serverError = err as AxiosError<
                    ErrorFieldResponse | undefined
                >;
                if (serverError && serverError?.response) {
                    console.log(serverError.response?.data?.errors);
                    const fieldError = serverError?.response?.data;
                    if (fieldError?.errors) {
                        Object.keys(fieldError.errors).forEach((key) => {
                            const errorMessage = fieldError.errors[key];
                            setError(key as any, {
                                type: "manual",
                                message: errorMessage[0],
                            });
                        });
                    }
                }
                setIsButtonLoading(false);
                setIsSuccessSubmit(false);
            }
            handleErrorResponse(err);
            console.log(err);
            setIsSuccessSubmit(false);
            setIsButtonLoading(false);
        }
    };

    const handleErrorResponse = useCallback((error) => {
        if (defaultAxios.isAxiosError(error)) {
            const serverError = error as AxiosError<any>;
            console.log(serverError.response);
            if (serverError && serverError.response) {
                console.log(`serverError`, serverError);
                if (serverError.response.data.data.errors) {
                    Swal.fire({
                        title: "Terjadi Kesalahan!",
                        text: `${serverError.response.data.message}`,
                        icon: "error",
                        confirmButtonColor: "#45A779",
                        customClass: {
                            container: "my-swal",
                        },
                    });
                    console.log("", `${serverError.response.data.data}`, [
                        { text: "OK" },
                    ]);
                }
            } else {
                Swal.fire({
                    title: "Terjadi Kesalahan!",
                    text: `Terjadi kesalahan! Silahkan coba lagi.`,
                    icon: "error",
                    confirmButtonColor: "#45A779",
                    customClass: {
                        container: "my-swal",
                    },
                });
                console.log("", `Terjadi kesalahan! Silahkan coba lagi.`, [
                    { text: "OK" },
                ]);
            }
        }
    }, []);

    useEffect(() => {
        if (ukmIdUser) {
            setParamsSubKategori((prev) => ({
                ...prev,
                ukmId: ukmIdUser && ukmIdUser[0],
            }));
        }
    }, [ukmIdUser]);

    useEffect(() => {
        if (isSuccessSubmit === true) {
            refetchSubKategori();
        }
        setIsSuccessSubmit(false);
        console.log(isSuccessSubmit);
    }, [isSuccessSubmit, refetchSubKategori]);

    useEffect(() => {
        if (isOpenModalSubKategori) {
            reset(initialValues);
        }
    }, [initialValues, isOpenModalSubKategori, reset]);

    const isLoading = statusUser === "loading";

    if (isLoading) {
        return (
            <Stack
                display="grid"
                gridTemplateColumns="1fr"
                alignItems="center"
                height={400}
                position="relative"
            >
                <Spinner />
            </Stack>
        );
    }

    return (
        <Dialog
            // maxWidth="sm"
            maxWidth="md"
            fullScreen={isPhoneScreen}
            fullWidth={true}
            open={isOpenModalSubKategori}
            onClose={closeModalSubKategori}
            scroll="body"
            PaperProps={{
                sx: {
                    borderRadius: 1,
                    position: "relative",
                },
            }}
        >
            <DialogTitle>
                <Stack direction="row" spacing={1} alignItems="center">
                    <IconButton onClick={closeModalSubKategori}>
                        <ArrowBackIcon />
                    </IconButton>
                    <Typography variant="h6" fontWeight="bold">
                        Atur Sub Kategori
                    </Typography>
                </Stack>
            </DialogTitle>
            <DialogContent>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Grid
                        container
                        // alignItems="center"
                        spacing={1}
                        mt={2}
                    >
                        <Grid item xs={6}>
                            <Controller
                                name="kategori"
                                control={control}
                                render={({ field }) => (
                                    <Autocomplete
                                        disablePortal
                                        loading={isLoadingKategori}
                                        disableClearable
                                        options={mappedKategori ?? []}
                                        getOptionLabel={(option) => option.nama}
                                        isOptionEqualToValue={(option, value) =>
                                            String(option.id) ===
                                            String(value.id)
                                        }
                                        onInputChange={(e) => {
                                            if (e) {
                                                const element =
                                                    e.target as HTMLInputElement;
                                                setParamsKategori((prev) => ({
                                                    ...prev,
                                                    search: element.value,
                                                }));
                                            } else {
                                                return;
                                            }
                                        }}
                                        size="small"
                                        value={field.value}
                                        onChange={(
                                            _event,
                                            kategori: IOptions,
                                        ) => {
                                            field.onChange(kategori);
                                        }}
                                        fullWidth
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Kategori"
                                                error={Boolean(errors.kategori)}
                                                helperText={
                                                    errors.kategori &&
                                                    "Pilih salah satu"
                                                }
                                            />
                                        )}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Controller
                                name="nama"
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        // margin="dense"
                                        autoFocus
                                        id="nama"
                                        placeholder="Sub kategori baru..."
                                        size="small"
                                        fullWidth
                                        variant="outlined"
                                        error={Boolean(errors.nama)}
                                        helperText={
                                            errors.nama
                                                ? errors.nama.message
                                                : ""
                                        }
                                        {...field}
                                    />
                                )}
                            />
                        </Grid>
                    </Grid>
                    <Stack direction="row" justifyContent="flex-end" mt={1}>
                        <LoadingButton
                            loading={isButtonLoading}
                            loadingPosition={isPhoneScreen ? "center" : "start"}
                            // loadingPosition="center"
                            variant="contained"
                            startIcon={isPhoneScreen ? undefined : <AddIcon />}
                            type="submit"
                            // sx={{
                            //     height: isPhoneScreen ? undefined : "40px",
                            // }}
                        >
                            {isPhoneScreen ? <AddIcon /> : "Simpan"}
                        </LoadingButton>
                    </Stack>
                </form>
                <Stack marginTop={3} spacing={1}>
                    <Stack direction="row" justifyContent="flex-end">
                        <Button
                            variant="outlined"
                            startIcon={<UploadIcon />}
                            disableElevation
                            onClick={() => {
                                openModalUnggahKategori();
                                closeModalSubKategori();
                                handleIsKategoriFalse();
                            }}
                        >
                            Unggah Sub Kategori
                        </Button>
                    </Stack>
                    <Box
                        sx={{
                            // height: 550,
                            width: "100%",
                            backgroundColor: "#ffffff",
                            borderRadius: 1,
                            "& .headerColumn": {
                                backgroundColor: "#E4EEE8",
                            },
                            "& .MuiDataGrid-columnHeaderTitle": {
                                fontWeight: "bold",
                            },
                            "& .MuiDataGrid-columnSeparator": {
                                visibility: "hidden",
                            },
                            "& .MuiDataGrid-cell:focus-within": {
                                outline: "none !important",
                            },
                            "& .MuiDataGrid-iconButtonContainer": {
                                visibility: "visible",
                                width: "0 !important",
                            },
                        }}
                    >
                        <DataGrid
                            autoHeight
                            paginationMode="server"
                            components={{
                                ColumnUnsortedIcon: () => {
                                    return <ImportExportIcon />;
                                },
                                NoRowsOverlay: NoRowsImage,
                                LoadingOverlay: LoadingTabel,
                                // Pagination: CustomPagination,
                            }}
                            rows={subKategori?.content ?? []}
                            columns={columns}
                            disableColumnMenu
                            disableSelectionOnClick
                            // rowsPerPageOptions={[10, 15, 20]}
                            rowsPerPageOptions={[10]}
                            onPageSizeChange={handleOnSizeChange}
                            onPageChange={handleOnPagination}
                            rowCount={subKategori?.totalElements ?? 0}
                            page={paramsSubKategori.page - 1}
                            pageSize={paramsSubKategori.size}
                            density="standard"
                            loading={isLoadingSubKategori}
                        />
                    </Box>
                </Stack>
            </DialogContent>
        </Dialog>
    );
};

export default ModalSubKategori;
