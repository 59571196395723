import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import ArrowBack from "@mui/icons-material/ArrowBack";
import Typography from "@mui/material/Typography";
import DialogContent from "@mui/material/DialogContent";
import DetailOpnameStep from "./DetailOpnameStep/DetailOpnameStep";
import { ContentGetStokOpnameResponse } from "../../constants/types";

interface IModalProsesOpnameProps {
    open: boolean;
    onClose: () => void;
    tanggal: string;
    data: ContentGetStokOpnameResponse[];
}

const ModalProsesOpname = ({
    open,
    onClose,
    tanggal,
    data,
}: IModalProsesOpnameProps) => {
    const theme = useTheme();
    const isPhoneScreen = useMediaQuery(theme.breakpoints.between("xs", "sm"));

    return (
        <Dialog
            maxWidth="md"
            fullScreen={isPhoneScreen}
            fullWidth={true}
            open={open}
            onClose={onClose}
            scroll="body"
            PaperProps={{
                sx: {
                    borderRadius: 1,
                },
            }}
        >
            <DialogTitle>
                <Stack direction="row" spacing={1} alignItems="center">
                    <IconButton onClick={onClose}>
                        <ArrowBack />
                    </IconButton>
                    <Typography variant="h6" fontWeight="bold">
                        Proses Opname
                    </Typography>
                </Stack>
            </DialogTitle>
            <DialogContent dividers>
                <DetailOpnameStep
                    opname={data}
                    open={open}
                    onClose={onClose}
                    tanggal={tanggal}
                />
            </DialogContent>
        </Dialog>
    );
};

export default ModalProsesOpname;
