import React, { useEffect, useState, useMemo } from "react";
import {
    Box,
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    Stack,
    FormHelperText,
    Grid,
    InputAdornment,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography,
    Switch,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import {
    SelectedProductBundling,
    ProductsBundling,
    BundlingValues,
    AddBundlingResponse,
    ErrorFieldResponse,
    ModalBundlingField,
    ParamsGetSubKategori,
    ParamsGetKategori,
} from "../../../constants/types";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import NumberFormat from "react-number-format";
import { useStokModal } from "../../../context/stokModalContext";
import useKategori from "../../../services/queries/useKategori";
import Spinner from "../../Spinner/Spinner";
import useUser from "../../../services/queries/useUser";
import toRupiah from "../../../utils/toRupiah";
import "./DetailItemStep.css";
import axios from "../../../services/axios";
import defaultAxios, { AxiosError } from "axios";
import Swal from "sweetalert2";
import { useTransaksi } from "../../../context/transaksiContext";
import { LoadingButton } from "@mui/lab";
import { useAuth } from "../../../context/authContext";
import useUkm from "../../../services/queries/useUkm";
import useSubKategori from "../../../services/queries/useSubKategori";
import useIncrementSKU from "../../../services/queries/useIncrementSKU";

interface IDetailItemStepProps {
    products: SelectedProductBundling[];
    onBack: () => void;
    dataStokBundle: ModalBundlingField;
}

const FormSchema = Yup.object({
    item: Yup.array().of(
        Yup.object().shape({
            itemId: Yup.number(),
            jumlahItem: Yup.string()
                .required("Kolom wajib diisi")
                .test({
                    name: "min-1",
                    test: (value) => Number(value) > 0,
                    message: "Jumlah barang tidak boleh kurang dari 1",
                }),
        }),
    ),
    isActiveStok: Yup.boolean(),
    namaBundling: Yup.string()
        .required("Kolom wajib diisi")
        .max(50, "Nama bundling terlalu panjang"),
    deskripsiBundling: Yup.string()
        .nullable()
        .max(500, "Deskripsi terlalu panjang"),
    sku: Yup.string()
        .required("Kolom wajib diisi")
        .max(50, "SKU terlalu panjang"),
    hargaBundling: Yup.string().required("Kolom wajib diisi"),
    jumlahStok: Yup.string()
        .nullable()
        .when("isActiveStok", {
            is: true,
            then: Yup.string().required("Kolom wajib diisi"),
        }),
    kategoriId: Yup.string().required("Kolom wajib diisi"),
    isSubBaru: Yup.boolean(),
    subId: Yup.string().when("isSubBaru", {
        is: false,
        then: Yup.string().required("Kolom wajib diisi"),
    }),
    subBaru: Yup.string().when("isSubBaru", {
        is: true,
        then: Yup.string()
            .required("Kolom wajib diisi")
            .max(50, "Kategori baru terlalu panjang"),
    }),
    gambar: Yup.mixed(),
    // .test(
    //     "fileType",
    //     "Gambar harus berformat jpg/jpeg/png",
    //     (value) =>
    //         ["image/jpg", "image/jpeg", "image/png"].includes(value.type),
    // ),
    favorite: Yup.boolean().required("Kolom wajib diisi"),
});

const DetailItemStep = ({
    products,
    onBack,
    dataStokBundle,
}: IDetailItemStepProps) => {
    const { ukmIdUser } = useAuth();
    const { setFetchingItems } = useTransaksi();
    const { dataStok, closeModal } = useStokModal();
    const initialParamsUkm = {
        size: 500,
        page: 1,
        search: "",
    };
    const { data: ukm } = useUkm(initialParamsUkm);
    const { data: user, status: statusUser } = useUser();
    const initialParamsIncrementSKU = {
        ukmId: ukmIdUser && ukmIdUser[0],
    };
    const { data: incrementSKU } = useIncrementSKU(initialParamsIncrementSKU);

    const initialValues = useMemo(
        () => ({
            id: undefined,
            item: products.map((li) => ({
                itemId: li.id,
                jumlahItem: 1,
            })) ?? [
                {
                    itemId: 1,
                    jumlahItem: 1,
                },
            ],
            namaBundling: "",
            deskripsiBundling: "",
            sku: "",
            hargaBundling: "",
            isActiveStok: true,
            jumlahStok: 1,
            isSubBaru: false,
            subBaru: "",
            subId: "",
            kategoriId: "",
            gambar: null,
            favorite: false,
        }),
        [products],
    );

    const initialParams = {
        ukmId: undefined,
        search: "",
        size: 500,
        page: 1,
        kategoriId: undefined,
    };
    const [paramsKategori, setParamsKategori] =
        useState<ParamsGetKategori>(initialParams);
    const { data: kategori, status: statusKategori } =
        useKategori(paramsKategori);
    const [paramsSubKategori, setParamsSubKategori] =
        useState<ParamsGetSubKategori>(initialParams);
    const { data: subKategori } = useSubKategori(paramsSubKategori);

    const {
        handleSubmit,
        control,
        formState: { errors },
        register,
        reset,
        setValue,
        setError,
        clearErrors,
        watch,
    } = useForm<BundlingValues>({
        resolver: yupResolver(FormSchema),
        defaultValues: initialValues,
    });

    useEffect(() => {
        if (user) {
            setParamsKategori((prev) => ({
                ...prev,
                ukmId: ukmIdUser && ukmIdUser[0],
            }));
            setParamsSubKategori((prev) => ({
                ...prev,
                ukmId: ukmIdUser && ukmIdUser[0],
            }));
        }
    }, [ukmIdUser, user]);

    const generateSKU = () =>
        !!!watch("namaBundling") || watch("namaBundling") === ""
            ? ""
            : watch("namaBundling")
                  .toUpperCase()
                  .replace(" ", "")
                  .substring(0, 4) +
              "-" +
              // String(Math.floor(Math.random() * 10000) + 1);
              incrementSKU?.urutanke;

    const isSubBaru = watch("isSubBaru");
    // const isActiveStok = watch("isActiveStok");
    const itemField = watch("item");

    const watchAll = watch();

    useEffect(() => console.log("watchAll", watchAll), [watchAll]);

    const [isButtonLoading, setIsButtonLoading] = useState(false);

    const onSubmit = async (values: BundlingValues) => {
        console.log(values);
        const formattedHarga = String(values.hargaBundling).replace(/\./g, "");
        setIsButtonLoading(true);
        if (dataStok) {
            try {
                const formData = new FormData();
                formData.append("ukmId", String(ukmIdUser && ukmIdUser[0]));
                formData.append("namaBarang", values.namaBundling);
                formData.append("deskripsiBarang", values.deskripsiBundling);
                formData.append(
                    "subId",
                    values.isSubBaru === true ? "" : String(values.subId),
                );
                formData.append(
                    "subBaru",
                    values.isSubBaru === true ? String(values.subBaru) : "",
                );
                formData.append(
                    "kategoriId",
                    values.isSubBaru === true ? String(values.kategoriId) : "",
                );
                formData.append("sku", values.sku);
                if (values.gambar) {
                    formData.append(
                        "gambar",
                        values.gambar ? (values.gambar as any) : "",
                    );
                }

                for (var i = 0; i < values.item.length; i += 1) {
                    formData.append(
                        `stokSatuan[${i}][stokId]`,
                        String(values.item[i].itemId),
                    );
                    formData.append(
                        `stokSatuan[${i}][jumlah]`,
                        String(values.item[i].jumlahItem),
                    );
                }
                formData.append("harga", formattedHarga);
                formData.append(
                    "isActiveStok",
                    values.isActiveStok === true ? "1" : "0",
                );
                formData.append(
                    "favorite",
                    values.favorite === true ? "1" : "0",
                );
                formData.append(
                    "jumlahStok",
                    values.isActiveStok === true
                        ? String(values.jumlahStok)
                        : values.isActiveStok === false
                        ? ""
                        : "",
                );
                formData.append("stokId", String(values.id));
                const { data } = await axios.post<AddBundlingResponse>(
                    `api/stokbundling-update`,
                    // { ...rest, ukmId: user?.ukmId[0] },
                    formData,
                    {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                    },
                );

                if (data.code === 200) {
                    Swal.fire({
                        title: `${data.message}`,
                        position: "top-end",
                        showConfirmButton: false,
                        icon: "success",
                        toast: true,
                        timer: 3000,
                        timerProgressBar: true,
                        showCloseButton: true,
                        customClass: {
                            container: "my-swal",
                        },
                    });
                    setFetchingItems();
                    closeModal();
                    setIsButtonLoading(false);
                }
            } catch (error) {
                if (defaultAxios.isAxiosError(error)) {
                    const serverError = error as AxiosError<
                        ErrorFieldResponse | undefined
                    >;
                    if (serverError && serverError?.response) {
                        const fieldError = serverError?.response?.data;
                        if (fieldError?.errors) {
                            Object.keys(fieldError.errors).forEach((key) => {
                                const errorMessage = fieldError.errors[key];
                                setError(key as keyof typeof initialValues, {
                                    type: "manual",
                                    message: errorMessage[0],
                                });
                            });
                        } else {
                            Swal.fire({
                                title: `${
                                    fieldError?.message ?? "Terjadi Kesalahan!"
                                }`,
                                position: "top-end",
                                showConfirmButton: false,
                                icon: "error",
                                toast: true,
                                timer: 3000,
                                timerProgressBar: true,
                                showCloseButton: true,
                                customClass: {
                                    container: "my-swal",
                                },
                            });
                        }
                    }
                }
                setIsButtonLoading(false);
            }
        } else {
            try {
                const formData = new FormData();
                formData.append("ukmId", String(ukmIdUser && ukmIdUser[0]));
                formData.append("namaBarang", values.namaBundling);
                formData.append("deskripsiBarang", values.deskripsiBundling);
                formData.append(
                    "subId",
                    values.isSubBaru === true ? "" : String(values.subId),
                );
                formData.append(
                    "subBaru",
                    values.isSubBaru === true ? String(values.subBaru) : "",
                );
                formData.append(
                    "kategoriId",
                    values.isSubBaru === true ? String(values.kategoriId) : "",
                );
                formData.append("sku", values.sku);
                if (values.gambar) {
                    formData.append(
                        "gambar",
                        values.gambar ? (values.gambar as any) : "",
                    );
                }

                for (let i = 0; i < values.item.length; i += 1) {
                    formData.append(
                        `stokSatuan[${i}][stokId]`,
                        String(values.item[i].itemId),
                    );
                    formData.append(
                        `stokSatuan[${i}][jumlah]`,
                        String(values.item[i].jumlahItem),
                    );
                }
                formData.append("harga", formattedHarga);
                formData.append(
                    "isActiveStok",
                    values.isActiveStok === true ? "1" : "0",
                );
                formData.append(
                    "favorite",
                    values.favorite === true ? "1" : "0",
                );
                formData.append(
                    "jumlahStok",
                    values.isActiveStok === true
                        ? String(values.jumlahStok)
                        : values.isActiveStok === false
                        ? ""
                        : "",
                );
                const { data } = await axios.post<AddBundlingResponse>(
                    "api/stokbundling",
                    formData,
                    {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                    },
                );

                if (data.code === 200) {
                    Swal.fire({
                        title: `${data.message}`,
                        position: "top-end",
                        showConfirmButton: false,
                        icon: "success",
                        toast: true,
                        timer: 3000,
                        timerProgressBar: true,
                        showCloseButton: true,
                        customClass: {
                            container: "my-swal",
                        },
                    });
                    setFetchingItems();
                    closeModal();
                    setIsButtonLoading(false);
                }
            } catch (error) {
                if (defaultAxios.isAxiosError(error)) {
                    const serverError = error as AxiosError<
                        ErrorFieldResponse | undefined
                    >;
                    if (serverError && serverError?.response) {
                        const fieldError = serverError?.response?.data;
                        if (fieldError?.errors) {
                            Object.keys(fieldError.errors).forEach((key) => {
                                const errorMessage = fieldError.errors[key];
                                setError(key as keyof typeof initialValues, {
                                    type: "manual",
                                    message: errorMessage[0],
                                });
                            });
                        } else {
                            Swal.fire({
                                title: `${
                                    fieldError?.message ?? "Terjadi Kesalahan!"
                                }`,
                                position: "top-end",
                                showConfirmButton: false,
                                icon: "error",
                                toast: true,
                                timer: 3000,
                                timerProgressBar: true,
                                showCloseButton: true,
                                customClass: {
                                    container: "my-swal",
                                },
                            });
                        }
                    }
                }
                setIsButtonLoading(false);
            }
        }
    };

    const countHargaTotal = (
        products: SelectedProductBundling[],
        item: ProductsBundling[],
    ) => {
        let total = 0;
        if (item.length === products.length) {
            products.forEach((li, idx) => {
                const harga = li.harga;
                const jumlah = item[idx].jumlahItem;
                const subtotal = Number(harga) * Number(jumlah);
                total += subtotal;
            });
        }
        return total;
    };

    useEffect(() => {
        if (products) {
            const total = countHargaTotal(products, itemField);
            if (total > 0) {
                setValue("hargaBundling", String(total));
            }
        }
    }, [itemField, products, setValue]);

    useEffect(() => {
        const subscription = watch((_, { name, type }) => {
            if (name?.match(/item/i) && type === "change") {
                if (products) {
                    const total = countHargaTotal(products, itemField);
                    if (total > 0) {
                        setValue("hargaBundling", String(total));
                    }
                }
            }
        });
        return () => subscription.unsubscribe();
    }, [watch, itemField, products, setValue]);

    useEffect(() => {
        if (dataStokBundle) {
            reset(dataStokBundle);
            console.log("Data Bundle", dataStokBundle);
        } else {
            reset(initialValues);
            console.log("Data Initial", initialValues);
        }
    }, [reset, initialValues, dataStokBundle]);

    useEffect(() => {
        if (dataStokBundle.kategoriId) {
            reset({ kategoriId: dataStokBundle.kategoriId });
        }
    }, [dataStokBundle.kategoriId, reset]);

    const isLoading = statusKategori === "loading" || statusUser === "loading";

    if (isLoading)
        return (
            <Stack
                display="grid"
                gridTemplateColumns="1fr"
                alignItems="center"
                height={400}
                position="relative"
            >
                <Spinner />
            </Stack>
        );

    return (
        <Box className="detail-bundling">
            <form>
                <Stack mb={5} direction="column" spacing={2}>
                    {products &&
                        products?.map((li, idx) => (
                            <Box
                                className="detail-bundling__field-array"
                                key={String(li.id)}
                            >
                                <Box>
                                    <Typography fontWeight="bold">
                                        {li.nama}
                                    </Typography>
                                    <Typography fontSize="16px">
                                        {toRupiah(li.harga ?? 0)}
                                        {li.stok !== null
                                            ? `, Tersedia(${li.stok})`
                                            : ""}{" "}
                                    </Typography>
                                </Box>
                                <Controller
                                    name={`item.${idx}.jumlahItem`}
                                    control={control}
                                    render={({ field, fieldState }) => (
                                        <TextField
                                            type="number"
                                            id={`item.${idx}.jumlahItem`}
                                            label="Jumlah Barang"
                                            fullWidth
                                            variant="outlined"
                                            error={!!fieldState?.error}
                                            helperText={
                                                fieldState?.error &&
                                                fieldState?.error.message
                                            }
                                            {...field}
                                        />
                                    )}
                                    // rules={{
                                    //     max: {
                                    //         value: li.stok,
                                    //         message: "Jumlah melebihi stok",
                                    //     },
                                    // }}
                                />
                                <input
                                    type="hidden"
                                    {...register(`item.${idx}.itemId`)}
                                    value={li.id}
                                />
                            </Box>
                        ))}
                </Stack>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12}>
                        <Controller
                            name="namaBundling"
                            control={control}
                            render={({ field }) => (
                                <TextField
                                    // margin="dense"
                                    id="namaBundling"
                                    label="Nama Paket"
                                    fullWidth
                                    variant="outlined"
                                    error={Boolean(errors.namaBundling)}
                                    helperText={
                                        errors.namaBundling
                                            ? errors.namaBundling.message
                                            : ""
                                    }
                                    {...field}
                                    onBlur={() => {
                                        if (dataStok) {
                                            field.onBlur();
                                        } else {
                                            setValue("sku", generateSKU());
                                            clearErrors("sku");
                                            field.onBlur();
                                        }
                                    }}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Controller
                            name="hargaBundling"
                            control={control}
                            render={({ field }) => (
                                <NumberFormat
                                    customInput={TextField}
                                    label="Harga"
                                    // margin="dense"
                                    thousandSeparator="."
                                    decimalSeparator=","
                                    fullWidth
                                    variant="outlined"
                                    allowNegative={false}
                                    // onValueChange={(values) => {
                                    //     setValue(
                                    //         "hargaBundling",
                                    //         String(values),
                                    //     );
                                    // }}
                                    error={Boolean(errors.hargaBundling)}
                                    helperText={
                                        errors.hargaBundling
                                            ? errors.hargaBundling.message
                                            : ""
                                    }
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <Typography>Rp.</Typography>
                                            </InputAdornment>
                                        ),
                                    }}
                                    {...field}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Controller
                            name="sku"
                            control={control}
                            render={({ field }) => (
                                <TextField
                                    // margin="dense"
                                    id="sku"
                                    label="SKU"
                                    fullWidth
                                    variant="outlined"
                                    error={Boolean(errors.sku)}
                                    helperText={
                                        errors.sku ? errors.sku.message : ""
                                    }
                                    {...field}
                                />
                            )}
                            defaultValue={generateSKU()}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <Controller
                            name="deskripsiBundling"
                            control={control}
                            render={({ field }) => (
                                <TextField
                                    // margin="dense"
                                    id="deskripsiBundling"
                                    label="Deskripsi Paket (opsional)"
                                    fullWidth
                                    variant="outlined"
                                    multiline
                                    rows={4}
                                    error={Boolean(errors.deskripsiBundling)}
                                    helperText={
                                        errors.deskripsiBundling
                                            ? errors.deskripsiBundling.message
                                            : ""
                                    }
                                    {...field}
                                />
                            )}
                        />
                    </Grid>
                </Grid>
                <Box marginTop={2} marginBottom={1}>
                    <Typography variant="body1">Gambar</Typography>
                    <Typography variant="body2" color="#545E6A">
                        Silahkan upload gambar paket (opsional).
                    </Typography>
                </Box>
                <Stack>
                    <Controller
                        name="gambar"
                        control={control}
                        render={({ field }) => (
                            <TextField
                                // margin="dense"
                                id="gambar"
                                fullWidth
                                type="file"
                                variant="outlined"
                                inputProps={{
                                    accept: "image/*",
                                }}
                                error={Boolean(errors.gambar)}
                                helperText={
                                    errors.gambar ? errors.gambar.message : ""
                                }
                                {...field}
                                onChange={(event: any) => {
                                    if (event.target.files[0].size < 2097152) {
                                        console.log(event.target.files[0]);
                                        setValue(
                                            "gambar",
                                            event.target.files[0],
                                        );
                                        clearErrors("gambar");
                                    } else {
                                        event.target.value = "";
                                        setError("gambar", {
                                            type: "manual",
                                            message:
                                                "Ukuran gambar harus kurang dari 2MB",
                                        });
                                    }
                                }}
                                value={undefined}
                            />
                        )}
                    />
                    <Typography variant="caption" color="#6D6D6D">
                        *Resolusi optimal: 300 x 300
                    </Typography>
                    <Typography variant="caption" color="#6D6D6D">
                        *Ukuran maksimal: 2MB
                    </Typography>
                </Stack>
                <Grid container alignItems="center" marginTop={2}>
                    <Grid item xs={5} md={5}>
                        <Typography variant="body1">Kategori</Typography>
                    </Grid>
                    <Grid item>
                        <Controller
                            name="isSubBaru"
                            control={control}
                            render={({ field }) => (
                                <FormGroup>
                                    <FormControlLabel
                                        control={<Checkbox {...field} />}
                                        label="Tambah sub kategori baru"
                                        componentsProps={{
                                            typography: {
                                                variant: "body2",
                                            },
                                        }}
                                    />
                                </FormGroup>
                            )}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="body2" color="#545E6A">
                            Cek jika ingin menambah sub kategori baru.
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container spacing={2} marginTop={1}>
                    {isSubBaru ? (
                        <>
                            <Grid item xs={12} md={4}>
                                <FormControl fullWidth disabled={isSubBaru}>
                                    <Controller
                                        control={control}
                                        name="kategoriId"
                                        render={({
                                            field: { onChange, value },
                                        }) => (
                                            <FormControl fullWidth>
                                                <InputLabel>
                                                    Pilih Kategori
                                                </InputLabel>
                                                <Select
                                                    label="Pilih Kategori"
                                                    margin="dense"
                                                    onChange={(event) => {
                                                        onChange(event);
                                                        if (event) {
                                                            setParamsSubKategori(
                                                                (prev) => ({
                                                                    ...prev,
                                                                    kategoriId:
                                                                        undefined,
                                                                }),
                                                            );
                                                        } else {
                                                            return;
                                                        }
                                                        setValue(
                                                            "subId",
                                                            undefined,
                                                        );
                                                    }}
                                                    value={value}
                                                    error={Boolean(
                                                        errors.kategoriId,
                                                    )}
                                                >
                                                    {kategori &&
                                                    kategori.totalElements >
                                                        0 ? (
                                                        kategori.content.map(
                                                            (k) => (
                                                                <MenuItem
                                                                    key={k.id}
                                                                    value={k.id}
                                                                >
                                                                    {k.nama}
                                                                </MenuItem>
                                                            ),
                                                        )
                                                    ) : (
                                                        <MenuItem value="">
                                                            Tidak ada kategori
                                                        </MenuItem>
                                                    )}
                                                </Select>
                                            </FormControl>
                                        )}
                                    />
                                    <FormHelperText
                                        error={Boolean(errors.kategoriId)}
                                    >
                                        {errors.kategoriId
                                            ? errors.kategoriId.message
                                            : " "}
                                    </FormHelperText>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Controller
                                    name="subBaru"
                                    control={control}
                                    render={({ field }) => (
                                        <TextField
                                            // margin="dense"
                                            id="subBaru"
                                            label="Sub Kategori Baru"
                                            fullWidth
                                            variant="outlined"
                                            error={Boolean(errors.subBaru)}
                                            helperText={
                                                errors.subBaru
                                                    ? errors.subBaru.message
                                                    : " "
                                            }
                                            {...field}
                                        />
                                    )}
                                    rules={{
                                        required: "Sub kategori baru required",
                                    }}
                                />
                            </Grid>
                        </>
                    ) : (
                        <>
                            <Grid item xs={12} md={4}>
                                <FormControl fullWidth disabled={isSubBaru}>
                                    <Controller
                                        control={control}
                                        name="kategoriId"
                                        render={({
                                            field: { onChange, value },
                                        }) => (
                                            <FormControl fullWidth>
                                                <InputLabel>
                                                    Pilih Kategori
                                                </InputLabel>
                                                <Select
                                                    label="Pilih Kategori"
                                                    margin="dense"
                                                    onChange={(event) => {
                                                        onChange(event);
                                                        if (event) {
                                                            setParamsSubKategori(
                                                                (prev) => ({
                                                                    ...prev,
                                                                    kategoriId:
                                                                        event
                                                                            .target
                                                                            .value,
                                                                }),
                                                            );
                                                        } else {
                                                            return;
                                                        }
                                                        setValue(
                                                            "subId",
                                                            undefined,
                                                        );
                                                    }}
                                                    value={value}
                                                    error={Boolean(
                                                        errors.kategoriId,
                                                    )}
                                                >
                                                    {kategori &&
                                                    kategori.totalElements >
                                                        0 ? (
                                                        kategori.content.map(
                                                            (k) => (
                                                                <MenuItem
                                                                    key={k.id}
                                                                    value={k.id}
                                                                >
                                                                    {k.nama}
                                                                </MenuItem>
                                                            ),
                                                        )
                                                    ) : (
                                                        <MenuItem value="">
                                                            Tidak ada kategori
                                                        </MenuItem>
                                                    )}
                                                </Select>
                                            </FormControl>
                                        )}
                                    />
                                    <FormHelperText
                                        error={Boolean(errors.kategoriId)}
                                    >
                                        {errors.kategoriId
                                            ? errors.kategoriId.message
                                            : " "}
                                    </FormHelperText>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <FormControl fullWidth disabled={isSubBaru}>
                                    <Controller
                                        control={control}
                                        name="subId"
                                        render={({
                                            field: { onChange, value },
                                        }) => (
                                            <FormControl fullWidth>
                                                <InputLabel>
                                                    Pilih Sub Kategori
                                                </InputLabel>
                                                <Select
                                                    label="Pilih Sub Kategori"
                                                    margin="dense"
                                                    onChange={onChange}
                                                    value={value}
                                                    error={Boolean(
                                                        errors.subId,
                                                    )}
                                                >
                                                    {subKategori &&
                                                    subKategori.totalElements >
                                                        0 ? (
                                                        subKategori.content.map(
                                                            (k) => (
                                                                <MenuItem
                                                                    key={k.id}
                                                                    value={k.id}
                                                                >
                                                                    {k.nama}
                                                                </MenuItem>
                                                            ),
                                                        )
                                                    ) : (
                                                        <MenuItem value="">
                                                            {ukm !== null &&
                                                            ukm !== undefined
                                                                ? "Tidak ada sub kategori"
                                                                : "Pilih UKM terlebih dulu"}
                                                        </MenuItem>
                                                    )}
                                                </Select>
                                            </FormControl>
                                        )}
                                    />
                                    <FormHelperText
                                        error={Boolean(errors.subId)}
                                    >
                                        {errors.subId
                                            ? errors.subId.message
                                            : " "}
                                    </FormHelperText>
                                </FormControl>
                            </Grid>
                        </>
                    )}
                </Grid>
                <Grid container alignItems="center" mb={3}>
                    <Grid item xs={5} md={5}>
                        <Typography variant="body1">Favorit</Typography>
                    </Grid>
                    <Grid item>
                        <Controller
                            name="favorite"
                            control={control}
                            render={({ field }) => (
                                <FormGroup>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={field.value}
                                                onChange={field.onChange}
                                                sx={{
                                                    "& .MuiSwitch-thumb": {
                                                        color: "#eaeaea",
                                                    },
                                                }}
                                            />
                                        }
                                        label={
                                            field.value
                                                ? "Favorit"
                                                : "Tidak Favorit"
                                        }
                                        componentsProps={{
                                            typography: {
                                                variant: "body2",
                                            },
                                        }}
                                    />
                                </FormGroup>
                            )}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="body2" color="#545E6A">
                            Jika mengaktifkan favorit akan membuat tampilan
                            barang berada di paling awal.
                        </Typography>
                    </Grid>
                </Grid>
                <Box
                    mt={4}
                    display="grid"
                    gridTemplateColumns="1fr 1fr 1fr"
                    justifyContent="flex-end"
                    columnGap="16px"
                >
                    <div />
                    <Button onClick={onBack} variant="outlined">
                        Kembali
                    </Button>
                    <LoadingButton
                        loading={isButtonLoading}
                        loadingPosition="center"
                        variant="contained"
                        onClick={handleSubmit(onSubmit)}
                    >
                        Simpan
                    </LoadingButton>
                </Box>
            </form>
        </Box>
    );
};

export default DetailItemStep;
