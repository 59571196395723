import {
    Dialog,
    DialogTitle,
    Stack,
    IconButton,
    Typography,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button,
} from "@mui/material";
import React, { useCallback } from "react";
import { useHapusStokModal } from "../../context/hapusStokModalContext";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import axios from "../../services/axios";
import defaultAxios, { AxiosError } from "axios";
import Swal from "sweetalert2";

interface IModalDeleteStokProps {
    refetch: () => void;
}

const ModalDeleteStok = ({ refetch }: IModalDeleteStokProps) => {
    const { isOpenModalHapusStok, closeModalHapusStok, namaStok, idStok } =
        useHapusStokModal();

    const handleDelete = async (id: string | number | undefined) => {
        try {
            const { data } = await axios.delete(`/api/stokhapus/${id}`);
            if (data.code === 200) {
                Swal.fire({
                    title: namaStok + " berhasil dihapus!",
                    position: "top-end",
                    showConfirmButton: false,
                    icon: "success",
                    toast: true,
                    timer: 3000,
                    timerProgressBar: true,
                    showCloseButton: true,
                    customClass: {
                        container: "my-swal",
                    },
                });
                refetch();
            }
            closeModalHapusStok();
        } catch (error) {
            handleErrorResponse(error);
        }
    };

    const handleErrorResponse = useCallback(
        (error) => {
            if (defaultAxios.isAxiosError(error)) {
                const serverError = error as AxiosError<any>;
                if (serverError && serverError.response) {
                    Swal.fire({
                        title: "Terjadi Kesalahan!",
                        text: `${serverError.response.data.message}`,
                        icon: "error",
                        confirmButtonColor: "#45A779",
                        customClass: {
                            container: "my-swal",
                        },
                    });
                    closeModalHapusStok();
                } else {
                    Swal.fire({
                        title: "Terjadi Kesalahan!",
                        text: `Telah Terjadi Kesalahan`,
                        icon: "error",
                        confirmButtonColor: "#45A779",
                        customClass: {
                            container: "my-swal",
                        },
                    });
                    closeModalHapusStok();
                }
            }
        },
        [closeModalHapusStok],
    );

    return (
        <Dialog
            maxWidth="xs"
            fullWidth={true}
            open={isOpenModalHapusStok}
            onClose={closeModalHapusStok}
            PaperProps={{
                sx: {
                    //   minHeight: "150px",
                    //   maxHeight: "150px",
                    borderRadius: 1,
                },
            }}
        >
            <DialogTitle>
                <Stack direction="row" spacing={1} alignItems="center">
                    <IconButton onClick={closeModalHapusStok}>
                        <ArrowBackIcon />
                    </IconButton>
                    <Typography variant="h6" fontWeight="bold">
                        Hapus Barang
                    </Typography>
                </Stack>
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Barang {namaStok} akan dihapus secara permanen. Yakin ingin
                    menghapus?
                </DialogContentText>
            </DialogContent>
            <DialogActions sx={{ padding: 2 }}>
                <Button
                    variant="outlined"
                    onClick={() => handleDelete(idStok)}
                    color="error"
                >
                    Hapus
                </Button>
                <Button
                    variant="contained"
                    onClick={closeModalHapusStok}
                    color="error"
                >
                    Tidak
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ModalDeleteStok;
