import React from "react";
import id from "date-fns/locale/id";
import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
// import moment from "moment";
import { Controller, useFormContext } from "react-hook-form";
import { FakturPembelianValues } from "../../../constants/types";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import toRupiah from "../../../utils/toRupiah";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import NumberFormat from "react-number-format";
import InputAdornment from "@mui/material/InputAdornment";
import Grid from "@mui/material/Grid";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import ArrowDropDown from "@mui/icons-material/ArrowDropDown";

registerLocale("id", id);
setDefaultLocale("id");

interface IBarangTerpilihProps {
    id: number;
    nama: string;
    sku: string;
    idx: number;
    hargaJual: number;
    hargaBeli: number | null;
}

const BarangTerpilih = ({
    id,
    nama,
    sku,
    idx,
    hargaJual,
    hargaBeli,
}: IBarangTerpilihProps) => {
    const today = new Date();
    const { control, setValue, register, watch } =
        useFormContext<FakturPembelianValues>();
    const isTanggalExp = watch(`detail.${idx}.isTanggalExp`);

    return (
        <Box
            className="detail-bundling__field-array"
            alignItems="end"
            key={String(id)}
        >
            <Box>
                <Typography fontWeight="bold">{`${
                    idx + 1
                }. ${nama} (${sku})`}</Typography>
                <Typography>
                    Harga jual: {toRupiah(hargaJual)}
                    {typeof hargaBeli === "number" &&
                        ` | Harga beli terakhir: ${toRupiah(hargaBeli)}`}
                </Typography>
                <Box width="100%">
                    <Stack direction="row" spacing={1} marginTop={1}>
                        <React.Fragment>
                            <Controller
                                name={`detail.${idx}.jumlah`}
                                control={control}
                                render={({ field, fieldState }) => (
                                    <TextField
                                        type="number"
                                        variant="outlined"
                                        placeholder="Jumlah Stok"
                                        size="small"
                                        id={`detail.${idx}.jumlahStok`}
                                        error={!!fieldState?.error}
                                        helperText={
                                            fieldState?.error &&
                                            fieldState?.error.message
                                        }
                                        {...field}
                                    />
                                )}
                            />
                            <input
                                type="hidden"
                                {...register(`detail.${idx}.stokId`)}
                                value={id}
                            />
                        </React.Fragment>
                        <Stack direction="row" alignItems="center" spacing={1}>
                            <Typography>@</Typography>
                            <Box>
                                <Controller
                                    name={`detail.${idx}.hargaBeli`}
                                    control={control}
                                    render={({ field, fieldState }) => (
                                        <NumberFormat
                                            customInput={TextField}
                                            label="Harga Beli (satuan)"
                                            // margin="dense"
                                            thousandSeparator="."
                                            decimalSeparator=","
                                            size="small"
                                            fullWidth
                                            variant="outlined"
                                            onValueChange={(values) => {
                                                const { value } = values;
                                                const valueAkhir =
                                                    value.replace(".", "");
                                                console.log(valueAkhir);
                                                setValue(
                                                    `detail.${idx}.hargaBeli`,
                                                    Number(valueAkhir),
                                                );
                                            }}
                                            error={!!fieldState?.error}
                                            helperText={
                                                fieldState?.error &&
                                                fieldState?.error.message
                                            }
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <Typography>
                                                            Rp.
                                                        </Typography>
                                                    </InputAdornment>
                                                ),
                                            }}
                                            {...field}
                                        />
                                    )}
                                />
                            </Box>
                        </Stack>
                    </Stack>
                </Box>
                <Grid container alignItems="center" spacing={1} marginTop={1}>
                    <Grid item>
                        <Controller
                            name={`detail.${idx}.isTanggalExp`}
                            control={control}
                            render={({ field }) => (
                                <FormGroup>
                                    <FormControlLabel
                                        control={<Checkbox {...field} />}
                                        label="Centang jika ingin beri kadaluarsa"
                                        componentsProps={{
                                            typography: {
                                                variant: "body2",
                                            },
                                        }}
                                    />
                                </FormGroup>
                            )}
                        />
                    </Grid>
                    {isTanggalExp && (
                        <Grid item>
                            <Controller
                                name={`detail.${idx}.tanggalExp`}
                                control={control}
                                render={({ field }) => (
                                    <DatePicker
                                        className="range-filter"
                                        dateFormat="dd/MM/yyyy"
                                        popperPlacement="top-start"
                                        popperModifiers={[
                                            {
                                                name: "offset",
                                                options: {
                                                    offset: [0, 200],
                                                },
                                            },
                                            {
                                                name: "preventOverflow",
                                                options: {
                                                    rootBoundary: "viewport",
                                                    tether: false,
                                                    altAxis: true,
                                                },
                                            },
                                        ]}
                                        disabledKeyboardNavigation
                                        onFocus={(e) => e.target.blur()}
                                        startDate={field.value}
                                        onChange={(update) => {
                                            field.onChange(update);
                                        }}
                                        minDate={today}
                                        monthsShown={1}
                                        customInput={
                                            <Stack
                                                display="flex"
                                                justifyContent="space-between"
                                                direction="row"
                                                // width="100%"
                                                bgcolor="#FFFF"
                                                height="40px"
                                                sx={{
                                                    border: "1px solid #cbcbcb",
                                                    borderRadius: "4px",
                                                    alignItems: "center",
                                                    padding: 2,
                                                    cursor: "pointer",
                                                }}
                                            >
                                                <Typography>
                                                    {field.value?.toLocaleString(
                                                        "id",
                                                        {
                                                            year: "numeric",
                                                            month: "long",
                                                            day: "numeric",
                                                        },
                                                    )}
                                                </Typography>
                                                <ArrowDropDown
                                                    sx={{
                                                        marginRight: "-9px",
                                                    }}
                                                />
                                            </Stack>
                                        }
                                    />
                                )}
                            />
                        </Grid>
                    )}
                </Grid>
                <Controller
                    name={`detail.${idx}.keterangan`}
                    control={control}
                    render={({ field, fieldState }) => (
                        <TextField
                            variant="outlined"
                            placeholder="Keterangan"
                            size="small"
                            id="keterangan"
                            fullWidth
                            multiline
                            rows={3}
                            error={!!fieldState?.error}
                            helperText={
                                fieldState?.error && fieldState?.error.message
                            }
                            sx={{ marginTop: 1 }}
                            {...field}
                        />
                    )}
                />
            </Box>
        </Box>
    );
};

export default BarangTerpilih;
