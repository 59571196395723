import React, { useEffect, useMemo, useState } from "react";
import {
    // IOptions,
    // ParamsGetSupplier,
    SelectedProductPreOrder,
} from "../../../constants/types";
import * as yup from "yup";
import { useForm, FormProvider, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTransaksi } from "../../../context/transaksiContext";
// import useSupplier from "../../../services/queries/useSupplier";
import { useAuth } from "../../../context/authContext";
import { addDays } from "../../../utils/addDate";
import moment from "moment";
import formatHarga from "../../../utils/formatHarga";
import axios from "../../../services/axios";
import Swal from "sweetalert2";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import id from "date-fns/locale/id";
import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import Stack from "@mui/material/Stack";
import ArrowDropDown from "@mui/icons-material/ArrowDropDown";
// import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import toRupiahDesimal from "../../../utils/toRupiahDesimal";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import ItemTerpilih from "../ItemTerpilih/ItemTerpilih";

registerLocale("id", id);
setDefaultLocale("id");

interface IDetailItemStepProps {
    products: SelectedProductPreOrder[];
    onBack: () => void;
    open: boolean;
    onClose: () => void;
}

interface IValues {
    ukmId?: number;
    isKodeFaktur: boolean;
    kodeFaktur: string;
    tanggal?: Date | null;
    supplier?: {
        id: number;
        nama: string;
    };
    detail: {
        isTanggalExp: boolean;
        tanggalExp?: Date | null;
        stokPusat?: {
            id: number;
            nama: string;
            harga: string;
        };
        stokId: number;
        hargaBeli: string | number;
        jumlah: string | number;
        keterangan: string;
        totalHarga: string | number;
    }[];
}

const schema = yup.object().shape({
    isKodeFaktur: yup.boolean(),
    kodeFaktur: yup
        .string()
        .nullable()
        .when("isKodeFaktur", {
            is: true,
            then: yup.string().required("Kolom wajib diisi"),
        }),
    // supplier: yup
    //     .object()
    //     .shape({
    //         id: yup.string().required("Kolom wajib diisi"),
    //         nama: yup.string().required("Kolom wajib diisi"),
    //     })
    //     .required("Kolom wajib diisi"),
    detail: yup.array().of(
        yup.object().shape({
            stokPusat: yup
                .object()
                .shape({
                    id: yup.string().required("Kolom wajib diisi"),
                    nama: yup.string().required("Kolom wajib diisi"),
                    harga: yup.string().required("Kolom wajib diisi"),
                })
                .required("Kolom wajib diisi"),
            stokId: yup.number().nullable(),
            hargaBeli: yup.string().required("Kolom wajib diisi"),
            jumlah: yup.string().required("Kolom wajib diisi"),
            keterangan: yup.string().nullable(),
        }),
    ),
});

const DetailItemStep = ({
    products,
    onBack,
    open,
    onClose,
}: IDetailItemStepProps) => {
    const { ukmIdUser } = useAuth();
    const today = new Date();
    const [isButtonLoading, setIsButtonLoading] = useState(false);
    const { setFetchingItems } = useTransaksi();
    // const initialParamsSupplier = {
    //     size: 300,
    //     page: 1,
    //     search: "",
    //     kategori: "",
    // };
    // const [paramsSupplier, setParamsSupplier] = useState<ParamsGetSupplier>(
    //     initialParamsSupplier,
    // );
    // const { data: supplier, isLoading: isLoadingSupplier } =
    //     useSupplier(paramsSupplier);
    // const mappedSupplier = supplier?.content.map((li) => ({
    //     id: li.id,
    //     nama: li.nama,
    // }));

    const initialValues: IValues = useMemo(
        () => ({
            ukmId: ukmIdUser && ukmIdUser[0],
            isKodeFaktur: false,
            kodeFaktur: "",
            tanggal: new Date(),
            detail: products.map((li) => ({
                isTanggalExp: false,
                tanggalExp: addDays(7),
                stokId: li.id,
                hargaBeli: 0,
                jumlah: 1,
                keterangan: "",
                totalHarga: 0,
            })) ?? [
                {
                    isTanggalExp: false,
                    tanggalExp: null,
                    stokId: 1,
                    hargaBeli: 0,
                    jumlah: 1,
                    keterangan: "",
                    totalHarga: 0,
                },
            ],
        }),
        [products, ukmIdUser],
    );

    const methods = useForm<IValues>({
        resolver: yupResolver(schema),
        defaultValues: initialValues,
    });

    const isKodeFaktur = methods.watch("isKodeFaktur");
    const watchDetail = methods.watch("detail");
    const totalFaktur = watchDetail
        .map((li) => Number(String(li.totalHarga)))
        .reduce(
            (accumulator, currentValue) => accumulator + Number(currentValue),
            0,
        );

    useEffect(() => console.log("totalFaktur", totalFaktur), [totalFaktur]);

    const onSubmit = async (values: IValues) => {
        // alert(JSON.stringify(values));
        setIsButtonLoading(true);
        try {
            const body = {
                ukmId: values.ukmId,
                // suplierId: values.supplier ? values.supplier.id : undefined,
                tanggal: values.tanggal
                    ? moment(values.tanggal).format("DD/MM/YYYY")
                    : null,
                kodeFaktur: values.isKodeFaktur ? values.kodeFaktur : null,
                detail: values.detail.map((li) => ({
                    tanggalExp: li.isTanggalExp
                        ? moment(li.tanggalExp).format("DD/MM/YYYY")
                        : undefined,
                    stokPusatId: Number(li.stokPusat?.id),
                    stokId: li.stokId,
                    hargaBeli: formatHarga(String(li.hargaBeli)),
                    jumlah: Number(li.jumlah),
                    keterangan: li.keterangan,
                })),
            };
            console.log("body", body);
            const { data } = await axios.post(`/api/po`, body);
            if (data.code === 200) {
                Swal.fire({
                    title: "Pre order dibuat",
                    position: "top-end",
                    showConfirmButton: false,
                    icon: "success",
                    toast: true,
                    timer: 3000,
                    timerProgressBar: true,
                    showCloseButton: true,
                    customClass: {
                        container: "my-swal",
                    },
                });
                onClose();
                setFetchingItems();
            }
            setIsButtonLoading(false);
        } catch (error) {
            setIsButtonLoading(false);
            console.error(error);
        }
    };

    useEffect(() => {
        if (open) {
            methods.reset(initialValues);
        }
    }, [initialValues, open, methods]);

    return (
        <React.Fragment>
            <Grid container alignItems="start" spacing={1} marginBottom={1}>
                <Grid item xs={12} sm={6} md={5}>
                    <Typography fontWeight={500} component="div">
                        Tanggal Transaksi:
                    </Typography>
                    <Controller
                        name="tanggal"
                        control={methods.control}
                        render={({ field }) => (
                            <DatePicker
                                className="range-filter"
                                dateFormat="dd/MM/yyyy"
                                disabledKeyboardNavigation
                                onFocus={(e) => e.target.blur()}
                                startDate={field.value}
                                onChange={(update) => {
                                    field.onChange(update);
                                }}
                                minDate={today}
                                monthsShown={1}
                                customInput={
                                    <Stack
                                        display="flex"
                                        justifyContent="space-between"
                                        direction="row"
                                        width="100%"
                                        bgcolor="#FFFF"
                                        height="40px"
                                        sx={{
                                            border: "1px solid #cbcbcb",
                                            borderRadius: "4px",
                                            alignItems: "center",
                                            padding: 2,
                                            cursor: "pointer",
                                        }}
                                    >
                                        <Typography>
                                            {field.value?.toLocaleString("id", {
                                                year: "numeric",
                                                month: "long",
                                                day: "numeric",
                                            })}
                                        </Typography>
                                        <ArrowDropDown
                                            sx={{
                                                marginRight: "-9px",
                                            }}
                                        />
                                    </Stack>
                                }
                            />
                        )}
                    />
                </Grid>
                {/* <Grid item xs={12} sm={6} md={5}>
                    <Typography fontWeight={500} component="div">
                        Supplier:
                    </Typography>
                    <Controller
                        name="supplier"
                        control={methods.control}
                        render={({ field, fieldState }) => (
                            <Autocomplete
                                disablePortal
                                loading={isLoadingSupplier}
                                options={mappedSupplier ?? []}
                                getOptionLabel={(option) => option.nama}
                                isOptionEqualToValue={(option, value) =>
                                    option.id === value.id
                                }
                                size="small"
                                value={field.value}
                                onInputChange={(e) => {
                                    if (e) {
                                        const element =
                                            e.target as HTMLInputElement;
                                        setParamsSupplier((prev) => ({
                                            ...prev,
                                            search: element.value,
                                        }));
                                    } else {
                                        return;
                                    }
                                }}
                                onChange={(
                                    _event,
                                    newSupplier: IOptions | null,
                                ) => {
                                    field.onChange(newSupplier);
                                }}
                                fullWidth
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        placeholder="Pilih Supplier"
                                        error={!!fieldState?.error}
                                        helperText={
                                            fieldState?.error &&
                                            "Pilih satu supplier"
                                        }
                                    />
                                )}
                            />
                        )}
                    />
                </Grid> */}
            </Grid>
            <Grid container alignItems="center">
                <Grid item xs={6} sm={3}>
                    <Typography>Beri kode faktur manual?</Typography>
                </Grid>
                <Grid item xs={6} sm={3}>
                    <Controller
                        name="isKodeFaktur"
                        control={methods.control}
                        render={({ field }) => (
                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={field.value}
                                            onChange={field.onChange}
                                            sx={{
                                                "& .MuiSwitch-thumb": {
                                                    color: "#eaeaea",
                                                },
                                            }}
                                        />
                                    }
                                    label={field.value ? "Ya" : "Tidak"}
                                    componentsProps={{
                                        typography: {
                                            variant: "body2",
                                        },
                                    }}
                                />
                            </FormGroup>
                        )}
                    />
                </Grid>
                {isKodeFaktur && (
                    <Grid item xs={12}>
                        <Controller
                            name="kodeFaktur"
                            control={methods.control}
                            render={({ field, fieldState }) => (
                                <TextField
                                    variant="outlined"
                                    placeholder="Kode Faktur"
                                    size="small"
                                    id="kodeFaktur"
                                    error={!!fieldState?.error}
                                    helperText={
                                        fieldState?.error &&
                                        fieldState?.error.message
                                    }
                                    {...field}
                                />
                            )}
                        />
                    </Grid>
                )}
            </Grid>
            <Stack mt={2} mb={5} direction="column" spacing={2}>
                {products &&
                    products?.map((li, idx) => (
                        <FormProvider key={idx.toString()} {...methods}>
                            <ItemTerpilih
                                key={String(li.id)}
                                id={li.id}
                                nama={li.nama}
                                sku={li.sku}
                                idx={idx}
                            />
                        </FormProvider>
                    ))}
            </Stack>
            <Divider />
            <Box sx={{ paddingY: 3 }}>
                <Grid
                    container
                    spacing={2}
                    alignItems="flex-start"
                    marginBottom={2}
                >
                    <Grid item xs={6} sm={3}>
                        <Typography fontWeight={500} component="div">
                            Total Keseluruhan:
                        </Typography>
                        <Typography>
                            {toRupiahDesimal(Number(totalFaktur))}
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
            <Box
                mt={4}
                display="grid"
                gridTemplateColumns="1fr 1fr 1fr"
                justifyContent="flex-end"
                columnGap="16px"
            >
                <div />
                <Button onClick={onBack} variant="outlined">
                    Kembali
                </Button>
                <LoadingButton
                    loading={isButtonLoading}
                    loadingPosition="center"
                    variant="contained"
                    onClick={methods.handleSubmit(onSubmit)}
                >
                    Simpan
                </LoadingButton>
            </Box>
        </React.Fragment>
    );
};

export default DetailItemStep;
