import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import React, { useEffect, useState } from "react";
import { SelectedProductFaktur } from "../../constants/types";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Typography from "@mui/material/Typography";
import DialogContent from "@mui/material/DialogContent";
import Grid from "@mui/material/Grid";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import PilihBarangStep from "./PilihBarangStep/PilihBarangStep";
import DetailBarangStep from "./DetailBarangStep/DetailBarangStep";

interface IModalFakturPembayaranProps {
    isOpenModalFakturPembelian: boolean;
    closeModalFakturPembelian: () => void;
}

const ModalFakturPembayaran = ({
    isOpenModalFakturPembelian,
    closeModalFakturPembelian,
}: IModalFakturPembayaranProps) => {
    const theme = useTheme();
    const isPhoneScreen = useMediaQuery(theme.breakpoints.between("xs", "sm"));
    const [activeStep, setActiveStep] = useState(0);
    const [products, setProducts] = useState<SelectedProductFaktur[]>([]);

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        if (activeStep === 1) {
            setProducts([]);
        }
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const addProducts = (item: SelectedProductFaktur[]) => {
        setProducts(item);
    };

    const resetBundlingSteps = () => {
        setActiveStep(0);
        setProducts([]);
    };

    const steps = [
        {
            label: "Pilih produk",
            description: `Anda dapat memilih produk lebih dari satu yang ingin anda masukkan ke dalam faktur.`,
        },
        {
            label: "Atur faktur pembelian",
            description:
                "Atur faktur pembelian untuk setiap stok barang yang anda pilih.",
        },
    ];

    useEffect(() => {
        if (!isOpenModalFakturPembelian) {
            resetBundlingSteps();
        }
    }, [isOpenModalFakturPembelian]);

    return (
        <Dialog
            maxWidth="md"
            fullScreen={isPhoneScreen}
            fullWidth={true}
            open={isOpenModalFakturPembelian}
            onClose={closeModalFakturPembelian}
            scroll="body"
            PaperProps={{
                sx: {
                    borderRadius: 1,
                },
            }}
        >
            <DialogTitle>
                <Stack direction="row" spacing={1} alignItems="center">
                    <IconButton onClick={closeModalFakturPembelian}>
                        <ArrowBackIcon />
                    </IconButton>
                    <Typography variant="h6" fontWeight="bold">
                        Faktur Pembelian
                    </Typography>
                </Stack>
            </DialogTitle>
            <DialogContent dividers>
                <React.Fragment>
                    <Grid>
                        <Stepper activeStep={activeStep} orientation="vertical">
                            {steps.map((step, index) => (
                                <Step key={String(index + 1)}>
                                    <StepLabel>{step.label}</StepLabel>
                                    <StepContent>
                                        <Typography color="GrayText">
                                            {step.description}
                                        </Typography>
                                        <Box my={4}>
                                            {index === 0 && (
                                                <React.Fragment>
                                                    <PilihBarangStep
                                                        addProducts={
                                                            addProducts
                                                        }
                                                    />
                                                    <Box
                                                        mt={4}
                                                        display="grid"
                                                        gridTemplateColumns="1fr 1fr 1fr"
                                                        justifyContent="flex-end"
                                                        columnGap="16px"
                                                    >
                                                        <div />
                                                        <Button
                                                            disabled={
                                                                index === 0
                                                            }
                                                            onClick={handleBack}
                                                            variant="outlined"
                                                        >
                                                            Kembali
                                                        </Button>
                                                        <Button
                                                            variant="contained"
                                                            disabled={
                                                                activeStep ===
                                                                    0 &&
                                                                products.length ===
                                                                    0
                                                            }
                                                            onClick={handleNext}
                                                        >
                                                            Lanjutkan
                                                        </Button>
                                                    </Box>
                                                </React.Fragment>
                                            )}
                                            {index === 1 && (
                                                <DetailBarangStep
                                                    products={products}
                                                    onBack={handleBack}
                                                    isOpenModalFakturPembelian={
                                                        isOpenModalFakturPembelian
                                                    }
                                                    closeModalFakturPembelian={
                                                        closeModalFakturPembelian
                                                    }
                                                />
                                            )}
                                        </Box>
                                    </StepContent>
                                </Step>
                            ))}
                        </Stepper>
                    </Grid>
                </React.Fragment>
            </DialogContent>
        </Dialog>
    );
};

export default ModalFakturPembayaran;
